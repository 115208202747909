import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isBoolean, without } from 'lodash';
import { PlayerLayerContext } from '../../contexts/PlayerLayer.context';
import { translate as t } from '../../helpers/translate';
import {
    ApiError, PlayerBaseData, PlayerReport, PlayerReportService, ReportParams, SideBarService, UserPlayerReport
} from '../../services/openapi';
import PlayerDetails from './PlayerDetails/PlayerDetails';
import ReportLayer from './ReportLayer/ReportLayer';
import { ReportActionType } from '../../interfaces/carried-on.interfaces';
import { FradiLoader } from '../FradiLoader/FradiLoader';
import { Toastify } from '../Toastify/Toastify';
import { FILE, cx } from '../../helpers/utility';
import { toastError } from '../../helpers/errorhandler';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import useAuth from '../../hooks/auth.hook';
import ListButton from '../ListButtons/ListButton';
import PlayerListButton from '../PlayerListButton/PlayerListButton';
import { PlayerReportRequestType } from '../../interfaces/reports.interface';
import css from './PageLayer.module.scss';
import IconBack from '../../../assets/images/icons/chevron-left.svg';

interface ComponentProps {
    isNormalView?: boolean,
    selectedReportId?: number,
}

const PageLayer = function({
    isNormalView,
    selectedReportId,
}: ComponentProps): ReactElement {

    const { requestParams } = useAuth();
    const navigate = useNavigate();
    const layerContext = useContext(PlayerLayerContext);
    const [loadings, setLoadings] = useState<string[]>([]);
    const [selectedReport, setSelectedReport] = useState<PlayerReport>();
    const [playerBaseData, setPlayerBaseData] = useState<PlayerBaseData>();
    const [reportList, setReportList] = useState<UserPlayerReport[]>([]);
    const [reportConfig, setReportConfig] = useState<ReportParams>();
    const [latestReport, setLatestReport] = useState<PlayerReport>();
    const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);
    const [playerIsFollowed, setPlayerIsFollowed] = useState<boolean>(false);
    const [playerInShortList, setPlayerInShortList] = useState<boolean>(false);
    const [needConfirm, setNeedConfirm] = useState<boolean>(false);

    const actionOfReport = useCallback(
        (reportId: number | boolean, actionType: ReportActionType): Promise<void> => {
            if ((actionType === ReportActionType.VIEW || actionType === ReportActionType.ADD) && requestParams.UserID) {
                setLoadings(l => [...l, 'selectedReport']);
                return PlayerReportService.playerReportCreate({
                    ...requestParams,
                    ReportID: isBoolean(reportId) ? undefined : +reportId
                }).finally(
                    () => setLoadings(l => without(l, 'selectedReport'))
                ).then(
                    (report) => {
                        setSelectedReport(report);
                    }
                ).catch(
                    toastError
                );
            }
            else if (actionType === ReportActionType.DOWNLOAD && requestParams.UserID) {
                setLoadings(l => [...l, 'downloadReport']);
                return PlayerReportService.downloadPlayerReportBase64Create({
                    ...requestParams,
                    PlayerID: playerBaseData?.PlayerID,
                    ReportID: isBoolean(reportId) ? undefined : +reportId
                }).finally(
                    () => setLoadings(l => without(l, 'downloadReport'))
                ).then(
                    (fileData) => {
                        const blob = FILE.base64ToBlob(fileData.FileBase64);
                        FILE.download(blob, fileData.filename);
                    }
                ).catch(
                    toastError
                );
            }
            else if (actionType === ReportActionType.DELETE && requestParams.UserID) {
                setLoadings(l => [...l, 'deleteReport']);
                return PlayerReportService.deletePlayerReportCreate({
                    ...requestParams,
                    ReportID: isBoolean(reportId) ? undefined : +reportId
                }).finally(
                    () => setLoadings(l => without(l, 'deleteReport'))
                ).then(
                    () => {
                        return;
                    }
                ).catch(
                    toastError
                );
            }
            else {
                return Promise.resolve();
            }
        },
        [playerBaseData?.PlayerID, requestParams]
    );

    const loadReportList = useCallback(
        (playerId: number) => {
            if (requestParams.UserID) {
                PlayerReportService.playerReportListCreate({
                    ...requestParams,
                    PlayerID: playerId
                }).then(
                    resp => {
                        setReportList(resp.PlayerReports ?? []);
                    }
                ).catch(
                    (error: ApiError) => {
                        setReportList([]);
                        toastError(error);
                    }
                );
            }
        },
        [requestParams]
    );

    const closePageLayer = useCallback(
        () => {
            if (!layerContext.context) {
                return;
            }
            layerContext.setContext({
                ...layerContext.context,
                isModalOpen: false
            });
            if (layerContext.context?.closeCallback) {
                layerContext.context.closeCallback();
            }
        },
        [layerContext]
    );

    const loadLatestReport = useCallback(
        (playerId: number | undefined) => {
            if (requestParams.UserID && playerId) {
                PlayerReportService.latestReportForPlayerCreate({
                    ...requestParams,
                    PlayerID: playerId
                }).then(resp => {
                    setLatestReport(resp);
                }).catch((e) => {
                    setLatestReport(undefined);
                    toastError(e);
                });
            }
        },
        [requestParams]
    );

    useEffect(
        () => {
            if (layerContext.context?.playerId && requestParams.UserID) {
                setLoadings(l => [...l, 'baseData', 'reportConfig']);
                Promise.all([
                    PlayerReportService.playerBaseDataCreate({
                        ...requestParams,
                        PlayerID: layerContext.context.playerId
                    }),
                    PlayerReportService.reportParamsCreate(
                        requestParams
                    )
                ]).finally(
                    () => setLoadings(l => without(l, 'baseData', 'reportConfig'))
                ).then(
                    ([baseData, reportConfig]) => {
                        setPlayerBaseData(baseData);
                        setReportConfig(reportConfig);
                        setPlayerIsFollowed(baseData.FollowedFlg);
                        setPlayerInShortList(baseData.InShortlistFlg);
                    }
                ).catch(
                    toastError
                );
                loadReportList(layerContext.context.playerId);
                loadLatestReport(layerContext.context.playerId);
            }
        },
        [layerContext.context?.playerId, loadLatestReport, loadReportList, requestParams]
    );

    useEffect(
        () => {
            if (requestParams.UserID && selectedReportId) {
                actionOfReport(selectedReportId, ReportActionType.VIEW);
            }
            return () => {
                setSelectedReport(undefined);
            };
        },
        [actionOfReport, requestParams.UserID, selectedReportId]
    );

    useEffect(
        () => {
            const historyBackHandler = () => {
                closePageLayer();
            };

            window.addEventListener('popstate', historyBackHandler);

            return () => {
                window.removeEventListener('popstate', historyBackHandler);
            };
        },
        [closePageLayer]
    );

    function closeReportLayer() {
        setSelectedReport(undefined);
        if (layerContext.context?.fromPage) {
            navigate(-1);
        }
        else if (layerContext.context) {
            loadReportList(layerContext.context.playerId);
            loadLatestReport(layerContext.context.playerId);
        }
    }

    function saveReport(reportSheet: PlayerReportRequestType) {
        if (requestParams.UserID) {
            setLoadings(l => [...l, 'saveReport']);
            PlayerReportService.savePlayerReportCreate({
                ...requestParams,
                PlayerReport: {
                    ...reportSheet,
                    PlayerID: playerBaseData?.PlayerID,
                    PlayerPositionID: reportSheet.PlayerPositionID ?? 0
                }
            }).finally(
                () => setLoadings(l => without(l, 'saveReport'))
            ).then((_savedReport) => {
                Toastify({
                    titleKey: 'general.message.save',
                }).success();
                closeReportLayer();
            }).catch(
                toastError
            );
        }
    }

    function navigateToPlayerDetails(reportId?: number) {
        setSelectedReport(undefined);
        navigate('/scouting/player/' + playerBaseData?.PlayerID + (reportId ? '/' + reportId : ''));
    }

    function navigateToCompare() {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isModalOpen: false
            });
        }
        navigate(`/scouting/compare/${playerBaseData?.PlayerID}`);
    }

    function backToPlayerDetails() {
        if (layerContext.context?.fromPage) {
            navigate(-1);
        }
        else {
            closeReportLayer();
            if (selectedReportId) {
                navigateToPlayerDetails();
            }
        }
    }

    function followPlayer() {
        if (layerContext.context && requestParams.UserID) {
            const EndpointRequest = playerIsFollowed
                ? SideBarService.unFollowPlayerCreate
                : SideBarService.followPlayerCreate;
            setLoadings(l => [...l, 'followPlayer']);
            EndpointRequest({
                ...requestParams,
                PlayerID: layerContext.context.playerId
            }).finally(() =>
                setLoadings(l => without(l, 'followPlayer'))
            ).then(_resp => {
                setPlayerIsFollowed(
                    current => !current
                );
            }).catch(
                toastError
            );
        }
    }

    function cancelReport() {
        if (needConfirm && !selectedReport?.ReadOnlyFlg) {
            setIsCancelModalVisible(true);
        }
        else {
            backToPlayerDetails();
        }
    }

    return <>
        {(layerContext.context?.isModalOpen || isNormalView) && (
            <div className={cx(css.pageLayerBox, isNormalView ? css.normalView : null)}>
                <div className={css.pageLayerHeader}>
                    {!selectedReport && <>
                        <a className={css.linkify} onClick={closePageLayer}>
                            <img src={IconBack} alt={t('a18y.back')}/>
                            <span>{t('general.button.back')}</span>
                        </a>
                        <h1 className={css.headerTitle}>{t('playerDetails.title')}</h1>
                        <div className={css.actions}>
                            {/*
                            {!isNormalView && (
                                <button className="btn btn-primary btn-outline" onClick={() => navigateToPlayerDetails()}>
                                    <img src={iconEye} alt=""/>
                                </button>
                            )}
                            */}
                            <button
                                className={cx('btn btn-primary', !playerIsFollowed && 'btn-outline')}
                                onClick={() => followPlayer()}
                            >
                                {t('playerDetails.action.follow')}
                            </button>
                            {playerBaseData && <>
                                <ListButton
                                    type="shortList"
                                    className={cx('btn', css.tallButton)}
                                    playerId={playerBaseData.PlayerID}
                                    playerInList={playerInShortList}
                                    onToggle={(inList) => setPlayerInShortList(inList!)}
                                />
                                <PlayerListButton
                                    className={cx('btn', css.tallButton)}
                                    playerId={playerBaseData.PlayerID}
                                />
                            </>}
                            <button className="btn btn-secondary btn-outline" onClick={navigateToCompare}>
                                {t('playerDetails.action.compare')}
                            </button>
                        </div>
                    </>}
                    {selectedReport && <>
                        <a className={css.linkify} onClick={() => cancelReport()}>
                            <img src={IconBack} alt={t('a18y.back')}/>
                            <span>{layerContext.context?.fromPage ? t('general.button.back') : t('general.button.backToPlayer')}</span>
                        </a>
                        <span className={css.headerTitle}>{t('playerReport.title.' + (!selectedReport.ReportID ? 'new' : 'edit'))}</span>
                        {/*
                        {!isNormalView &&
                            <button
                                className="btn btn-primary btn-outline"
                                onClick={() => navigateToPlayerDetails(selectedReport?.ReportID)}
                            >
                                <img src={iconEye} alt=""/>
                            </button>
                        }
                        */}
                    </>}
                </div>
                <div className={css.details}>
                    {!selectedReport && playerBaseData && reportConfig && (
                        <PlayerDetails
                            reportConfig={reportConfig}
                            reportList={reportList}
                            latestReport={latestReport}
                            playerBaseData={playerBaseData}
                            actionOfReport={actionOfReport}
                        />
                    )}
                    {selectedReport && playerBaseData && reportConfig && (
                        <ReportLayer
                            reportConfig={reportConfig}
                            reportData={selectedReport}
                            playerBaseData={playerBaseData}
                            onCloseLayer={closeReportLayer}
                            onSave={saveReport}
                            onNeedConfirmChange={setNeedConfirm}
                        />
                    )}
                    <FradiLoader visible={loadings.length > 0}/>
                </div>
                <ConfirmModal
                    isVisible={isCancelModalVisible}
                    text={'general.message.without-save'}
                    changeVisibility={() => setIsCancelModalVisible(false)}
                    callback={() => Promise.resolve(backToPlayerDetails())}
                />
            </div>
        )}
    </>;

};

export default PageLayer;
