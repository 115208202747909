import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import 'react-toastify/dist/ReactToastify.css'; // https://fkhadra.github.io/react-toastify/how-to-style
import 'simplebar-react/dist/simplebar.min.css';
import { routerConfig } from './routes.config';
import { msalConfig } from './authConfig';
import './styles/index.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <MsalProvider instance={msalInstance}>
        <RouterProvider router={routerConfig}/>
    </MsalProvider>
);
