import { ReactElement } from 'react';
import NotFoundTemplate from './NotFoundTemplate';

const NotFound = function (): ReactElement {

    return <NotFoundTemplate/>;

};

export default NotFound;
