import jsPDF from 'jspdf';
import { translate as t } from '../../helpers/translate';
import { PlayerSearchData } from '../openapi';
import {
    BoxDescriptor, blackHex, c, createPlayerImageCanvas,
    darkGreenHex, lightBorderHex, lightGreenHex, lightGreyHex, whiteHex
} from './pdf-generation-layout';
import { pdfFontContentRegular, pdfFontContentBold } from '../../../data/fonts';
import { SVG, parsePlayerName } from '../../helpers/utility';
import { addSvgToPdf, roundedBox, textEllipsis } from './pdf-generation-utility';
import { Toastify } from '../../modules/Toastify/Toastify';
import { CATEGORIES } from '../../interfaces/reports.interface';
import IconStarFilled from '../../../assets/images/icons/icon_star_filled_green.svg';

const widths = {
    card: c(80),
    image: c(104),
    name: c(184),
    year: c(352),
    team: c(414),
    position: c(682),
    minutes: c(733),
    chart: c(857),
    shadowTeam: c(969)
};

export const createListCard = async function({
    pdf,
    player,
    index,
    chart
}: {
    pdf: jsPDF,
    player: PlayerSearchData,
    index: number,
    chart: SVGSVGElement | null
}) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');
    pdf.addFileToVFS('open-sans-bold.ttf', pdfFontContentBold);
    pdf.addFont('open-sans-bold.ttf', 'bold', 'bold');

    const cardBox: BoxDescriptor = [c(80), c(270) + c(105) * index, c(1040), c(90)];
    const imageSize = 65;

    const ctx = pdf.context2d;

    // sor
    ctx.strokeStyle = lightGreenHex;
    ctx.fillStyle = whiteHex;
    ctx.lineWidth = 0.5;
    roundedBox(ctx, cardBox, c(5));

    // játékos kép
    const img = await createPlayerImageCanvas(player.PlayerImageBase64, imageSize / 2);
    pdf.addImage(
        img, 'PNG', widths.image, cardBox[1] + c(15), c(imageSize), c(imageSize), '', 'NONE', 0
    );

    // játékos kép körvonal
    ctx.strokeStyle = CATEGORIES[player.ClassificationID as keyof typeof CATEGORIES] ?? lightBorderHex;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.arc(widths.image + c(imageSize) / 2, cardBox[1] + c(15) + c(imageSize) / 2, c(imageSize) / 2, 0, Math.PI * 2, false);
    ctx.stroke();

    // játékos név
    const [firstName, lastName] = parsePlayerName(player.PlayerName);
    pdf.setTextColor(darkGreenHex);
    pdf.setFontSize(9).setFont('normal', 'normal', 'normal');
    pdf.text(firstName, widths.name, cardBox[1] + c(40), { align: 'left' });
    pdf.setFontSize(9).setFont('bold', 'normal', 'normal');
    pdf.text(textEllipsis(pdf, lastName.toUpperCase(), c(170)), widths.name, cardBox[1] + c(64), { align: 'left' });

    // játékos adatok
    pdf.setTextColor(blackHex);
    pdf.setFontSize(9).setFont('normal', 'normal', 'normal');
    pdf.text(String(player.Birthday ?? '-'), widths.year, cardBox[1] + c(52), { align: 'left' });
    pdf.text(textEllipsis(pdf, player.TeamName, c(240)), widths.team + c(25), cardBox[1] + c(52), { align: 'left' });
    pdf.text(String(player.PlayerPositionID), widths.position, cardBox[1] + c(52), { align: 'left' });
    pdf.text(String(player.MinutesPlayed), widths.minutes, cardBox[1] + c(52), { align: 'left' });

    // csapat kép
    pdf.addImage(
        player.TeamImageBase64, 'PNG', widths.team, cardBox[1] + c(35), c(20), c(20), '', 'NONE', 0
    );

    // grafikon
    if (chart) {
        await addSvgToPdf({
            pdf,
            svgElement: chart,
            qualityWidth: 150,
            dimensions: {
                x: widths.chart,
                y: cardBox[1] + c(7),
                w: c(75)
            }
        }).catch(() => {
            Toastify({
                titleKey: 'general.message.error.pdf-generation.svg-insertion-failed'
            }).error();
        });
    }

    // shadow team
    if (player.InShadowTeamFlg) {
        await addSvgToPdf({
            pdf,
            svgElement: await SVG.urlToElement(IconStarFilled) as SVGSVGElement,
            qualityWidth: 100,
            dimensions: {
                x: widths.shadowTeam,
                y: cardBox[1] + c(35),
                w: c(18)
            }
        }).catch(() => {
            Toastify({
                titleKey: 'general.message.error.pdf-generation.svg-insertion-failed'
            }).error();
        });
        pdf.setFontSize(8).setFont('normal', 'normal', 'normal');
        pdf.text(t('pdf.shortlist.shadowteam'), widths.shadowTeam + c(24), cardBox[1] + c(52), { align: 'left' });
    }

};

export const createListHeader = function(pdf: jsPDF) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');

    const y = c(256);
    pdf.setTextColor(lightGreyHex);
    pdf.setFontSize(7).setFont('normal', 'normal', 'normal');
    pdf.text('Név'.toUpperCase(), widths.name, y, { align: 'left' });
    pdf.text('Szül.év'.toUpperCase(), widths.year, y, { align: 'left' });
    pdf.text('Csapat'.toUpperCase(), widths.team, y, { align: 'left' });
    pdf.text('Poszt'.toUpperCase(), widths.position, y, { align: 'left' });
    pdf.text('Játékpercek'.toUpperCase(), widths.minutes, y, { align: 'left' });

};
