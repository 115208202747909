import { createContext } from 'react';
import { AreaProps } from '../../pages/scouting-page/overview-page/map.interface';
import { Categories, CategoryEnum } from '../interfaces/reports.interface';
import { ContextProps } from './context.helper';
import { PlayerSearchData } from '../services/openapi';

export interface AreaContextProps {
    area: AreaProps,
    category: CategoryEnum,
    categoryCollection?: Categories<PlayerSearchData[]>
    isModalOpen?: boolean,
}

export const AreaContext = createContext<ContextProps<AreaContextProps>>({
    context: undefined,
    setContext: () => undefined,
});
