import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { cx, getYear, parsePlayerName } from '../../../shared/helpers/utility';
import { translate as t } from '../../../shared/helpers/translate';
import { ProfileGroupTypes } from '../../../shared/interfaces/carried-on.interfaces';
import Select, { OptionType } from '../../../shared/modules/select/select';
import EchartsRadar from '../../../shared/modules/EchartsWrapper/EchartsRadar/EchartsRadar';
import { FradiLoader } from '../../../shared/modules/FradiLoader/FradiLoader';
import { PlayerReportData } from './Compare';
import { PlayerReport, PlayerSearchData, ReportParams } from '../../../shared/services/openapi';
import { PlayerLayerContext } from '../../../shared/contexts/PlayerLayer.context';
import usePlayerProfile from '../../../shared/hooks/player-profile.hook';
import { CategoryEnum } from '../../../shared/interfaces/reports.interface';
import Tooltip from '../../../shared/modules/Tooltip/Tooltip';
import css from './Compare.module.scss';

const CompareTemplate = function({
    loadings,
    players,
    teamReport,
    comparePlayer1,
    comparePlayer2,
    setComparePlayerId1,
    setComparePlayerId2,
    reportConfig,
    handlePlayerLayer
}: {
    loadings: string[],
    players: OptionType<PlayerSearchData>[],
    teamReport: PlayerReport | undefined,
    comparePlayer1: PlayerReportData | undefined,
    comparePlayer2: PlayerReportData | undefined,
    setComparePlayerId1: React.Dispatch<React.SetStateAction<number | undefined>>,
    setComparePlayerId2: React.Dispatch<React.SetStateAction<number | undefined>>,
    reportConfig: ReportParams | undefined,
    handlePlayerLayer: (playerId: number) => void
}): ReactElement {

    const layerContext = useContext(PlayerLayerContext);
    const [selectedIndicator, setSelectedIndicator] = useState<string>('all');
    const [samePositionPlayers, setSamePositionPlayers] = useState<OptionType<PlayerSearchData>[]>([]);
    const [rightSideSelected, setRightSideSelected] = useState<OptionType<PlayerSearchData>[]>([]);

    const { orderCollection, setOrderCollection, chartCollectionConfig } = usePlayerProfile();

    useEffect(
        () => {
            setSamePositionPlayers(
                players.filter(
                    player => (
                        player.data?.PlayerPositionID === comparePlayer1?.report.PlayerPositionID &&
                        player.id !== comparePlayer1?.baseData.PlayerID
                    )
                )
            );
        },
        [comparePlayer1?.baseData.PlayerID, comparePlayer1?.report.PlayerPositionID, players]
    );

    useEffect(
        () => {
            if (comparePlayer1?.report.PlayerPositionID && reportConfig?.ProfileIndex) {
                const position = reportConfig.ProfileIndex.find(
                    item => item.PositionID === comparePlayer1?.report.PlayerPositionID
                );
                if (position && position.ProfileIndexList) {
                    setOrderCollection(collection => Object.fromEntries(
                        Object.keys(collection).map(
                            key => [
                                key,
                                (position.ProfileIndexList?.find(
                                    group => group.ProfileIndexGroupID === key
                                ))?.ProfileIndexIDList?.map(
                                    item => item.ProfileIndexID
                                ) ?? collection[key]
                            ]
                        )
                    ));
                }
            }
        },
        [comparePlayer1, reportConfig, setOrderCollection]
    );

    const indicatorOptions: OptionType[] = [
        {
            id: 'all',
            value: t('general.group.all')
        },
        ...Object.values(ProfileGroupTypes).map(
            profileGroup => ({
                id: profileGroup,
                value: t(`general.group.${profileGroup}`)
            })
        )
    ];

    function searchToTeam(teamName: string) {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isSearchModalOpen: teamName,
            });
        }
    }

    const playerCard = (player: PlayerReportData | undefined) => {
        if (!player) {
            return <div className={css.playerInfoCard}></div>;
        }
        const name = parsePlayerName(player.baseData.PlayerName);
        return <>
            <div className={css.playerInfoCard}>
                {player && <>
                    <div className={css.nameAndIndex}>
                        <div className={css.nameAndPicture} onClick={() => handlePlayerLayer(player.baseData.PlayerID)}>
                            <div className={cx(
                                css.pictureContainer,
                                css['category-' + (Object.keys(CategoryEnum)[
                                    Object.values(CategoryEnum).indexOf(player.report.ClassificationID as unknown as CategoryEnum)
                                ] ?? 'none')]
                            )}>
                                <img src={player.baseData.PlayerImageUrl} alt=""/>
                            </div>
                            <div className={css.name}>
                                <p className={css.firstName}>{name[0]}</p>
                                <Tooltip text={player.baseData.PlayerName} ifOverflow={true}>
                                    <p className={css.surName}>{name[1]}</p>
                                </Tooltip>
                                <div className={css.scoutingIndex}>
                                    <p className={css.indexTitle}>{t('scouting.compare.scouting-index')}</p>
                                    <div className={css.valueContainer}>
                                        <div className={css.solidBorder}>
                                            <p className={css.value}>{player.report.ScoutingIndexGroupMean ?? '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={css.borderLineX}></div>
                    <div className={css.playerInfo}>
                        <div className={css.birthDate}>
                            <p className={css.title}>{t('playerDetails.label.year-of-birth')}:</p>
                            <p className={css.value}>{getYear(player.baseData.Birthday)}</p>
                        </div>
                        <div className={css.team}>
                            <p className={css.title}>{t('playerDetails.label.team')}:</p>
                            <div className={cx(css.value, css.logoAndName)} onClick={() => searchToTeam(player.baseData.TeamName)}>
                                <img src={player.baseData.TeamImageUrl} alt=""/>
                                <p className={css.value}>{player.baseData.TeamName}</p>
                            </div>
                        </div>
                        <div className={css.post}>
                            <p className={css.title}>{t('playerDetails.label.post')}:</p>
                            <p className={css.value}>{player.report.PlayerPositionID}</p>
                        </div>
                        <div className={css.profile}>
                            <p className={css.title}>{t('playerDetails.label.profile')}:</p>
                            <p className={css.value}>{player.report.PlayerProfileID}</p>
                        </div>
                        <div className={css.internationality}>
                            <p className={css.title}>{t('playerDetails.label.internationality')}:</p>
                            <p className={css.value}>{player.baseData.PlayedInNational} ({player.baseData.NationalTeamCategory})</p>
                        </div>
                    </div>
                </>}
            </div>
        </>;
    };

    return <>
        <div className={cx('contentContainer', css.playerCompare)}>
            <div className="contentHeader">
                <h1>{t('scouting.compare.title')}</h1>
            </div>
            <div className={css.mainContainer}>

                <div className={css.filters}>
                    <Select
                        label={t('scouting.compare.filter')}
                        hideLabelWhenSelected={true}
                        isSoloLabel={true}
                        filter={true}
                        runInitialChange={true}
                        options={players}
                        selectedOptions={
                            comparePlayer1
                                ? [{
                                    id: comparePlayer1.baseData.PlayerID,
                                    value: comparePlayer1.baseData.PlayerName
                                }]
                                : []
                        }
                        changeSelected={
                            (selectedOptions) => {
                                if (selectedOptions.length > 0) {
                                    setComparePlayerId1(
                                        selectedOptions[0].id as number
                                    );
                                }
                                setRightSideSelected([]);
                                setComparePlayerId2(undefined);
                            }
                        }
                    ></Select>
                    <Select
                        label={t('scouting.compare.filter')}
                        hideLabelWhenSelected={true}
                        isSoloLabel={true}
                        filter={true}
                        disabled={!comparePlayer1}
                        options={samePositionPlayers}
                        selectedOptions={rightSideSelected}
                        changeSelected={
                            (selectedOptions) => {
                                setComparePlayerId2(
                                    selectedOptions[0].id as number
                                );
                                setRightSideSelected(selectedOptions);
                            }
                        }
                    ></Select>
                </div>

                <div className={css.playerCardContainer}>

                    <div className={css.separator}></div>

                    {playerCard(comparePlayer1)}

                    <div className={css.barChart}>
                        {Object.values(ProfileGroupTypes).map(profileGroup => (
                            <div key={profileGroup} className={css.barLine}>
                                <div className={css.label}>{t(`general.group.${profileGroup}`)}</div>
                                <div className={css.left}>
                                    <div
                                        className={cx(css.team)}
                                        style={{width: `${teamReport?.[`${profileGroup}Mean`] ?? 0}%`}}
                                    ></div>
                                    <div
                                        className={cx(css.player)}
                                        style={{width: `${comparePlayer1?.report[`${profileGroup}Mean`] ?? 0}%`}}
                                    ></div>
                                </div>
                                <div className={css.right}>
                                    <div
                                        className={cx(css.team)}
                                        style={{width: `${teamReport?.[`${profileGroup}Mean`] ?? 0}%`}}
                                    ></div>
                                    <div
                                        className={cx(css.player)}
                                        style={{width: `${comparePlayer2 ? comparePlayer2?.report[`${profileGroup}Mean`] ?? 0 : 0}%`}}
                                    ></div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {playerCard(comparePlayer2)}

                </div>
                <div className={css.pieAndBarChartContainer}>
                    <div className={css.selectChart}>
                        <Select
                            label={'playerDetails.label.indicator'}
                            isSoloLabel={true}
                            hideLabelWhenSelected={true}
                            isLabelOnTop={true}
                            disabled={!comparePlayer1}
                            options={indicatorOptions}
                            selectedOptions={indicatorOptions.filter(item => item.id === selectedIndicator)}
                            changeSelected={
                                (selectedOptions) => setSelectedIndicator(
                                    selectedOptions.length ? String(selectedOptions[0].id) : 'all'
                                )
                            }
                        />
                    </div>
                    {true && (
                        <div className={css.highlightedChart}>
                            <div className={css.bigChartContainer}>
                                <EchartsRadar<PlayerReport>
                                    showLabel={true}
                                    transparentArea={true}
                                    multiDataList={[
                                        teamReport ?? undefined,
                                        comparePlayer1 ? comparePlayer1.report : undefined,
                                        comparePlayer2 ? comparePlayer2.report : undefined,
                                    ]}
                                    colors={[
                                        teamReport ? '#BBBBBB' : undefined,
                                        comparePlayer1 ? '#FFD28F' : undefined,
                                        comparePlayer2 ? '#ADDEBA' : undefined,
                                    ]}
                                    labelColors={[
                                        teamReport ? '#808080' : undefined,
                                        comparePlayer1 ? '#C0951B' : undefined,
                                        comparePlayer2 ? '#47A16D' : undefined,
                                    ]}
                                    config={chartCollectionConfig[selectedIndicator]}
                                    order={orderCollection[selectedIndicator] as (keyof PlayerReport)[]}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <FradiLoader visible={loadings.includes('comparePlayers')}/>
    </>;

};

export default CompareTemplate;
