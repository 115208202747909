import { RouteObject, redirect } from 'react-router-dom';
import ShortList from './short-list/ShortList';
import ShadowTeam from './shadow-team/ShadowTeam';
import Compare from './compare/Compare';
import OverviewPage from './overview-page/OverviewPage';
import PlayerFilter from './player-filter/PlayerFilter';
import { PlayerDetailsPage } from './player-details-page/PlayerDetailsPage';
import GeneralList, { GeneralListType } from './general-list/GeneralList';
import SavedPlayerList from './saved-player-list/SavedPlayerList';
import NotFound from '../not-found/NotFound';

export const scoutingRoutes: RouteObject[] = [
    {
        path: '',
        loader: () => {
            return redirect('/scouting/overview');
        }
    },
    {
        path: 'overview',
        element: <OverviewPage/>,
    },
    {
        path: 'player-filter',
        element: <PlayerFilter/>,
    },
    {
        path: 'shortlist',
        element: <ShortList/>,
    },
    {
        path: 'shadow-team',
        element: <ShadowTeam own={false}/>,
    },
    {
        path: 'own-shadow-team',
        element: <ShadowTeam own={true}/>
    },
    {
        path: 'compare',
        element: <Compare/>,
    },
    {
        path: 'compare/:playerId',
        element: <Compare/>,
    },
    {
        path: 'player/:playerId',
        element: <PlayerDetailsPage/>
    },
    {
        path: 'player/:playerId/:reportId',
        element: <PlayerDetailsPage/>
    },
    {
        path: 'followed-players',
        element: <GeneralList type={GeneralListType.followed}/>
    },
    {
        path: 'uploaded-report-players',
        element: <GeneralList type={GeneralListType.reported}/>
    },
    {
        path: 'alert-players',
        element: <GeneralList type={GeneralListType.alert}/>
    },
    {
        path: 'cube-alert-players',
        element: <GeneralList type={GeneralListType.cubeAlert}/>
    },
    {
        path: 'saved-player-list',
        element: <SavedPlayerList/>
    },
    // {
    //     path: 'actual-player-list',
    //     element: <ActualPlayerList/>
    // },
    {
        path: '*',
        element: <NotFound/>
    }
];
