import React, { ReactElement, useState } from 'react';
import TabBox from '../../shared/modules/TabBox/TabBox';
import PageLayer from '../../shared/modules/PageLayer/PageLayer';
import { MenuContext } from '../../shared/contexts/Menu.context';
import css from './ScoutingPage.module.scss';

const ScoutingPageTemplate = function({
    ownShadowTeam
}: {
    ownShadowTeam: boolean
}): ReactElement {

    const [menuReload, setMenuReload] = useState(false);

    return <>
        <div className={css.contentBox}>
            <MenuContext.Provider value={{ context: menuReload, setContext: setMenuReload }}>
                <div className={css.outletContainer}>
                    <TabBox ownShadowTeam={ownShadowTeam} />
                    <PageLayer/>
                </div>
            </MenuContext.Provider>
            {/*
            <SidebarContext.Provider value={{ context: sidebarReload, setContext: setSidebarReload }}>
                <LeftSidebar/>
                <div className={css.SideboxContainer}>
                    <RightSidebar searchSelect={openSearchModal}/>
                </div>
            </SidebarContext.Provider>
            */}
        </div>
    </>;

};

export default ScoutingPageTemplate;
