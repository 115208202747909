/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyReturn } from '../models/EmptyReturn';
import type { NotDownloadedPlayerLists } from '../models/NotDownloadedPlayerLists';
import type { PlayerLists } from '../models/PlayerLists';
import type { PlayerSearch } from '../models/PlayerSearch';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlayerListsService {

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deleteFromPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteFromPlayerList/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deletePlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeletePlayerList/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static latestPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LatestPlayerList/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns NotDownloadedPlayerLists
     * @throws ApiError
     */
    public static notDownloadedPlayerListsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<NotDownloadedPlayerLists> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/NotDownloadedPlayerLists/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static playerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerList/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns PlayerLists
     * @throws ApiError
     */
    public static playerListsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerLists> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerLists/',
            body: data,
        });
    }

    /**
     * Create a new player list
     * @param data
     * @returns PlayerLists
     * @throws ApiError
     */
    public static savePlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Name of the player list
             */
            PlayerListName?: string;
            /**
             * Player list ID if editing already created player list
             */
            PlayerListID?: number | null;
        },
    ): CancelablePromise<PlayerLists> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavePlayerList/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static saveToPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SaveToPlayerList/',
            body: data,
        });
    }

}
