import { createContext } from 'react';
import { ContextProps } from './context.helper';

export interface PlayerLayerContextProps {
    playerId: number,
    isModalOpen?: boolean,
    fromPage?: boolean,
    isSearchModalOpen?: string,
    closeCallback?: () => void,
}

export const PlayerLayerContext = createContext<ContextProps<PlayerLayerContextProps>>({
    context: undefined,
    setContext: () => undefined,
});
