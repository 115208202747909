import React, { ReactElement, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { condition, cx } from '../../helpers/utility';
import { BoxPoint, TooltipCollision, TooltipPosition } from './Tooltip';
import css from './Tooltip.module.scss';

export interface AutoRect {
    x: number;
    y: number;
    w: number;
}

const extractBoxPoint = function(pointDescriptor: BoxPoint): { x: string, y: string } {
    return condition([
        [pointDescriptor === BoxPoint['left top'], { x: 'left', y: 'top' }],
        [pointDescriptor === BoxPoint['left center'], { x: 'left', y: 'center' }],
        [pointDescriptor === BoxPoint['left bottom'], { x: 'left', y: 'bottom' }],
        [pointDescriptor === BoxPoint['right top'], { x: 'right', y: 'top' }],
        [pointDescriptor === BoxPoint['right center'], { x: 'right', y: 'center' }],
        [pointDescriptor === BoxPoint['right bottom'], { x: 'right', y: 'bottom' }],
        [pointDescriptor === BoxPoint['center top'], { x: 'center', y: 'top' }],
        [pointDescriptor === BoxPoint['center center'], { x: 'center', y: 'center' }],
        [pointDescriptor === BoxPoint['center bottom'], { x: 'center', y: 'bottom' }]
    ]);
};

const extractCollision = function(collisionHandler: TooltipCollision): { x: string, y: string } {
    return condition([
        [collisionHandler === TooltipCollision['none none'], { x: 'none', y: 'none' }],
        [collisionHandler === TooltipCollision['none fit'], { x: 'none', y: 'fit' }],
        [collisionHandler === TooltipCollision['none flip'], { x: 'none', y: 'flip' }],
        [collisionHandler === TooltipCollision['fit none'], { x: 'fit', y: 'none' }],
        [collisionHandler === TooltipCollision['fit fit'], { x: 'fit', y: 'fit' }],
        [collisionHandler === TooltipCollision['fit flip'], { x: 'fit', y: 'flip' }],
        [collisionHandler === TooltipCollision['flip none'], { x: 'flip', y: 'none' }],
        [collisionHandler === TooltipCollision['flip fit'], { x: 'flip', y: 'fit' }],
        [collisionHandler === TooltipCollision['flip flip'], { x: 'flip', y: 'flip' }],
        [collisionHandler === TooltipCollision['shrink none'], { x: 'shrink', y: 'none' }],
        [collisionHandler === TooltipCollision['shrink fit'], { x: 'shrink', y: 'fit' }],
        [collisionHandler === TooltipCollision['shrink flip'], { x: 'shrink', y: 'flip' }]
    ]);
};

const TooltipTemplate = function({
    baseElement,
    html,
    ifOverflow,
    position,
    className
}: {
    baseElement: ReactElement,
    html: ReactElement,
    ifOverflow: boolean,
    position: TooltipPosition,
    className: string
}): ReactElement {

    const baseElementRef = useRef<HTMLElement | null>(null);
    const measureTip = useRef<HTMLDivElement | null>(null);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [tooltipPos, setTooltipPos] = useState<AutoRect>({ x: 0, y: 0, w: 0 });

    useEffect(
        () => {
            if (isActive && baseElementRef.current && measureTip.current) {
                const toggler = position.of === 'toggler' ? baseElementRef.current : position.of;
                const base = toggler.getBoundingClientRect();
                const tip = measureTip.current.getBoundingClientRect();

                const tipPos: AutoRect = {
                    x: base.x,
                    y: base.y,
                    w: tip.width
                };

                // my, at
                const my = { x: extractBoxPoint(position.my).x, y: extractBoxPoint(position.my).y };
                const at = { x: extractBoxPoint(position.at).x, y: extractBoxPoint(position.at).y };
                if (at.x === 'center') {
                    tipPos.x += base.width / 2;
                }
                if (at.x === 'right') {
                    tipPos.x += base.width;
                }
                if (at.y === 'center') {
                    tipPos.y += base.height / 2;
                }
                if (at.y === 'bottom') {
                    tipPos.y += base.height;
                }
                if (my.x === 'center') {
                    tipPos.x -= tip.width / 2;
                }
                if (my.x === 'right') {
                    tipPos.x -= tip.width;
                }
                if (my.y === 'center') {
                    tipPos.y -= tip.height / 2;
                }
                if (my.y === 'bottom') {
                    tipPos.y -= tip.height;
                }

                // offset
                let offset = position.offset;
                if (!offset) {
                    offset = { x: 0, y: 0 };
                }
                tipPos.x += offset.x;
                tipPos.y += offset.y;

                // within
                let restrict: { x: number, y: number, width: number, height: number };
                if (!position.within) {
                    restrict = { x: -Infinity, y: -Infinity, width: Infinity, height: Infinity };
                }
                else if (position.within === window) {
                    restrict = {
                        x: 0, y: 0, width: window.innerWidth, height: window.innerHeight
                    };
                }
                else if (position.within === document) {
                    restrict = {
                        x: 0, y: 0, width: document.documentElement.scrollWidth, height: document.documentElement.scrollHeight
                    };
                }
                else {
                    restrict = (position.within as HTMLElement).getBoundingClientRect();
                }

                // collision
                let collisionStr = position.collision;
                if (!collisionStr) {
                    collisionStr = TooltipCollision['none none'];
                }
                const collision = { x: extractCollision(collisionStr).x, y: extractCollision(collisionStr).y };

                if (collision.x === 'fit') {
                    if (tipPos.x < restrict.x) {
                        tipPos.x += restrict.x - tipPos.x;
                    }
                    if (tipPos.x + tip.width > restrict.x + restrict.width) {
                        tipPos.x -= (tipPos.x + tip.width) - (restrict.x + restrict.width);
                    }
                }
                if (collision.y === 'fit') {
                    if (tipPos.y < restrict.y) {
                        tipPos.y += restrict.y - tipPos.y;
                    }
                    if (tipPos.y + tip.height > restrict.y + restrict.height) {
                        tipPos.y -= (tipPos.y + tip.height) - (restrict.y + restrict.height);
                    }
                }
                if (collision.x === 'flip') {
                    // TODO
                    console.warn('[Tooltip] flip collision not implemented');
                }
                if (collision.y === 'flip') {
                    // TODO
                    console.warn('[Tooltip] flip collision not implemented');
                }
                if (collision.x === 'shrink') {
                    if (tipPos.x < restrict.x) {
                        tipPos.w -= restrict.x - tipPos.x;
                    }
                    if (tipPos.x + tip.width > restrict.x + restrict.width) {
                        tipPos.w -= (tipPos.x + tip.width) - (restrict.x + restrict.width);
                    }
                }

                setTooltipPos({ x: tipPos.x, y: tipPos.y, w: tipPos.w });
            }
        },
        [isActive, position]
    );

    const moduleClassNames = className.split(' ').map(
        (cl: string) => css[cl]
    ).join(' ');

    const trigger = function(value: boolean) {
        if (
            !ifOverflow ||
            (baseElementRef.current && baseElementRef.current.scrollWidth > baseElementRef.current.clientWidth)
        ) {
            setIsActive(value);
        }
    };

    const triggerElement = React.cloneElement(
        baseElement,
        {
            ref: baseElementRef,
            onMouseOver: () => trigger(true),
            onMouseOut: () => trigger(false)
        }
    );

    return <>
        {triggerElement}
        {isActive &&
            ReactDOM.createPortal(
                <div
                    ref={measureTip}
                    className={cx(css.tooltip, moduleClassNames)}
                    style={{
                        left: `${tooltipPos.x}px`,
                        top: `${tooltipPos.y}px`,
                        width: tooltipPos.w ? `${tooltipPos.w}px` : 'auto'
                    }}
                    onClick={(event) => event.stopPropagation()}
                    onMouseOver={() => trigger(true)}
                    onMouseOut={() => trigger(false)}
                >
                    {html}
                </div>,
                document.body
            )
        }
    </>;

};

export default TooltipTemplate;
