import React, { ReactNode } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS as DnDCSS } from '@dnd-kit/utilities';
import { PlayerSearchData, SearchPlayer } from '../../services/openapi';
import { SHADOW_TEAM_ALLOWED_PLAYER_NUM } from '../../constants';
import css from './PositionModifier/PositionModifier.module.scss';

export const SortableItem = function({
    id,
    children
}: {
    id: number,
    children: ReactNode
}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: DnDCSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'grab'
    };

    return <>
        <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
            <div className={css.drag}></div>
            {children}
        </div>
    </>;
};

export const Droppable = function({
    id,
    droppedPlayers,
    children
}: {
    id: number,
    droppedPlayers: (PlayerSearchData | SearchPlayer | null)[],
    children: ReactNode
}) {
    const { isOver, setNodeRef } = useDroppable({
        id
    });

    const filledAreas = droppedPlayers.reduce(
        (acc: number[], curr, index) => {
            if (curr) {
                acc.push(index);
            }
            return acc;
        },
        []
    );

    return <>
        <div ref={setNodeRef} className={isOver && !filledAreas.includes(id) ? css.placeholderWrapper : ''}>
            {children}
        </div>
    </>;
};

export const Draggable = function({
    id,
    player,
    currentPlayerList,
    children
}: {
    id: number,
    player: PlayerSearchData | SearchPlayer,
    currentPlayerList: (PlayerSearchData | SearchPlayer | null)[],
    children: ReactNode
}) {
    const disabled = currentPlayerList && currentPlayerList.filter(
        pl => !!pl
    ).length >= SHADOW_TEAM_ALLOWED_PLAYER_NUM;
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id,
        data: {
            player
        },
        disabled
    });
    let style: React.CSSProperties = { };
    if (transform) {
        style = {
            transform: `translate3d(${transform.x <= 0 ? transform.x : 0}px, ${transform.y}px, 0)`
        };
    }

    return <>
        <div ref={setNodeRef} style={style ?? ''} {...attributes} {...listeners}>
            {!disabled && (
                <div className={css.drag}></div>
            )}
            {children}
        </div>
    </>;
};
