import React, { ReactElement, useState } from 'react';
import { cx } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import css from './BooleanSelect.module.scss';

interface ComponentProps {
    value: boolean | null,
    small?: boolean,
    onSelect: (rate: boolean | null) => void,
    directionSwitch?: boolean,
    trueLabel?: string,
    falseLabel?: string,
    disabled?: boolean,
    nullable?: boolean
}

export const BooleanSelect = function({
    value,
    small,
    onSelect,
    directionSwitch,
    trueLabel = 'general.button.yes',
    falseLabel = 'general.button.no',
    disabled = false,
    nullable = false
}: ComponentProps): ReactElement {

    const [choice, setChoice] = useState<boolean | null>(value);

    function selectChoice(selectedCategory: boolean) {
        if (choice === selectedCategory && nullable) {
            setChoice(null);
            onSelect(null);
        }
        else {
            setChoice(selectedCategory);
            onSelect(selectedCategory);
        }
    }

    return <>
        <div className={cx(css.ChoiceBox, small ? css.small : null)}>
            {(directionSwitch ? [0, 1] : [1, 0]).map(item =>
                <button
                    className={cx('btn icon', css.choiceButton, css[('category' + item)], choice === !!item ? css.selected : null)}
                    key={item}
                    disabled={disabled}
                    onClick={() => selectChoice(!!item)}
                >
                    {t(item ? trueLabel : falseLabel)}
                </button>
            )}
        </div>
    </>;

};
