import dayjs from 'dayjs';
import * as dictionaryEn from '../../assets/locale/en.json';
import * as dictionaryHu from '../../assets/locale/hu.json';
import { get } from 'lodash';

const LS_LANGUAGE_KEY = 'fradi_scouting_language';

export enum LanguageEnum {
    HU = 'hu',
    EN = 'en'
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const languagesCollection: Record<LanguageEnum, any> = {
    [LanguageEnum.HU]: dictionaryHu,
    [LanguageEnum.EN]: dictionaryEn,
};

const defaultLang: LanguageEnum = LanguageEnum.HU;

export function getCurrentLang(): LanguageEnum {
    if (!localStorage.getItem(LS_LANGUAGE_KEY)) {
        localStorage.setItem(LS_LANGUAGE_KEY, defaultLang);
    }
    return localStorage.getItem(LS_LANGUAGE_KEY) as LanguageEnum;
}

export function setCurrentLang(selectedLang: LanguageEnum) {
    localStorage.setItem(LS_LANGUAGE_KEY, selectedLang);
}

export function translate(label: string, selectedLang: LanguageEnum = getCurrentLang()): string {
    const translatedLabel = get(languagesCollection[selectedLang].default, label);
    if (!translatedLabel) {
        // console.warn('@Missing translation to:', label);
    }
    return translatedLabel ?? label;
}

export const formatDate = function(date?: dayjs.ConfigType): string {
    if (getCurrentLang() === 'en') {
        return dayjs(date).format('DD. MM. YYYY');
    }
    else if (getCurrentLang() === 'hu') {
        return dayjs(date).format('YYYY. MM. DD.');
    }
    else {
        return dayjs(date).format('YYYY-MM-DD');
    }
};
