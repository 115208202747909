import React, { ReactElement, useState } from 'react';
import { noop, without } from 'lodash';
import useAuth from '../../hooks/auth.hook';
import { Toastify } from '../Toastify/Toastify';
import { PlayerReportService } from '../../services/openapi';
import { toastError } from '../../helpers/errorhandler';
import FileUploaderTemplate from './FileUploader.template';

export interface RawFileType {
    name: string;
    size: number;
    type: string;
}

export interface FileCheckResult {
    allowed: boolean;
    fileData: RawFileType;
    errors: FileError[];
}

enum FileError {
    Size,
    Type
}

export const MB = 1024 * 1024;

const FileUploader = function({
    playerId,
    maxSize,
    allowedTypes,
    allowedExtensions,
    onUpload = noop
}: {
    maxSize: number,
    allowedTypes: string[],
    allowedExtensions: string[],
    playerId: number,
    onUpload?: (resp: void | number) => void
}): ReactElement {

    const { requestParams } = useAuth(playerId);
    const [loadings, setLoadings] = useState<string[]>([]);
    const [step, setStep] = useState<1 | 2>(1);
    const [file, setFile] = useState<File | null>(null);
    const [allowed, setAllowed] = useState(false);

    const check = function(file: RawFileType): FileError[] {
        const errors: FileError[] = [];
        if (file.size > maxSize) {
            errors.push(FileError.Size);
        }
        if (!allowedTypes.includes(file.type)) {
            errors.push(FileError.Type);
        }
        return errors;
    };

    const prepare = function(fileList: FileList): FileCheckResult {
        if (fileList.length > 1) {
            Toastify({
                titleKey: 'general.message.error.file-upload.multiple-file-disallowed'
            }).warning();
            return {
                allowed: false,
                fileData: {
                    name: '',
                    size: 0,
                    type: ''
                },
                errors: []
            };
        }
        if (fileList.length === 0) {
            return {
                allowed: false,
                fileData: {
                    name: '',
                    size: 0,
                    type: ''
                },
                errors: []
            };
        }
        const rawFile = fileList[0];
        const fileData: RawFileType = {
            name: rawFile.name,
            size: rawFile.size,
            type: rawFile.type
        };
        const errors = check(fileData);
        if (errors.length === 0) {
            setAllowed(true);
            setFile(rawFile);
            return {
                allowed: true,
                fileData,
                errors
            };
        }
        else {
            setAllowed(false);
            errors.forEach(
                error => {
                    if (error === FileError.Size) {
                        Toastify({
                            titleKey: 'general.message.error.file-upload.wrong-size'
                        }).warning();
                    }
                    if (error === FileError.Type) {
                        Toastify({
                            titleKey: 'general.message.error.file-upload.wrong-type'
                        }).warning();
                    }
                }
            );
            return {
                allowed: false,
                fileData,
                errors
            };
        }
    };

    const send = function(): Promise<void | number> {
        if (file) {
            setLoadings(l => [...l, 'upload']);
            return PlayerReportService.uploadPlayerReportCreate(
                file,
                requestParams.UserID,
                requestParams.LangID,
                requestParams.PlayerID
            ).finally(
                () => setLoadings(l => without(l, 'upload'))
            ).then(
                (report) => {
                    onUpload(report.ReportID);
                    return report.ReportID;
                }
            ).catch(
                toastError
            );
        }
        else {
            Toastify({
                titleKey: 'general.message.error.file-upload.no-file'
            }).warning();
            return Promise.resolve();
        }
    };

    return (
        <FileUploaderTemplate
            loadings={loadings}
            maxSize={maxSize}
            allowedExtensions={allowedExtensions}
            allowed={allowed}
            step={step}
            setStep={setStep}
            prepare={prepare}
            send={send}
        />
    );

};

export default FileUploader;
