import React, { ReactElement } from 'react';
import SimpleBar from 'simplebar-react';
import { translate as t } from '../../../../shared/helpers/translate';
import { Html, cx } from '../../../../shared/helpers/utility';
import { PlayerList, PlayerSearchData } from '../../../../shared/services/openapi';
import PlayerShortListCard from '../../../../shared/modules/PlayerShortListCard/PlayerShortListCard';
import { FradiLoader } from '../../../../shared/modules/FradiLoader/FradiLoader';
import Tooltip from '../../../../shared/modules/Tooltip/Tooltip';
import { SortingDescriptor } from '../../general-list/GeneralList';
import css from './LoadedPlayerList.module.scss';
import cssList from '../../short-list/ShortList.module.scss';
import ClipboardGrey from '../../../../assets/images/icons/icon_file_clipboard_grey.svg';

const LoadedPlayerListTemplate = function({
    handlePlayerLayer,
    searchToTeam,
    saveCurrentListPdf,
    loadedList,
    players,
    loadings,
    sorting,
    changeSorting
}: {
    handlePlayerLayer: (playerId: number) => void,
    searchToTeam: (teamName: string) => void,
    saveCurrentListPdf: (list: PlayerList, players: PlayerSearchData[]) => void,
    loadedList?: PlayerList,
    players: PlayerSearchData[],
    loadings: string[],
    sorting: SortingDescriptor,
    changeSorting: (column: keyof PlayerSearchData) => void
}): ReactElement {

    const sortableColumn = function(
        column: keyof PlayerSearchData, label: string, style: Record<string, string> = cssList
    ) {
        return (
            <Tooltip text={t(`general.label.sort.${label}`)}>
                <th
                    className={cx(
                        style[label], 'sortableColumn', sorting.column === column && 'sort-' + sorting.direction
                    )}
                    onClick={() => changeSorting(column)}
                >
                    {t(`playerDetails.label.${label}`)}
                </th>
            </Tooltip>
        );
    };

    return <>
        <div className={cx('contentContainer', cssList.shortList)}>
            {loadedList && <>
                <div className={cx('contentHeader', cssList.contentHeader)}>
                    <h1>{t('scouting.saved-player-list.title')}</h1>
                    <div className="buttonsAndFilters">
                        <button
                            className={cx(
                                'btn btn-secondary btn-outline',
                                cssList.filterPageButton,
                                loadings.includes('pdf') ? cssList.loading : null
                            )}
                            disabled={loadings.includes('pdf')}
                            onClick={() => saveCurrentListPdf(loadedList, players)}
                        >
                            <img src={ClipboardGrey} alt=""/>
                            {t('scouting.saved-player-list.current-export')}
                        </button>
                    </div>
                </div>

                <h2 className={css.savedList}>
                    {loadedList.ListName}&nbsp;
                    ({t('scouting.saved-player-list.date')}: {loadedList.ListDate.split('T')[0]},&nbsp;
                    {t('scouting.saved-player-list.username')}: {loadedList.UserName})
                </h2>
                <div className={cssList.shortListContentModal}>
                    <div className={cx(cssList.shortListContainer, cssList.listed)}>
                        <p className={cssList.emptyStateText}><Html>{t('scouting.saved-player-list.empty-list-text')}</Html></p>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <table>
                                <thead>
                                    <tr className={cssList.listHeader}>
                                        <th className={cssList.playerPicture}></th>
                                        {sortableColumn('PlayerName', 'playerName')}
                                        {sortableColumn('ClassificationID', 'classification')}
                                        {sortableColumn('Birthday', 'birthYear')}
                                        {sortableColumn('TeamName', 'teamName')}
                                        {sortableColumn('PlayerPositionID', 'post')}
                                        {sortableColumn('MinutesPlayed', 'minutes')}
                                        {sortableColumn('ReportDate', 'date')}
                                        {sortableColumn('ScoutingIndexGroupMean', 'fradiIndex')}
                                        <th className={cssList.polarChart}></th>
                                        {sortableColumn('InShadowTeamFlg', 'shadowTeam')}
                                    </tr>
                                </thead>
                                <tbody>
                                    {players.map(player => (
                                        <PlayerShortListCard
                                            key={player.PlayerID}
                                            player={player}
                                            handlePlayerLayer={handlePlayerLayer}
                                            searchToTeam={searchToTeam}
                                            excludedColumns={['playerList', 'shortList']}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </div>
                </div>
            </>}
            <FradiLoader visible={!loadedList}/>
        </div>
    </>;

};

export default LoadedPlayerListTemplate;
