/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LastNPlayers } from '../models/LastNPlayers';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SideBarService {

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static cubePlayerAlertsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/CubePlayerAlerts/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static followPlayerCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * User language id from Azure AD B2C
             */
            PlayerID?: number;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/FollowPlayer/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static lastFollowedPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LastFollowedPlayers/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static lastSavedReportsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LastSavedReports/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static lastViewedPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LastViewedPlayers/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static playerAlertsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerAlerts/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static unFollowPlayerCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * User language id from Azure AD B2C
             */
            PlayerID?: number;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/UnFollowPlayer/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns LastNPlayers
     * @throws ApiError
     */
    public static viewPlayerCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * User language id from Azure AD B2C
             */
            PlayerID?: number;
        },
    ): CancelablePromise<LastNPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ViewPlayer/',
            body: data,
        });
    }

}
