import europe from './europe.json';
import asia from './asia.json';
import africa from './africa.json';
import northAmerica from './northAmerica.json';
import southAmerica from './southAmerica.json';
import oceania from './oceania.json';
import {FeatureCollection, Geometry, GeometryCollection} from 'geojson';

export const ContinentEurope: GeometryCollection = europe as GeometryCollection;
export const ContinentAsia: GeometryCollection = asia as GeometryCollection;
export const ContinentAfrica: GeometryCollection = africa as GeometryCollection;
export const ContinentNorthAmerica: GeometryCollection = northAmerica as GeometryCollection;
export const ContinentSouthAmerica: GeometryCollection = southAmerica as GeometryCollection;
export const ContinentOceania: GeometryCollection = oceania as GeometryCollection;

export interface GeoContinentProperties {
    continentName: string,
    continentId: string,
    lat: number,
    lng: number,
}

export const ContinentCollection: FeatureCollection<Geometry, GeoContinentProperties> = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                continentName: 'Europe',
                continentId: 'europe',
                lat: 47,
                lng: 10,
            },
            geometry: ContinentEurope,
        },
        {
            type: 'Feature',
            properties: {
                continentName: 'Asia',
                continentId: 'asia',
                lat: 40,
                lng: 110,
            },
            geometry: ContinentAsia,
        },
        {
            type: 'Feature',
            properties: {
                continentName: 'Africa',
                continentId: 'africa',
                lat: 0,
                lng: 30
            },
            geometry: ContinentAfrica,
        },
        {
            type: 'Feature',
            properties: {
                continentName: 'NorthAmerica',
                continentId: 'northAmerica',
                lat: 40,
                lng: -100,
            },
            geometry: ContinentNorthAmerica,
        },
        {
            type: 'Feature',
            properties: {
                continentName: 'SouthAmerica',
                continentId: 'southAmerica',
                lat: -30,
                lng: -60,
            },
            geometry: ContinentSouthAmerica,
        },
        {
            type: 'Feature',
            properties: {
                continentName: 'Oceania',
                continentId: 'oceania',
                lat: -30,
                lng: 135,
            },
            geometry: ContinentOceania,
        },
    ]
};
