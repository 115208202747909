/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllPlayerAvgStatsByPosition } from '../models/AllPlayerAvgStatsByPosition';
import type { EmptyReturn } from '../models/EmptyReturn';
import type { FileBase64 } from '../models/FileBase64';
import type { Formation } from '../models/Formation';
import type { PlayerBaseData } from '../models/PlayerBaseData';
import type { PlayerReport } from '../models/PlayerReport';
import type { PlayerReportAlt2 } from '../models/PlayerReportAlt2';
import type { PlayerReportList } from '../models/PlayerReportList';
import type { PlayerStatsLastSeasons } from '../models/PlayerStatsLastSeasons';
import type { PlayerStrengthWeakness } from '../models/PlayerStrengthWeakness';
import type { ReportParams } from '../models/ReportParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlayerReportService {

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deleteFromPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteFromPlayerList/',
            body: data,
        });
    }

    /**
     * Delete the report based on the provided report ID
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deletePlayerReportCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * Report ID
             */
            ReportID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeletePlayerReport/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns FileBase64
     * @throws ApiError
     */
    public static downloadPlayerReportBase64Create(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player id
             */
            PlayerID?: number;
            /**
             * Report id
             */
            ReportID?: number;
        },
    ): CancelablePromise<FileBase64> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DownloadPlayerReportBase64/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerReport
     * @throws ApiError
     */
    public static latestReportForPlayerCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LatestReportForPlayer/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns AllPlayerAvgStatsByPosition
     * @throws ApiError
     */
    public static ownTeamPlayerAvgReportCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Position Id if needed
             */
            PositionID?: number;
        },
    ): CancelablePromise<AllPlayerAvgStatsByPosition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/OwnTeamPlayerAvgReport/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerBaseData
     * @throws ApiError
     */
    public static playerBaseDataCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerBaseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerBaseData/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerReport
     * @throws ApiError
     */
    public static playerReportCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Report ID
             */
            ReportID?: number;
        },
    ): CancelablePromise<PlayerReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerReport/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerReportList
     * @throws ApiError
     */
    public static playerReportListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerReportList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerReportList/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerStatsLastSeasons
     * @throws ApiError
     */
    public static playerStatsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerStatsLastSeasons> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerStats/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerStrengthWeakness
     * @throws ApiError
     */
    public static playerStrengthWeaknessCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerStrengthWeakness> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerStrengthWeakness/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns Formation
     * @throws ApiError
     */
    public static reportFormationPositionsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<Formation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ReportFormationPositions/',
            body: data,
        });
    }

    /**
     * Get report params
     * @param data
     * @returns ReportParams
     * @throws ApiError
     */
    public static reportParamsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<ReportParams> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ReportParams/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerReportAlt2
     * @throws ApiError
     */
    public static savePlayerReportCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player report
             */
            PlayerReport?: {
                ReportID?: number | null;
                PlayerID?: number;
                Comment?: string | null;
                ClassificationID?: string | null;
                PlayerPositionID?: number;
                PlayerProfileID?: string | null;
                ResellableFlg?: boolean | null;
                Speed?: number | null;
                Dynamic?: number | null;
                Agility?: number | null;
                PhysiqueGroupSum?: number | null;
                PhysiqueGroupMean?: number | null;
                GameIntelligence?: number | null;
                Agression?: number | null;
                MentalityGroupSum?: number | null;
                MentalityGroupMean?: number | null;
                Technique?: number | null;
                BallStrikingAbility?: number | null;
                PressureResistance?: number | null;
                TechnicalGroupSum?: number | null;
                TechnicalGroupMean?: number | null;
                Positioning?: number | null;
                BoxDefending?: number | null;
                OneVOneDefending?: number | null;
                DefendingDuel?: number | null;
                DefendingIntensity?: number | null;
                DefendingInTransition?: number | null;
                PressingIntensity?: number | null;
                DefendingArealDuel?: number | null;
                DefendingGroupSum?: number | null;
                DefendingGroupMean?: number | null;
                CombinativeGame?: number | null;
                Acceleration?: number | null;
                OneVOneAttacking?: number | null;
                AttackingIntensity?: number | null;
                AttackingInTransition?: number | null;
                Passing?: number | null;
                AttackingDuel?: number | null;
                Progressivity?: number | null;
                BoxPresence?: number | null;
                Finishing?: number | null;
                Crossing?: number | null;
                KeyPasses?: number | null;
                AttackingArealDuel?: number | null;
                PlayAgainstDeepBlock?: number | null;
                AttackingGroupSum?: number | null;
                AttackingGroupMean?: number | null;
                ScoutingIndexGroupSum?: number | null;
                ScoutingIndexGroupMean?: number | null;
                FormationPosition_1?: string | null;
                FormationPosition_2?: string | null;
                FormationPosition_3?: string | null;
                TypicalFormation?: string | null;
            };
        },
    ): CancelablePromise<PlayerReportAlt2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavePlayerReport/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static saveToPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SaveToPlayerList/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param reportFile Document
     * @param userId User Object ID from Azure AD B2C
     * @param langId User language id from Azure AD B2C
     * @param playerId Player id
     * @returns PlayerReportAlt2
     * @throws ApiError
     */
    public static uploadPlayerReportCreate(
        reportFile: Blob,
        userId: string = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        langId: string = 'hu',
        playerId: number = 316750,
    ): CancelablePromise<PlayerReportAlt2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/UploadPlayerReport/',
            formData: {
                'ReportFile': reportFile,
                'UserID': userId,
                'LangID': langId,
                'PlayerID': playerId,
            },
        });
    }

}
