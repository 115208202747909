import jsPDF, { Context2d } from 'jspdf';
import { truncate } from 'lodash';
import { IMG, SVG, switching } from '../../helpers/utility';
import { Toastify } from '../../modules/Toastify/Toastify';
import { SizeType } from '../../interfaces/common';
import { BoxDescriptor } from './pdf-generation-layout';

export interface DimensionType {
    x: number;
    y: number;
    w: number;
    h?: number;
    rotate?: number;
}

export const pdfSize: SizeType = {
    width: 297,
    height: 210
};

/**
 * Szöveg szélessége a pdf-ben definiált mértékegységben
 * @param {jsPDF} pdf - jsPDF objektum
 * @param {string} text - szöveg
 * @return {number} szélesség
 */
export const textWidth = function(pdf: jsPDF, text: string): number {
    return pdf.getStringUnitWidth(text) * pdf.getFontSize() / pdf.internal.scaleFactor;
};

/**
 * Szöveg végének levágása ... végződéssel
 * @param {jsPDF} pdf - jsPDF objektum
 * @param {string} text - szöveg
 * @param {number} maxWidth - szöveg maximális hossza
 * @return {string} levágott szöveg
 */
export const textEllipsis = function(pdf: jsPDF, text: string, maxWidth: number): string {
    while (textWidth(pdf, text) > maxWidth) {
        text = truncate(text, {
            length: text.length - 1,
            omission: '...'
        });
    }
    return text;
};

/**
 * SVG beszúrása pdf-be
 * @param {object} obj
 * @param {jsPDF} obj.pdf - jsPDF objektum
 * @param {SVGSVGElement} obj.svgElement - svg dom elem
 * @param {number} obj.qualityWidth - romlás nelküli nagyíthatóság (ha van benne pixelgrafikus elem)
 * @param {DimensionType} obj.dimensions - pozíció (magasság nélkül)
 * @param {SizeType} obj.[box] - konténer méretei, amibe be kell férjen
 * @param {object} obj.[align] - konténeren belüli pozíció
 * @return {Promise<void>}
 */
export const addSvgToPdf = function({
    pdf,
    svgElement,
    qualityWidth,
    dimensions,
    box = null,
    align = { horizontal: 'center', vertical: 'middle' }
}: {
    pdf: jsPDF,
    svgElement: SVGSVGElement,
    qualityWidth: number,
    dimensions: DimensionType,
    box?: SizeType | null,
    align?: {
        horizontal: 'left' | 'center' | 'right',
        vertical: 'top' | 'middle' | 'bottom',
    }
}): Promise<void> {
    const svgDataUrl = SVG.elementToDataUrl(svgElement);
    return IMG.getRatio(
        svgDataUrl
    ).then(
        (ratio: number) => Promise.all([
            ratio,
            SVG.dataUrlToPng(svgDataUrl, qualityWidth)
        ])
    ).then(
        ([ ratio, pngDataUrl ]: [number, string]) => {
            if (box) {
                let x = 0, y = 0, width = 0, height = 0;
                if (ratio > box.width / box.height) {
                    // svg lapos
                    width = box.width;
                    height = box.width / ratio;
                    x = dimensions.x;
                    y = switching(align.vertical, {
                        'top': dimensions.y,
                        'middle': dimensions.y + (box.height - height) / 2,
                        'bottom': dimensions.y + box.height - height
                    }, 0);
                }
                if (ratio <= box.width / box.height) {
                    // svg keskeny
                    width = box.height * ratio;
                    height = box.height;
                    x = switching(align.horizontal, {
                        'left': dimensions.x,
                        'center': dimensions.x + (box.width - width) / 2,
                        'right': dimensions.x + box.width - width
                    }, 0);
                    y = dimensions.y;
                }
                pdf.addImage(
                    pngDataUrl, 'PNG', x, y, width, height, '', 'NONE', dimensions.rotate || 0
                );
            }
            else {
                pdf.addImage(
                    pngDataUrl, 'PNG', dimensions.x, dimensions.y, dimensions.w, dimensions.w / ratio, '', 'NONE', dimensions.rotate || 0
                );
            }
        }
    ).catch((_error: string | Event) => {
        Toastify({
            titleKey: 'general.message.error.pdf-generation.svg-insertion-failed'
        }).error();
    });
};

/**
 * Lekerekített sarkú téglalap rajzolása
 * @param {Context2d} ctx - jspdf canvas2d kontextus
 * @param {BoxDescriptor} box - doboz mérete
 * @param {number} round - l;ekerekítés sugara
 */
export const roundedBox = function(ctx: Context2d, box: BoxDescriptor, round: number) {
    ctx.beginPath();
    ctx.moveTo(box[0] + round, box[1]);
    ctx.lineTo(box[0] + box[2] - round, box[1]);
    ctx.arc(box[0] + box[2] - round, box[1] + round, round, Math.PI * 3 / 2, Math.PI * 2, false);
    ctx.lineTo(box[0] + box[2], box[1] + box[3] - round);
    ctx.arc(box[0] + box[2] - round, box[1] + box[3] - round, round, 0, Math.PI / 2, false);
    ctx.lineTo(box[0] + round, box[1] + box[3]);
    ctx.arc(box[0] + round, box[1] + box[3] - round, round, Math.PI / 2, Math.PI, false);
    ctx.lineTo(box[0], box[1] + round);
    ctx.arc(box[0] + round, box[1] + round, round, Math.PI, Math.PI * 3 / 2, false);
    ctx.stroke();
    ctx.fill();
};
