import {createContext} from 'react';
import {ContextProps} from './context.helper';

export interface HeaderContextProps {
    searchClickCallback: () => void,
}

export const HeaderContext = createContext<ContextProps<HeaderContextProps>>({
    context: undefined,
    setContext: () => undefined,
});
