import React, { ReactElement, useEffect, useState } from 'react';
import useAuth from '../../shared/hooks/auth.hook';
import { toastError } from '../../shared/helpers/errorhandler';
import { AdditionalService } from '../../shared/services/openapi';
import ScoutingPageTemplate from './ScoutingPageTemplate';

const ScoutingPage = function(): ReactElement {

    const { requestParams } = useAuth();
    const [ownShadowTeam, setOwnShadowTeam] = useState(false);

    useEffect(
        () => {
            if (requestParams.UserID) {
                AdditionalService.checkPrivilegesCreate(
                    requestParams
                ).then(
                    (check) => {
                        setOwnShadowTeam(check.OwnShadowTeamFlg);
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    return <>
        <ScoutingPageTemplate
            ownShadowTeam={ownShadowTeam}
        />
    </>;

};

export default ScoutingPage;
