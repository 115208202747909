import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { without } from 'lodash';
import { FileMetaDataType, createZip } from '../../../shared/helpers/utility';
import { NotDownloadedPlayerList, PlayerList, PlayerListsService, PlayerSearchData } from '../../../shared/services/openapi';
import { toastError } from '../../../shared/helpers/errorhandler';
import useAuth from '../../../shared/hooks/auth.hook';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import { generatePdf } from '../../../shared/services/pdf-generation/pdf-generation-playerlist';
import { createSvg } from '../short-list/ShortList';
import SavedPlayerListTemplate from './SavedPlayerListTemplate';

export interface PlayerListConfigSetting {
    id: number;
    type: 'rename' | 'delete';
}

const SavedPlayerList = function(): ReactElement {

    const { requestParams, account } = useAuth();

    const [loadings, setLoadings] = useState<string[]>([]);
    const [selectedConfigSettings, setSelectedConfigSettings] = useState<PlayerListConfigSetting | null>(null);
    const [configCollectionList, setConfigCollectionList] = useState<PlayerList[]>([]);
    const [isListModalVisible, setIsListModalVisible] = useState<boolean>(false);
    const [loadedList, setLoadedList] = useState<PlayerList>();
    const [players, setPlayers] = useState<PlayerSearchData[]>([]);
    const [progressValue, setProgressValue] = useState<number>(0);

    const getSavedPlayerLists = useCallback(
        () => {
            setLoadings(l => [...l, 'lists']);
            return PlayerListsService.playerListsCreate(
                requestParams
            ).finally(
                () => setLoadings(l => without(l, 'lists'))
            ).then(lists => {
                setConfigCollectionList(lists.PlayerLists ?? []);
            }).catch(
                toastError
            );
        },
        [requestParams]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                getSavedPlayerLists();
            }
        },
        [getSavedPlayerLists, requestParams]
    );

    const createPlayerList = function(name: string) {
        setLoadings(l => [...l, 'list']);
        return PlayerListsService.savePlayerListCreate({
            ...requestParams,
            PlayerListName: name,
            PlayerListID: null
        }).finally(
            () => {
                setLoadings(l => without(l, 'list'));
            }
        ).then(
            () => {
                setPlayers([]);
                getSavedPlayerLists();
            }
        ).catch(
            toastError
        );
    };

    const loadPlayerList = function(list: PlayerList) {
        setLoadings(l => [...l, 'list']);
        return PlayerListsService.playerListCreate({
            ...requestParams,
            PlayerListID: list.PlayerListID
        }).finally(
            () => setLoadings(l => without(l, 'list'))
        ).then(playerListData => {
            setIsListModalVisible(true);
            setPlayers(playerListData.Players ?? []);
            setLoadedList(list);
        }).catch(
            toastError
        );
    };

    const renamePlayerList = function(listId: number, name: string) {
        setLoadings(l => [...l, 'list']);
        return PlayerListsService.savePlayerListCreate({
            ...requestParams,
            PlayerListName: name,
            PlayerListID: listId
        }).finally(
            () => {
                setLoadings(l => without(l, 'list'));
            }
        ).then(
            () => {
                getSavedPlayerLists();
            }
        ).catch(
            toastError
        );
    };

    const deletePlayerList = function(listId: number) {
        setLoadings(l => [...l, 'list']);
        PlayerListsService.deletePlayerListCreate({
            ...requestParams,
            PlayerListID: listId
        }).finally(
            () => setLoadings(l => without(l, 'list'))
        ).then(_resp => {
            setSelectedConfigSettings(null);
            return getSavedPlayerLists();
        }).catch(
            toastError
        );
    };

    const getPdf = function(list: NotDownloadedPlayerList): Promise<Blob | void> {
        setLoadings(l => [...l, 'pdf']);
        return generatePdf({
            list: list.Players,
            charts: createSvg(list.Players),
            additionalData: {
                label: list.ListName,
                date: list.ListDate,
                username: list.UserName
            }
        }).finally(
            () => setLoadings(l => without(l, 'pdf'))
        ).then(
            (pdf: jsPDF) => {
                return pdf.output('blob');
            }
        ).catch(
            (error: Error) => {
                Toastify({
                    titleKey: 'general.message.error.pdf-generation.playerlist-failed'
                }).error();
                console.error(error);
            }
        );
    };

    const downloadZip = function(filename: string) {
        const files: FileMetaDataType[] = [];

        setProgressValue(0);

        setLoadings(l => [...l, 'download']);
        return PlayerListsService.notDownloadedPlayerListsCreate(
            requestParams
        ).finally(
            () => setLoadings(l => without(l, 'download'))
        ).then(
            async (groupedList) => {
                if (groupedList.PlayerLists.length === 0) {
                    Toastify({
                        titleKey: 'scouting.saved-player-list.no-downloadable-list'
                    }).warning();
                    return;
                }
                let current = 1;
                for (const playerList of groupedList.PlayerLists) {
                    setProgressValue(Math.round((current / (groupedList.PlayerLists.length + 2)) * 100));
                    const file = await getPdf(playerList);
                    if (file) {
                        files.push({
                            name: `Playerlist_${playerList.ListName}_${account?.name}.pdf`.replace(/\s/g, '_'),
                            lastModified: new Date(),
                            input: file
                        });
                    }
                    current++;
                }
                setProgressValue(Math.round((current / (groupedList.PlayerLists.length + 2)) * 100));
                await createZip(files, `${filename}.zip`);
                setProgressValue(100);
            }
        ).catch(
            toastError
        );
    };

    return <>
        <SavedPlayerListTemplate
            loadedList={loadedList}
            players={players}
            configCollectionList={configCollectionList}
            createPlayerList={createPlayerList}
            loadPlayerList={loadPlayerList}
            renamePlayerList={renamePlayerList}
            deletePlayerList={deletePlayerList}
            downloadZip={downloadZip}
            progressValue={progressValue}
            setProgressValue={setProgressValue}
            selectedConfigSettings={selectedConfigSettings}
            setSelectedConfigSettings={setSelectedConfigSettings}
            isListModalVisible={isListModalVisible}
            setIsListModalVisible={setIsListModalVisible}
            loadings={loadings}
        />
    </>;

};

export default SavedPlayerList;
