import jsPDF from 'jspdf';
import { translate as t } from '../../helpers/translate';
import { PlayerBaseData, PlayerReport } from '../openapi';
import {
    BoxDescriptor, addLayout, blackHex, c, createPlayerImageCanvas, darkGreenHex,
    darkGreyHex, lightBorderHex, lightGreenHex, lightGreyHex, paleGreenHex, whiteHex
} from './pdf-generation-layout';
import { getYear, parsePlayerName, switching } from '../../helpers/utility';
import { addSvgToPdf, roundedBox, textEllipsis } from './pdf-generation-utility';
import { Data } from '../../interfaces/common';
import { ProfileGroupTypes } from '../../interfaces/carried-on.interfaces';
import { CATEGORIES } from '../../interfaces/reports.interface';
import { pdfFontContentBold, pdfFontContentRegular, pdfFontHeaderBold } from '../../../data/fonts';

const playerDataBox = async function({
    pdf,
    player,
    report,
    chart
}: {
    pdf: jsPDF,
    player: PlayerBaseData,
    report: PlayerReport,
    chart: SVGSVGElement | undefined
}) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');
    pdf.addFileToVFS('open-sans-bold.ttf', pdfFontContentBold);
    pdf.addFont('open-sans-bold.ttf', 'bold', 'bold');

    const box: BoxDescriptor = [c(82), c(220), c(1040), c(380)];
    const imageSize = 90;

    const textPositions = [
        box[1] + c(43),
        box[1] + c(92),
        box[1] + c(141),
        box[1] + c(190),
        box[1] + c(239),
        box[1] + c(288),
        box[1] + c(337)
    ];
    const labelX = box[0] + c(470);
    const valueX = box[0] + c(732);

    const ctx = pdf.context2d;

    // doboz
    ctx.strokeStyle = lightGreenHex;
    ctx.fillStyle = whiteHex;
    ctx.lineWidth = 0.5;
    roundedBox(ctx, box, c(5));

    // játékos kép
    const img = await createPlayerImageCanvas(player.PlayerImageBase64, imageSize / 2);
    pdf.addImage(
        img, 'PNG', box[0] + c(30), box[1] + c(30), c(imageSize), c(imageSize), '', 'NONE', 0
    );

    // játékos kép körvonal
    ctx.strokeStyle = CATEGORIES[report.ClassificationID as keyof typeof CATEGORIES] ?? lightBorderHex;
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(box[0] + c(30) + c(imageSize) / 2, box[1] + c(30) + c(imageSize) / 2, c(imageSize) / 2 + 1, 0, Math.PI * 2, false);
    ctx.stroke();

    // játékos név
    const [firstName, lastName] = parsePlayerName(player.PlayerName);
    pdf.setTextColor(darkGreenHex);
    pdf.setFontSize(11).setFont('normal', 'normal', 'normal');
    pdf.text(firstName, box[0] + c(145), box[1] + c(67), { align: 'left' });
    pdf.setFontSize(11).setFont('bold', 'normal', 'normal');
    pdf.text(textEllipsis(pdf, lastName.toUpperCase(), c(300)), box[0] + c(145), box[1] + c(104), { align: 'left' });

    // játékos scouting index
    const offset = [373, 60];
    let radius: number;
    pdf.setTextColor('#D3A416');
    pdf.setFontSize(11).setFont('normal', 'normal', 'normal');
    pdf.text(String(report.ScoutingIndexGroupMean ?? '-'), box[0] + c(offset[0]), box[1] + c(offset[1]), { align: 'left' });

    // játékos scouting index körvonal
    radius = 41;
    ctx.strokeStyle = '#ffeaac';
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(
        box[0] + c(offset[0]) + c(radius) / 2 - c(8), box[1] + c(offset[1]) + c(radius) / 2 - c(29), c(radius) / 2, 0, Math.PI * 2, false
    );
    ctx.stroke();
    radius = 35;
    ctx.strokeStyle = '#D3A416';
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.arc(
        box[0] + c(offset[0]) + c(radius) / 2 - c(5), box[1] + c(offset[1]) + c(radius) / 2 - c(26), c(radius) / 2, 0, Math.PI * 2, false
    );
    ctx.stroke();

    // elvalasztó vonalak
    pdf.setDrawColor(paleGreenHex);
    pdf.setLineWidth(0.5);
    pdf.line(box[0] + c(23), box[1] + c(142) + 0.5, box[0] + c(420), box[1] + c(142) + 0.5);
    pdf.line(box[0] + c(446), box[1] + c(25), box[0] + c(446), box[1] + c(357));

    // játekos adatok
    pdf.setTextColor(lightGreyHex);
    pdf.setFontSize(9).setFont('normal', 'normal', 'normal');
    pdf.text(t('pdf.player-report.player.birthyear').toUpperCase(), labelX, textPositions[0], { align: 'left' });
    pdf.text(t('pdf.player-report.player.team').toUpperCase(), labelX, textPositions[1], { align: 'left' });
    pdf.text(t('pdf.player-report.player.post').toUpperCase(), labelX, textPositions[2], { align: 'left' });
    pdf.text(t('pdf.player-report.player.profile').toUpperCase(), labelX, textPositions[3], { align: 'left' });
    pdf.text(t('pdf.player-report.player.internationality').toUpperCase(), labelX, textPositions[4], { align: 'left' });
    pdf.text(t('pdf.player-report.player.category').toUpperCase(), labelX, textPositions[5], { align: 'left' });
    pdf.text(t('pdf.player-report.player.resellable').toUpperCase(), labelX, textPositions[6], { align: 'left' });
    pdf.setTextColor(darkGreyHex);
    pdf.setFontSize(10).setFont('bold', 'normal', 'normal');
    pdf.text(getYear(player.Birthday) || '-', valueX, textPositions[0], { align: 'left' });
    pdf.text(player.TeamName, valueX + c(25), textPositions[1], { align: 'left' });
    pdf.text(String(report.PlayerPositionID), valueX, textPositions[2], { align: 'left' });
    pdf.text(report.PlayerProfileID ? t(`general.profile.${report.PlayerProfileID}`) : '-', valueX, textPositions[3], { align: 'left' });
    pdf.text(String(player.PlayedInNational), valueX, textPositions[4], { align: 'left' });
    pdf.text(report.ClassificationID ?? '-', valueX, textPositions[5], { align: 'left' });
    pdf.text(
        switching(report.ResellableFlg, {
            true: t('general.button.yes'),
            false: t('general.button.no')
        }, '-'),
        valueX, textPositions[6], { align: 'left' }
    );

    // csapat kép
    pdf.addImage(
        player.TeamImageBase64, 'PNG', valueX, textPositions[1] - c(20), c(20), c(20), '', 'NONE', 0
    );

    // grafikon
    if (chart) {
        await addSvgToPdf({
            pdf,
            svgElement: chart,
            qualityWidth: 800,
            dimensions: {
                x: box[0] + c(20),
                y: box[1] + c(156),
                w: 0
            },
            box: {
                width: c(410),
                height: c(220)
            }
        });
    }

};

const chartBox = async function({
    pdf,
    report,
    charts
}: {
    pdf: jsPDF,
    report: PlayerReport,
    charts: Data<SVGSVGElement>
}) {

    pdf.addFileToVFS('montserrat-semibold.ttf', pdfFontHeaderBold);
    pdf.addFont('montserrat-semibold.ttf', 'bold', 'normal');

    const box: BoxDescriptor = [c(82), c(624), c(1040), c(720)];
    const ctx = pdf.context2d;

    const centerChart = {
        attacking: [8, 10, 9, 7, 11],
        defending: [6, 2, 4, 3, 5]
    };
    let isAttacking = false;

    if (centerChart.attacking.includes(report.PlayerPositionID ?? 0)) {
        isAttacking = true;
    }

    // doboz
    ctx.strokeStyle = lightGreenHex;
    ctx.fillStyle = whiteHex;
    ctx.lineWidth = 0.5;
    roundedBox(ctx, box, c(5));

    // vonalak
    const crossing = [c(274), c(123)];
    pdf.setLineDashPattern([2, 1], 0);
    pdf.line(box[0], box[1] + box[3] / 2, box[0] + crossing[0], box[1] + box[3] / 2);
    pdf.line(box[0] + box[2], box[1] + box[3] / 2, box[0] + box[2] - crossing[0], box[1] + box[3] / 2);
    pdf.line(box[0] + box[2] / 2, box[1], box[0] + box[2] / 2, box[1] + crossing[1]);
    pdf.line(box[0] + box[2] / 2, box[1] + box[3], box[0] + box[2] / 2, box[1] + box[3] - crossing[1]);
    pdf.line(box[0] + box[2] - crossing[0], box[1] + box[3] / 2, box[0] + box[2] / 2, box[1] + crossing[1]);
    pdf.line(box[0] + crossing[0], box[1] + box[3] / 2, box[0] + box[2] / 2, box[1] + crossing[1]);
    pdf.line(box[0] + crossing[0], box[1] + box[3] / 2, box[0] + box[2] / 2, box[1] + box[3] - crossing[1]);
    pdf.line(box[0] + box[2] / 2, box[1] + box[3] - crossing[1], box[0] + box[2] - crossing[0], box[1] + box[3] / 2);
    pdf.setLineDashPattern([1, 0], 0);

    // grafikon címek
    const titlePos = [
        [box[0] + c(216), box[1] + c(46)], [box[0] + c(826), box[1] + c(46)],
        [box[0] + c(518), box[1] + c(229)],
        [box[0] + c(216), box[1] + c(422)], [box[0] + c(826), box[1] + c(422)]
    ];
    pdf.setTextColor(darkGreenHex);
    pdf.setFontSize(9).setFont('bold', 'normal', 'normal');
    pdf.text(t(`general.group.${ProfileGroupTypes.PHYSIQUE}`), titlePos[0][0], titlePos[0][1], { align: 'center' });
    pdf.text(t(`general.group.${ProfileGroupTypes.MENTALITY}`), titlePos[1][0], titlePos[1][1], { align: 'center' });
    pdf.text(
        t(`general.group.${ProfileGroupTypes.ATTACKING}`),
        isAttacking ? titlePos[2][0] : titlePos[4][0],
        isAttacking ? titlePos[2][1] : titlePos[4][1],
        { align: 'center' }
    );
    pdf.text(
        t(`general.group.${ProfileGroupTypes.DEFENDING}`),
        isAttacking ? titlePos[4][0] : titlePos[2][0],
        isAttacking ? titlePos[4][1] : titlePos[2][1],
        { align: 'center' }
    );
    pdf.text(t(`general.group.${ProfileGroupTypes.TECHNICAL}`), titlePos[3][0], titlePos[3][1], { align: 'center' });

    // grafikonok
    const order = [
        { type: ProfileGroupTypes.PHYSIQUE, pos: [40, 60] },
        { type: ProfileGroupTypes.MENTALITY, pos: [645, 60] },
        { type: ProfileGroupTypes.ATTACKING, pos: isAttacking ? [340, 220] : [40, 440] },
        { type: ProfileGroupTypes.DEFENDING, pos: isAttacking ? [40, 440] : [340, 220] },
        { type: ProfileGroupTypes.TECHNICAL, pos: [645, 440] }
    ];
    for (const groupType of order) {
        if (charts[groupType.type]) {
            await addSvgToPdf({
                pdf,
                svgElement: charts[groupType.type],
                qualityWidth: 800,
                dimensions: {
                    x: box[0] + c(groupType.pos[0]),
                    y: box[1] + c(groupType.pos[1]),
                    w: 0
                },
                box: {
                    width: c(360),
                    height: c(290)
                }
            });
        }
    }

};

const commentBox = async function({
    pdf,
    report,
    length
}: {
    pdf: jsPDF,
    report: PlayerReport,
    length: number
}) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');
    pdf.addFileToVFS('open-sans-bold.ttf', pdfFontContentBold);
    pdf.addFont('open-sans-bold.ttf', 'bold', 'bold');

    const box: BoxDescriptor = [c(82), c(1368), c(1040), c(148)];
    if (length > 4) {
        box[1] = c(220);
        box[3] = c(68) + length * c(19);
    }
    const ctx = pdf.context2d;

    // doboz
    ctx.strokeStyle = lightGreenHex;
    ctx.fillStyle = whiteHex;
    ctx.lineWidth = 0.5;
    roundedBox(ctx, box, c(5));

    // szöveg
    pdf.setTextColor(blackHex);
    pdf.setFontSize(9).setFont('bold', 'normal', 'normal');
    pdf.text(t('pdf.player-report.comment') + ':', box[0] + c(24), box[1] + c(42), { align: 'left' });
    pdf.setFontSize(8).setFont('normal', 'normal', 'normal');
    if (report.Comment) {
        const lines = pdf.splitTextToSize(report.Comment, c(992));
        pdf.text(lines, box[0] + c(24), box[1] + c(68), { align: 'left' });
    }
    else {
        pdf.text('-', box[0] + c(24), box[1] + c(68), { align: 'left' });
    }

};

export const generatePdf = async function({
    player,
    report,
    charts
}: {
    player: PlayerBaseData,
    report: PlayerReport,
    charts: Map<string, SVGSVGElement>
}): Promise<jsPDF> {

    const pdf = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
        unit: 'px'
    });

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');

    let allPages = 1;

    pdf.setFontSize(8).setFont('normal', 'normal', 'normal');
    const commentLines = pdf.splitTextToSize(report.Comment ?? '', c(992));
    if (commentLines.length > 4) {
        allPages = 2;
    }

    pdf.setPage(1);
    await addLayout({
        pdf,
        title: t('pdf.player-report.title'),
        page: { current: 1, all: allPages },
        additionalData: {
            date: report.Date,
            username: report.UserName
        }
    });

    await playerDataBox({
        pdf,
        player,
        report,
        chart: [...charts].find(
            ([key, _svg]) => key.split('-')[1] === 'all'
        )?.[1]
    });

    await chartBox({
        pdf,
        report,
        charts: [...charts].reduce(
            (acc, [key, svg]) => ({
                ...acc,
                [key.split('-')[1]]: svg
            }),
            { }
        )
    });

    if (commentLines.length > 4) {
        pdf.addPage();
        pdf.setPage(2);
        await addLayout({
            pdf,
            title: t('pdf.player-report.title'),
            page: { current: 2, all: allPages },
            additionalData: {
                date: report.Date,
                username: report.UserName
            }
        });
    }

    await commentBox({
        pdf,
        report,
        length: commentLines.length
    });

    return pdf;

};
