import React, { ChangeEvent, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { isEqual, pick, uniqWith, without } from 'lodash';
import { toastError } from '../../../shared/helpers/errorhandler';
import useAuth from '../../../shared/hooks/auth.hook';
import {
    FollowedPlayersService, LastNPlayerData, LastNPlayers, PlayerBaseData, PlayerReportService, PlayerReportsService,
    PlayerSearch, PlayerSearchData, PlayerSearchDataReport, PlayerSearchReport, SideBarService, UserPlayerReport
} from '../../../shared/services/openapi';
import { PlayerLayerContext } from '../../../shared/contexts/PlayerLayer.context';
import { FileMetaDataType, SortDirection, createZip, sortDescriptor, switching } from '../../../shared/helpers/utility';
import { createPdf } from '../../../shared/modules/PageLayer/PlayerDetails/cards/ReportList';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import usePlayerProfile from '../../../shared/hooks/player-profile.hook';
import { Data } from '../../../shared/interfaces/common';
import { OptionType } from '../../../shared/modules/select/select';
import GeneralListTemplate from './GeneralListTemplate';
import { CategoryEnum, CategoryListOrder } from '../../../shared/interfaces/reports.interface';

export enum GeneralListType {
    'followed' = 'followed-players',
    'reported' = 'last-updated-reports',
    'alert' = 'alert',
    'cubeAlert' = 'cube-alert'
}

export interface SortingDescriptor {
    column: keyof (PlayerSearchDataReport & PlayerSearchData);
    direction: SortDirection;
}

const defaultSorting: Record<GeneralListType, SortingDescriptor> = {
    [GeneralListType.followed]: { column: 'PlayerName', direction: SortDirection.asc },
    [GeneralListType.reported]: { column: 'ReportDate', direction: SortDirection.desc },
    [GeneralListType.alert]: { column: 'PlayerName', direction: SortDirection.asc },
    [GeneralListType.cubeAlert]: { column: 'PlayerName', direction: SortDirection.asc },
};

const GeneralList = function({
    type
}: {
    type: GeneralListType
}): ReactElement {

    const { requestParams, account } = useAuth();
    const { chartCollectionConfig, getIndicators } = usePlayerProfile();
    const layerContext = useContext(PlayerLayerContext);
    const [loadings, setLoadings] = useState<string[]>([]);
    const [progressValue, setProgressValue] = useState<number>(0);
    const [players, setPlayers] = useState<PlayerSearchData[]>([]);
    const [filteredPlayers, setFilteredPlayers] = useState<PlayerSearchData[]>([]);
    const [reports, setReports] = useState<PlayerSearchDataReport[]>([]);
    const [filteredReports, setFilteredReports] = useState<PlayerSearchDataReport[]>([]);
    const [alerts, setAlerts] = useState<LastNPlayerData[]>([]);
    const [cubeAlerts, setCubeAlerts] = useState<LastNPlayerData[]>([]);
    const [optionPosition, setOptionPosition] = useState<OptionType[]>([]);
    const [selectedPosition, setSelectedPosition] = useState<number>(0);
    const [optionCategory, setOptionCategory] = useState<OptionType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sorting, setSorting] = useState<SortingDescriptor>(defaultSorting[type]);

    useEffect(
        () => {
            setSorting(defaultSorting[type]);
        },
        [type]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                let reportedList: PlayerSearchDataReport[] = [];

                const EndpointRequest = switching(type, {
                    [GeneralListType.followed]: FollowedPlayersService.followedPlayersCreate,
                    [GeneralListType.reported]: PlayerReportsService.playerReportsCreate,
                    [GeneralListType.alert]: SideBarService.playerAlertsCreate,
                    [GeneralListType.cubeAlert]: SideBarService.cubePlayerAlertsCreate,
                });

                setLoadings(l => [...l, 'list']);
                EndpointRequest(
                    requestParams
                ).finally(
                    () => {
                        setLoadings(l => without(l, 'list'));
                    }
                ).then(
                    (list: PlayerSearch | PlayerSearchReport | LastNPlayers) => {
                        const positions = uniqWith(
                            ((list as PlayerSearch | PlayerSearchReport).Players ?? []).map(
                                playerReport => ({
                                    id: playerReport.PlayerPositionID,
                                    value: String(playerReport.PlayerPositionID)
                                })
                            ),
                            isEqual
                        ).sort(
                            (pos1: OptionType, pos2: OptionType) => sortDescriptor(pos1.id, pos2.id, SortDirection.asc)
                        );
                        const categories = uniqWith(
                            ((list as PlayerSearch | PlayerSearchReport).Players ?? []).map(
                                playerReport => ({
                                    id: playerReport.ClassificationID,
                                    value: String(playerReport.ClassificationID)
                                })
                            ),
                            isEqual
                        ).sort(
                            (cat1: OptionType, cat2: OptionType) => {
                                const a = CategoryListOrder.indexOf(cat1.id as CategoryEnum);
                                const b = CategoryListOrder.indexOf(cat2.id as CategoryEnum);
                                return sortDescriptor(a, b, SortDirection.desc);
                            }
                        );
                        setOptionPosition(positions);
                        setOptionCategory(categories);
                        if (type === GeneralListType.followed) {
                            setPlayers((list as PlayerSearch).Players ?? []);
                        }
                        if (type === GeneralListType.reported) {
                            reportedList = (list as PlayerSearchReport).Players ?? [];
                            setReports(reportedList);
                            setFilteredReports(reportedList);
                        }
                        if (type === GeneralListType.alert) {
                            setAlerts((list as LastNPlayers).Players ?? []);
                        }
                        if (type === GeneralListType.cubeAlert) {
                            setCubeAlerts((list as LastNPlayers).Players ?? []);
                        }
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams, type]
    );

    const sortingList = useCallback(
        (p1: PlayerSearchData, p2: PlayerSearchData): number => {
            if (sorting.column === 'ClassificationID') {
                const a = CategoryListOrder.indexOf(p1.ClassificationID as CategoryEnum);
                const b = CategoryListOrder.indexOf(p2.ClassificationID as CategoryEnum);
                return sortDescriptor(a, b, sorting.direction);
            }
            else {
                return sortDescriptor(
                    p1[sorting.column as keyof PlayerSearchData], p2[sorting.column as keyof PlayerSearchData], sorting.direction
                );
            }
        },
        [sorting.column, sorting.direction]
    );

    useEffect(
        () => {
            setFilteredReports(
                reports.filter(
                    report => (
                        (selectedPosition === 0 || report.PlayerPositionID === selectedPosition) &&
                        (selectedCategory === '' || report.ClassificationID === selectedCategory) &&
                        (report.PlayerName.toLowerCase()).includes(searchTerm.toLowerCase())
                    )
                ).sort(
                    sortingList
                )
            );
        },
        [reports, searchTerm, selectedCategory, selectedPosition, sortingList]
    );

    useEffect(
        () => {
            setFilteredPlayers(
                players.filter(
                    player => (
                        (selectedPosition === 0 || player.PlayerPositionID === selectedPosition) &&
                        (selectedCategory === '' || player.ClassificationID === selectedCategory) &&
                        (player.PlayerName.toLowerCase()).includes(searchTerm.toLowerCase())
                    )
                ).sort(
                    sortingList
                )
            );
        },
        [players, searchTerm, selectedCategory, selectedPosition, sortingList]
    );

    const changeSearchTerm = function(event: ChangeEvent<HTMLInputElement>) {
        setSearchTerm(event.target.value);
    };

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true
        });
    };

    const searchToTeam = function(teamName: string) {
        layerContext.setContext({
            playerId: 0,
            isSearchModalOpen: teamName,
        });
    };

    const changeSorting = function(column: keyof (PlayerSearchDataReport & PlayerSearchData)) {
        if (sorting.column === column) {
            setSorting({
                column,
                direction: sorting.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc
            });
        }
        else {
            setSorting({
                column,
                direction: SortDirection.asc
            });
        }
    };

    const getPdf = function({
        player,
        report,
        orderCollection
    }: {
        player: PlayerBaseData,
        report: UserPlayerReport,
        orderCollection: Data<string[]>
    }): Promise<Blob | void> {
        setLoadings(l => [...l, 'pdf']);
        return createPdf({
            player,
            report,
            requestParams,
            orderCollection,
            chartCollectionConfig
        }).finally(
            () => {
                setLoadings(l => without(l, 'pdf'));
            }
        ).then(
            (pdf) => {
                return pdf.output('blob');
            }
        ).catch(
            (error: Error) => {
                Toastify({
                    titleKey: 'general.message.error.pdf-generation.player-report-failed'
                }).error();
                console.error(error);
            }
        );
    };

    const downloadZip = function(filename: string) {
        const files: FileMetaDataType[] = [];

        setProgressValue(0);

        setLoadings(l => [...l, 'download']);
        return Promise.all([
            PlayerReportsService.notDownloadedReportsCreate(requestParams),
            PlayerReportService.reportParamsCreate(requestParams)
        ]).finally(
            () => {
                setLoadings(l => without(l, 'download'));
            }
        ).then(
            async ([dwlReports, reportConfig]) => {
                if (dwlReports.PlayerReports.length === 0) {
                    Toastify({
                        titleKey: 'scouting.general-list.no-downloadable-report'
                    }).warning();
                    return;
                }
                let current = 1;
                for (const report of dwlReports.PlayerReports) {
                    setProgressValue(Math.round((current / (dwlReports.PlayerReports.length + 2)) * 100));
                    const playerData = await PlayerReportService.playerBaseDataCreate({
                        ...requestParams,
                        PlayerID: report.PlayerID
                    });
                    const file = await getPdf({
                        player: playerData,
                        report: pick(report, 'ReportID', 'UserName', 'PlayerID', 'ReportType', 'Date') as UserPlayerReport,
                        orderCollection: getIndicators(report.PlayerPositionID ?? 0, reportConfig)
                    });
                    if (file) {
                        files.push({
                            name: `${report.PlayerPositionID}/${playerData.PlayerName}_player_report_${report.PlayerID}_${account?.name}.pdf`.replace(/\s/g, '_'),
                            lastModified: new Date(),
                            input: file
                        });
                    }
                    current++;
                }
                setProgressValue(Math.round((current / (dwlReports.PlayerReports.length + 2)) * 100));
                await createZip(files, `${filename}.zip`);
                setProgressValue(100);
            }
        ).catch(
            toastError
        );
    };

    return <>
        <GeneralListTemplate
            type={type}
            players={filteredPlayers}
            reports={filteredReports}
            alerts={alerts}
            cubeAlerts={cubeAlerts}
            handlePlayerLayer={handlePlayerLayer}
            searchToTeam={searchToTeam}
            downloadZip={downloadZip}
            progressValue={progressValue}
            setProgressValue={setProgressValue}
            optionPosition={optionPosition}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            optionCategory={optionCategory}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            searchTerm={searchTerm}
            changeSearchTerm={changeSearchTerm}
            sorting={sorting}
            changeSorting={changeSorting}
            loadings={loadings}
        />
    </>;

};

export default GeneralList;
