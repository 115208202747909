import {LogLevel, Configuration} from '@azure/msal-browser';

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        SignIn: process.env.REACT_APP_AZURE_SIGNIN_NAME,
        forgotPassword: process.env.REACT_APP_AZURE_FORGOTPASSWORD_NAME,
        editProfile: process.env.REACT_APP_AZURE_EDITPROFILE_NAME
    },
    authorities: {
        SignIn: {
            authority: process.env.REACT_APP_AZURE_SIGNIN_AUTHORITY
        },
        forgotPassword: {
            authority: process.env.REACT_APP_AZURE_FORGOTPASSWORD_AUTHORITY
        },
        editProfile: {
            authority: process.env.REACT_APP_AZURE_EDITPROFILE_AUTHORITY
        }
    },
    authorityDomain: process.env.REACT_APP_AZURE_AUTHORITYDOMAIN as string
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.SignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/welcome', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: process.env.REACT_APP_AZURE_LOGOUT_REDIRECT_URL, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If 'true', will navigate back to the original request location before processing the auth code.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' | 'localStorage'.
        storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    // console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: []
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a 'login_hint' property.
 */
export const silentRequest = {
    scopes: ['openid', 'profile'],
    loginHint: 'example@domain.net'
};
