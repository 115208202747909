/* eslint-disable max-len */
import {Feature, FeatureCollection, Geometry, GeometryCollection} from 'geojson';
import gbEnglandGeo from '../gb/englandGeo.json';
import gbNorthernIrelandGeo from '../gb/northernIrelandGeo.json';
import gbScotlandGeo from '../gb/scotlandGeo.json';
import gbWalesGeo from '../gb/walesGeo.json';
// created width https://vector.rocks/
import gibGeo from './gibraltar.json';

export const CountryEngland: GeometryCollection = gbEnglandGeo as GeometryCollection;
export const CountryNorthernIreland: GeometryCollection = gbNorthernIrelandGeo as GeometryCollection;
export const CountryScotland: GeometryCollection = gbScotlandGeo as GeometryCollection;
export const CountryWales: GeometryCollection = gbWalesGeo as GeometryCollection;
export const CountryGibraltar: FeatureCollection<Geometry, GeoCustomProperties> = gibGeo as FeatureCollection<Geometry, GeoCustomProperties>;


export interface GeoCustomProperties {
    name: string,
    name_hu: string,
    adm0_iso: string,
    continent: string,
    lat: number,
    lng: number,
    label_y: number,
    label_x: number,
}

// "Scotland"
// "Wales"
// "England"
// "Northern Ireland"


const nationalitiesList: Feature<Geometry, GeoCustomProperties>[] = [
    {
        NationalityName: 'Bonaire',
        name: 'Bonaire',
        name_hu: 'Bonaire',
        NationalityID: 'BES'
    },
    {
        NationalityName: 'FrenchGuiana',
        name: 'French Guiana',
        name_hu: 'Francia Guyana',
        NationalityID: 'GUF'
    },
    {
        NationalityName: 'Guadeloupe',
        name: 'Guadeloupe',
        name_hu: 'Guadeloupe',
        NationalityID: 'GLP'
    },
    {
        NationalityName: 'Martinique',
        name: 'Martinique',
        name_hu: 'Martinique',
        NationalityID: 'MTQ'
    },
    {
        NationalityName: 'Mauritius',
        name: 'Mauritius',
        name_hu: 'Mauritius',
        NationalityID: 'MUS'
    },
    {
        NationalityName: 'Mayotte',
        name: 'Mayotte',
        name_hu: 'Mayotte',
        NationalityID: 'MYT'
    },
    {
        NationalityName: 'NetherlandsAntilles',
        name: 'Netherlands Antilles',
        name_hu: 'Holland Antillák',
        NationalityID: 'ANT'
    },
    {
        NationalityName: 'Palestine',
        name: 'Palestine',
        name_hu: 'Palesztina',
        NationalityID: 'PSE'
    },
    {
        NationalityName: 'PapuaNewGuinea',
        name: 'Papua New Guinea',
        name_hu: 'Pápua Új-Guinea',
        NationalityID: 'PNG'
    },
    {
        NationalityName: 'Réunion',
        name: 'Réunion',
        name_hu: 'Réunion',
        NationalityID: 'REU'
    },
    {
        NationalityName: 'Seychelles',
        name: 'Seychelles',
        name_hu: 'Seychelle-szigetek',
        NationalityID: 'SYC'
    },
    {
        NationalityName: 'Sudan',
        name: 'Sudan',
        name_hu: 'Szudán',
        NationalityID: 'SDN'
    },
    {
        NationalityName: 'WesternSahara',
        name: 'Western Sahara',
        name_hu: 'Nyugat-Szahara',
        NationalityID: 'ESH'
    }
].map<Feature<Geometry, GeoCustomProperties>>(item => ({
    type: 'Feature',
    properties: {
        name: item.name,
        name_hu: item.name_hu,
        adm0_iso: item.NationalityID,
        continent: item.NationalityName,
        lat: 0,
        lng: 0,
        label_y: 0,
        label_x: 0,
    } as GeoCustomProperties,
    geometry: {
        type: 'GeometryCollection',
        geometries: []
    }
}));


export const CustomGeoCollection: FeatureCollection<Geometry, GeoCustomProperties> = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                name: 'England',
                name_hu: 'Anglia',
                adm0_iso: 'GB-ENG',
                continent: 'europe',
                lat: 52,
                lng: -1,
                label_y: 52,
                label_x: -1,
            },
            geometry: CountryEngland,
        },
        {
            type: 'Feature',
            properties: {
                name: 'Northern Ireland',
                name_hu: 'Észak-Írország',
                adm0_iso: 'GB-NIR',
                continent: 'europe',
                lat: 54.6,
                lng: -6.5,
                label_y: 54.6,
                label_x: -6.5,
            },
            geometry: CountryNorthernIreland,
        },
        {
            type: 'Feature',
            properties: {
                name: 'Scotland',
                name_hu: 'Skócia',
                adm0_iso: 'GB-SCT',
                continent: 'europe',
                lat: 56.5,
                lng: -4,
                label_y: 56.5,
                label_x: -4,
            },
            geometry: CountryScotland,
        },
        {
            type: 'Feature',
            properties: {
                name: 'Wales',
                name_hu: 'Wales',
                adm0_iso: 'GB-WLS',
                continent: 'europe',
                lat: 52.5,
                lng: -3.5,
                label_y: 52.5,
                label_x: -3.5,
            },
            geometry: CountryWales,
        },

        ...nationalitiesList,
        ...CountryGibraltar.features,
        // translate vaules
    ],
};
