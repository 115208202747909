import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { isNumber, without } from 'lodash';
import { translate as t } from '../../helpers/translate';
import { cx } from '../../helpers/utility';
import { toastError } from '../../helpers/errorhandler';
import useAuth from '../../hooks/auth.hook';
import { PlayerReportsService } from '../../services/openapi';
import { MenuContext } from '../../contexts/Menu.context';
import css from './TabBox.module.scss';

const TabBox = function({
    ownShadowTeam
}: {
    ownShadowTeam: boolean
}): ReactElement {

    const { requestParams } = useAuth();
    const { context: menuReload, setContext: setMenuReload } = useContext(MenuContext);
    const firstLoad = useRef(true);
    const [_loadings, setLoadings] = useState<string[]>([]);
    const [notViewedCount, setNotViewedCount] = useState<number>();

    useEffect(
        () => {
            if (requestParams.UserID && (menuReload || firstLoad.current)) {
                if (firstLoad.current) {
                    firstLoad.current = false;
                }
                setLoadings(l => [...l, 'notViewed']);
                PlayerReportsService.notViewedPlayerReportsCreate(
                    requestParams
                ).finally(
                    () => {
                        setLoadings(l => without(l, 'notViewed'));
                        setMenuReload(false);
                    }
                ).then(
                    (notViewedReports) => {
                        setNotViewedCount(notViewedReports.NotViewedReportsCount);
                    }
                ).catch(
                    toastError
                );
            }
        },
        [menuReload, requestParams, setMenuReload]
    );

    return <>
        <div className={css.tabBox}>
            <NavLink
                className={
                    ({isActive}) => cx(css.tabBoxButton, isActive ? css.active : null)
                }
                to={'/scouting/overview'}
            >
                {t('scouting.tabs.overview')}
            </NavLink>
            <NavLink
                className={
                    ({isActive}) => cx(css.tabBoxButton, isActive ? css.active : null)
                }
                to={'/scouting/uploaded-report-players'}
            >
                {t('scouting.tabs.last-updated-reports')}
                {isNumber(notViewedCount) && notViewedCount > 0 && <>
                    &nbsp;<span className={css.itemValue}>({notViewedCount} {t('general.label.new')})</span>
                </>}
            </NavLink>
            <NavLink
                className={
                    ({isActive}) => cx(css.tabBoxButton, isActive ? css.active : null)
                }
                to={'/scouting/shortlist'}
            >
                {t('scouting.tabs.shortlist')}
            </NavLink>
            <NavLink
                className={
                    ({isActive}) => cx(css.tabBoxButton, isActive ? css.active : null)
                }
                to={'/scouting/shadow-team'}
            >
                {t('scouting.tabs.shadow-team')}
            </NavLink>
            <NavLink
                className={
                    ({isActive}) => cx(css.tabBoxButton, isActive ? css.active : null)
                }
                to={'/scouting/saved-player-list'}
            >
                {t('scouting.tabs.saved-player-list')}
            </NavLink>
            <div className={cx(css.tabBoxButton, css.playerAlerts)}>
                {t('scouting.tabs.player-alert')}
                <div className={css.dropDownContainer}>
                    <div className={css.dropDownMenu}>
                        <NavLink className={css.dropDownItem} to={'/scouting/followed-players'}>
                            {t('scouting.tabs.followed-players')}
                        </NavLink>
                        <NavLink className={css.dropDownItem} to={'/scouting/player-filter'}>
                            {t('scouting.tabs.player-filter')}&nbsp;
                        </NavLink>
                        <NavLink className={css.dropDownItem} to={'/scouting/compare'}>
                            {t('scouting.tabs.compare')}
                        </NavLink>
                        {/*
                        <NavLink className={css.dropDownItem} to={'/scouting/actual-player-list'}>
                            {t('scouting.tabs.actual-player-list')}
                        </NavLink>
                        */}
                        {ownShadowTeam && (
                            <NavLink className={css.dropDownItem} to={'/scouting/own-shadow-team'}>
                                {t('scouting.tabs.own-shadow-team')}
                            </NavLink>
                        )}
                        <span className={cx(css.dropDownItem, css.disabled)} /* to={'/scouting/alert-players'} */>
                            {t('scouting.tabs.alert')}
                        </span>
                        <span className={cx(css.dropDownItem, css.disabled)} /* to={'/scouting/cube-alert-players'} */>
                            {t('scouting.tabs.cube-alert')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className={css.h100p}>
            <Outlet/>
        </div>
    </>;

};

export default TabBox;
