import React, { ReactElement, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import * as echarts from 'echarts';
import jsPDF from 'jspdf';
import { without } from 'lodash';
import { cx } from '../../../../helpers/utility';
import { translate as t } from '../../../../helpers/translate';
import { ReportActionType } from '../../../../interfaces/carried-on.interfaces';
import { ApiError, PlayerBaseData, PlayerReport, PlayerReportService, UserPlayerReport } from '../../../../services/openapi';
import { timeFormat } from '../../../../helpers/formatter';
import useAuth from '../../../../hooks/auth.hook';
import { Toastify } from '../../../Toastify/Toastify';
import { generatePdf } from '../../../../services/pdf-generation/pdf-generation-player-report';
import { RadarConfig, getChartOptions } from '../../../EchartsWrapper/EchartsRadar/EchartsRadar';
import usePlayerProfile from '../../../../hooks/player-profile.hook';
import { Data } from '../../../../interfaces/common';
import Tooltip from '../../../Tooltip/Tooltip';
import GenericModal from '../../../GenericModal/GenericModal';
import FileUploader, { MB } from '../../../FileUploader/FileUploader';
import { toastError } from '../../../../helpers/errorhandler';
import css from './ReportList.module.scss';
import parentCss from '../PlayerDetails.module.scss';
import IconFileRecord from '../../../../../assets/images/icons/icon_file_add.svg';
import IconManualRecord from '../../../../../assets/images/icons/icon_file_match_report.svg';
import IconCubeRecord from '../../../../../assets/images/icons/icon_cube_report.svg';
import IconEye from '../../../../../assets/images/icons/icon_eye_open_view.svg';
import IconDelete from '../../../../../assets/images/icons/icon_delete.svg';
import IconDownload from '../../../../../assets/images/icons/icon_file_download.svg';
import IconMail from '../../../../../assets/images/icons/icon_mail.svg';
import IconPdf from '../../../../../assets/images/icons/icon_file_clipboard.svg';

export const createPdf = function({
    player,
    report,
    requestParams,
    orderCollection,
    chartCollectionConfig
}: {
    player: PlayerBaseData,
    report: UserPlayerReport,
    requestParams: ReturnType<typeof useAuth>['requestParams'],
    orderCollection: Data<string[]>,
    chartCollectionConfig: Data<RadarConfig<PlayerReport>>
}): Promise<jsPDF> {

    return PlayerReportService.playerReportCreate({
        ...requestParams,
        ReportID: report.ReportID
    }).then(
        (pdfReportData) => {
            const reportSvg: Map<string, SVGSVGElement> = new Map();
            Object.keys(orderCollection).forEach(
                indicator => {
                    const options = getChartOptions({
                        dataList: pdfReportData,
                        config: chartCollectionConfig[indicator],
                        order: orderCollection[indicator] as (keyof PlayerReport)[],
                        showLabel: true,
                        showTicks: true,
                        colors: indicator === 'all' ? ['#C9E8D0'] : ['#ABCBE5'],
                        animation: false
                    });

                    const chart = echarts.init(
                        document.createElement('div'), null, { renderer: 'svg', width: 530, height: 280 }
                    );
                    chart.setOption(options);
                    reportSvg.set(
                        `${pdfReportData.ReportID}-${indicator}`,
                        chart.getDom().querySelector('svg') as SVGSVGElement
                    );
                }
            );

            return generatePdf({
                player,
                report: pdfReportData,
                charts: reportSvg
            });
        }
    ).then(
        (pdf: jsPDF) => {
            return pdf;
        }
    );
};

export interface PlayerBaseCardProps {
    reports: UserPlayerReport[],
    player: PlayerBaseData,
    actionOfReport: (reportId: number | boolean, action: ReportActionType) => Promise<void>,
    orderCollection: Record<string, string[]>
}

const ReportList = function({
    reports,
    player,
    actionOfReport,
    orderCollection
}: PlayerBaseCardProps): ReactElement {

    const { requestParams } = useAuth();
    const { chartCollectionConfig } = usePlayerProfile();

    const [_loadings, setLoadings] = useState<string[]>([]);
    const [fileUploaderOpened, setFileUploaderOpened] = useState(false);
    const [reportList, setReportList] = useState<UserPlayerReport[]>(reports);

    useEffect(
        () => {
            setReportList(reports);
        },
        [reports]
    );

    const uploadReport = function() {
        setFileUploaderOpened(true);
    };

    const tableRefresh = function(_reportId: number) {
        if (requestParams.UserID) {
            PlayerReportService.playerReportListCreate({
                ...requestParams,
                PlayerID: player.PlayerID
            }).then(
                resp => {
                    setReportList(resp.PlayerReports ?? []);
                }
            ).catch(
                (error: ApiError) => {
                    setReportList([]);
                    toastError(error);
                }
            );
        }
    };

    const savePdf = function(report: UserPlayerReport) {
        setLoadings(l => [...l, 'pdf']);
        createPdf({
            player,
            report,
            requestParams,
            orderCollection,
            chartCollectionConfig
        }).finally(
            () => {
                setLoadings(l => without(l, 'pdf'));
            }
        ).then(
            (pdf) => {
                pdf.save(`${player.PlayerName.replace(/\s/g, '_')}_player_report.pdf`);
            }
        ).catch(
            (error: Error) => {
                Toastify({
                    titleKey: 'general.message.error.pdf-generation.player-report-failed'
                }).error();
                console.error(error);
            }
        );
    };

    return <>
        <div className={cx(parentCss.content, parentCss.report, css.report)}>
            <div className={css.reportHeader}>
                <h2 className={css.headerTitle}>{t('playerDetails.report.title')}</h2>
                <div className={css.headerActions}>
                    <button
                        className={cx('btn icon', css.actionBtn)}
                        onClick={() => uploadReport()}
                    >
                        <Tooltip text={t('playerReport.button.file-record')}>
                            <img src={IconFileRecord} alt="" />
                        </Tooltip>
                    </button>
                    <button
                        className={cx('btn icon', css.actionBtn)}
                        onClick={() => actionOfReport(true, ReportActionType.ADD)}
                    >
                        <Tooltip text={t('playerReport.button.manual-record')}>
                            <img src={IconManualRecord} alt="" />
                        </Tooltip>
                    </button>
                    <button
                        className={cx('btn icon disabled', css.actionBtn)}
                        onClick={() => console.info('add file')}
                    >
                        <Tooltip text={t('playerReport.button.cube-record')} className="inactive">
                            <img src={IconCubeRecord} alt="" />
                        </Tooltip>
                    </button>
                </div>
            </div>

            <div className={'separatorLine horizontal'}></div>

            <SimpleBar style={{maxHeight: '388px', marginRight: '-24px'}} autoHide={false}>
                <div className={css.reportList}>
                    <div className={cx(css.row, css.tableLine)}>
                        <div className={cx(css.label, css.reportTableDate)}>{t('playerDetails.report.table.date')}</div>
                        <div className={cx(css.label, css.reportTableReporter)}>{t('playerDetails.report.table.reporter')}</div>
                        <div className={cx(css.label, css.reportTableType)}>{t('playerDetails.report.table.type')}</div>
                        <div className={cx(css.label, css.reportTableAction)}></div>
                    </div>

                    {reportList.length > 0 && reportList.map(report => (
                        <div className={css.row} key={report.ReportID}>
                            <div className={css.reportTableDate}>{timeFormat(report.Date)}</div>
                            <div className={css.reportTableReporter}>{report.UserName}</div>
                            <div className={css.reportTableType}>{t('playerDetails.report.type.' + report.ReportType)}</div>
                            <div className={css.reportTableAction}>
                                {report.ReportType === 'Scout' && (
                                    <button
                                        className={cx('btn icon', css.actionBtn)}
                                        onClick={() => actionOfReport(report.ReportID, ReportActionType.VIEW)}
                                    >
                                        <Tooltip text={t('playerReport.button.preview')}>
                                            <img src={IconEye} alt={t('playerReport.button.preview')}/>
                                        </Tooltip>
                                    </button>
                                )}
                                {report.ReportType === 'Custom' && (
                                    <button
                                        className={cx('btn icon', css.actionBtn)}
                                        onClick={() => actionOfReport(report.ReportID, ReportActionType.DOWNLOAD)}
                                    >
                                        <Tooltip text={t('playerReport.button.download')}>
                                            <img src={IconDownload} alt={t('playerReport.button.download')}/>
                                        </Tooltip>
                                    </button>
                                )}
                                <button
                                    className={cx('btn icon', css.actionBtn)}
                                >
                                    <Tooltip text={t('playerReport.button.send')} className="inactive">
                                        <img src={IconMail} alt={t('playerReport.button.send')}/>
                                    </Tooltip>
                                </button>
                                {report.ReportType === 'Scout' && (
                                    <button
                                        className={cx('btn icon', css.actionBtn)}
                                        onClick={() => savePdf(report)}
                                    >
                                        <Tooltip text={t('playerReport.button.pdf-export')}>
                                            <img src={IconPdf} alt={t('playerReport.button.pdf-export')}/>
                                        </Tooltip>
                                    </button>
                                )}
                                <button
                                    className={cx('btn icon', css.actionBtn)}
                                    onClick={async () => {
                                        await actionOfReport(report.ReportID, ReportActionType.DELETE);
                                        tableRefresh(0);
                                    }}
                                >
                                    <Tooltip text={t('playerReport.button.delete')}>
                                        <img src={IconDelete} alt={t('playerReport.button.delete')}/>
                                    </Tooltip>
                                </button>
                            </div>
                        </div>
                    ))}
                    {!reportList.length && <span className={css.noListedElement}>{t('general.message.noListedReport')}</span>}
                </div>
            </SimpleBar>
        </div>
        <GenericModal
            title={t('playerReport.upload.title')}
            className="fileUploaderModal"
            isVisible={fileUploaderOpened}
            changeVisibility={() => setFileUploaderOpened(false)}
        >
            <FileUploader
                maxSize={10 * MB}
                allowedTypes={[
                    'application/pdf',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ]}
                allowedExtensions={['.pdf', '.docx']}
                playerId={player.PlayerID}
                onUpload={(reportId) => {
                    tableRefresh(reportId ?? 0);
                    setFileUploaderOpened(false);
                }}
            />
        </GenericModal>
    </>;

};

export default ReportList;
