import React, { ReactElement } from 'react';
import { MapPopupProps } from './MapPopup';
import { cx } from '../../helpers/utility';
import { CategoryEnum } from '../../interfaces/reports.interface';
import css from './MapPopup.module.scss';
import IconExpand from '../../../assets/images/icons/icon_arrows_expand_2.svg';

const MapPopupTemplate = function({
    area,
    categories,
    openCallback
}: MapPopupProps): ReactElement {

    return <>
        <div className={css.mapPopup}>
            <div className={css.mapPopupHeader}>
                <span className={css.headerTitle}>{area}</span>
                <button className={'btn icon'} onClick={() => openCallback(CategoryEnum.a, area)}>
                    <img className={css.sectionIcon} src={IconExpand} alt=""/>
                </button>
            </div>
            <div className={css.mapPopupContent}>
                <button
                    onClick={() => openCallback(CategoryEnum.a, area)}
                    className={cx(css.category, css.categoryA)}>
                    <span className={css.categoryValue}>{categories?.A ?? 0}</span>
                </button>
                <button
                    onClick={() => openCallback(CategoryEnum.bplus, area)}
                    className={cx(css.category, css.categoryBplus)}>
                    <span className={css.categoryValue}>{categories?.['B+'] ?? 0}</span>
                </button>
                <button
                    onClick={() => openCallback(CategoryEnum.b, area)}
                    className={cx(css.category, css.categoryB)}>
                    <span className={css.categoryValue}>{categories?.B ?? 0}</span>
                </button>
                <button
                    onClick={() => openCallback(CategoryEnum.c, area)}
                    className={cx(css.category, css.categoryC)}>
                    <span className={css.categoryValue}>{categories?.C ?? 0}</span>
                </button>
                <button
                    onClick={() => openCallback(CategoryEnum.d, area)}
                    className={cx(css.category, css.categoryD)}>
                    <span className={css.categoryValue}>{categories?.D ?? 0}</span>
                </button>
                <button
                    onClick={() => openCallback(CategoryEnum.x, area)}
                    className={cx(css.category, css.categoryX)}>
                    <span className={css.categoryValue}>{categories?.X ?? 0}</span>
                </button>
                <button
                    onClick={() => openCallback(CategoryEnum.p, area)}
                    className={cx(css.category, css.categoryP)}>
                    <span className={css.categoryValue}>{categories?.P ?? 0}</span>
                </button>
            </div>

        </div>
    </>;

};

export default MapPopupTemplate;
