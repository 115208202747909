/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Base500 } from '../models/Base500';
import type { CheckPrivileges } from '../models/CheckPrivileges';
import type { EmptyReturn } from '../models/EmptyReturn';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdditionalService {

    /**
     * Search for all player by name
     * @param data
     * @returns CheckPrivileges
     * @throws ApiError
     */
    public static checkPrivilegesCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<CheckPrivileges> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/CheckPrivileges/',
            body: data,
        });
    }

    /**
     * ErrorGen
     * @param data
     * @returns any
     * @throws ApiError
     */
    public static errorGenCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            ErrorCd?: number;
        },
    ): CancelablePromise<{
        /**
         * User Object ID from Azure AD B2C
         */
        UserID?: string;
        ErrorCd?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ErrorGen/',
            body: data,
        });
    }

    /**
     * Get players whos report needs to be updated.
     * @returns Base500
     * @throws ApiError
     */
    public static playerReportsToUpdateCreate(): CancelablePromise<Base500> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerReportsToUpdate/',
        });
    }

    /**
     * Save to shortlist
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static emailSendTestCreate(): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/email_send_test/',
        });
    }

}
