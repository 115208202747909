/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerSearch } from '../models/PlayerSearch';
import type { ShadowTeam } from '../models/ShadowTeam';
import type { ShadowTeamFormations } from '../models/ShadowTeamFormations';
import type { ShadowTeamPlayers } from '../models/ShadowTeamPlayers';
import type { ShadowTeams } from '../models/ShadowTeams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShadowTeamService {

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeams
     * @throws ApiError
     */
    public static deleteShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            ShadowTeamID?: number;
        },
    ): CancelablePromise<ShadowTeams> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteShadowTeam/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeamPlayers
     * @throws ApiError
     */
    public static loadShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            ShadowTeamID?: number;
        },
    ): CancelablePromise<ShadowTeamPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LoadShadowTeam/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static ownTeamPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/OwnTeamPlayers/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeam
     * @throws ApiError
     */
    public static saveShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            ShadowTeamData?: {
                ShadowTeamID?: number;
                /**
                 * Formation ID
                 */
                FormationID?: string;
                /**
                 * Name of the shadow team
                 */
                ShadowTeamName?: string;
                Positions?: Array<{
                    PositionID?: string;
                    Players?: Array<{
                        PlayerID?: number;
                        PlayerRank?: number;
                    }>;
                }>;
            };
        },
    ): CancelablePromise<ShadowTeam> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SaveShadowTeam/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeams
     * @throws ApiError
     */
    public static savedShadowTeamsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<ShadowTeams> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavedShadowTeams/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeamFormations
     * @throws ApiError
     */
    public static shadowTeamFormationsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<ShadowTeamFormations> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ShadowTeamFormations/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static shortlistCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Shortlist/',
            body: data,
        });
    }

}
