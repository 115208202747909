import { ReactElement, useEffect, useState } from 'react';
import { without } from 'lodash';
import { toastError } from '../../../helpers/errorhandler';
import { translate as t } from '../../../helpers/translate';
import { toggleArray, cx } from '../../../helpers/utility';
import { Formation, PlayerReport, PlayerReportService, ShadowTeamPosition, ShadowTeamService } from '../../../services/openapi';
import { FradiLoader } from '../../FradiLoader/FradiLoader';
import { Toastify } from '../../Toastify/Toastify';
import Select from '../../select/select';
import useAuth from '../../../hooks/auth.hook';
import { PlayerReportRequestType } from '../../../interfaces/reports.interface';
import { REPORT_MAX_POSITIONS } from '../../../constants';
import css from './SecondaryPositionsField.module.scss';
import PitchLines from '../../../../assets/images/footballPitchLines.svg';

const SecondaryPositionsField = function({
    editing,
    reportData,
    readOnly,
    setReportSheet
}: {
    editing: boolean,
    reportData: PlayerReport,
    readOnly: boolean,
    setReportSheet?: React.Dispatch<React.SetStateAction<PlayerReportRequestType>>
}): ReactElement {

    const { requestParams } = useAuth();

    const [loadings, setLoadings] = useState<string[]>([]);
    const [formations, setFormations] = useState<Formation[]>([]);
    const [positions, setPositions] = useState<ShadowTeamPosition[]>([]);
    const [selectedFormation, setSelectedFormation] = useState<string>();
    const [selectedPositions, setSelectedPositions] = useState<string[]>([]);

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'formations']);
                Promise.all([
                    ShadowTeamService.shadowTeamFormationsCreate(requestParams),
                    PlayerReportService.reportFormationPositionsCreate(requestParams)
                ]).finally(
                    () => {
                        setLoadings(l => without(l, 'formations'));
                    }
                ).then(
                    ([formationList, positionList]) => {
                        setFormations(formationList.Formations);
                        setPositions(positionList.Positions);
                        const preSelectedPositions = [
                            reportData.FormationPosition_1, reportData.FormationPosition_2, reportData.FormationPosition_3
                        ];
                        setSelectedPositions(preSelectedPositions.filter(p => !!p) as string[]);
                        setSelectedFormation(reportData.TypicalFormation ?? undefined);

                        // setSecondaryPositions(formationList.Formations.reduce(
                        //     (acc, curr) => ({
                        //         ...acc,
                        //         [curr.FormationID]: curr.Positions.map(
                        //             pos => ({
                        //                 positionId: Number(pos.PositionID),
                        //                 formationId: formationList.Formations[0].FormationID,
                        //                 name: pos.PositionID,
                        //                 lat: pos.PositionY ?? 0,
                        //                 lng: pos.PositionX ?? 0
                        //             })
                        //         )
                        //     }),
                        //     { }
                        // ));
                        // setSelectedSecondaryPositions({
                        //     [formationList.Formations[0].FormationID]: [
                        //         reportData.Formation_4141_1,
                        //         reportData.Formation_4141_2,
                        //         reportData.Formation_4141_3
                        //     ],
                        //     [formationList.Formations[1].FormationID]: [
                        //         reportData.Formation_4231_1,
                        //         reportData.Formation_4231_2,
                        //         reportData.Formation_4231_3
                        //     ]
                        // });
                    }
                ).catch(
                    toastError
                );
            }
        },
        [
            reportData.FormationPosition_1,
            reportData.FormationPosition_2,
            reportData.FormationPosition_3,
            reportData.TypicalFormation,
            requestParams
        ]
    );

    function onPositionChanged(positionId: string) {
        if (!readOnly && setReportSheet) {
            if (
                selectedPositions.filter(p => !!p).length >= REPORT_MAX_POSITIONS &&
                !selectedPositions.includes(positionId)
            ) {
                Toastify({
                    titleKey: t('playerReport.message.maximumPositionReached')
                }).warning();
            }
            else {
                const newPositions = toggleArray(selectedPositions, positionId);
                setSelectedPositions(newPositions);
                setReportSheet(report => {
                    return {
                        ...report,
                        FormationPosition_1: newPositions[0] ?? undefined,
                        FormationPosition_2: newPositions[1] ?? undefined,
                        FormationPosition_3: newPositions[2] ?? undefined
                    };
                });
            }
        }
    }

    return <>
        {!loadings.includes('formations') && formations && (
            <div className={css.footballField}>
                <div className={css.footballFieldSelect}>
                    {editing && (
                        <Select
                            isSoloLabel={true}
                            isLabelOnTop={true}
                            hideLabelWhenSelected={true}
                            label={'playerReport.label.typical-formation'}
                            disabled={readOnly}
                            options={formations.map(
                                formation => ({
                                    id: formation.FormationID,
                                    value: formation.FormationID
                                })
                            )}
                            selectedOptions={[
                                {
                                    id: selectedFormation ?? '',
                                    value: selectedFormation ?? ''
                                }
                            ]}
                            changeSelected={
                                (selectedOptions) => {
                                    setSelectedFormation(selectedOptions[0].id as string);
                                    setReportSheet?.(report => ({
                                        ...report,
                                        TypicalFormation: selectedOptions[0].id as string
                                    }));
                                }
                            }
                        ></Select>
                    ) || (
                        <span>
                            {t('playerReport.label.typical-formation')}: {selectedFormation}
                        </span>
                    )}
                </div>
                <div className={css.footballPitch}>
                    <img className={css.pitchLines} src={PitchLines} alt=""/>
                    {
                        positions.map((position, index) => (
                            <div
                                key={index}
                                className={css.positionBox}
                                style={{
                                    left: `${100 - position.PositionY}%`,
                                    top: `${100 - position.PositionX}%`
                                }}
                            >
                                <div className={css.position}>
                                    <button
                                        className={cx(
                                            css.positionButton,
                                            selectedPositions.includes(
                                                position.PositionID
                                            )
                                                ? css.selected
                                                : null,
                                            css[
                                                selectedPositions.includes(
                                                    position.PositionID
                                                )
                                                    ? (
                                                        'position-' +
                                                        selectedPositions.indexOf(
                                                            position.PositionID
                                                        )
                                                    )
                                                    : ''
                                            ],
                                            readOnly ? css.disabled : null
                                        )}
                                        onClick={() => onPositionChanged(position.PositionID)}
                                    >{position.PositionID || 0}</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )}
        {loadings.includes('formations') && (
            <div className={css.loader}>
                <FradiLoader visible={true}/>
            </div>
        )}
    </>;

};

export default SecondaryPositionsField;
