import React, { ReactElement, useState } from 'react';
import { Categories, CategoryEnum } from '../../interfaces/reports.interface';
import { PlayerSearchData } from '../../services/openapi';
import CategoryCollectionTemplate, { CategoryCollectionCallbacks } from './CategoryCollectionTemplate';

const CategoryCollection = function({
    listCollection,
    initialCategory = CategoryEnum.a
}: {
    listCollection?: Categories<PlayerSearchData[]>
    initialCategory?: CategoryEnum
}): ReactElement {

    const [currentCategory, setCurrentCategory] = useState<CategoryEnum>(initialCategory);

    const actionCallbacks: CategoryCollectionCallbacks = {
        onCategoryChange: setCurrentCategory,
    };

    return <>
        <CategoryCollectionTemplate
            actionCallbacks={actionCallbacks}
            category={currentCategory}
            playerList={listCollection ? listCollection[currentCategory] : []}
        />
    </>;

};

export default CategoryCollection;
