import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useBlocker } from 'react-router-dom';
import jsPDF from 'jspdf';
import { without } from 'lodash';
import { toastError } from '../../../shared/helpers/errorhandler';
import useAuth from '../../../shared/hooks/auth.hook';
import {
    ComparePlayersService, PlayerSearchData, ShadowTeam as ShadowTeamType, ShadowTeamPlayers, ShadowTeamService, AdditionalService
} from '../../../shared/services/openapi';
import { ShadowTeamContext, ShadowTeamContextProps } from '../../../shared/contexts/ShadowTeam.context';
import { generatePdf } from '../../../shared/services/pdf-generation/pdf-generation-shadowteam';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import { ShadowTeamEndpoints } from './endpoints';
import ConfirmModal from '../../../shared/modules/ConfirmModal/ConfirmModal';
import ShadowTeamTemplate, { ShadowTeamDescriptor } from './ShadowTeamTemplate';

export interface ShadowTeamSaveRequest {
    ShadowTeamID?: number;
    FormationID: string;
    ShadowTeamName: string;
    Positions: {
        PositionID: string;
        Players: {
            PlayerID: number;
            PlayerRank: number;
        }[];
    }[];
}

const ShadowTeam = function({
    own
}: {
    own: boolean
}): ReactElement {

    const { account, requestParams } = useAuth();
    const navigate = useNavigate();
    const [loadings, setLoadings] = useState<string[]>([]);
    const [shadowTeamContextValue, setShadowTeamContextValue] = useState<ShadowTeamContextProps>({
        formations: [],
        selectedFormation: undefined,
        isModalOpen: false
    });
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [allPlayers, setAllPlayers] = useState<PlayerSearchData[]>([]);
    const [currentShadowTeam, setCurrentShadowTeam] = useState<ShadowTeamDescriptor>({ });
    const [loadedShadowTeam, setLoadedShadowTeam] = useState<ShadowTeamPlayers>();
    const [loadedShadowTeamMetaData, setLoadedShadowTeamMetaData] = useState<ShadowTeamType>();
    const [allowed, setAllowed] = useState<boolean>(false);
    const [everythingSaved, setEverythingSaved] = useState<boolean>(true);
    const [navigateConfirmModal, setNavigateConfirmModal] = useState<boolean>(false);

    const isShadowTeamEmpty = useCallback(
        () => {
            return Object.values(currentShadowTeam).flat().length === 0;
        },
        [currentShadowTeam]
    );

    useEffect(
        () => {
            const handleBeforeUnload = (event: BeforeUnloadEvent) => {
                if (!everythingSaved && !isShadowTeamEmpty()) {
                    event.preventDefault();
                    event.returnValue = '';
                    return true;
                }
                else {
                    return null;
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => window.removeEventListener('beforeunload', handleBeforeUnload);
        },
        [everythingSaved, isShadowTeamEmpty]
    );

    const shouldBlockNavigation = () => {
        if (!everythingSaved && !isShadowTeamEmpty()) {
            setNavigateConfirmModal(true);
            return true;
        }
        else {
            return false;
        }
    };

    const blocker = useBlocker(shouldBlockNavigation);

    useEffect(
        () => {
            if (requestParams.UserID) {
                if (own) {
                    AdditionalService.checkPrivilegesCreate(
                        requestParams
                    ).then(
                        (check) => {
                            setAllowed(check.OwnShadowTeamFlg);
                            if (!check.OwnShadowTeamFlg) {
                                navigate('/scouting/overview');
                            }
                        }
                    ).catch(
                        toastError
                    );
                }
                else {
                    setAllowed(true);
                }
            }
        },
        [navigate, own, requestParams]
    );

    useEffect(
        () => {
            setIsModalVisible(shadowTeamContextValue?.isModalOpen ?? false);
        },
        [shadowTeamContextValue]
    );

    const closeModal = function(changedState: boolean) {
        setShadowTeamContextValue(
            context => (
                context ? { ...context, isModalOpen: changedState } : context
            )
        );
    };

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'data']);
                Promise.all([
                    ShadowTeamService.shadowTeamFormationsCreate(requestParams),
                    ComparePlayersService.allReportedPlayersCreate(requestParams)
                ]).finally(
                    () => {
                        setLoadings(l => without(l, 'data'));
                    }
                ).then(
                    ([formationList, allReportedPlayers]) => {
                        setShadowTeamContextValue({
                            formations: formationList.Formations,
                            selectedFormation: undefined,
                            isModalOpen: false
                        });
                        setAllPlayers(allReportedPlayers.Players ?? []);
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    const saveShadowTeam = function({
        ShadowTeamID,
        FormationID,
        ShadowTeamName,
        Positions
    }: ShadowTeamSaveRequest): Promise<ShadowTeamType | null | void> {
        if (requestParams.UserID) {
            setLoadings(l => [...l, 'saveShadowTeam']);
            return ShadowTeamEndpoints[own ? 'own' : 'glob'].saved(
                requestParams
            ).finally(
                () => {
                    setLoadings(l => without(l, 'saveShadowTeam'));
                    setEverythingSaved(true);
                }
            ).then(
                shadowTeams => {
                    if (
                        !ShadowTeamID &&
                        (shadowTeams.ShadowTeams ?? []).find(savedShadowTeam => savedShadowTeam.ShadowTeamName === ShadowTeamName)
                    ) {
                        return null;
                    }
                    else {
                        return ShadowTeamEndpoints[own ? 'own' : 'glob'].save({
                            ...requestParams,
                            ShadowTeamData: {
                                ShadowTeamID: ShadowTeamID ?? undefined,
                                FormationID,
                                ShadowTeamName,
                                Positions
                            }
                        });
                    }
                }
            ).then(
                (currentlySavedShadowTeam) => {
                    return currentlySavedShadowTeam;
                }
            ).catch(
                toastError
            );
        }
        else {
            return Promise.resolve();
        }
    };

    const savePdf = async function(): Promise<void> {
        if (shadowTeamContextValue.selectedFormation) {
            setLoadings(l => [...l, 'pdf']);
            generatePdf({
                formation: shadowTeamContextValue.selectedFormation,
                shadowTeam: currentShadowTeam,
                additionalData: {
                    label: loadedShadowTeamMetaData ? loadedShadowTeamMetaData?.ShadowTeamName : undefined,
                    date: loadedShadowTeamMetaData ? loadedShadowTeamMetaData.Date : undefined,
                    username: loadedShadowTeamMetaData ? loadedShadowTeamMetaData.UserName : account?.name
                }
            }).finally(
                () => setLoadings(l => without(l, 'pdf'))
            ).then(
                (pdf: jsPDF) => {
                    const filename = loadedShadowTeam ? `${loadedShadowTeam?.ShadowTeamName}_shadowteam.pdf` : 'Shadowteam.pdf';
                    pdf.save(filename);
                }
            ).catch(
                (error: Error) => {
                    Toastify({
                        titleKey: 'general.message.error.pdf-generation.shadowteam-failed'
                    }).error();
                    console.error(error);
                }
            );
        }
        else {
            Toastify({
                titleKey: 'general.message.error.pdf-generation.formation-not-chosen'
            }).warning();
        }
    };

    return <>
        {allowed && (
            <ShadowTeamContext.Provider value={{ context: shadowTeamContextValue, setContext: setShadowTeamContextValue }}>
                <ShadowTeamTemplate
                    own={own}
                    loadings={loadings}
                    setLoadings={setLoadings}
                    isModalVisible={isModalVisible}
                    closeModal={closeModal}
                    saveShadowTeam={saveShadowTeam}
                    allPlayers={allPlayers}
                    currentShadowTeam={currentShadowTeam}
                    setCurrentShadowTeam={setCurrentShadowTeam}
                    loadedShadowTeam={loadedShadowTeam}
                    setLoadedShadowTeam={setLoadedShadowTeam}
                    setLoadedShadowTeamMetaData={setLoadedShadowTeamMetaData}
                    savePdf={savePdf}
                    setEverythingSaved={setEverythingSaved}
                />
                {navigateConfirmModal && (
                    <ConfirmModal
                        isVisible={navigateConfirmModal}
                        text={'scouting.shadow.message.before-leave'}
                        changeVisibility={() => setNavigateConfirmModal(false)}
                        callback={async () => blocker.proceed?.()}
                    />
                )}
            </ShadowTeamContext.Provider>
        )}
    </>;

};

export default ShadowTeam;
