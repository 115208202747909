import React, { ReactElement, useState } from 'react';
import MultiThumbSliderTemplate, { MultiThumbTemplateCallbacks, Thumb } from './multi-thumb-slider-template';

export interface MultiThumbSliderProps {
    minRange: number;
    maxRange: number;
    minValue?: number;
    maxValue: number;
    soloThumb?: boolean;
    inverted?: boolean;
    label?: string;
    disabled?: boolean,
    changedValue: (multiRange: MultiThumbRange) => void
}

export interface MultiThumbRange {
    min: number;
    max: number;
}

const MultiThumbSlider = function({
    minRange,
    maxRange,
    minValue,
    maxValue,
    label,
    soloThumb,
    inverted,
    disabled,
    changedValue,
}: MultiThumbSliderProps): ReactElement {

    const [selectedThumb, setSelectedThumb] = useState<Thumb>();

    const templateCallbacks: MultiThumbTemplateCallbacks = {
        selectedThumb: setSelectedThumb,
        changeByButton: changeValByButton,
        changeByGrab: changedValue
    };

    function changeValByButton(direction: number) {
        const supposedMin = (selectedThumb === Thumb.MAX) ? minValue ?? 0 : (minValue ?? 0) + direction;
        const supposedMax = (selectedThumb === Thumb.MAX) ? maxValue + direction : maxValue;
        changedValue && changedValue({
            min: (supposedMin >= minRange && supposedMin <= maxRange) ? (supposedMin ?? 0) : (minValue ?? 0),
            max: (supposedMax >= minRange && supposedMax <= maxRange) ? (supposedMax ?? 0) : (maxValue ?? 0)
        });
    }

    return (
        <MultiThumbSliderTemplate
            label={label}
            selectedThumb={selectedThumb}
            actionCallbacks={templateCallbacks}
            minValue={minValue}
            maxValue={maxValue}
            disabled={disabled}
            soloThumb={soloThumb}
            inverted={inverted}
            minRange={minRange}
            maxRange={maxRange}
        />
    );

};

export default MultiThumbSlider;
