/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchAllPlayer } from '../models/SearchAllPlayer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SearchFieldService {

    /**
     * Search for all player by name
     * @param data
     * @returns SearchAllPlayer
     * @throws ApiError
     */
    public static searchAllPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            SeachField?: string;
            PlayerIDs?: Array<number>;
        },
    ): CancelablePromise<SearchAllPlayer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SearchAllPlayers/',
            body: data,
        });
    }

}
