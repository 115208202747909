import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { noop } from 'lodash';
import { PlayerSearchDataReport } from '../../services/openapi';
import { cx, parsePlayerName } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import { CategoryEnum } from '../../interfaces/reports.interface';
import Tooltip from '../Tooltip/Tooltip';
import useAuth from '../../hooks/auth.hook';
import ListButton from '../ListButtons/ListButton';
import css from './PlayerReportListCard.module.scss';
import IconViewed from '../../../assets/images/icons/icon_file_read.svg';
import IconNotViewed from '../../../assets/images/icons/icon_file_unread.svg';

const PlayerReportListCard = function({
    report,
    handlePlayerLayer = noop,
    searchToTeam = noop
}: {
    report: PlayerSearchDataReport,
    handlePlayerLayer?: (playerId: number) => void,
    searchToTeam?: (teamName: string) => void
}): ReactElement {

    const { requestParams } = useAuth();
    const navigate = useNavigate();

    const preview = function() {
        if (requestParams.UserID) {
            navigate('/scouting/player/' + report.PlayerID + '/' + report.ReportID);
        }
    };

    return <>
        <tr className={css.listElement}>
            <td className={css.playerPictureCell}>
                <div className={cx(
                    css.playerPicture,
                    css['category-' + (Object.keys(CategoryEnum)[
                        Object.values(CategoryEnum).indexOf(report.ClassificationID as unknown as CategoryEnum)
                    ] ?? 'none')]
                )}>
                    <img src={report.PlayerImageUrl} alt=""/>
                </div>
            </td>
            <td className={css.playerName}>
                <div onClick={() => handlePlayerLayer(report.PlayerID)}>
                    <p className={css.firstName}>{parsePlayerName(report.PlayerName)[0]}</p>
                    <p className={css.surName}>{parsePlayerName(report.PlayerName)[1]}</p>
                </div>
            </td>
            <td className={css.classification}>{report.ClassificationID}</td>
            <td className={css.birthYear}>{report.Birthday}</td>
            <td className={css.teamName}>
                <div className={css.flexContainer}>
                    <img src={report.TeamImageUrl} alt=""/>
                    <p className={css.nameText} onClick={() => searchToTeam(report.TeamName)}>{report.TeamName}</p>
                </div>
            </td>
            <td className={css.date}>{report.ReportDate.split('T')[0]}</td>
            <td className={css.user}>{report.UserName}</td>
            <td className={css.post}>{report.PlayerPositionID}</td>
            <td className={css.minutes}>{report.MinutesPlayed}</td>
            <td className={css.fradiIndex}>
                <div className={css.dottedCircle}>
                    <div className={css.valueCircle}>
                        <span className={css.indexValue}>{report.ScoutingIndexGroupMean ?? '-'}</span>
                    </div>
                </div>
            </td>
            <td className={css.shortList}>
                <ListButton
                    type="shortList"
                    playerId={report.PlayerID}
                    playerInList={report.InShortlistFlg}
                />
            </td>
            <td className={css.preview}>
                <div className={cx(css.dataContainer, css.toggleShadowTeam)} onClick={() => preview()}>
                    <Tooltip text={t('playerReport.button.preview')}>
                        <img
                            src={report.ViewedFlg ? IconViewed : IconNotViewed}
                            alt={report.ViewedFlg ? t('playerReport.button.viewed') : t('playerReport.button.not-viewed')}
                        />
                    </Tooltip>
                </div>
            </td>
        </tr>
    </>;

};

export default PlayerReportListCard;
