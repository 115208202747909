import React, { ReactElement, useContext } from 'react';
import * as echarts from 'echarts';
import jsPDF from 'jspdf';
import { without } from 'lodash';
import { PlayerLayerContext } from '../../../shared/contexts/PlayerLayer.context';
import useShortList from '../../../shared/hooks/shortList.hook';
import { generatePdf } from '../../../shared/services/pdf-generation/pdf-generation-shortlist';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import { getChartOptions } from '../../../shared/modules/EchartsWrapper/EchartsRadar/EchartsRadar';
import { indicatorOrder } from '../../../shared/constants';
import { PlayerSearchData } from '../../../shared/services/openapi';
import useAuth from '../../../shared/hooks/auth.hook';
import { SortDirection } from '../../../shared/helpers/utility';
import ShortListTemplate from './ShortListTemplate';

export interface SortingDescriptor {
    column: keyof PlayerSearchData;
    direction: SortDirection;
}

export const createSvg = function(
    playerList: PlayerSearchData[]
): Map<number, SVGSVGElement> {

    const svg: Map<number, SVGSVGElement> = new Map();
    playerList.forEach(
        player => {
            const options = getChartOptions({
                dataList: player,
                order: indicatorOrder,
                showLabel: false,
                showTicks: false,
                dotSize: 5,
                animation: false
            });

            const chart = echarts.init(
                document.createElement('div'), null, { renderer: 'svg', width: 80, height: 80 }
            );
            chart.setOption(options);
            svg.set(player.PlayerID, chart.getDom().querySelector('svg') as SVGSVGElement);
        }
    );

    return svg;

};

const ShortList = function(): ReactElement {

    const { account } = useAuth();
    const layerContext = useContext(PlayerLayerContext);

    const {
        loadings,
        setLoadings,
        shortList,
        filteredShortList,
        setFilteredShortList,
        filteredSelfList,
        optionPosition,
        selectedPosition,
        setSelectedPosition,
        optionCategory,
        selectedCategory,
        setSelectedCategory,
        searchTerm,
        changeSearchTerm,
        sorting,
        changeSorting
    } = useShortList();

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true
        });
    };

    const searchToTeam = function(teamName: string) {
        layerContext.setContext({
            playerId: 0,
            isSearchModalOpen: teamName,
        });
    };

    const savePdf = async function(filtered: boolean = false): Promise<void> {
        const list = filtered ? filteredShortList : shortList;
        if (list.length > 0) {
            setLoadings(l => [...l, 'pdf']);
            generatePdf({
                list: list,
                charts: createSvg(list),
                additionalData: {
                    username: account?.name
                }
            }).finally(
                () => setLoadings(l => without(l, 'pdf'))
            ).then(
                (pdf: jsPDF) => {
                    pdf.save('Shortlist.pdf');
                }
            ).catch(
                (error: Error) => {
                    Toastify({
                        titleKey: 'general.message.error.pdf-generation.shortlist-failed'
                    }).error();
                    console.error(error);
                }
            );
        }
        else {
            Toastify({
                titleKey: 'general.message.error.pdf-generation.shortlist-empty'
            }).warning();
        }
    };

    return <>
        <ShortListTemplate
            loadings={loadings}
            shortListPlayers={filteredShortList}
            setShortListPlayers={setFilteredShortList}
            selfListPlayers={filteredSelfList}
            optionPosition={optionPosition}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
            optionCategory={optionCategory}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            handlePlayerLayer={handlePlayerLayer}
            searchToTeam={searchToTeam}
            searchTerm={searchTerm}
            changeSearchTerm={changeSearchTerm}
            sorting={sorting}
            changeSorting={changeSorting}
            savePdf={savePdf}
        />
    </>;

};

export default ShortList;
