import React, { ReactElement } from 'react';
import SimpleBar from 'simplebar-react';
import { CATEGORIES, CategoryEnum } from '../../interfaces/reports.interface';
import { cx } from '../../helpers/utility';
import OverviewPlayerCard from '../OverviewPlayerCard/OverviewPlayerCard';
import { PlayerSearchData } from '../../services/openapi';
import { translate as t } from '../../helpers/translate';
import usePlayerList from '../../hooks/player-list.hook';
import css from './CategoryCollection.module.scss';
import StatePlayer from '../../../assets/images/state_player.svg';

export interface CategoryCollectionCallbacks {
    onCategoryChange: (category: CategoryEnum) => void,
}

interface SampleTemplateProps {
    category: CategoryEnum,
    playerList: PlayerSearchData[] | undefined,
    actionCallbacks: CategoryCollectionCallbacks,
}

const CategoryCollectionTemplate = function({
    playerList,
    category,
    actionCallbacks,
}: SampleTemplateProps): ReactElement {

    const {
        playerInList: playerInShortList, changePlayerInList: changePlayerInShortList
    } = usePlayerList(playerList ?? [], 'InShortlistFlg');

    return <>
        <div className={css.categoryCollection}>
            <div className={css.categorySelect}>
                {(Object.keys(CATEGORIES) as (keyof typeof CATEGORIES)[]).map(currentCategory => (
                    <button
                        key={currentCategory}
                        onClick={() => actionCallbacks.onCategoryChange(CategoryEnum[currentCategory])}
                        className={cx(
                            css.category,
                            css[`category-${currentCategory}`],
                            category === CategoryEnum[currentCategory] ? css.active : ''
                        )}
                    ></button>
                ))}
            </div>
            <div className={css.list}>
                <div className={css.titleBox}>
                    {/*<div className={style.checkBox}></div>*/}
                    <div className={css.imageBox}></div>
                    <div className={css.nameBox}>{t('playerDetails.label.name')}</div>
                    <div className={css.yearBox}>{t('playerDetails.label.year-of-birth')}</div>
                    <div className={css.teamBox}>{t('playerDetails.label.team')}</div>
                    <div className={css.postBox}>{t('playerDetails.label.post')}</div>
                    <div className={css.minutesBox}>{t('playerDetails.label.minutes')}</div>
                    <div className={css.chartBox}></div>
                    <div className={css.actionBox}></div>
                </div>
                <SimpleBar style={{maxHeight: 'calc(100% - 46px)', minHeight: 'calc(100% - 46px)'}} autoHide={true}>
                    <div className={css.playerListContainer}>
                        {playerList?.map(player =>
                            <OverviewPlayerCard
                                key={player.PlayerID}
                                category={category}
                                player={player}
                                inShortList={playerInShortList.get(player.PlayerID) ?? false}
                                changeInShortList={(inList) => changePlayerInShortList(player.PlayerID, inList)}
                            />
                        )}
                        {!playerList?.length &&
                            <div className={css.emptyListContainer}>
                                <img src={StatePlayer} alt=""/>
                                <span>{t('general.message.noListedReport')}</span>
                            </div>
                        }
                    </div>
                </SimpleBar>
            </div>
        </div>
    </>;

};

export default CategoryCollectionTemplate;
