import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { translate as t } from '../../helpers/translate';
import { FileCheckResult, MB, RawFileType } from './FileUploader';
import { cx } from '../../helpers/utility';
import { FradiLoader } from '../FradiLoader/FradiLoader';
import css from './FileUploader.module.scss';

const FileUploaderTemplate = function({
    loadings,
    maxSize,
    allowedExtensions,
    allowed,
    step,
    setStep,
    prepare,
    send
}: {
    loadings: string[],
    maxSize: number,
    allowedExtensions: string[],
    allowed: boolean,
    step: 1 | 2,
    setStep: React.Dispatch<React.SetStateAction<1 | 2>>,
    prepare: (files: FileList) => FileCheckResult,
    send: () => Promise<void | number>
}): ReactElement {

    const [dropActive, setDropActive] = useState(false);
    const [dropHighlight, setDropHighlight] = useState(false);
    const [dropAccepted, setDropAccepted] = useState(false);
    const [fileData, setFileData] = useState<RawFileType>({
        name: '',
        size: 0,
        type: ''
    });

    const eventHandlers = {
        prevent: (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (event.type !== 'dragover'){
                event.stopPropagation();
            }
        },
        dragenter: () => {
            setDropActive(true);
            setDropHighlight(true);
            setDropAccepted(false);
        },
        dragleave: () => {
            setDropHighlight(false);
            setDropAccepted(false);
        },
        dragend: () => {
            setDropActive(false);
            setDropHighlight(false);
        },
        drop: (event: React.DragEvent<HTMLDivElement>) => {
            setDropActive(false);
            setDropHighlight(false);
            if (event.dataTransfer) {
                const result = prepare(event.dataTransfer.files);
                setDropAccepted(result.allowed);
                if (result.allowed) {
                    setStep(2);
                    setFileData(result.fileData);
                }
            }
        }
    };

    useEffect(
        () => {
            let timeout: NodeJS.Timeout;
            const handler = function() {
                if (timeout){
                    clearTimeout(timeout);
                    setDropActive(true);
                    setDropAccepted(false);
                }
                timeout = setTimeout(function() {
                    setDropActive(false);
                    setDropHighlight(false);
                }, 100);
            };

            document.addEventListener('dragover', handler);

            return () => {
                document.removeEventListener('dragover', handler);
            };
        },
        []
    );

    const fileUpload = function(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            const result = prepare(event.target.files);
            if (result.allowed) {
                setStep(2);
                setFileData(result.fileData);
            }
        }
    };

    return (
        <div className={css.fileUploader}>
            {step === 1 && (
                <div className={cx(css.container, css['step-' + step])}>
                    <div className={css.maxSizeAndFormat}>
                        <p>{t('playerReport.upload.max-size')}: {Math.round(maxSize / MB)} MB</p>
                        <p>{t('playerReport.upload.allowed-extensions')}:&nbsp;
                            {allowedExtensions.map(ext => ext.replace('.', '')).join(', ')}
                        </p>
                    </div>
                    <div
                        className={cx(
                            css.dropArea,
                            dropActive ? css.dropActive : null,
                            dropHighlight ? css.dropHighlight : null,
                            dropAccepted ? css.dropAccepted : null
                        )}
                        onDrag={(event) => { eventHandlers.prevent(event); }}
                        onDragStart={(event) => { eventHandlers.prevent(event); }}
                        onDragOver={(event) => { eventHandlers.prevent(event); }}
                        onDragEnter={(event) => { eventHandlers.prevent(event); eventHandlers.dragenter(); }}
                        onDragLeave={(event) => { eventHandlers.prevent(event); eventHandlers.dragleave(); }}
                        onDragEnd={(event) => { eventHandlers.prevent(event); eventHandlers.dragend(); }}
                        onDrop={(event) => { eventHandlers.prevent(event); eventHandlers.drop(event); }}
                    >
                        {t('playerReport.upload.area-text')}
                    </div>
                    <button className={css.uploadButton}>
                        {t('playerReport.upload.choose')}
                        <input
                            type="file"
                            accept={allowedExtensions.join(',')}
                            onChange={(event) => fileUpload(event)}
                        />
                    </button>
                </div>
            )}
            {step === 2 && (
                <div className={cx(css.container, css['step-' + step])}>
                    <div
                        className={cx(css.dropArea, css.dropInactive)}
                    >
                        <p>{t('playerReport.upload.file-name')}:</p>
                        <p className={css.fileName}>{fileData.name}</p>

                    </div>
                    <button className={css.uploadButton} disabled={!allowed} onClick={() => send()}>
                        {t('playerReport.upload.upload')}
                    </button>
                    <FradiLoader visible={loadings.includes('upload')}/>
                </div>
            )}
        </div>
    );

};

export default FileUploaderTemplate;
