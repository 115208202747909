import { createBrowserRouter, redirect, RouteObject } from 'react-router-dom';
import AppTheme from './AppTheme';
import ScoutingPage from './pages/scouting-page/ScoutingPage';
import { scoutingRoutes } from './pages/scouting-page/scouting.routes';
import Welcome from './pages/scouting-page/Welcome';
import NotFound from './pages/not-found/NotFound';

const routes: RouteObject[] = [
    {
        path: '/',
        element: <AppTheme/>,
        children: [
            {
                path: '',
                loader: () => {
                    return redirect('/scouting');
                }
            },
            {
                path: 'welcome',
                element: <Welcome/>,
            },
            {
                path: 'scouting',
                element: <ScoutingPage/>,
                children: scoutingRoutes,
            },
            {
                path: '*',
                element: <NotFound/>
            }
        ]
    },
];

export const routerConfig = createBrowserRouter(routes);
