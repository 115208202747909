export function getDataValue(div: HTMLDivElement | null, param: string, defaultValue = 0): number {
    if (!div) {
        return defaultValue;
    }
    const val = div.dataset[param] ?? defaultValue;
    return Number.isNaN(+val) ? defaultValue : +val;
}

export function setDataValue(div: HTMLDivElement, param: string, value: number) {
    div.dataset[param] = String(value);
}
