import jsPDF from 'jspdf';
import { formatDate, translate as t } from '../../helpers/translate';
import { IMG, SVG } from '../../helpers/utility';
import { Toastify } from '../../modules/Toastify/Toastify';
import { addSvgToPdf } from './pdf-generation-utility';
import { pdfFontHeaderRegular, pdfFontHeaderBold, pdfFontHeaderExtraBold } from '../../../data/fonts';
import logo from '../../../assets/images/fradi_logo_3_stars.svg';

export type PositionDescriptor = [x: number, y: number];
export type BoxDescriptor = [x: number, y: number, w: number, h: number];

export interface VisualizationTextsType {
    defending: string[];
    possession: string[];
    attacking: string[];
    setpieces?: string[];
}

export interface PdfLayoutData {
    competitionName: string;
    seasonName: string;
    teamName: string;
    playerName?: string;
}

export interface AdditionalData {
    label?: string;
    date?: string;
    username?: string;
}

export const whiteHex = '#FFFFFF';
export const blackHex = '#000000';
export const bgHex = '#FAFAFA';
export const paleGreenHex = '#D7EBDD';
export const lightGreenHex = '#A5D1B1';
export const darkGreenHex = '#186037';
export const darkGreyHex = '#444444';
export const lightGreyHex = '#808080';
export const lightBorderHex = '#e4e4e4';
export const black = IMG.hexToRgb('#000000');
export const white = IMG.hexToRgb('#FFFFFF');
export const lightGreen = IMG.hexToRgb('#A5D1B1');
export const darkGreen = IMG.hexToRgb('#186037');
export const lightGrey = IMG.hexToRgb('#808080');
export const darkGrey = IMG.hexToRgb('#444444');

/**
 * Mértékengység konvertalás px -> mm
 * @param {number} size - px-ben megadott méret az xd fájlban
 * @return {number} mm-ben megadott méret a pdf fájlban
 */
export const c = (size: number): number => (size * 0.372);

export const addLayout = async function({
    pdf,
    title,
    positionId,
    page,
    additionalData
}: {
    pdf: jsPDF,
    title: string,
    positionId?: string,
    page: { current: number, all: number },
    additionalData: AdditionalData
}): Promise<void> {

    pdf.addFileToVFS('montserrat.ttf', pdfFontHeaderRegular);
    pdf.addFont('montserrat.ttf', 'normal', 'normal');
    pdf.addFileToVFS('montserrat-semibold.ttf', pdfFontHeaderBold);
    pdf.addFont('montserrat-semibold.ttf', 'bold', 'normal');
    pdf.addFileToVFS('montserrat-extrabold.ttf', pdfFontHeaderExtraBold);
    pdf.addFont('montserrat-extrabold.ttf', 'extrabold', 'normal');

    const ctx = pdf.context2d;

    // háttér
    ctx.fillStyle = bgHex;
    ctx.fillRect(c(0), c(0), c(1200), c(1697));

    ctx.fillStyle = whiteHex;
    ctx.fillRect(c(0), c(0), c(1200), c(160));
    ctx.fillRect(c(0), c(1697 - 160), c(1200), c(160));

    // vonalak
    ctx.strokeStyle = lightGreenHex;
    ctx.lineWidth = 0.5;

    ctx.moveTo(c(0), c(160));
    ctx.lineTo(c(1200), c(160));
    ctx.stroke();

    ctx.moveTo(c(0), c(1697 - 160));
    ctx.lineTo(c(1200), c(1697 - 160));
    ctx.stroke();

    // logo
    await addSvgToPdf({
        pdf,
        svgElement: await SVG.urlToElement(logo) as SVGSVGElement,
        qualityWidth: 300,
        dimensions: {
            x: c(80),
            y: c(80),
            w: c(88)
        }
    }).catch(() => {
        Toastify({
            titleKey: 'general.message.error.pdf-generation.svg-insertion-failed'
        }).error();
    });

    pdf.setTextColor(blackHex);

    // logo szöveg
    pdf.setFontSize(18).setFont('extrabold', 'normal', 'normal');
    pdf.text(t('pdf.title1'), c(186), c(135), { align: 'left' });
    pdf.setFontSize(18).setFont('normal', 'normal', 'normal');
    pdf.text(t('pdf.title2'), c(320), c(135), { align: 'left' });

    // cím
    pdf.setFontSize(18).setFont('bold', 'normal', 'normal');
    pdf.text(title, c(1120), positionId ? c(100) : c(135), { align: 'right' });

    // pozíció (shortlist-hez)
    if (positionId) {
        pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
        pdf.text(`${t('pdf.shortlist.position')} -`, c(1093), c(137), { align: 'right' });
        pdf.setFontSize(10).setFont('bold', 'normal', 'normal');
        pdf.text(positionId, c(1120), c(137), { align: 'right' });
    }

    // label
    if (additionalData.label) {
        pdf.setFontSize(14).setFont('bold', 'normal', 'normal');
        pdf.text(additionalData.label, c(1120), c(60), { align: 'right' });
    }

    // footer
    pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
    pdf.text(formatDate(), c(80), c(1697 - 108), { align: 'left' });
    pdf.text(
        t('pdf.page').replace(/\{page\}/g, String(page.current)).replace(/\{all\}/g, String(page.all)),
        c(1048), c(1697 - 108), { align: 'left' }
    );

    // usernév
    if (additionalData.username) {
        pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
        pdf.text(additionalData.username, c(600), c(1697 - 108), { align: 'center' });
    }

    // rögzítés dátum
    if (additionalData.date) {
        pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
        pdf.text(formatDate(additionalData.date), c(600), c(1697 - 70), { align: 'center' });
    }

};

/**
 * Játékos képe kör alakúra vágva
 * @param {string} base64 - kép base54 kódja
 * @param {number} r - kör sugara
 * @return {Promise} canvas elem
 */
export const createPlayerImageCanvas = async function(base64: string, r: number): Promise<HTMLCanvasElement> {
    return new Promise(
        (resolve, reject) => {
            const dataUrl = `data:image/png;base64,${base64}`;
            IMG.getRatio(
                dataUrl
            ).then((ratio) => {
                const image = document.createElement('img');
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = r * 2;
                    canvas.height = r * 2;
                    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
                    ctx.beginPath();
                    ctx.arc(r, r, r, 0, Math.PI * 2);
                    ctx.clip();
                    if (ratio > 1) {
                        // széles kép
                        ctx.drawImage(
                            image,
                            (image.width - image.width * ratio) / 2, 0, image.width * ratio, image.height,
                            0, 0, canvas.width, canvas.height
                        );
                    }
                    else {
                        // magas kép
                        ctx.drawImage(
                            image,
                            0, (image.height - image.height * ratio) / 2, image.width, image.height * ratio,
                            0, 0, canvas.width, canvas.height
                        );
                    }
                    resolve(canvas);
                };
                image.onerror = (error) => {
                    reject(error);
                };
                image.src = dataUrl;
            }).catch(() => {
                Toastify({
                    titleKey: 'general.message.error.pdf-generation.svg-insertion-failed'
                }).error();
            });
        }
    );
};
