import React, { createRef, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { GeoJSON, MapContainer, Marker, Popup } from 'react-leaflet';
import { Layer, Map as LeafletMap, Icon as LeafletIcon, LeafletEventHandlerFnMap } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Feature, FeatureCollection, GeoJsonObject, Geometry } from 'geojson';
import { GeoCountryProperties } from '../../interfaces/geo-json.interface';
import MapPopup from '../MapPopup/MapPopup';
import { GeoContinentProperties } from '../../../assets/geojson/continents/continentCollection';
import { camelIfy, cx, switching } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import { Categories, CategoryEnum } from '../../interfaces/reports.interface';
import { AreaContext } from '../../contexts/Area.context';
import { CustomGeoCollection, GeoCustomProperties } from '../../../assets/geojson/gb/customGeoCollection';
import { PlayerSearchClassificationGroup, PlayerSearchData, PlayerSearchFull } from '../../services/openapi';
import css from './MapSelect.module.scss';
import iconUrl from '../../../assets/images/marker_player.svg';

interface SampleTemplateProps {
    countries: FeatureCollection<Geometry, GeoCountryProperties> | undefined;
    continents: FeatureCollection<Geometry, GeoContinentProperties>,
    langName: keyof GeoCountryProperties,
    reports: PlayerSearchFull,
}

interface ContinentCategory {
    ContinentID: string;
    Categories: Categories<PlayerSearchData[]>;
}

const groupByClassificationID = function(playersList: PlayerSearchClassificationGroup[] | undefined) {
    const groupedPlayers: Categories<PlayerSearchData[]> = {};

    if (!playersList) {
        return groupedPlayers;
    }
    playersList.forEach((playerObj) => {
        const { ClassificationID, Players } = playerObj;

        if (!ClassificationID) {
            return;
        }
        if (!groupedPlayers[ClassificationID as keyof Categories]) {
            groupedPlayers[ClassificationID as keyof Categories] = [...Players ?? []];
        } else if (Players) {
            groupedPlayers[ClassificationID as keyof Categories]?.push(...Players);
        }
    });

    return groupedPlayers;
};

const continentCategories = function(reports: PlayerSearchFull): ContinentCategory[] {
    // [...({...reports}?.Continents ?? [])]
    return [...reports?.Continents ?? []].map(
        continent => ({
            ContinentID: continent.ContinentID,
            Categories: groupByClassificationID(
                continent?.Countries?.flatMap(
                    i => i.Classifications
                ) as PlayerSearchClassificationGroup[]
            )
        })
    );
};

export const getContinentCategories = function(reports: PlayerSearchFull, continentId: string) {
    return continentCategories(reports)?.find(
        category => category.ContinentID === continentId
    )?.Categories;
};

const groupToCategory = function(categories?: PlayerSearchClassificationGroup[]): Categories<PlayerSearchData[]> | undefined {
    if (!categories) {
        return undefined;
    }
    return Object.fromEntries(
        categories.map(
            category => [category.ClassificationID, category.Players]
        )
    );
};

export const getCountryCategories = function(reports: PlayerSearchFull, countryISO: string) {
    return groupToCategory(
        reports?.Continents?.flatMap(
            continent => continent.Countries
        ).find(
            country => country?.CountryID === countryISO
        )?.Classifications
    );
};

const MapSelectTemplate = function({
    countries,
    continents,
    langName,
    reports,
}: SampleTemplateProps): ReactElement {

    const leafletMap = createRef<LeafletMap>();
    const [selectedArea, setSelectedArea] = useState<Feature<Geometry, GeoCountryProperties>>();
    const [currentContinent, setCurrentContinent] = useState<string>();
    const [isCountryView, setIsCountryView] = useState<boolean>(false);
    const [markerIcon, setMarkerIcon] = useState<LeafletIcon>();
    const areaContext = useContext(AreaContext);

    const reportCountriesISOList = useMemo<(string | undefined)[]>(
        () => reports?.Continents?.map(
            continent => ({
                ContinentID: continent.ContinentID,
                CountriesISO: continent?.Countries?.flatMap(country => country.CountryID)
            })
        ).flatMap(
            continent => continent.CountriesISO
        ) ?? [], [reports]
    );

    useEffect(
        () => {
            setMarkerIcon(new LeafletIcon({
                iconUrl,
                iconAnchor: [12, 45],
                popupAnchor: [0, -45],
            }));
        },
        []
    );

    useEffect(
        () => {
            leafletMap.current?.on({
                zoomend: () => {
                    setIsCountryView((leafletMap.current?.getZoom() ?? 0) > 3);
                    if (leafletMap.current?.getZoom() === 2) {
                        leafletMap.current.setView({lat: 45, lng: 15}, 2);
                    }
                }
            } as LeafletEventHandlerFnMap);
        },
        [leafletMap]
    );

    function countryFeatureHandler(feature: Feature<Geometry, GeoCountryProperties>, layer: Layer) {
        layer.on({
            click: () => {
                setSelectedArea(feature);
                setCurrentContinent(camelIfy(feature.properties.continent));
            }
        });
    }

    function continentFeatureHandler(feature: Feature<Geometry, GeoContinentProperties>, continentLayer: Layer) {
        continentLayer.on({
            click: (event) => {
                event.sourceTarget._map.setView({lat: feature.properties.lat, lng: feature.properties.lng}, 4);
                setCurrentContinent(feature.properties.continentId);
            }
        });
    }

    function setViewToContinent(continent: GeoContinentProperties) {
        leafletMap.current?.setView({lat: continent.lat, lng: continent.lng}, 4);
        setCurrentContinent(continent.continentId);
    }

    function openContinentModal(category: CategoryEnum, area: string, continentId: string) {
        areaContext.setContext({
            area: {
                name: area,
                id: continentId
            },
            categoryCollection: getContinentCategories(reports, continentId),
            category: category,
            isModalOpen: true,
        });
    }

    function openCountryCategories(category: CategoryEnum, area: string, countryISO: string) {
        areaContext.setContext({
            area: {
                name: area,
                id: countryISO
            },
            categoryCollection: getCountryCategories(reports, countryISO),
            category: category,
            isModalOpen: true,
        });
    }

    function categoryConverterToNumber(players?: Categories<PlayerSearchData[]> | undefined): Categories | undefined {
        if (!players) {
            return undefined;
        }
        return Object.fromEntries(Object.entries(players).map(([category, player]) => [category, player.length]));
    }

    function countryCategories(continentId: string, countryId: string) {
        return categoryConverterToNumber(
            groupToCategory(
                reports?.Continents?.find(
                    continent => continent.ContinentID?.toLowerCase() === continentId.toLowerCase()
                )?.Countries?.find(
                    item => item.CountryID === countryId
                )?.Classifications
            )
        );
    }

    return <>
        <div className={css.mapBox}>
            <div className={cx(css.continentSelect, isCountryView ? css.countryView : '')}>
                {continents.features.map(continent =>
                    <button
                        key={continent.properties.continentId}
                        onClick={() => setViewToContinent(continent.properties)}
                        className={cx(css.continentOption, currentContinent === continent.properties.continentId ? css.active : '')}
                    >
                        {t('map.continent.' + continent.properties.continentId)}
                    </button>
                )}
            </div>
            <MapContainer
                minZoom={2}
                maxZoom={8}
                ref={leafletMap}
                style={{height: '100%', width: '100%'}}
                className={css.customLeafletContainer}
                center={[45, 15]}
                zoom={2}
            >
                {!isCountryView && !!continents && <>
                    <GeoJSON
                        data={
                            continents.features as unknown as GeoJsonObject
                        }
                        onEachFeature={continentFeatureHandler}
                    ></GeoJSON>
                    {reports && continentCategories(reports) && markerIcon && (
                        continents.features.map(continent =>
                            <Popup
                                key={continent.properties.continentId}
                                position={
                                    switching(continent.properties.continentId, {
                                        'europe': { lat: continent.properties.lat + 8, lng: continent.properties.lng + 3 },
                                        'asia': { lat: continent.properties.lat - 7, lng: continent.properties.lng },
                                    }, { lat: continent.properties.lat, lng: continent.properties.lng })
                                }
                                closeOnClick={false}
                                closeButton={false}
                                autoPan={false}
                                autoClose={false}
                            >
                                <MapPopup
                                    openCallback={
                                        (category, area) => openContinentModal(category, area, continent.properties.continentId)
                                    }
                                    categories={
                                        Object.fromEntries(
                                            Object.entries(
                                                getContinentCategories(reports, continent.properties.continentId) ?? {}
                                            ).map(
                                                ([a, b]) => [a, b.length]
                                            )
                                        )
                                    }
                                    area={t('map.continent.' + continent.properties.continentId)}
                                />
                            </Popup>
                        )
                    )}
                </>}
                {isCountryView && countries?.features && <>
                    <GeoJSON
                        data={
                            (countries as any).features
                        }
                        onEachFeature={countryFeatureHandler}
                    >
                        <Popup closeButton={false}>
                            {selectedArea && (
                                <MapPopup
                                    categories={countryCategories(selectedArea.properties.continent, selectedArea.properties.adm0_iso)}
                                    openCallback={
                                        (category, area) => openCountryCategories(
                                            category, area, selectedArea.properties.adm0_iso
                                        )
                                    }
                                    area={selectedArea.properties[langName]}
                                />
                            )}
                        </Popup>
                    </GeoJSON>
                    <GeoJSON
                        onEachFeature={countryFeatureHandler}
                        data={
                            (CustomGeoCollection as any).features
                        }
                    >
                        {selectedArea &&
                            <Popup closeButton={false}>
                                <MapPopup
                                    categories={countryCategories(selectedArea.properties.continent, selectedArea.properties.adm0_iso)}
                                    openCallback={
                                        (category, area) => openCountryCategories(
                                            category, area, selectedArea.properties.adm0_iso
                                        )
                                    }
                                    area={selectedArea.properties[langName]}
                                />
                            </Popup>
                        }
                    </GeoJSON>
                    {isCountryView && [...countries.features, ...CustomGeoCollection.features]
                        .filter(country => reportCountriesISOList
                            .some(iso => country.properties.adm0_iso === iso))
                        .map(country =>
                            <Marker
                                icon={markerIcon}
                                alt={country.properties.name}
                                title={country.properties.name}
                                key={country.properties.adm0_iso + country.properties.label_y}
                                position={{lat: country.properties.label_y, lng: country.properties.label_x}}
                            >
                                <Popup closeButton={false}>
                                    <MapPopup
                                        categories={countryCategories(country.properties.continent, country.properties.adm0_iso)}
                                        openCallback={
                                            (category, area) => openCountryCategories(
                                                category, area, country.properties.adm0_iso
                                            )
                                        }
                                        area={
                                            country.properties[langName as keyof (GeoCountryProperties | GeoCustomProperties)] as string
                                        }
                                    />
                                </Popup>
                            </Marker>
                        )
                    }
                </>}
            </MapContainer>
        </div>
    </>;

};

export default MapSelectTemplate;
