import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { getCurrentLang } from '../helpers/translate';

const useAuth = function(playerId: number | undefined = undefined) {

    const { instance } = useMsal();
    const [account, setAccount] = useState<AccountInfo | null>();
    const [requestParams, setRequestParams] = useState({
        UserID: '',
        LangID: getCurrentLang(),
        PlayerID: playerId
    });

    useDeepCompareEffect(
        () => {
            setAccount(instance.getActiveAccount());
            if (account && account.idTokenClaims && account.idTokenClaims['oid']) {
                setRequestParams({
                    UserID: account.idTokenClaims['oid'],  // 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
                    LangID: getCurrentLang(),
                    PlayerID: playerId
                });
            }
        },
        [account, instance, playerId]
    );

    return {
        account,
        instance,
        requestParams
    };

};

export default useAuth;
