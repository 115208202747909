import React, { ReactElement, useEffect, useState } from 'react';
import { cx, toggleArray } from '../../helpers/utility';
import { CategoryEnum } from '../../interfaces/reports.interface';
import { Toastify } from '../Toastify/Toastify';
import { translate as t } from '../../../shared/helpers/translate';
import css from './CategorySelect.module.scss';

interface ComponentProps {
    values: CategoryEnum[],
    onSelect: (rate: CategoryEnum[]) => void,
    multiple?: boolean,
    disabled?: boolean,
    disabledValues?: CategoryEnum[]
}

export const CategorySelect = function ({
    values,
    onSelect,
    multiple = false,
    disabled = false,
    disabledValues = []
}: ComponentProps): ReactElement {

    const [categories, setCategories] = useState<CategoryEnum[]>([]);

    useEffect(
        () => {
            setCategories(values);
        },
        [values]
    );

    function selectCategory(selectedCategory: CategoryEnum) {
        if (disabledValues.includes(selectedCategory) && selectedCategory === CategoryEnum.x) {
            Toastify({
                titleKey: t('playerReport.message.notValidAgeForX')
            }).warning();
        }
        else if (disabledValues.includes(selectedCategory) && selectedCategory === CategoryEnum.p) {
            Toastify({
                titleKey: t('playerReport.message.notValidAgeForP')
            }).warning();
        }
        else if (multiple) {
            onSelect(toggleArray(categories, selectedCategory));
            setCategories(selecteds => toggleArray(selecteds, selectedCategory));
        }
        else {
            onSelect([selectedCategory]);
            setCategories([selectedCategory]);
        }
    }

    return <>
        <div className={css.CategoryBox}>
            {Object.entries(CategoryEnum).map(
                ([categoryKey, categoryValue]) => (
                    <button
                        key={categoryKey}
                        className={cx(
                            'btn icon',
                            css.categoryButton,
                            css[('category-' + categoryKey)],
                            categories.includes(categoryValue) ? css.selected : null,
                            disabledValues.includes(categoryValue) ? css.disabled : null
                        )}
                        disabled={disabled}
                        onClick={() => selectCategory(categoryValue)}
                    >
                        {categoryValue}
                    </button>
                )
            )}
        </div>
    </>;

};
