import { IndicatorValues } from './interfaces/carried-on.interfaces';
import { SearchParam, PlayerReport, PlayerSearchData } from './services/openapi';

export const TODAY = new Date();

export const PLAYER_SEARCH_MIN_CHARS = 3;
export const PLAYER_SEARCH_WAITING = 1200;

export const SHADOW_TEAM_ALLOWED_PLAYER_NUM = 4;

export const REPORT_COMMENT_MAX_LENGTH = 4000;
export const REPORT_MAX_POSITIONS = 3;

export const SIDEBAR_LIMITS = {
    viewed: 5,
    followed: 5,
    reported: 5,
    alert: 6,
    cubeAlert: 6
};

export const SIDEBAR_BREAKPOINT = 1400;

export const indicatorOrder: Array<keyof IndicatorValues> = [
    'PhysiqueGroupMean',
    'MentalityGroupMean',
    'TechnicalGroupMean',
    'DefendingGroupMean',
    'AttackingGroupMean',
];

export const nullFilterParam: SearchParam = {
    SearchID: 0,
    SearchField: '',
    MinutesPlayedMin: 0,
    ClassificationID: [],
    PlayerPositionID: 0,
    PlayerProfileID: '',
    ResellableFlg: false,
    Nationality: [],
    AgeRange: {min: 0, max: 0},
    SpeedRange: {min: 0, max: 0},
    DynamicRange: {min: 0, max: 0},
    AgilityRange: {min: 0, max: 0},
    PhysiqueGroupMeanRange: {min: 0, max: 0},
    GameIntelligenceRange: {min: 0, max: 0},
    AgressionRange: {min: 0, max: 0},
    MentalityGroupMeanRange: {min: 0, max: 0},
    TechniqueRange: {min: 0, max: 0},
    BallStrikingAbilityRange: {min: 0, max: 0},
    PressureResistanceRange: {min: 0, max: 0},
    TechnicalGroupMeanRange: {min: 0, max: 0},
    PositioningRange: {min: 0, max: 0},
    BoxDefendingRange: {min: 0, max: 0},
    OneVOneDefendingRange: {min: 0, max: 0},
    DefendingDuelRange: {min: 0, max: 0},
    DefendingIntensityRange: {min: 0, max: 0},
    DefendingInTransitionRange: {min: 0, max: 0},
    PressingIntensityRange: {min: 0, max: 0},
    DefendingArealDuelRange: {min: 0, max: 0},
    DefendingGroupMeanRange: {min: 0, max: 0},
    CombinativeGameRange: {min: 0, max: 0},
    AccelerationRange: {min: 0, max: 0},
    OneVOneAttackingRange: {min: 0, max: 0},
    AttackingIntensityRange: {min: 0, max: 0},
    AttackingInTransitionRange: {min: 0, max: 0},
    PassingRange: {min: 0, max: 0},
    CrossingRange: {min: 0, max: 0},
    AttackingDuelRange: {min: 0, max: 0},
    ProgressivityRange: {min: 0, max: 0},
    BoxPresenceRange: {min: 0, max: 0},
    FinishingRange: {min: 0, max: 0},
    KeyPassesRange: {min: 0, max: 0},
    AttackingArealDuelRange: {min: 0, max: 0},
    PlayAgainstDeepBlockRange: {min: 0, max: 0},
    AttackingGroupMeanRange: {min: 0, max: 0},
    ScoutingIndexGroupMeanRange: {min: 0, max: 0},
};

export const nullPlayerReport: PlayerReport = {
    ReportID: 0,
    UserName: '',
    Date: '',
    Comment: '',
    ClassificationID: '',
    PlayerID: 0,
    PlayerPositionID: 0,
    PlayerProfileID: '',
    ResellableFlg: false,
    Speed: 0,
    Dynamic: 0,
    Agility: 0,
    PhysiqueGroupSum: 0,
    PhysiqueGroupMean: 0,
    GameIntelligence: 0,
    Agression: 0,
    MentalityGroupSum: 0,
    MentalityGroupMean: 0,
    Technique: 0,
    BallStrikingAbility: 0,
    PressureResistance: 0,
    TechnicalGroupSum: 0,
    TechnicalGroupMean: 0,
    Positioning: 0,
    BoxDefending: 0,
    OneVOneDefending: 0,
    DefendingDuel: 0,
    DefendingIntensity: 0,
    DefendingInTransition: 0,
    PressingIntensity: 0,
    DefendingArealDuel: 0,
    DefendingGroupSum: 0,
    DefendingGroupMean: 0,
    CombinativeGame: 0,
    Acceleration: 0,
    OneVOneAttacking: 0,
    AttackingIntensity: 0,
    AttackingInTransition: 0,
    Passing: 0,
    AttackingDuel: 0,
    Progressivity: 0,
    BoxPresence: 0,
    Finishing: 0,
    KeyPasses: 0,
    Crossing: 0,
    AttackingArealDuel: 0,
    PlayAgainstDeepBlock: 0,
    AttackingGroupSum: 0,
    AttackingGroupMean: 0,
    ScoutingIndexGroupSum: 0,
    ScoutingIndexGroupMean: 0,
    ReadOnlyFlg: false,
    FormationPosition_1: '',
    FormationPosition_2: '',
    FormationPosition_3: '',
    TypicalFormation: ''
};

export const nullPlayer: PlayerSearchData = {
    Nationality: [],
    PlayerID: 0,
    PlayerName: '',
    PlayerImageUrl: '',
    PlayerImageBase64: '',
    Height: 0,
    Weight: 0,
    FootID: '',
    NationalTeamID: '',
    PlayedInNational: 0,
    NationalTeamCategory: '',
    TeamID: '',
    TeamName: '',
    TeamImageUrl: '',
    TeamImageBase64: '',
    ClassificationID: '',
    PlayerPositionID: 0,
    PhysiqueGroupMean: 0,
    MentalityGroupMean: 0,
    TechnicalGroupMean: 0,
    DefendingGroupMean: 0,
    AttackingGroupMean: 0,
    ScoutingIndexGroupMean: 0,
    Birthday: 0,
    InShadowTeamFlg: false,
    InShortlistFlg: false,
    InPlayerListFlg: false,
    FollowedFlg: false,
    MinutesPlayed: 0,
    ReportDate: '',
};
