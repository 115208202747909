import React, { ReactElement, useEffect, useState } from 'react';
import { without } from 'lodash';
import { toastError } from '../../helpers/errorhandler';
import { cx } from '../../helpers/utility';
import useAuth from '../../hooks/auth.hook';
import { ShortlistService } from '../../services/openapi';
import { translate as t } from '../../helpers/translate';
import Tooltip from '../Tooltip/Tooltip';
import css from './ListButton.module.scss';
import AddIcon from '../../../assets/images/icons/icon_add.svg';
import RemoveIcon from '../../../assets/images/icons/icon_check_circle.svg';

const ListButton = function({
    type,
    className = '',
    playerId,
    playerInList,
    onToggle = () => Promise.resolve()
}: {
    type: 'shortList' | 'playerList',
    className?: string,
    playerId: number,
    playerInList: boolean,
    onToggle?: (inList?: boolean) => Promise<void> | void
}): ReactElement {

    const { requestParams } = useAuth();
    const [loadings, setLoadings] = useState<string[]>([]);
    const [inList, setInList] = useState<boolean>(playerInList);

    useEffect(
        () => {
            setInList(playerInList);
        },
        [playerInList]
    );

    function getLabels() {
        if (type === 'shortList') {
            return {
                title: inList
                    ? 'general.button.removeFromShortListTitle'
                    : 'general.button.addToShortListTitle',
                button: inList
                    ? 'general.button.removeFromShortList'
                    : 'general.button.addToShortList'
            };
        }
        else {
            return {
                title: inList
                    ? 'general.button.removeFromPlayerListTitle'
                    : 'general.button.addToPlayerListTitle',
                button: inList
                    ? 'general.button.removeFromPlayerList'
                    : 'general.button.addToPlayerList'
            };
        }
    }

    async function toggleList() {
        if (requestParams.UserID) {
            let EndpointRequest;
            if (type === 'shortList') {
                if (inList) {
                    EndpointRequest = ShortlistService.deleteFromShortlistCreate;
                }
                else {
                    EndpointRequest = ShortlistService.saveToShortlistCreate;
                }
                setLoadings(l => [...l, 'toggleList']);
                EndpointRequest({
                    ...requestParams,
                    PlayerID: playerId
                }).finally(
                    () => {
                        setLoadings(l => without(l, 'toggleList'));
                    }
                ).then(
                    (_resp) => {
                        onToggle(!inList);
                        setInList(current => !current);
                    }
                ).catch(
                    toastError
                );
            }
            else {
                setLoadings(l => [...l, 'toggleList']);
                await onToggle();
                setLoadings(l => without(l, 'toggleList'));
                // if (inList) {
                //     EndpointRequest = PlayerListsService.deleteFromPlayerListCreate;
                // }
                // else {
                //     EndpointRequest = PlayerListsService.saveToPlayerListCreate;
                // }
            }
        }
    }

    return <>
        <Tooltip text={t(getLabels().title)}>
            <button
                className={cx(
                    css.toggleList,
                    inList ? css.inList : css.notInList,
                    loadings.includes('toggleList') ? css.loading : null,
                    className
                )}
                disabled={loadings.includes('toggleList')}
                onClick={() => toggleList()}
            >
                <img className={css.addIcon} src={inList ? RemoveIcon : AddIcon} alt=""/>
                <p>
                    {t(getLabels().button)}
                </p>
            </button>
        </Tooltip>
    </>;

};

export default ListButton;
