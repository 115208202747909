import React, { ReactElement, useEffect,  useRef, useState } from 'react';
import * as echarts from 'echarts';
import { EChartOption, ECharts, EChartsResponsiveOption } from 'echarts';
import { noop } from 'lodash';
import { Data } from '../../interfaces/common';
import { Geometry, Rect } from '../../helpers/geometry';
import css from './EchartsWrapper.module.scss';

export interface EchartsWrapperProps {
    option: EChartOption | EChartsResponsiveOption,
    size?: { width: number, height: number },
    createSvg?: (svg: SVGSVGElement | null | undefined) => void
}

const EchartsWrapper = function ({
    option,
    size,
    createSvg = noop
}: EchartsWrapperProps): ReactElement {

    const chartRef = useRef<HTMLDivElement>(null);
    const [chartSet, setChartSet] = useState<ECharts>();
    const executed = useRef(false);

    useEffect(
        () => {
            let initTime = 0;
            if (initTime) {
                window.clearTimeout(initTime);
            }
            initTime = window.setTimeout(initChart, 1);
        },
        []
    );

    useEffect(
        () => {
            if (size) {
                chartSet?.resize(size);
            }
            chartSet?.on('click', handleChartClick);
            chartSet?.on('finished', () => handleCollisions(chartSet));
            chartSet?.setOption(option);
            if (chartSet && !executed.current) {
                createSvg(chartSet.getDom().querySelector('svg'));
                executed.current = true;
            }

            return () => {
                chartSet?.off('click', handleChartClick);
            };
        },
        [chartSet, createSvg, option, size]
    );

    useEffect(
        () => {
            chartSet?.setOption(option);
        },
        [chartSet, option]
    );

    function initChart() {
        setChartSet(() => {
            if (!chartRef.current) {
                return;
            }
            return echarts.init(chartRef.current, undefined, {
                renderer: 'svg'
            });
        });
    }

    function handleChartClick(params: Data<any>) {
        console.info(params);
    }

    function handleCollisions(chartSet: echarts.EChartsType) {
        const svg = chartSet.getDom().querySelector('svg');
        if (svg) {
            const dimensions: Rect[] = [];
            // Jobb híján ezzel vannak kiszürve az axisLabel-ek
            const textNodeList = Array.from(svg.querySelectorAll('text')).filter(
                textNode => {
                    const rect = textNode.getBoundingClientRect();
                    return rect.width < 30;
                }
            );
            textNodeList.forEach(
                (textNode, index, textNodes) => {
                    const rect = textNode.getBoundingClientRect();
                    const coord = /^translate\(([\d.]+) ([\d.]+)\)$/.exec(textNode.getAttribute('transform') ?? '');
                    if (rect.width < 30) {
                        dimensions.push({
                            x: Number(coord?.[1]),
                            y: Number(coord?.[2]),
                            w: rect.width,
                            h: rect.height
                        });
                        const last = dimensions.length - 1;
                        if (last > 0) {
                            const collisionWithIndex = dimensions.slice(0, -1).findIndex(
                                dim => Geometry.isRectIntersection(dimensions[last], dim)
                            );
                            if (collisionWithIndex > -1) {
                                const intersection = Geometry.getRectIntersection(dimensions[last], dimensions[collisionWithIndex]);
                                const lastOffset = (dimensions[last].x === intersection.x) ? 12 : -12;
                                textNode.setAttribute(
                                    'transform',
                                    `translate(${dimensions[last].x + lastOffset} ${dimensions[last].y})`
                                );
                                textNodes[collisionWithIndex].setAttribute(
                                    'transform',
                                    `translate(${dimensions[collisionWithIndex].x - lastOffset} ${dimensions[collisionWithIndex].y})`
                                );
                            }
                        }
                    }
                }
            );
        }
    }

    return <>
        <div ref={chartRef} className={css.chartBox}></div>
        {/*<>{console.info(JSON.stringify((option as any).series[0].data))}</>*/}
    </>;

};

export default EchartsWrapper;
