import { useState } from 'react';
import { PlayerSearchData } from '../services/openapi';
import useDeepCompareEffect from 'use-deep-compare-effect';

const usePlayerList = function(players: PlayerSearchData[], flagName: string) {

    const [playerInList, setPlayerInList] = useState<Map<number, boolean>>(new Map());

    useDeepCompareEffect(
        () => {
            const inSpecifiedList = new Map(playerInList);
            players.forEach(
                player => {
                    if (!playerInList.has(player.PlayerID)) {
                        inSpecifiedList.set(player.PlayerID, player[flagName as keyof PlayerSearchData] as boolean);
                    }
                }
            );
            setPlayerInList(inSpecifiedList);
        },
        [flagName, players]
    );

    const changePlayerInList = function(playerId: number, inList: boolean) {
        const inSpecifiedList = new Map(playerInList);
        inSpecifiedList.set(playerId, inList);
        setPlayerInList(inSpecifiedList);
    };

    return {
        playerInList,
        setPlayerInList,
        changePlayerInList
    };

};

export default usePlayerList;
