import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { AreaContext, AreaContextProps } from '../../../shared/contexts/Area.context';
import MapSelect from '../../../shared/modules/MapSelect/MapSelect';
import GenericModal from '../../../shared/modules/GenericModal/GenericModal';
import CategoryCollection from '../../../shared/modules/CategoryCollection/CategoryCollection';
import { PlayerLayerContext } from '../../../shared/contexts/PlayerLayer.context';
import useAuth from '../../../shared/hooks/auth.hook';
import { OverviewService, PlayerSearchFull } from '../../../shared/services/openapi';
import { toastError } from '../../../shared/helpers/errorhandler';
import { getContinentCategories, getCountryCategories } from '../../../shared/modules/MapSelect/MapSelectTemplate';
import css from './OverviewPage.module.scss';

const OverviewPage = function (): ReactElement {

    const { requestParams } = useAuth();
    const layerContext = useContext(PlayerLayerContext);
    const firstLoad = useRef<boolean>(true);
    const [mapReload, setMapReload] = useState<boolean>(false);
    const [areaContextValue, setAreaContextValue] = useState<AreaContextProps>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [reports, setReports] = useState<PlayerSearchFull>({ });

    useEffect(
        () => {
            if (layerContext.context && !layerContext.context.isModalOpen) {
                setMapReload(true);
            }
        },
        [layerContext.context, setMapReload]
    );

    useEffect(
        () => {
            setIsModalVisible(areaContextValue?.isModalOpen ?? false);
        },
        [areaContextValue]
    );

    useEffect(
        () => {
            if (requestParams.UserID && (mapReload || firstLoad.current)) {
                if (firstLoad.current) {
                    firstLoad.current = false;
                }
                OverviewService.searchAllReportedPlayersCreate(
                    requestParams
                ).then(resp => {
                    setReports(() => ({ ...resp }));
                    setAreaContextValue(
                        context => (
                            context
                                ? {
                                    ...context,
                                    categoryCollection:
                                        getContinentCategories({ ...resp }, context.area.id) ||
                                        getCountryCategories({ ...resp }, context.area.id)
                                }
                                : undefined
                        )
                    );
                    setMapReload(false);
                }).catch(
                    toastError
                );
            }
        },
        [mapReload, requestParams, setMapReload]
    );

    function closeModal(changedState: boolean) {
        setAreaContextValue(
            context => (
                context
                    ? {
                        ...context,
                        isModalOpen: changedState
                    }
                    : undefined
            )
        );
    }

    return <>
        <AreaContext.Provider value={{ context: areaContextValue, setContext: setAreaContextValue }}>
            <div className={css.overview}>
                <MapSelect
                    reports={reports}
                />
                <GenericModal
                    changeVisibility={closeModal}
                    isVisible={isModalVisible}
                    title={areaContextValue?.area.name ?? '-'}
                >
                    <CategoryCollection
                        initialCategory={areaContextValue?.category}
                        listCollection={areaContextValue?.categoryCollection}
                    />
                </GenericModal>
            </div>
        </AreaContext.Provider>
    </>;

};

export default OverviewPage;
