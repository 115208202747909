import React, { ReactElement, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { OptionType } from './select';
import { ArrayOfObjects, Html, cx, macrotask } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import { Rect } from '../../helpers/geometry';
import css from './select.module.scss';

const SelectTemplate = function<T>({
    label,
    className,
    multiple,
    position,
    options,
    currentSelectedOptions,
    emptySelection,
    disabled,
    isSoloLabel,
    hideLabelWhenSelected,
    selection,
    isLabelOnTop,
    clearable,
    filter,
    filterText,
    setFilterText
}: {
    label: string,
    className: string,
    multiple: boolean,
    position: 'absolute' | 'static',
    options: OptionType<T>[],
    currentSelectedOptions: OptionType<T>[],
    emptySelection: () => void,
    disabled: boolean,
    isSoloLabel?: boolean,
    hideLabelWhenSelected?: boolean,
    selection: (selected: OptionType<T>) => void,
    isLabelOnTop?: boolean,
    clearable: boolean,
    filter: boolean,
    filterText: string,
    setFilterText: React.Dispatch<React.SetStateAction<string>>
}): ReactElement {

    const [isActive, setIsActive] = useState<boolean>(false);
    const selectButton = useRef<HTMLInputElement>(null);
    const filterInput = useRef<HTMLInputElement>(null);
    const [dropDownPos, setDropDownPos] = useState<Rect>({ x: 0, y: 0, w: 0, h: 0 });

    useEffect(
        () => {
            if (isActive && selectButton.current) {
                const size = selectButton.current.getBoundingClientRect();
                setDropDownPos({
                    x: size.x,
                    y: size.y,
                    w: size.width,
                    h: size.height
                });
            }
        },
        [isActive]
    );

    const moduleClassNames = className.split(' ').map(
        (cl: string) => css[cl]
    ).join(' ');

    const toggleActive = function() {
        if (!disabled) {
            if (isActive) {
                setFilterText('');
            }
            setIsActive((current: boolean) => !current);
            macrotask(() => {
                filterInput.current?.focus();
            });
        }
    };

    const selectOption = function(selected: OptionType<T>) {
        if (!multiple) {
            setIsActive(false);
        }
        selection(selected);
    };

    const filterControl = function(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Escape') {
            setFilterText('');
        }
    };

    const filterHighlight = function(text: string) {
        if (filter) {
            return <Html>{text.replace(RegExp(`^(.*?)(${filterText})(.*?)$`, 'gi'), '$1<strong>$2</strong>$3')}</Html>;
        }
        else {
            return text;
        }
    };

    const emptySelectionEvent = function(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        event.stopPropagation();
        emptySelection();
    };

    return <>
        <div className={css.selectBox}>
            {isLabelOnTop && <span className={css.selectLabel}>{t(label)}</span>}
            <div className={cx(css.selectWrapper, moduleClassNames, css[position])}>
                <div
                    ref={selectButton}
                    className={cx(css.selectButton, disabled ? css.disabled : '', isActive ? css.opened : null)}
                    onClick={toggleActive}
                >
                    <span className={css.defaultElement}>
                        {(hideLabelWhenSelected && currentSelectedOptions.length > 0) ? '' : t(label)}
                        {isSoloLabel
                            ? currentSelectedOptions.length > 0 && (
                                hideLabelWhenSelected ? '' : label && ': '
                            ) + currentSelectedOptions[0].value
                            : currentSelectedOptions.length > 0 && (
                                <>&nbsp;({currentSelectedOptions.length})</>
                            )
                        }
                    </span>
                    {(multiple || clearable) && (
                        <span
                            className={css.emptySelection}
                            onClick={(event) => emptySelectionEvent(event)}
                        >X</span>
                    )}
                    {/*
                    <Loop forEach={currentSelecteds}>
                        {(selected: OptionType) => (
                            <span key={selected.id} className={styles.selectedElement}>{selected.value}</span>
                        )}
                    </Loop>
                    */}
                </div>
                {isActive &&
                    ReactDOM.createPortal(
                        <div className={css.selectBackdrop} onClick={toggleActive}>
                            <div
                                className={css.selectOptions}
                                style={{
                                    left: `${dropDownPos.x}px`, top: `${dropDownPos.y + dropDownPos.h}px`, width: `${dropDownPos.w}px`
                                }}
                                onClick={(event) => event.stopPropagation()}
                            >
                                {filter && (
                                    <div className={css.filterContainer}>
                                        <input
                                            className={css.filterInput}
                                            ref={filterInput}
                                            type="text"
                                            value={filterText}
                                            onChange={(event) => setFilterText(event.target.value)}
                                            onKeyDown={(event) => filterControl(event)}
                                        />
                                    </div>
                                )}
                                {options.map(option =>
                                    <div
                                        key={option.id}
                                        className={cx(
                                            css.selectOption,
                                            ArrayOfObjects.take(currentSelectedOptions, 'id').includes(option.id) ? css.selected : ''
                                        )}
                                        onClick={() => selectOption(option)}
                                    >
                                        {filterHighlight(option.value)}
                                    </div>
                                )}
                            </div>
                        </div>,
                        document.body
                    )
                }
            </div>
        </div>
    </>;

};

export default SelectTemplate;
