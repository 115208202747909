import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Welcome = function (): ReactElement {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/scouting');
    }, [navigate]);

    return <>Welcome</>;

};

export default Welcome;
