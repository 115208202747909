import React, { ChangeEvent, ReactElement, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Html, cx } from '../../../shared/helpers/utility';
import { translate as t } from '../../../shared/helpers/translate';
import Select, { OptionType } from '../../../shared/modules/select/select';
import { PlayerSearchData } from '../../../shared/services/openapi';
import { FradiLoader } from '../../../shared/modules/FradiLoader/FradiLoader';
import { ShortListTabEnum } from '../../../shared/interfaces/carried-on.interfaces';
import TabAccordion from '../../../shared/modules/TabAccordion/TabAccordion';
import PlayerShortListCard from '../../../shared/modules/PlayerShortListCard/PlayerShortListCard';
import usePlayerList from '../../../shared/hooks/player-list.hook';
import Tooltip from '../../../shared/modules/Tooltip/Tooltip';
import { SortingDescriptor } from '../general-list/GeneralList';
import css from './ShortList.module.scss';
import ClipboardGrey from '../../../assets/images/icons/icon_file_clipboard_grey.svg';

const ShortListTemplate = function({
    loadings,
    shortListPlayers,
    setShortListPlayers,
    selfListPlayers,
    optionPosition,
    selectedPosition,
    setSelectedPosition,
    optionCategory,
    selectedCategory,
    setSelectedCategory,
    handlePlayerLayer,
    searchToTeam,
    searchTerm,
    changeSearchTerm,
    sorting,
    changeSorting,
    savePdf
}: {
    loadings: string[],
    shortListPlayers: PlayerSearchData[],
    setShortListPlayers: React.Dispatch<React.SetStateAction<PlayerSearchData[]>>,
    selfListPlayers: PlayerSearchData[],
    optionPosition: OptionType[],
    selectedPosition: number,
    setSelectedPosition: React.Dispatch<React.SetStateAction<number>>,
    optionCategory: OptionType[],
    selectedCategory: string,
    setSelectedCategory: React.Dispatch<React.SetStateAction<string>>,
    handlePlayerLayer: (playerId: number) => void,
    searchToTeam: (teamName: string) => void,
    searchTerm: string,
    changeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void,
    sorting: SortingDescriptor,
    changeSorting: (column: keyof PlayerSearchData) => void,
    savePdf: (filtered: boolean) => Promise<void>
}): ReactElement {

    const tabList = Object.values(ShortListTabEnum);
    const {
        playerInList: playerInPlayerList, changePlayerInList: changePlayerInPlayerList
    } = usePlayerList(shortListPlayers, 'InPlayerListFlg');

    const [_selectType, setSelectType] = useState<ShortListTabEnum>(ShortListTabEnum.FULL);

    const changeTab = function(selectedTab: ShortListTabEnum) {
        setSelectType(selectedTab);
    };

    const removeShortList = function(playerId: number) {
        setShortListPlayers(
            players => players.filter(
                player => player.PlayerID !== playerId
            )
        );
    };

    const sortableColumn = function(
        column: keyof PlayerSearchData, label: string, style: Record<string, string> = css
    ) {
        return (
            <Tooltip text={t(`general.label.sort.${label}`)}>
                <th
                    className={cx(
                        style[label], 'sortableColumn', sorting.column === column && 'sort-' + sorting.direction
                    )}
                    onClick={() => changeSorting(column)}
                >
                    {t(`playerDetails.label.${label}`)}
                </th>
            </Tooltip>
        );
    };

    return <>
        <div className={cx('contentContainer', css.shortList)}>
            <div className={cx('contentHeader', css.contentHeader)}>
                <h1>{t('scouting.short-list.title')}</h1>
                <div className="buttonsAndFilters">
                    <input
                        className={css.search}
                        type="search"
                        placeholder={t('scouting.search')}
                        value={searchTerm}
                        onChange={event => changeSearchTerm(event)}
                    />
                    <Select
                        isSoloLabel={true}
                        options={[
                            { id: '', value: t('scouting.short-list.all-categories') },
                            ...optionCategory
                        ]}
                        changeSelected={
                            (options) => setSelectedCategory(
                                String(options[0]?.id) ?? ''
                            )
                        }
                        selectedOptions={[{
                            id: selectedCategory,
                            value: selectedCategory === '' ? t('scouting.short-list.all-categories') : String(selectedCategory)
                        }]}
                        label={t('playerDetails.label.classification')}
                    ></Select>
                    <Select
                        isSoloLabel={true}
                        options={[
                            { id: 0, value: t('scouting.short-list.all-positions') },
                            ...optionPosition
                        ]}
                        changeSelected={
                            (options) => setSelectedPosition(
                                +options[0]?.id || 0
                            )
                        }
                        selectedOptions={[{
                            id: selectedPosition,
                            value: selectedPosition === 0 ? t('scouting.short-list.all-positions') : String(selectedPosition)
                        }]}
                        label={t('playerDetails.label.post')}
                    ></Select>
                    <button
                        className={cx(
                            'btn btn-secondary btn-outline',
                            css.dropdownToggle,
                            css.filterPageButton,
                            loadings.includes('pdf') ? css.loading : null
                        )}
                        disabled={loadings.includes('pdf') || loadings.includes('shortList')}
                    >
                        <img src={ClipboardGrey} alt=""/>
                        {t('scouting.short-list.export')}
                        <div className={css.dropdownMenu}>
                            <a
                                className={cx(css.dropdownItem)}
                                onClick={() => savePdf(false)}
                            >
                                {t('scouting.short-list.export-entire')}
                            </a>
                            <a
                                className={css.dropdownItem}
                                onClick={() => savePdf(true)}
                            >
                                {t('scouting.short-list.export-filtered')}
                            </a>
                        </div>
                    </button>
                </div>
            </div>
            <div className={css.shortListContent}>
                <TabAccordion<ShortListTabEnum>
                    tabs={tabList}
                    text={{
                        [ShortListTabEnum.FULL]: `${t(`tab.shortList.${ShortListTabEnum.FULL}`)} (${shortListPlayers.length})`,
                        [ShortListTabEnum.SELF]: `${t(`tab.shortList.${ShortListTabEnum.SELF}`)} (${selfListPlayers.length})`
                    }}
                    defaultTab={ShortListTabEnum.FULL}
                    tabSelect={changeTab}
                >
                    {(selectedTab) => (
                        <div className={cx(css.shortListContainer, css.listed)}>
                            <p className={css.emptyStateText}><Html>{t('scouting.short-list.empty-list-text')}</Html></p>
                            <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                                <table>
                                    <thead>
                                        <tr className={css.listHeader}>
                                            <th className={css.playerPicture}></th>
                                            {sortableColumn('PlayerName', 'playerName')}
                                            {sortableColumn('ClassificationID', 'classification')}
                                            {sortableColumn('Birthday', 'birthYear')}
                                            {sortableColumn('TeamName', 'teamName')}
                                            {sortableColumn('PlayerPositionID', 'post')}
                                            {sortableColumn('MinutesPlayed', 'minutes')}
                                            {sortableColumn('ReportDate', 'date')}
                                            {sortableColumn('ScoutingIndexGroupMean', 'fradiIndex')}
                                            {sortableColumn('InShadowTeamFlg', 'shadowTeam')}
                                            <th className={css.playerList}></th>
                                            {selectedTab === ShortListTabEnum.FULL && <th className={css.shortList}></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedTab === ShortListTabEnum.FULL &&
                                            shortListPlayers.map(player => (
                                                <PlayerShortListCard
                                                    key={player.PlayerID}
                                                    player={player}
                                                    inPlayerList={playerInPlayerList.get(player.PlayerID) ?? false}
                                                    changeInPlayerList={(inList) => changePlayerInPlayerList(player.PlayerID, inList)}
                                                    handlePlayerLayer={handlePlayerLayer}
                                                    searchToTeam={searchToTeam}
                                                    removeShortList={removeShortList}
                                                    excludedColumns={['polarChart']}
                                                />
                                            ))
                                        }
                                        {
                                            selectedTab === ShortListTabEnum.SELF &&
                                            selfListPlayers.map(player => (
                                                <PlayerShortListCard
                                                    key={player.PlayerID}
                                                    player={player}
                                                    inPlayerList={playerInPlayerList.get(player.PlayerID) ?? false}
                                                    changeInPlayerList={(inList) => changePlayerInPlayerList(player.PlayerID, inList)}
                                                    handlePlayerLayer={handlePlayerLayer}
                                                    searchToTeam={searchToTeam}
                                                    excludedColumns={['polarChart']}
                                                />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </SimpleBar>
                            <FradiLoader visible={loadings.includes('shortList')}/>
                        </div>
                    )}
                </TabAccordion>
            </div>
        </div>
    </>;

};

export default ShortListTemplate;
