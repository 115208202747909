import React, { ReactElement, useState } from 'react';
import { noop, times, without } from 'lodash';
import { OwnShadowTeamService, OwnTeamPlayerSearchData, PlayerSearchData } from '../../services/openapi';
import { cx, parsePlayerName } from '../../helpers/utility';
import { indicatorOrder, TODAY } from '../../constants';
import { formatDate, translate as t } from '../../helpers/translate';
import EchartsRadar from '../EchartsWrapper/EchartsRadar/EchartsRadar';
import { CategoryEnum } from '../../interfaces/reports.interface';
import Tooltip from '../Tooltip/Tooltip';
import ListButton from '../ListButtons/ListButton';
import Select, { OptionType } from '../select/select';
import useAuth from '../../hooks/auth.hook';
import { toastError } from '../../helpers/errorhandler';
import PlayerListButton from '../PlayerListButton/PlayerListButton';
import css from './PlayerShortListCard.module.scss';
import IconStarFilled from '../../../assets/images/icons/icon_star_filled.svg';
import IconStarEmpty from '../../../assets/images/icons/icon_star_empty.svg';

const PlayerShortListCard = function({
    player,
    inPlayerList,
    changeInPlayerList,
    handlePlayerLayer = noop,
    searchToTeam = noop,
    removeShortList = null,
    expiration = false,
    excludedColumns = []
}: {
    player: PlayerSearchData | OwnTeamPlayerSearchData,
    inPlayerList?: boolean,
    changeInPlayerList?: (inList: boolean) => void,
    handlePlayerLayer?: (playerId: number) => void,
    searchToTeam?: (teamName: string) => void,
    removeShortList?: ((playerId: number) => void) | null,
    expiration?: boolean,
    excludedColumns?: string[]
}): ReactElement {

    const { requestParams } = useAuth();

    const [_loadings, setLoadings] = useState<string[]>([]);

    const changeExpiration = function(selecteds: OptionType<unknown>[]) {
        const expireYear = Number(selecteds[0].id);
        if (requestParams.UserID) {
            setLoadings(l => [...l, 'changeExpireYear']);
            OwnShadowTeamService.savePlayerContractExpiryYearCreate({
                ...requestParams,
                PlayerID: player.PlayerID,
                ExpiryYear: expireYear
            }).finally(
                () => {
                    setLoadings(l => without(l, 'changeExpireYear'));
                }
            ).catch(
                toastError
            );
        }
    };

    return <>
        <tr className={cx(
            css.listElement,
            (!excludedColumns.includes('shortList') || !excludedColumns.includes('playerList')) ? css.hasButton : ''
        )}>
            {!excludedColumns.includes('playerName') && <>
                <td className={css.playerPictureCell}>
                    <div className={cx(
                        css.playerPicture,
                        css['category-' + (Object.keys(CategoryEnum)[
                            Object.values(CategoryEnum).indexOf(player.ClassificationID as unknown as CategoryEnum)
                        ] ?? 'none')]
                    )}>
                        <img src={player.PlayerImageUrl} alt=""/>
                    </div>
                </td>
                <td className={css.playerName}>
                    <div onClick={() => handlePlayerLayer(player.PlayerID)}>
                        <p className={css.firstName}>{parsePlayerName(player.PlayerName)[0]}</p>
                        <p className={css.surName}>{parsePlayerName(player.PlayerName)[1]}</p>
                    </div>
                </td>
            </>}
            {!excludedColumns.includes('classification') && <>
                <td className={css.classification}>{player.ClassificationID}</td>
            </>}
            {!excludedColumns.includes('birthYear') && <>
                <td className={css.birthYear}>{player.Birthday}</td>
            </>}
            {!excludedColumns.includes('teamName') && <>
                <td className={css.teamName}>
                    <div className={css.flexContainer}>
                        <img src={player.TeamImageUrl} alt=""/>
                        <p className={css.nameText} onClick={() => searchToTeam(player.TeamName)}>{player.TeamName}</p>
                    </div>
                </td>
            </>}
            {expiration && <>
                <td className={css.expiration}>
                    <Select
                        className="thin"
                        isSoloLabel={true}
                        hideLabelWhenSelected={true}
                        options={times(8).map(
                            index => ({ id: index + TODAY.getFullYear(), value: String(index + TODAY.getFullYear()) })
                        )}
                        selectedOptions={
                            ((player as OwnTeamPlayerSearchData).ContractExpiryYear)
                                ? [{
                                    id: (player as OwnTeamPlayerSearchData).ContractExpiryYear,
                                    value: String((player as OwnTeamPlayerSearchData).ContractExpiryYear)
                                }]
                                : []
                        }
                        changeSelected={(selectedOptions) => changeExpiration(selectedOptions)}
                        label={'scouting.shadow.modal.label.expiration-date'}
                    />
                </td>
            </>}
            {!excludedColumns.includes('post') && <>
                <td className={css.post}>{player.PlayerPositionID}</td>
            </>}
            {!excludedColumns.includes('minutes') && <>
                <td className={css.minutes}>{player.MinutesPlayed}</td>
            </>}
            {!excludedColumns.includes('date') && <>
                <td className={css.date}>{player.ReportDate ? formatDate(player.ReportDate) : '-'}</td>
            </>}
            {!excludedColumns.includes('fradiIndex') && <>
                <td className={css.fradiIndex}>
                    <div className={css.dottedCircle}>
                        <div className={css.valueCircle}>
                            <span className={css.indexValue}>{player.ScoutingIndexGroupMean ?? '-'}</span>
                        </div>
                    </div>
                </td>
            </>}
            {!excludedColumns.includes('polarChart') && <>
                <td className={css.polarChart}>
                    <EchartsRadar
                        showLabel={false}
                        size={{ width: 80, height: 80 }}
                        dataList={player}
                        order={indicatorOrder}
                        dotSize={5}
                        showTicks={false}
                    />
                </td>
            </>}
            {!excludedColumns.includes('shadowTeam') && <>
                <td className={css.shadowTeam}>
                    <Tooltip
                        text={
                            player.InShadowTeamFlg ? t('scouting.short-list.in-shadow-team') : t('scouting.short-list.not-in-shadow-team')
                        }
                    >
                        <div className={cx(css.dataContainer, css.toggleShadowTeam)}>
                            <img
                                src={player.InShadowTeamFlg ? IconStarFilled : IconStarEmpty}
                                alt={
                                    player.InShadowTeamFlg
                                        ? t('scouting.short-list.in-shadow-team')
                                        : t('scouting.short-list.not-in-shadow-team')
                                }
                            />
                        </div>
                    </Tooltip>
                </td>
            </>}
            {!excludedColumns.includes('playerList') && inPlayerList !== undefined && changeInPlayerList && <>
                <td className={css.playerList}>
                    <PlayerListButton
                        className={cx('btn', css.tallButton)}
                        playerId={player.PlayerID}
                    />
                </td>
            </>}
            {!excludedColumns.includes('shortList') && removeShortList && <>
                <td className={css.shortList}>
                    <ListButton
                        type="shortList"
                        playerId={player.PlayerID}
                        playerInList={true}
                        onToggle={() => removeShortList(player.PlayerID)}
                    />
                </td>
            </>}
        </tr>
        <tr className={css.emptyRow}></tr>
    </>;

};

export default PlayerShortListCard;
