import React, { ReactElement } from 'react';
import css from './ProgressLine.module.scss';

interface ComponentProps {
    value: number;
}

export const ProgressLine = function({
    value
}: ComponentProps): ReactElement {

    return <>
        <div className={css.lineContainer}>
            <div className={css.progress} style={{width: value + '%'}}>
                <span>{value + '%'}</span>
            </div>
        </div>
    </>;

};
