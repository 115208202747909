import React, { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { FeatureCollection, Geometry } from 'geojson';
import { GeoCountryProperties } from '../../interfaces/geo-json.interface';
import { getCurrentLang } from '../../helpers/translate';
import { PlayerSearchFull } from '../../services/openapi';
import MapSelectTemplate from './MapSelectTemplate';
import { ContinentCollection } from '../../../assets/geojson/continents/continentCollection';
import data from './../../../assets/geojson/mediumgeo.json';
// import data from './../../../assets/geojson/lowgeo.json';

const MapSelect = function({
    reports
}: {
    reports: PlayerSearchFull
}): ReactElement {

    const [countries, setCountries] = useState<FeatureCollection<Geometry, GeoCountryProperties>>();
    const translatedNameProp = 'name_' + getCurrentLang() as keyof GeoCountryProperties;

    const hiddenCountryList: MutableRefObject<string[]> = useRef([
        'GBR',
    ]);

    useEffect(
        () => {
            setCountries(() => {
                const featureCollection = data as FeatureCollection<Geometry, GeoCountryProperties>;
                return {
                    ...featureCollection,
                    features: featureCollection.features.filter(
                        feature => !hiddenCountryList.current.includes(feature.properties.adm0_iso)
                    )
                };
            });
        },
        []
    );

    return <>
        <MapSelectTemplate
            langName={translatedNameProp}
            countries={countries}
            reports={reports}
            continents={ContinentCollection}
        />
    </>;

};

export default MapSelect;
