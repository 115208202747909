import React, { ReactElement, useContext } from 'react';
import { cx, getYear, parsePlayerName } from '../../../../helpers/utility';
import { translate as t } from '../../../../helpers/translate';
import { PlayerLayerContext } from '../../../../contexts/PlayerLayer.context';
import { PlayerBaseData, PlayerReport } from '../../../../services/openapi';
import { NationNames } from '../../../NationNames/NationNames';
import { CategoryEnum } from '../../../../interfaces/reports.interface';
import css from './PlayerBase.module.scss';
import parentCss from '../PlayerDetails.module.scss';

export interface PlayerBaseCardProps {
    baseData: PlayerBaseData,
    reportData?: PlayerReport,
}

const PlayerBaseCard = function({
    baseData,
    reportData,
}: PlayerBaseCardProps): ReactElement {

    const layerContext = useContext(PlayerLayerContext);
    const playerName = parsePlayerName(baseData.PlayerName);

    function searchToTeam(teamName: string) {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isSearchModalOpen: teamName
            });
        }
    }

    return <>
        <div className={cx(parentCss.content, parentCss.baseData, parentCss.profile, css.profile)}>
            <div className={css.playerRow}>
                <div className={cx(css.sector, css.sectorPlayer)}>
                    <img
                        src={baseData.PlayerImageUrl}
                        className={
                            css['category-' + (Object.keys(CategoryEnum)[
                                Object.values(CategoryEnum).indexOf(reportData?.ClassificationID as unknown as CategoryEnum)
                            ] ?? 'none')]
                        }
                        alt=""
                    />
                    <div className={css.playerName}>
                        <span className={css.first}>{playerName[0]}</span>
                        <span className={css.last}>{playerName[1]}</span>
                    </div>
                </div>
                <div className={cx(css.sector, css.sectorPoint)}>
                    <span className={css.label}>{t('playerDetails.label.index')}</span>
                    <div className={css.dottedCircle}>
                        <div className={css.valueCircle}>
                            <span className={css.indexValue}>{reportData?.ScoutingIndexGroupMean ?? '-'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'separatorLine horizontal'}></div>

            <div className={css.infoRow}>
                <div className={cx(css.sector, css.infoList)}>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.Birthday')}:</span>
                        <span className={css.value}>{getYear(baseData.Birthday)}</span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.Height')}:</span>
                        <span className={css.value}>{baseData.Height ? `${baseData.Height} ${t('general.unit.cm')}` : '-'}</span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.Weight')}:</span>
                        <span className={css.value}>{baseData.Weight ?? '-'}</span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.FootID')}:</span>
                        <span className={css.value}>{baseData.FootID ? t(`playerDetails.label.foot.${baseData.FootID}`) : '-'}</span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.NationalityID')}:</span>
                        <span className={css.value}><NationNames nations={baseData.Nationality?.map(item => item.NationalityID)}/></span>
                    </div>
                </div>

                <div className={'separatorLine vertical'}></div>

                <div className={cx(css.sector, css.infoList)}>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.TeamName')}:</span>
                        <span className={cx(css.value, css.teamSearch)} onClick={() => searchToTeam(baseData.TeamName)}>
                            {baseData.TeamName}
                        </span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.PlayerPositionID')}:</span>
                        <span className={css.value}>{reportData?.PlayerPositionID ?? '-'}</span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.PlayerProfileID')}:</span>
                        <span className={css.value}>
                            {reportData?.PlayerProfileID
                                ? reportData.PlayerProfileID.split(',').map(
                                    profileId => t(`general.profile.${profileId}`)
                                ).join('/')
                                : '-'
                            }
                        </span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.ClassificationID')}:</span>
                        <span className={css.value}>{reportData?.ClassificationID ?? '-'}</span>
                    </div>
                    <div className={css.infoRecord}>
                        <span className={css.label}>{t('playerDetails.label.info.PlayedInNational')}:</span>
                        <span className={css.value}>{baseData.PlayedInNational} ({baseData.NationalTeamCategory})</span>
                    </div>

                </div>
            </div>
        </div>
    </>;

};

export default PlayerBaseCard;
