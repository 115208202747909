import React, { ReactElement, useEffect, useState } from 'react';
import TooltipTemplate from './Tooltip.template';

export enum BoxPoint {
    'left top',
    'left center',
    'left bottom',
    'right top',
    'right center',
    'right bottom',
    'center top',
    'center center',
    'center bottom'
}

export enum TooltipCollision {
    'none none',
    'none fit',
    'none flip',
    'fit none',
    'fit fit',
    'fit flip',
    'flip none',
    'flip fit',
    'flip flip',
    'shrink none',
    'shrink fit',
    'shrink flip'
}

export interface TooltipPosition {
    my: BoxPoint;
    at: BoxPoint;
    of: HTMLElement | 'toggler';
    within?: HTMLElement | Document | Window;
    offset?: {
        x: number;
        y: number;
    };
    collision?: TooltipCollision
}

/**
 * Custom tooltip
 * @param {object} param
 * @return {ReactElement}
 * @example
 *  <Tooltip text={player.TeamName} className="red">
 *      <p className={css.nameText}>{player.TeamName}</p>
 *  </Tooltip>
 *  <Tooltip html={<div style={{ color: 'red' }}>{player.TeamName}</div>} ifOverflow={true}>
 *      <p className={css.nameText}>{player.TeamName}</p>
 *  </Tooltip>
 */
const Tooltip = function({
    text = null,
    html = null,
    ifOverflow = false,
    position = {
        my: BoxPoint['left top'],
        at: BoxPoint['left bottom'],
        of: 'toggler',
        within: window,
        offset: { x: 0, y: 15 },
        collision: TooltipCollision['fit fit']
    },
    className = '',
    children
}: {
    text?: string | null,
    html?: ReactElement | null,
    ifOverflow?: boolean,
    position?: TooltipPosition,
    className?: string,
    children: ReactElement
}): ReactElement {

    const [tooltipHtml, setTooltipHtml] = useState<ReactElement | null>(null);

    useEffect(
        () => {
            if (html) {
                setTooltipHtml(html);
            }
            if (!html && text) {
                setTooltipHtml(<span>{text}</span>);
            }
        },
        [html, text]
    );

    return <>
        {tooltipHtml && (
            <TooltipTemplate
                baseElement={children}
                html={tooltipHtml}
                ifOverflow={ifOverflow}
                position={position}
                className={className}
            />
        )}
        {!tooltipHtml && (
            <>{children}</>
        )}
    </>;

};

export default Tooltip;
