import React, { ReactElement } from 'react';
import { noop } from 'lodash';
import { PlayerSearchData } from '../../services/openapi';
import { cx, parsePlayerName } from '../../helpers/utility';
import { indicatorOrder } from '../../constants';
import { translate as t } from '../../helpers/translate';
import EchartsRadar from '../EchartsWrapper/EchartsRadar/EchartsRadar';
import { CategoryEnum } from '../../interfaces/reports.interface';
import Tooltip from '../Tooltip/Tooltip';
import css from './PlayerShadowTeamCard.module.scss';

const PlayerShadowTeamCard = function({
    player,
    handlePlayerLayer = noop,
    removeFromList
}: {
    player: PlayerSearchData,
    handlePlayerLayer?: (playerId: number) => void,
    removeFromList: (playerId: number) => void
}): ReactElement {

    return <>
        <div className={css.listElement}>
            <div className={css.delete} onClick={() => removeFromList(player.PlayerID)}>X</div>
            <div className={css.topSection}>
                <div className={cx(
                    css.playerPicture,
                    css['category-' + (Object.keys(CategoryEnum)[
                        Object.values(CategoryEnum).indexOf(player.ClassificationID as unknown as CategoryEnum)
                    ] ?? 'none')]
                )}>
                    <img src={player.PlayerImageUrl} alt=""/>
                </div>
                <div className={css.playerName} onClick={() => handlePlayerLayer(player.PlayerID)}>
                    <p className={css.firstName}>{parsePlayerName(player.PlayerName)[0]}</p>
                    <p className={css.surName}>{parsePlayerName(player.PlayerName)[1]}</p>
                    <p className={css.birthYear}>{player.Birthday}</p>
                </div>
                <div className={css.polarChart}>
                    <EchartsRadar
                        showLabel={false}
                        size={{ width: 80, height: 80 }}
                        dataList={player}
                        order={indicatorOrder}
                        dotSize={5}
                        showTicks={false}
                    />
                </div>
            </div>
            <div className={css.bottomSection}>
                <div className={css.teamName}>
                    <div className={css.label}>{t('playerDetails.label.info.TeamName')}</div>
                    <div className={css.value}>
                        <img src={player.TeamImageUrl} alt=""/>
                        <Tooltip text={player.TeamName} ifOverflow={true}>
                            <p className={css.nameText}>{player.TeamName}</p>
                        </Tooltip>
                    </div>
                </div>
                <div className={css.post}>
                    <div className={css.label}>{t('playerDetails.label.post')}</div>
                    <div className={css.value}>
                        {player.PlayerPositionID}
                    </div>
                </div>
                <div className={css.minutes}>
                    <div className={css.label}>{t('playerDetails.label.minutes')}</div>
                    <div className={css.value}>
                        {player.MinutesPlayed}
                    </div>
                </div>
            </div>
        </div>
    </>;

};

export default PlayerShadowTeamCard;
