import { ProfileIndexID } from '../services/openapi';
import { PlayerReportRequestType } from './reports.interface';

export type IndicatorValues = Pick<PlayerReportRequestType,
    'PhysiqueGroupMean' |
    'MentalityGroupMean' |
    'TechnicalGroupMean' |
    'AttackingGroupMean' |
    'DefendingGroupMean'
>

export enum SeasonTabEnum {
    CURRENT = 'Current',
    PREVIOUS = 'Previous'
}

export enum TeamTabEnum {
    SHORTLIST = 'shortList',
    OWN_TEAM = 'ownTeam',
    SEARCHLIST = 'searchList'
}

export enum FilterTabMainEnum {
    DEFAULT = 'default',
    INDEX = 'index',
    PHYSIQUE = 'PhysiqueGroup',
    MENTALITY = 'MentalityGroup',
    TECHNICAL = 'TechnicalGroup',
}

export enum FilterTabSecondaryEnum {
    DEFENDING = 'DefendingGroup',
    ATTACKING = 'AttackingGroup'
}

export enum ShortListTabEnum {
    FULL = 'ShortList',
    SELF = 'SelfTeam'
}

export enum ReportActionType {
    VIEW = 'view',
    SHARE = 'share',
    ADD = 'add',
    DOWNLOAD = 'download',
    DELETE = 'delete'
}

export enum ProfileGroupTypes {
    PHYSIQUE = 'PhysiqueGroup',
    MENTALITY = 'MentalityGroup',
    TECHNICAL = 'TechnicalGroup',
    DEFENDING = 'DefendingGroup',
    ATTACKING = 'AttackingGroup'
}

export type ProfileGroupContent = {
    [key in ProfileGroupTypes ]: ProfileIndexID[]
}

export enum ProfileInTypes {
    MIRRORSIDE = 'MirrorSide',
    LINESIDE = 'LineSide',
    COMPLEX = 'Complex',
    PLAYMAKER = 'PlayMaker',
    SIDETEN = 'SideTen',
    FINISHER = 'Finisher',
    POWERSTRIKER = 'PowerStriker',
    LINESTRIKER = 'LineStriker',
    BOXTOBOX = 'BoxToBox',
    ATTACKINGTYPE = 'AttackingType',
    DEFENDINGTYPE = 'DefendingType',
    ORGANIZER = 'Organizer',
    PHYSICAL = 'Physical',
    READER = 'Reader'
}

export interface ConfigSetting {
    id: number;
    type: 'rename' | 'delete';
}
