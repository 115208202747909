import jsPDF from 'jspdf';
import { translate as t } from '../../helpers/translate';
import { PlayerSearchData } from '../openapi';
import { AdditionalData, addLayout } from './pdf-generation-layout';
import { createListCard, createListHeader } from './pdf-generation-common';

const maxCardPerPage = 12;

export const generatePdf = async function({
    list,
    charts,
    additionalData
}: {
    list: PlayerSearchData[],
    charts: Map<number, SVGSVGElement>,
    additionalData: AdditionalData
}): Promise<jsPDF> {

    const pdf = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
        unit: 'px'
    });

    const allPage = Math.ceil(list.length / maxCardPerPage);

    let currentPage = 1;

    pdf.setPage(currentPage);
    await addLayout({
        pdf,
        title: t('pdf.playerlist.title'),
        page: { current: currentPage, all: allPage },
        additionalData
    });
    createListHeader(pdf);
    let index = 0;
    for (const player of list) {
        if (index >= maxCardPerPage) {
            index = 0;
            pdf.addPage();
            pdf.setPage(++currentPage);
            await addLayout({
                pdf,
                title: t('pdf.playerlist.title'),
                page: { current: currentPage, all: allPage },
                additionalData
            });
            createListHeader(pdf);
        }
        const currentChart = [...charts.entries()].find(
            ([playerId, _chart]) => playerId === player.PlayerID
        ) ?? [0, null];
        await createListCard({ pdf, player, index, chart: currentChart[1] });
        index++;
    }
    currentPage++;

    return pdf;

};
