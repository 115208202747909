export type Categories<T = number> = {
    [key in CategoryEnum]?: T
}

export enum CategoryEnum {
    a = 'A',
    bplus = 'B+',
    b = 'B',
    c = 'C',
    d = 'D',
    x = 'X',
    p = 'P'
}

export const CATEGORIES = {
    a: 'A',
    bplus: 'B+',
    b: 'B',
    c: 'C',
    d: 'D',
    x: 'X',
    p: 'P'
};

export const PDF_CATEGORIES = {
    [CategoryEnum.a]: '#47A16D',
    [CategoryEnum.bplus]: '#A5D1B1',
    [CategoryEnum.b]: '#5898CC',
    [CategoryEnum.c]: '#98820D',
    [CategoryEnum.d]: '#E44E4E',
    [CategoryEnum.x]: '#F1C26E',
    [CategoryEnum.p]: '#186037'
};

export const CategoryListOrder: CategoryEnum[] = [
    CategoryEnum.p,
    CategoryEnum.x,
    CategoryEnum.d,
    CategoryEnum.c,
    CategoryEnum.b,
    CategoryEnum.bplus,
    CategoryEnum.a,
];

export const PLAYER_X_LIMIT = {
    max: 21
};

export const PLAYER_P_LIMIT = {
    max: 23
};

export interface PlayerReportRequestType {
    PlayerID: number;
    ReportID: number;
    UserName: string;
    Date: string;
    ReadOnlyFlg?: boolean | null;
    Acceleration?: number | null;
    Agility?: number | null;
    Agression?: number | null;
    AttackingArealDuel?: number | null;
    AttackingDuel?: number | null;
    AttackingGroupMean?: number | null;
    AttackingGroupSum?: number | null;
    AttackingIntensity?: number | null;
    AttackingInTransition?: number | null;
    BallStrikingAbility?: number | null;
    BoxDefending?: number | null;
    BoxPresence?: number | null;
    ClassificationID?: string | null;
    CombinativeGame?: number | null;
    Comment?: string | null;
    Crossing?: number | null;
    DefendingArealDuel?: number | null;
    DefendingDuel?: number | null;
    DefendingGroupMean?: number | null;
    DefendingGroupSum?: number | null;
    DefendingIntensity?: number | null;
    DefendingInTransition?: number | null;
    Dynamic?: number | null;
    Finishing?: number | null;
    FormationPosition_1?: string | null;
    FormationPosition_2?: string | null;
    FormationPosition_3?: string | null;
    GameIntelligence?: number | null;
    KeyPasses?: number | null;
    MentalityGroupMean?: number | null;
    MentalityGroupSum?: number | null;
    OneVOneAttacking?: number | null;
    OneVOneDefending?: number | null;
    Passing?: number | null;
    PhysiqueGroupMean?: number | null;
    PhysiqueGroupSum?: number | null;
    PlayAgainstDeepBlock?: number | null;
    PlayerPositionID?: number | null;
    PlayerProfileID?: string | null;
    Positioning?: number | null;
    PressingIntensity?: number | null;
    PressureResistance?: number | null;
    Progressivity?: number | null;
    ResellableFlg?: boolean | null;
    ScoutingIndexGroupMean?: number | null;
    ScoutingIndexGroupSum?: number | null;
    Speed?: number | null;
    TechnicalGroupMean?: number | null;
    TechnicalGroupSum?: number | null;
    Technique?: number | null;
    TypicalFormation?: string | null;
}
