import React, { ReactElement } from 'react';
import HeaderTemplate from './HeaderTemplate';

const Header = function (): ReactElement {

    return <HeaderTemplate/>;

};

export default Header;
