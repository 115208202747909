import React, { ReactElement, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RedirectRequest } from '@azure/msal-browser';
import { translate as t } from '../../helpers/translate';
import { cx } from '../../helpers/utility';
import { HeaderContext } from '../../contexts/Header.context';
import { b2cPolicies } from '../../../authConfig';
import useAuth from '../../hooks/auth.hook';
import Tooltip from '../Tooltip/Tooltip';
import { PlayerLayerContext } from '../../contexts/PlayerLayer.context';
import css from './Header.module.scss';
import Logo from '../../../assets/images/fradi_logo_3_stars.svg';
import Logout from '../../../assets/images/icons/icon_logout.svg';
import SettingsIcon from '../../../assets/images/icons/icon_settings.svg';
import SearchIcon from '../../../assets/images/icons/icon_search.svg';

const HeaderTemplate = function(): ReactElement {

    const { account, instance } = useAuth();
    const headerContext = useContext(HeaderContext);
    const playerContext = useContext(PlayerLayerContext);

    const closePopups = function() {
        if (playerContext.context) {
            playerContext.setContext({
                ...playerContext.context,
                isModalOpen: false,
                isSearchModalOpen: undefined
            });
        }
    };

    return <>
        <div className={css.headerBox}>
            <Link className={css.logoAndName} to={'/scouting/overview'} onClick={() => closePopups()}>
                <img className={css.fradiLogo} src={Logo} alt=""/>
                <div className={css.appName}>
                    <h1>{t('app-name-1')}</h1>
                    <h1 className={css.scouting}>{t('app-name-2')}</h1>
                </div>
            </Link>
            <div className={css.menu}>
                <NavLink
                    to={'/scouting'}
                    className={({isActive}) => cx(css.menuItem, isActive ? css.activeMenu : null)}
                >
                    <h2>{t('header.button.scouting')}</h2>
                </NavLink>
                <NavLink
                    to={'/analysis'}
                    className={({isActive}) => cx(css.menuItem, isActive ? css.activeMenu : null, css.disabled)}
                >
                    <h2>{t('header.button.analysis')}</h2>
                </NavLink>
            </div>
            <div className={css.headerActions}>
                <p className={css.welcomeText}>{t('header.welcome')} <span>{account?.name}</span></p>
                <button
                    className={cx('btn icon', css.actionIcon)}
                    onClick={headerContext.context?.searchClickCallback}
                >
                    <Tooltip text={t('header.button.search')}>
                        <img className={css.actionIcon} src={SearchIcon} alt=""/>
                    </Tooltip>
                </button>
                <button
                    className={cx('btn icon', css.actionIcon)}
                    onClick={() => instance.loginRedirect(b2cPolicies.authorities.editProfile as RedirectRequest)}
                >
                    <Tooltip text={t('header.button.settings')}>
                        <img className={css.actionIcon} src={SettingsIcon} alt=""/>
                    </Tooltip>
                </button>
                <button
                    className={cx('btn icon', css.actionIcon)}
                    onClick={() => instance.logoutRedirect()}
                >
                    <Tooltip text={t('header.button.logout')}>
                        <img className={css.actionIcon} src={Logout} alt=""/>
                    </Tooltip>
                </button>
            </div>
        </div>
    </>;

};

export default HeaderTemplate;
