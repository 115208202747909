import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { sum, without } from 'lodash';
import PlayerBaseCard from './cards/PlayerBaseCard';
import { ArrayOfObjects, cx } from '../../../helpers/utility';
import { translate as t } from '../../../helpers/translate';
import { ProfileGroupTypes, ReportActionType, SeasonTabEnum } from '../../../interfaces/carried-on.interfaces';
import TabAccordion from '../../TabAccordion/TabAccordion';
import ReportList from './cards/ReportList';
import {
    PlayerBaseData, PlayerReport, PlayerReportService, PlayerStats,
    PlayerStatsLastSeasons, PlayerStrengthWeakness, ReportParams, UserPlayerReport
} from '../../../services/openapi';
import Select, { OptionType } from '../../select/select';
import EchartsRadar from '../../EchartsWrapper/EchartsRadar/EchartsRadar';
import { toastError } from '../../../helpers/errorhandler';
import useAuth from '../../../hooks/auth.hook';
import usePlayerProfile from '../../../hooks/player-profile.hook';
import { FradiLoader } from '../../FradiLoader/FradiLoader';
import Tooltip from '../../Tooltip/Tooltip';
import css from './PlayerDetails.module.scss';
import imgStrength from '../../../../assets/images/icons/strength.svg';
import imgWeakness from '../../../../assets/images/icons/weakness.svg';
import SecondaryPositionsField from '../SecondaryPositionsField/SecondaryPositionsField';
import { nullPlayerReport } from '../../../constants';

const tabList = Object.values(SeasonTabEnum);

const indicatorOptions: OptionType[] = [
    {
        id: 'all',
        value: t('general.group.all')
    },
    ...Object.values(ProfileGroupTypes).map(
        profileGroup => ({
            id: profileGroup,
            value: t(`general.group.${profileGroup}`)
        })
    )
];

interface PlayerDetailsProps {
    playerBaseData: PlayerBaseData,
    reportList: UserPlayerReport[],
    reportConfig: ReportParams,
    actionOfReport: (reportId: number | boolean, action: ReportActionType) => Promise<void>,
    latestReport?: PlayerReport,
}

const PlayerDetails = function ({
    playerBaseData,
    actionOfReport,
    reportList,
    reportConfig,
    latestReport,
}: PlayerDetailsProps): ReactElement {

    const { requestParams } = useAuth();
    const { orderCollection, chartCollectionConfig, setOrderCollection, getIndicators } = usePlayerProfile();
    const [loadings, setLoadings] = useState<string[]>([]);
    const [teamReport, setTeamReport] = useState<PlayerReport>();
    const [reportStats, setReportStats] = useState<PlayerStatsLastSeasons>();
    const [strengthWeakness, setStrengthWeakness] = useState<PlayerStrengthWeakness>({ Strength: [], Weakness: [] });
    const [selectedIndicator, setSelectedIndicator] = useState<string>('all');

    useEffect(
        () => {
            setSelectedIndicator('all');
        },
        [latestReport]
    );

    const getTeamAvg = useCallback(
        (positionId: number) => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'avg']);
                PlayerReportService.ownTeamPlayerAvgReportCreate({
                    ...requestParams,
                    PositionID: positionId
                }).finally(
                    () => {
                        setLoadings(l => without(l, 'avg'));
                    }
                ).then(
                    (teamAvgReport) => {
                        setTeamReport(
                            positionId ? { ...teamAvgReport.Positions[0].AvgStat, ReadOnlyFlg: false } : nullPlayerReport
                        );
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    useEffect(
        () => {
            if (latestReport) {
                getTeamAvg(latestReport.PlayerPositionID ?? 0);
            }
        },
        [getTeamAvg, latestReport]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'data']);
                Promise.all([
                    PlayerReportService.playerStatsCreate({
                        ...requestParams,
                        PlayerID: playerBaseData.PlayerID
                    }),
                    PlayerReportService.playerStrengthWeaknessCreate({
                        ...requestParams,
                        PlayerID: playerBaseData.PlayerID
                    })
                ]).finally(
                    () => {
                        setLoadings(l => without(l, 'data'));
                    }
                ).then(
                    ([stats, sw]) => {
                        setReportStats(stats);
                        setStrengthWeakness(sw);
                    }
                ).catch(
                    toastError
                );
            }
        },
        [playerBaseData.PlayerID, requestParams]
    );

    useEffect(
        () => {
            if (latestReport?.PlayerPositionID) {
                setOrderCollection(getIndicators(latestReport.PlayerPositionID, reportConfig));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [latestReport?.PlayerPositionID, reportConfig, setOrderCollection]
    );

    return <>
        <div className={css.column}>
            {playerBaseData &&
                <PlayerBaseCard reportData={latestReport} baseData={playerBaseData}/>
            }
            <div className={cx(css.content, css.chartData, css.stats)}>
                <div className={css.statsHeader}>
                    <div className={css.indicatorBox}>
                        <Select
                            label={'playerDetails.label.indicator'}
                            isSoloLabel={true}
                            hideLabelWhenSelected={true}
                            isLabelOnTop={true}
                            changeSelected={
                                (selectedOptions) => setSelectedIndicator(
                                    selectedOptions.length ? String(selectedOptions[0].id) : 'all'
                                )
                            }
                            selectedOptions={indicatorOptions.filter(item => item.id === selectedIndicator)}
                            options={indicatorOptions}
                        />
                    </div>
                    <div className={css.traits}>
                        <div className={css.trait}>
                            <span className={css.label}>{t('playerDetails.label.post')}</span>
                            <span className={css.value}>{latestReport?.PlayerPositionID ?? '-'}</span>
                        </div>
                        <div className={css.trait}>
                            <span className={css.label}>{t('playerDetails.label.profile')}</span>
                            <span className={css.value}>
                                {latestReport?.PlayerProfileID
                                    ? latestReport.PlayerProfileID.split(',').map(
                                        profileId => t(`general.profile.${profileId}`)
                                    ).join('/')
                                    : '-'
                                }
                            </span>
                        </div>
                    </div>
                </div>

                <div className={css.chartField}>
                    {latestReport?.PlayerProfileID && (
                        <div className={css.roseChart}>
                            {/*
                            <EchartsStackedCircle<PlayerReport>
                                dataList={latestReport}
                                order={orderCollection[selectedIndicator] as (keyof PlayerReport)[]}
                                showLabel={true}
                            />
                            */}
                            <EchartsRadar<PlayerReport>
                                showLabel={true}
                                transparentArea={true}
                                multiDataList={[
                                    teamReport || undefined,
                                    latestReport || undefined,
                                ]}
                                size={{ width: 400, height: 300 }}
                                colors={[
                                    teamReport ? '#DDDDDD' : undefined,
                                    latestReport ? '#FFD28F' : undefined,
                                ]}
                                labelColors={[
                                    teamReport ? '#808080' : undefined,
                                    latestReport ? '#C0951B' : undefined,
                                ]}
                                config={chartCollectionConfig[selectedIndicator]}
                                order={orderCollection[selectedIndicator] as (keyof PlayerReport)[]}
                            />
                        </div>
                    )}

                    {latestReport && (
                        <div className={css.field}>
                            <SecondaryPositionsField
                                editing={false}
                                reportData={latestReport}
                                readOnly={!!latestReport.ReadOnlyFlg}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className={css.column}>
            <div className={cx(css.content, css.fullFill, css.seasons)}>
                <TabAccordion<SeasonTabEnum>
                    tabs={tabList}
                    preTag={'tab.season.'}
                    defaultTab={SeasonTabEnum.CURRENT}
                >
                    {(selectedTab) =>
                        <div className={css.statList}>

                            <div className={cx(css.row, css.tableLine)}>
                                <span className={cx(css.label, css.statCompetitionName)}>
                                    {t('playerDetails.report.stats.CompetitionName')}
                                </span>
                                <span className={cx(css.label, css.statMatchNumber)}>
                                    {t('playerDetails.report.stats.MatchNumber')}
                                </span>
                                <span className={cx(css.label, css.statGoalNumber)}>
                                    {t('playerDetails.report.stats.GoalNumber')}
                                </span>
                                <span className={cx(css.label, css.statGoalPassNumber)}>
                                    {t('playerDetails.report.stats.GoalPassNumber')}
                                </span>
                                <span className={cx(css.label, css.statMinutesPlayed)}>
                                    {t('playerDetails.report.stats.MinutesPlayed')}
                                </span>
                            </div>

                            {reportStats && <>
                                {reportStats[selectedTab as keyof PlayerStatsLastSeasons]?.map(stat =>
                                    <div key={stat.CompetitionID} className={css.row}>
                                        <span className={cx(css.label, css.statCompetitionName)}>{stat.CompetitionName}</span>
                                        <span className={cx(css.label, css.statMatchNumber)}>{stat.MatchNumber}</span>
                                        <span className={cx(css.label, css.statGoalNumber)}>{stat.GoalNumber}</span>
                                        <span className={cx(css.label, css.statGoalPassNumber)}>{stat.GoalPassNumber}</span>
                                        <span className={cx(css.label, css.statMinutesPlayed)}>{stat.MinutesPlayed}</span>
                                    </div>
                                )}
                                <div className={cx(css.row, css.separated)}>
                                    <span className={cx(css.label, css.statCompetitionName)}>
                                        {t('playerDetails.report.statSum')}
                                    </span>
                                    <span className={cx(css.label, css.statMatchNumber)}>
                                        {sum(
                                            ArrayOfObjects.take(
                                                reportStats[selectedTab as keyof PlayerStatsLastSeasons] as PlayerStats[],
                                                'MatchNumber'
                                            ) as number[]
                                        )}
                                    </span>
                                    <span className={cx(css.label, css.statGoalNumber)}>
                                        {sum(
                                            ArrayOfObjects.take(
                                                reportStats[selectedTab as keyof PlayerStatsLastSeasons] as PlayerStats[],
                                                'GoalNumber'
                                            ) as number[]
                                        )}
                                    </span>
                                    <span className={cx(css.label, css.statGoalPassNumber)}>
                                        {sum(
                                            ArrayOfObjects.take(
                                                reportStats[selectedTab as keyof PlayerStatsLastSeasons] as PlayerStats[],
                                                'GoalPassNumber'
                                            ) as number[]
                                        )}
                                    </span>
                                    <span className={cx(css.label, css.statMinutesPlayed)}>
                                        {sum(
                                            ArrayOfObjects.take(
                                                reportStats[selectedTab as keyof PlayerStatsLastSeasons] as PlayerStats[],
                                                'MinutesPlayed'
                                            ) as number[]
                                        )}
                                    </span>
                                </div>
                            </>}

                        </div>
                    }
                </TabAccordion>
            </div>

            <ReportList reports={reportList} player={playerBaseData} actionOfReport={actionOfReport} orderCollection={orderCollection}/>

            <div className={cx(css.content, css.additionalData)}>
                <div className={css.strength}>
                    <h3 className={css.swTitle}>{t('playerDetails.report.strength')}</h3>
                    {strengthWeakness.Strength.map(
                        (item) => (
                            <div className={css.swItem} key={item.IndexID}>
                                <Tooltip text={t(`general.indicator.${item.IndexID}`)} ifOverflow={true}>
                                    <span>{t(`general.indicator.${item.IndexID}`)}</span>
                                </Tooltip>
                                <img alt="" src={imgStrength}/>
                            </div>
                        )
                    )}
                </div>
                <div className={css.weakness}>
                    <h3 className={css.swTitle}>{t('playerDetails.report.weakness')}</h3>
                    {strengthWeakness.Weakness.map(
                        (item) => (
                            <div className={css.swItem} key={item.IndexID}>
                                <img alt="" src={imgWeakness}/>
                                <Tooltip text={t(`general.indicator.${item.IndexID}`)} ifOverflow={true}>
                                    <span>{t(`general.indicator.${item.IndexID}`)}</span>
                                </Tooltip>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
        <FradiLoader visible={loadings.length > 0}/>
    </>;

};

export default PlayerDetails;
