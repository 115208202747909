import React, { ReactElement } from 'react';
import { FeatureCollection } from 'geojson';
import { CustomGeoCollection, GeoCustomProperties } from '../../../assets/geojson/gb/customGeoCollection';
import { GeoCountryProperties } from '../../interfaces/geo-json.interface';
import { getCurrentLang } from '../../helpers/translate';
import geoJson from '../../../assets/geojson/mediumgeo.json';

interface ComponentProps {
    nations?: string[];
}

export const NationNames = function ({
    nations
}: ComponentProps): ReactElement {


    const nationalitiesCollection = [
        ...(geoJson as FeatureCollection).features, ...CustomGeoCollection.features
    ].map(
        feature => feature.properties as GeoCountryProperties
    );

    function getNationalitiesName(nations?: string[]) {
        if (!nations) {
            return '';
        }
        const nationNames = nations.map(nation => nationalitiesCollection.find(country => country.adm0_iso === nation));
        return nationNames.flatMap(nation => nation
            ? nation['name_' + getCurrentLang().toString() as keyof GeoCustomProperties] ?? nation.name
            : nation
        ).join(', ');
    }

    return <>
        {getNationalitiesName(nations)}
    </>;

};
