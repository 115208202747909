import { OwnShadowTeamService, ShadowTeamService } from '../../../shared/services/openapi';

export const ShadowTeamEndpoints = {
    glob: {
        saved: ShadowTeamService.savedShadowTeamsCreate,
        load: ShadowTeamService.loadShadowTeamCreate,
        save: ShadowTeamService.saveShadowTeamCreate,
        delete: ShadowTeamService.deleteShadowTeamCreate,
    },
    own: {
        saved: OwnShadowTeamService.savedOwnShadowTeamsCreate,
        load: OwnShadowTeamService.loadShadowTeamCreate,
        save: OwnShadowTeamService.saveOwnShadowTeamCreate,
        delete: OwnShadowTeamService.deleteShadowTeamCreate,
    }
};
