import React, { ReactElement, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayer from '../../../shared/modules/PageLayer/PageLayer';
import { PlayerLayerContext } from '../../../shared/contexts/PlayerLayer.context';
// import css from './PlayerDetailsPage.module.scss';

export const PlayerDetailsPage = function(): ReactElement {

    const { playerId, reportId } = useParams();
    const layerContext = useContext(PlayerLayerContext);
    const navigate = useNavigate();

    useEffect(
        () => {
            if (playerId) {
                layerContext.setContext({
                    playerId: +playerId,
                    isModalOpen: false,
                    fromPage: true,
                    closeCallback: () => navigate('/scouting/overview')
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate, playerId]
    );

    return <>
        <PageLayer isNormalView={true} selectedReportId={reportId ? +reportId : undefined}/>
    </>;

};
