/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerSearch } from '../models/PlayerSearch';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FollowedPlayersService {

    /**
     * Search for all player by name
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static followedPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/FollowedPlayers/',
            body: data,
        });
    }

}
