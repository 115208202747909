import React, { ReactElement } from 'react';
import { cx } from '../../../helpers/utility';
import { PlayerSearchData, SearchPlayer } from '../../../services/openapi';
import Tooltip from '../../Tooltip/Tooltip';
import css from './FootballPositionPopup.module.scss';

const FootballPositionPopup = function({
    players,
    selectPlayer,
    lineHeight,
    below = false
}: {
    players: (PlayerSearchData | SearchPlayer)[],
    selectPlayer: (player: number) => void,
    lineHeight: number,
    below?: boolean
}): ReactElement {

    function nameShorter(name: string): string {
        return name.slice(0, 1) + '.';
    }

    function yearShorter(year: number): string {
        const date = String(year);
        return '\'' + date.slice(2);
    }

    function getLastName(name: string): string {
        return name.split(' ').filter((item, index) => !!index).join(' ');
    }

    return <>
        <div className={css.positionPopup}>
            {players.filter(player => !!player).map(player =>
                <button
                    onClick={() => selectPlayer(player.PlayerID)}
                    className={css.playerRibbon}
                    key={player.PlayerID}
                >
                    <div className={css.infoBar}>
                        <div className={css.nameBox}>
                            <span className={css.name}>{nameShorter(player.PlayerName)}</span>
                            <Tooltip text={player.PlayerName} ifOverflow={true}>
                                <span className={cx(css.name, css.bold)}>{getLastName(player.PlayerName)}</span>
                            </Tooltip>
                        </div>
                        <span className={css.info}>{'Birthday' in player ? yearShorter(player.Birthday) : ''}</span>
                    </div>
                </button>
            )}
            <div className={cx(css.bottomLine, below && css.below)}>
                {lineHeight}
                <div className={css.string} style={{ height: `${lineHeight + 5}px` }}></div>
            </div>
        </div>
    </>;

};

export default FootballPositionPopup;
