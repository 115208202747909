/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllPlayerAvgStatsByPosition } from '../models/AllPlayerAvgStatsByPosition';
import type { PlayerBaseData } from '../models/PlayerBaseData';
import type { PlayerReport } from '../models/PlayerReport';
import type { PlayerSearch } from '../models/PlayerSearch';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ComparePlayersService {

    /**
     * Get base data of player
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static allReportedPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/AllReportedPlayers/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerReport
     * @throws ApiError
     */
    public static latestReportForPlayerCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LatestReportForPlayer/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns AllPlayerAvgStatsByPosition
     * @throws ApiError
     */
    public static ownTeamPlayerAvgReportCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Position Id if needed
             */
            PositionID?: number;
        },
    ): CancelablePromise<AllPlayerAvgStatsByPosition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/OwnTeamPlayerAvgReport/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerBaseData
     * @throws ApiError
     */
    public static playerBaseDataCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player name or team name
             */
            PlayerID?: number;
        },
    ): CancelablePromise<PlayerBaseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerBaseData/',
            body: data,
        });
    }

}
