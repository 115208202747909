import { Toastify } from '../modules/Toastify/Toastify';
import { ApiError, CancelError } from '../services/openapi';
import { translate as t } from './translate';

export function toastError(error: ApiError) {
    if (error instanceof CancelError) {
        // NOTE: ez nem számít hibának
    }
    else {
        console.error(error);
        if (error.body && error.body.ErrorCd) {
            const errorCode = error.body.ErrorCd;
            Toastify({
                titleKey: t('general.message.error.server.code' + errorCode) ?? error.body.ErrorMsg,
                // content: errorCode
            }).error();
        }
        else if (error.message && error.message.includes('Internal Server Error')) {
            Toastify({
                titleKey: t('general.message.error.server.codeundefined')
            }).error();
        }
        else {
            Toastify({
                titleKey: t('general.message.error.client')
            }).error();
        }
    }
}
