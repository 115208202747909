import React, { ReactElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { translate as t } from '../../helpers/translate';
import { cx } from '../../helpers/utility';
import { toastError } from '../../helpers/errorhandler';
import css from './ConfirmModal.module.scss';
import CloseIcon from '../../../assets/images/icons/icon_close_modal.svg';

const ConfirmModal = function ({
    text,
    className = '',
    isVisible = false,
    changeVisibility,
    callback
}: {
    text: string,
    className?: string,
    isVisible?: boolean,
    changeVisibility: (isVisible: boolean) => void,
    callback: () => Promise<void>
}): ReactElement {

    const [visibility, setVisibility] = useState<boolean>(isVisible);

    useEffect(
        () => {
            setVisibility(isVisible);
        },
        [isVisible, setVisibility]
    );

    useEffect(
        () => {
            const onModalEscaped = function(event: KeyboardEvent) {
                if (isVisible && event.key === 'Escape') {
                    changeVisibility(false);
                }
            };

            document.addEventListener('keyup', onModalEscaped);

            return () => {
                document.removeEventListener('keyup', onModalEscaped);
            };
        },
        [changeVisibility, isVisible]
    );

    const moduleClassNames = className.split(' ').map(
        (cl: string) => css[cl]
    ).join(' ');

    const stopEvent = function(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        event.stopPropagation();
    };

    const runCallback = function() {
        return callback()
            .then(() => {
                changeVisibility(false);
            })
            .catch(
                toastError
            );
    };

    return <>
        {visibility &&
            ReactDOM.createPortal(
                <div className={css.backDrop}>
                    <div className={cx(css.modalBox, moduleClassNames)} onClick={stopEvent}>
                        <div className={css.modalBoxHeader}>
                            <button className={cx('btn icon', css.close)} onClick={() => changeVisibility(false)}>
                                <img src={CloseIcon} alt="close"/>
                            </button>
                        </div>
                        <div className={css.modalBoxContent}>
                            <div className={css.modalBoxText}>{t(text)}</div>
                            <div className={css.buttonContainer}>
                                <button
                                    className={cx(css.modalBoxButton, 'btn btn-primary btn-outline')}
                                    onClick={() => runCallback()}
                                >
                                    {t('general.button.yes')}
                                </button>
                                <button
                                    className={cx(css.modalBoxButton, 'btn btn-secondary btn-outline')}
                                    onClick={() => changeVisibility(false)}
                                >
                                    {t('general.button.cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                document.body
            )
        }
    </>;

};

export default ConfirmModal;
