/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyReturn } from '../models/EmptyReturn';
import type { PlayerSearchPositionsByGroup } from '../models/PlayerSearchPositionsByGroup';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShortlistService {

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deleteFromPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteFromPlayerList/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deleteFromShortlistCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player ID
             */
            PlayerID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteFromShortlist/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns PlayerSearchPositionsByGroup
     * @throws ApiError
     */
    public static ownTeamPlayersShortlistCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearchPositionsByGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/OwnTeamPlayersShortlist/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static saveToPlayerListCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Name of the player list
             */
            PlayerListID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SaveToPlayerList/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static saveToShortlistCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * Player ID
             */
            PlayerID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SaveToShortlist/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns PlayerSearchPositionsByGroup
     * @throws ApiError
     */
    public static shortlistByPostitionCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearchPositionsByGroup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ShortlistByPostition/',
            body: data,
        });
    }

}
