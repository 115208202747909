import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { translate  as t } from '../../helpers/translate';
import { CancelablePromise, SearchAllPlayer, SearchFieldService, SearchPlayer } from '../../services/openapi';
import { cx } from '../../helpers/utility';
import { PlayerLayerContext } from '../../contexts/PlayerLayer.context';
import { FradiLoader } from '../FradiLoader/FradiLoader';
import { toastError } from '../../helpers/errorhandler';
import useAuth from '../../hooks/auth.hook';
import PlayerListButton from '../PlayerListButton/PlayerListButton';
import { PLAYER_SEARCH_MIN_CHARS, PLAYER_SEARCH_WAITING } from '../../constants';
import useDelayedSearch from '../../hooks/delayed-search.hook';
import css from './Header.module.scss';

function SearchModal({
    selectedPlayer
}: {
    selectedPlayer: (playerId: number) => void
}): ReactElement {

    const [searchList, setSearchList] = useState<SearchPlayer[]>();
    const layerContext = useContext(PlayerLayerContext);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { requestParams } = useAuth();
    const searchInput = useRef<HTMLInputElement>(null);
    const request = useRef<CancelablePromise<SearchAllPlayer>>();

    const searchHandler = useCallback(
        (searchText: string) => {
            setSearchTerm(searchText);
            if (requestParams.UserID) {
                setIsLoading(true);
                request.current = SearchFieldService.searchAllPlayersCreate({
                    ...requestParams,
                    SeachField: searchText
                });
                request.current.finally(
                    () => {
                        setIsLoading(false);
                        request.current = undefined;
                    }
                ).then(resp => {
                    setSearchList(resp.Players);
                    return resp;
                }).catch(
                    toastError
                );
                return request.current;
            }
        },
        [requestParams]
    );

    const {
        search,
        waiting,
        searchValue
    } = useDelayedSearch<SearchAllPlayer>(searchHandler, request, PLAYER_SEARCH_MIN_CHARS, PLAYER_SEARCH_WAITING);

    useEffect(
        () => {
            if (layerContext.context?.isSearchModalOpen) {
                setSearchTerm(layerContext.context.isSearchModalOpen);
                setSearchTerm(searchValue);
                searchHandler(layerContext.context.isSearchModalOpen);
            }
        },
        [layerContext.context, searchHandler, searchValue]
    );

    function setTeamSearch(teamName: string) {
        if (!searchInput.current || searchInput.current.value === teamName) {
            return;
        }
        searchInput.current.value = teamName;
        searchHandler(teamName);
    }

    return <>
        <div className={css.search}>
            <input
                ref={searchInput}
                autoFocus
                type="search"
                placeholder={t('search.placeholder')}
                value={searchValue}
                onChange={event => search(event.target.value)}
            />
            <div className={css.searchIcon}></div>
        </div>
        <div className={css.searchList}>
            {searchTerm.length >= PLAYER_SEARCH_MIN_CHARS && waiting && (
                <span>{t('general.message.waiting')}</span>
            )}
            {searchTerm.length >= PLAYER_SEARCH_MIN_CHARS && !searchList?.length && !isLoading && !waiting && (
                <span>{t('general.message.noListedItems')}</span>
            )}
            <FradiLoader visible={isLoading} />
            {searchList?.map(player => (
                <div className={css.playerItem} key={player.PlayerID}>
                    <div className={css.playerPicture}>
                        <img src={player.PlayerImageUrl} alt=""/>
                    </div>
                    <button className={cx(css.playerName, 'btn btn-primary btn-ghost')} onClick={() => selectedPlayer(player.PlayerID)}>
                        {player.PlayerName}
                    </button>
                    <div className={css.teamPicture}>
                        <img src={player.TeamImageUrl} alt=""/>
                    </div>
                    <button
                        tabIndex={-1}
                        onClick={() => setTeamSearch(player.TeamName)}
                        className={cx(css.teamName, 'btn btn-subtle btn-ghost')}
                    >{player.TeamName}</button>
                    <PlayerListButton
                        className={cx('btn', css.tallButton)}
                        playerId={player.PlayerID}
                    />
                </div>
            ))}
        </div>
    </>;

}

export default SearchModal;
