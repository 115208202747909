/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotViewedPlayerCount } from '../models/NotViewedPlayerCount';
import type { PlayerReports } from '../models/PlayerReports';
import type { PlayerSearchReport } from '../models/PlayerSearchReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlayerReportsService {

    /**
     * Search for all player by name
     * @param data
     * @returns PlayerReports
     * @throws ApiError
     */
    public static notDownloadedReportsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerReports> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/NotDownloadedReports/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns NotViewedPlayerCount
     * @throws ApiError
     */
    public static notViewedPlayerReportsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<NotViewedPlayerCount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/NotViewedPlayerReports/',
            body: data,
        });
    }

    /**
     * Search for all player by name
     * @param data
     * @returns PlayerSearchReport
     * @throws ApiError
     */
    public static playerReportsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearchReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/PlayerReports/',
            body: data,
        });
    }

}
