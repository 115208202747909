import jsPDF from 'jspdf';
import { clamp } from 'lodash';
import { indicatorOrder } from '../../constants';
import { OptionType } from '../../modules/select/select';
import { translate as t } from '../../helpers/translate';
import { FilterParams, PlayerSearchData, Range, SearchParam } from '../openapi';
import { AdditionalData, BoxDescriptor, addLayout, c, darkGreenHex, darkGreyHex, lightGreenHex, whiteHex } from './pdf-generation-layout';
import { ArrayOfObjects } from '../../helpers/utility';
import { roundedBox, textWidth } from './pdf-generation-utility';
import { FilterTabMainEnum, FilterTabSecondaryEnum } from '../../interfaces/carried-on.interfaces';
import { createListCard, createListHeader } from './pdf-generation-common';
import { pdfFontContentRegular, pdfFontContentBold } from '../../../data/fonts';

const maxCardPerPage = 12;

const createFilters = function({
    pdf,
    filterParams,
    filterConfig,
    nationalities
}: {
    pdf: jsPDF,
    filterParams: SearchParam,
    filterConfig: FilterParams,
    nationalities: OptionType[]
}) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');
    pdf.addFileToVFS('open-sans-bold.ttf', pdfFontContentBold);
    pdf.addFont('open-sans-bold.ttf', 'bold', 'bold');

    const rangeText = (range: Range): string => `${range.min} - ${range.max}`;

    const printScale = (prop: string, value: Range, index: number) => {
        pdf.setTextColor(darkGreyHex);
        pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
        const offset = textWidth(pdf, `${prop}: `);
        pdf.text(`${prop}: `, box(index)[0], box(index)[1] + c(30), { align: 'left' });
        pdf.setFontSize(10).setFont('bold', 'normal', 'normal');
        pdf.text(rangeText(value), box(index)[0] + offset, box(index)[1] + c(30), { align: 'left' });
    };

    const box = (index: number): BoxDescriptor => [
        c(80) + c(347) * (index % 3) + c(7) * (index % 3),
        c(220) + c(62) * Math.floor(index / 3),
        c(340),
        c(47)
    ];

    const wideBox = (index: number): BoxDescriptor => [
        c(80) + c(507) * (index % 2) + c(7) * (index % 2),
        c(220) + c(62) * Math.floor(index / 2),
        c(500),
        c(47)
    ];

    const outline = (index: number, rowNum: number) => {
        ctx.strokeStyle = lightGreenHex;
        ctx.fillStyle = whiteHex;
        ctx.lineWidth = 0.5;
        roundedBox(ctx, [c(60), box(index)[1] - c(3), box(index)[2] * 3 + c(60), (box(index)[3] + c(7)) * rowNum + c(rowNum * 5)], c(5));
    };

    let boxIndex = 0;
    let wideBoxIndex =0;

    const ctx = pdf.context2d;

    pdf.setTextColor(darkGreenHex);
    pdf.setFontSize(14).setFont('bold', 'normal', 'normal');
    pdf.text(t('tab.filter.default'), wideBox(wideBoxIndex)[0], wideBox(wideBoxIndex)[1] + c(40), { align: 'left' });
    boxIndex += 3;
    wideBoxIndex += 2;

    outline(boxIndex, 3);

    pdf.setTextColor(darkGreyHex);
    pdf.setFontSize(10).setFont('normal', 'normal', 'normal');

    pdf.text(
        `${t('playerDetails.label.post')}: ${filterParams.PlayerPositionID}`,
        wideBox(wideBoxIndex)[0], wideBox(wideBoxIndex)[1] + c(30), { align: 'left' }
    );
    wideBoxIndex++;

    pdf.text(
        `${t('playerDetails.label.profile')}: ${filterParams.PlayerProfileID ? t(`general.profile.${filterParams.PlayerProfileID}`) : '-'}`,
        wideBox(wideBoxIndex)[0], wideBox(wideBoxIndex)[1] + c(30), { align: 'left' }
    );
    wideBoxIndex++;

    const nationalityString = (ArrayOfObjects.takeOne(filterParams.Nationality ?? []) as string[]).map(
        (iso: string) => nationalities.find(
            ({ id }) => String(id) === iso
        )?.value ?? ''
    ).join(', ');
    const lines: string[] = pdf.splitTextToSize(
        `${t('playerDetails.label.nationality')} (${(filterParams.Nationality ?? []).length}): ${nationalityString}`, c(1040)
    );
    if (lines.length > 3) {
        lines[2] += '...';
    }
    pdf.text(
        lines.slice(0, 3), wideBox(wideBoxIndex)[0], wideBox(wideBoxIndex)[1] + c(30 - clamp(lines.length, 0, 3) * 8), { align: 'left' }
    );
    wideBoxIndex++;
    boxIndex += 6;

    pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
    const offsetCategory = textWidth(pdf, `${t('playerReport.label.category')}: `);
    pdf.text(
        `${t('playerReport.label.category')}: `,
        box(boxIndex)[0], box(boxIndex)[1] + c(30), { align: 'left' }
    );
    pdf.setFontSize(10).setFont('bold', 'normal', 'normal');
    pdf.text(
        filterParams.ClassificationID && filterParams.ClassificationID.length > 0
            ? ArrayOfObjects.takeOne(filterParams.ClassificationID).join(', ')
            : '-',
        box(boxIndex)[0] + offsetCategory, box(boxIndex)[1] + c(30), { align: 'left' }
    );
    boxIndex++;

    pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
    const offsetMinutes = textWidth(pdf, `${t('playerDetails.label.minutes')}: `);
    pdf.text(
        `${t('playerDetails.label.minutes')}: `,
        box(boxIndex)[0], box(boxIndex)[1] + c(30), { align: 'left' }
    );
    pdf.setFontSize(10).setFont('bold', 'normal', 'normal');
    pdf.text(
        `min ${filterParams.MinutesPlayedMin}`,
        box(boxIndex)[0] + offsetMinutes, box(boxIndex)[1] + c(30), { align: 'left' }
    );
    boxIndex++;

    pdf.setFontSize(10).setFont('normal', 'normal', 'normal');
    const offsetAge = textWidth(pdf, `${t('playerDetails.label.age')}: `);
    pdf.text(
        `${t('playerDetails.label.age')}: `,
        box(boxIndex)[0], box(boxIndex)[1] + c(30), { align: 'left' }
    );
    pdf.setFontSize(10).setFont('bold', 'normal', 'normal');
    pdf.text(
        rangeText(filterParams.AgeRange),
        box(boxIndex)[0] + offsetAge, box(boxIndex)[1] + c(30), { align: 'left' }
    );
    boxIndex++;

    pdf.setTextColor(darkGreenHex);
    pdf.setFontSize(14).setFont('bold', 'normal', 'normal');
    pdf.text(t('tab.filter.index'), box(boxIndex)[0], box(boxIndex)[1] + c(40), { align: 'left' });
    boxIndex += 3;

    outline(boxIndex, Math.ceil(indicatorOrder.length / 3));

    indicatorOrder.forEach(
        indicator => {
            printScale(
                t(`general.indicator.${indicator}`),
                filterParams[indicator + 'Range' as keyof SearchParam] as Range,
                boxIndex++
            );
        }
    );

    [...Object.values(FilterTabMainEnum), ...Object.values(FilterTabSecondaryEnum)].forEach(
        (group) => {
            if (filterParams.PlayerPositionID && group !== FilterTabMainEnum.DEFAULT && group !== FilterTabMainEnum.INDEX) {
                if (boxIndex % 3 !== 0) {
                    boxIndex += 3 - (boxIndex % 3);
                }
                pdf.setTextColor(darkGreenHex);
                pdf.setFontSize(14).setFont('bold', 'normal', 'normal');
                pdf.text(t(`tab.filter.${group}`), box(boxIndex)[0], box(boxIndex)[1] + c(40), { align: 'left' });
                boxIndex += 3;

                const items = filterConfig?.ProfileIndex?.find(
                    profile => profile.PositionID === filterParams.PlayerPositionID
                )?.ProfileIndexList?.find(
                    item => item.ProfileIndexGroupID === group
                )?.ProfileIndexIDList?.map(
                    item => item.ProfileIndexID
                );

                if (items) {
                    outline(boxIndex, Math.ceil(items.length / 3));

                    items.forEach(
                        indicator => {
                            printScale(
                                t(`general.indicator.${indicator}`),
                                filterParams[indicator + 'Range' as keyof SearchParam] as Range,
                                boxIndex++
                            );
                        }
                    );
                }
            }
        }
    );

};

export const generatePdf = async function({
    filterParams,
    filterConfig,
    nationalities,
    players,
    charts,
    additionalData
} : {
    filterParams: SearchParam,
    filterConfig: FilterParams,
    nationalities: OptionType[],
    players: PlayerSearchData[],
    charts: Map<number, SVGSVGElement>,
    additionalData: AdditionalData
}): Promise<jsPDF> {

    const pdf = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
        unit: 'px'
    });

    let currentPage = 1;
    const allPage = Math.ceil(players.length / maxCardPerPage) + 1;

    await addLayout({
        pdf,
        title: t('pdf.player-search.title'),
        page: { current: currentPage, all: allPage },
        additionalData
    });

    createFilters({
        pdf,
        filterParams,
        filterConfig,
        nationalities
    });

    let index = 0;
    for (const player of players) {
        if ((currentPage === 1 && index === 0) || index >= maxCardPerPage) {
            index = 0;
            pdf.addPage();
            pdf.setPage(++currentPage);
            await addLayout({
                pdf,
                title: t('pdf.player-search.title'),
                page: { current: currentPage, all: allPage },
                additionalData
            });
            createListHeader(pdf);
        }
        const currentChart = [...charts.entries()].find(
            ([playerId, _chart]) => playerId === player.PlayerID
        ) ?? [0, null];
        await createListCard({ pdf, player, index, chart: currentChart[1] });
        index++;
    }

    return pdf;

};
