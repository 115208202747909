import MapPopupTemplate from './MapPopupTemplate';
import { ReactElement } from 'react';
import {Categories, CategoryEnum} from '../../interfaces/reports.interface';

export interface MapPopupProps {
    area: string,
    categories?: Categories,
    openCallback: (category: CategoryEnum, area: string) => void,
}

const MapPopup = function ({
    area,
    categories,
    openCallback,
}: MapPopupProps): ReactElement {

    return <MapPopupTemplate openCallback={openCallback} area={area} categories={categories}/>;
};

export default MapPopup;
