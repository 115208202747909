import jsPDF from 'jspdf';
import { translate as t } from '../../helpers/translate';
import { Formation, PlayerSearchData, SearchPlayer, ShadowTeamPosition } from '../openapi';
import {
    AdditionalData, BoxDescriptor, PositionDescriptor, addLayout, blackHex, c, darkGreenHex, lightGreyHex, whiteHex
} from './pdf-generation-layout';
import { ShadowTeamDescriptor } from '../../../pages/scouting-page/shadow-team/ShadowTeamTemplate';
import { SizeType } from '../../interfaces/common';
import { addSvgToPdf, textEllipsis } from './pdf-generation-utility';
import { SVG, parsePlayerName, switching } from '../../helpers/utility';
import { Coord, Line } from '../../helpers/geometry';
import { Toastify } from '../../modules/Toastify/Toastify';
import { pdfFontContentRegular, pdfFontContentBold } from '../../../data/fonts';
import Background from '../../../assets/images/footballPitch.png';
import PitchLines from '../../../assets/images/footballPitchLines.svg';

const fieldSize: BoxDescriptor = [276, 188, 1161, 828];

/**
 * Mértékengység konvertalás shadow team pályán pos.x/y -> mm
 * @param {number} size - végpontból jövő méret
 * @return {number} mm-ben megadott méret a pdf fájlban
 */
export const cp = (size: number): Coord => ({
    x: c(fieldSize[2] - size / 100 * fieldSize[2] + fieldSize[0]),
    y: c(fieldSize[3] - size / 100 * fieldSize[3] + fieldSize[1])
});

const createPlayerBox = async function({
    pdf,
    x,
    y,
    circle,
    players
}: {
    pdf: jsPDF,
    x: number,
    y: number,
    circle: PositionDescriptor,
    players: (PlayerSearchData | SearchPlayer)[]
}) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');
    pdf.addFileToVFS('open-sans-bold.ttf', pdfFontContentBold);
    pdf.addFont('open-sans-bold.ttf', 'bold', 'bold');

    if (!players) return;

    const ctx = pdf.context2d;

    const box: BoxDescriptor = [x - c(130), y - c(40) - c(53), c(260), c(53)];

    let i = 0;
    for (const player of [...players].reverse()) {
        // háttér
        pdf.setFillColor(whiteHex);
        pdf.rect(...box, 'F');

        pdf.setDrawColor(darkGreenHex);

        // oldalsó vonalak
        pdf.setLineWidth(0.5);
        pdf.setLineDashPattern([1, 0], 0);
        pdf.line(box[0], box[1], box[0], box[1] + box[3]);
        pdf.line(box[0] + box[2], box[1], box[0] + box[2], box[1] + box[3]);

        if (i === 0) {
            // alsó vastag vonal
            pdf.setLineWidth(3);
            pdf.setLineDashPattern([1, 0], 0);
            pdf.line(box[0], box[1] + box[3], box[0] + box[2], box[1] + box[3]);
            // alsó háromszög
            ctx.fillStyle = darkGreenHex;
            ctx.beginPath();
            ctx.moveTo(box[0] + box[2] / 2 - c(15), box[1] + box[3]);
            ctx.lineTo(box[0] + box[2] / 2, box[1] + box[3] + c(15));
            ctx.lineTo(box[0] + box[2] / 2 + c(15), box[1] + box[3]);
            ctx.lineTo(box[0] + box[2] / 2 - c(15), box[1] + box[3]);
            ctx.fill();
            // vonal a pozícióhoz
            const line: Line = {
                x1: box[0] + box[2] / 2,
                y1: box[1] + box[3] + c(15),
                x2: circle[0] + (circle[0] > box[0] + box[2] / 2 ? -c(20) : c(20)),
                y2: circle[1] + (circle[1] > box[1] + box[3] + c(15) ? -c(20) : c(20))
            };
            if (Math.sqrt((line.x2 - line.x1) ** 2 + (line.y2 - line.y1) ** 2) > c(20)) {
                pdf.setLineWidth(0.5);
                pdf.line(line.x1, line.y1, line.x2, line.y2);
            }
        }
        if (i === players.length - 1) {
            // felső folytonos vonal
            pdf.setLineWidth(0.5);
            pdf.setLineDashPattern([1, 0], 0);
            pdf.line(box[0], box[1], box[0] + box[2], box[1]);
        }
        else {
            // felső pontozott vonal
            pdf.setDrawColor(darkGreenHex);
            pdf.setLineWidth(1);
            pdf.setLineDashPattern([1, 1], 0);
            pdf.line(box[0], box[1], box[0] + box[2], box[1]);
        }
        pdf.setLineDashPattern([1, 0], 0);

        // játékos név
        const [firstName, lastName] = parsePlayerName(player.PlayerName);
        pdf.setTextColor(darkGreenHex);
        pdf.setFontSize(8).setFont('bold', 'normal', 'normal');
        pdf.text(
            textEllipsis(pdf, `${firstName[0].toUpperCase()}. ${lastName.toUpperCase()}`, c(220)),
            box[0] + c(10), box[1] + c(33), { align: 'left' }
        );

        const birthYear = 'Birthday' in player ? String(player.Birthday).substring(2) : '';
        pdf.setTextColor(lightGreyHex);
        pdf.setFontSize(8).setFont('normal', 'normal', 'normal');
        pdf.text(
            `'${birthYear}`,
            box[0] + box[2] - c(30), box[1] + c(33), { align: 'left' }
        );

        box[1] -= box[3];
        i++;
    }

};

const createPositions = async function({
    pdf,
    formation,
    positions,
    shadowTeam
}: {
    pdf: jsPDF,
    formation: string,
    positions: ShadowTeamPosition[],
    shadowTeam: ShadowTeamDescriptor
}) {

    pdf.addFileToVFS('open-sans.ttf', pdfFontContentRegular);
    pdf.addFont('open-sans.ttf', 'normal', 'normal');

    const offsets = switching(formation, {
        '3-4-2-1': {
            'GK': [165, 20], 'CB': [0, -5], 'LCB': [-58, -10], 'RCB': [58, -10], 'LCMF': [-145, -100], 'RCMF': [145, -100],
            'LWB': [-58, 10], 'RWB': [58, 10], 'AMF1': [10, -10], 'AMF2': [-10, -10], 'CF': [0, -50],
        },
        '3-4-3': {
            'GK': [165, 20], 'CB': [0, -5], 'RCB': [58, -10], 'LCB': [-58, 10], 'RCMF': [145, -100],'LCMF': [-145, -100],
            'LWB': [-58, 10], 'RWB': [58, 10], 'LW': [10, -10], 'RW': [-10, -10], 'CF': [0, -70],
        },
        '4-1-4-1': {
            'GK': [165, 20], 'RB': [58, -10], 'RCB': [25, -10], 'LCB': [-27, -10], 'LB': [-58, -10], 'DMF': [0, -40],
            'RW': [30, -10], 'LCMF': [-40, -120], 'CF': [0, 0], 'RCMF': [40, -120], 'LW': [-30, -10],
        },
        '4-2-3-1': {
            'GK': [165, 20], 'RB': [58, -10], 'RCB': [27, -70], 'LCB': [-27, -70], 'LB': [-58, -10], 'RDMF': [70, -180],
            'RAMF': [50, 0], 'LDMF': [-70, -180], 'CF': [-20, -40], 'AMF': [60, -37], 'LAMF': [-50, 0],
        },
        '5-3-2': {
            'GK': [165, 20], 'CB': [0, -5], 'RCB': [115, -140], 'LCB': [-115, -140], 'LB': [-58, -10], 'RB': [58, -10],
            'DMF': [0, 0], 'LCMF': [-50, -120], 'RCMF': [50, -120], 'CF': [20, -50], 'SS': [-20, -50],
        },
    }, null);

    if (!offsets) {
        throw new Error('Not defined box offsets for this formation');
    }

    for (const pos of positions) {
        pdf.setDrawColor(darkGreenHex);
        pdf.setLineWidth(1);
        pdf.setFontSize(11).setFont('normal', 'normal', 'normal');
        pdf.setFillColor(whiteHex);
        pdf.circle(cp(pos.PositionY).y, cp(pos.PositionX).x, c(30), 'DF');
        pdf.setTextColor(blackHex);
        pdf.text(pos.PositionID, cp(pos.PositionY).y, cp(pos.PositionX).x + 3, { align: 'center' });
        await createPlayerBox({
            pdf,
            x: cp(pos.PositionY).y + (offsets as any)[pos.PositionID][0],
            y: cp(pos.PositionX).x + (offsets as any)[pos.PositionID][1],
            circle: [cp(pos.PositionY).y, cp(pos.PositionX).x],
            players: shadowTeam[pos.PositionID]
        });
    }

};

export const generatePdf = async function({
    formation,
    shadowTeam,
    additionalData
}: {
    formation: Formation,
    shadowTeam: ShadowTeamDescriptor,
    additionalData: AdditionalData
}): Promise<jsPDF> {

    const pdf = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
        unit: 'px'
    });

    const bg: SizeType = { width: c(827), height: c(1198) };

    pdf.setPage(1);
    await addLayout({
        pdf,
        title: t('pdf.shadowteam.title'),
        page: { current: 1, all: 1 },
        additionalData
    });

    pdf.addImage(
        Background, 'PNG', c(188), c(276), bg.width, bg.height, '', 'NONE', 0
    );
    await addSvgToPdf({
        pdf,
        svgElement: await SVG.urlToElement(PitchLines) as SVGSVGElement,
        qualityWidth: 1000,
        dimensions: {
            x: c(fieldSize[1] + 18),
            y: c(fieldSize[0] + 18),
            w: bg.width - 2 * c(18)
        }
    }).catch(() => {
        Toastify({
            titleKey: 'general.message.error.pdf-generation.svg-insertion-failed'
        }).error();
    });

    await createPositions({
        pdf,
        formation: formation.FormationID,
        positions: formation.Positions,
        shadowTeam
    });

    return pdf;

};
