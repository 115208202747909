import React, { ReactElement } from 'react';
import { MultiThumbRange } from './multi-thumb-slider';
import { cx } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import css from './multi-thumb-slider.module.scss';

export interface MultiThumbSliderTemplateProps {
  minRange: number;
  maxRange: number;
  minValue?: number;
  maxValue: number;
  soloThumb?: boolean,
  inverted?: boolean,
  label?: string;
  disabled?: boolean,
  selectedThumb: Thumb | undefined,
  // changedMin: (number) => void;
  // changedMax: (number) => void;
  actionCallbacks: MultiThumbTemplateCallbacks,
}

export interface MultiThumbTemplateCallbacks {
  changeByButton: (direction: number) => void,
  selectedThumb: (thumb: Thumb | undefined) => void,
  changeByGrab: (range: MultiThumbRange) => void,
}

export enum Thumb {
  MIN = 'min',
  MAX = 'max'
}

const MultiThumbSliderTemplate = function({
    minRange,
    maxRange,
    minValue,
    maxValue,
    soloThumb,
    inverted,
    label,
    selectedThumb,
    disabled,
    actionCallbacks,
}: MultiThumbSliderTemplateProps): ReactElement {

    return <>
        <div className={cx(css.multiThumbWrapper, disabled ? css.disabled : null)}>
            <div className={css.sliderHeader}>
                <div className={css.headerContent}>
                    <span className={css.label}>{t(label ?? '')}:</span>
                    {!soloThumb &&
                        <span className={css.labelValue}>
                            {Math.abs(maxValue) >= Math.abs(minValue ?? 0) ? (minValue ?? 0) : (maxValue ?? 0)}
                        </span>
                    }
                    {!soloThumb &&
                        <span className={css.labelValue}>-</span>
                    }
                    {typeof maxValue !== 'undefined' &&
                        <span className={css.labelValue}>
                            {Math.abs(maxValue) >= Math.abs(minValue ?? 0) ? (maxValue ?? 0) : (minValue ?? 0)}
                        </span>
                    }
                </div>
                <div className={css.headerActions}>
                    <button disabled={!selectedThumb} onClick={() => actionCallbacks.changeByButton(-1)}>-</button>
                    <button disabled={!selectedThumb} onClick={() => actionCallbacks.changeByButton(1)}>+</button>
                </div>
            </div>
            <div className={css.sliderContainer}>
                <span>{minRange}</span>
                <div className={css.sliderBox}>
                    {maxRange &&
                        <svg
                            className={cx(css.svgBackground, soloThumb ? css.solo : '', disabled ? 'disabled' : '')}
                            width={maxRange} xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x={(soloThumb
                                    ? !inverted
                                        ? 0
                                        : (100 - Math.abs((maxRange - maxValue) / maxRange) * 100) + '%'
                                    : (
                                        ((minValue === undefined ? maxValue: minValue < maxValue ? minValue : maxValue) - minRange) /
                                        (maxRange - minRange) * 100
                                    ) + '%')}
                                width={(soloThumb
                                    ? !inverted
                                        ? maxValue
                                        : Math.abs((maxRange - maxValue) / maxRange) * 100
                                    : Math.abs((
                                        (maxValue - minRange) / (maxRange - minRange) * 100) -
                                        (minValue
                                            ? ((minValue - minRange) / (maxRange - minRange) * 100)
                                            : 0))) + '%'}
                                height="100%"
                                fill={disabled ? '#C4DADD' : '#186037'}
                                stroke={disabled ? '#C4DADD' : '#186037'}
                            />
                        </svg>

                    }
                    {!soloThumb &&
                        <input
                            id={Thumb.MIN}
                            className={cx(css.slider, selectedThumb === Thumb.MIN ? css.selectedThumb : '')}
                            min={minRange}
                            value={minValue ?? 0}
                            onClick={() => actionCallbacks.selectedThumb(selectedThumb === Thumb.MIN ? undefined : Thumb.MIN)}
                            onChange={(e) => {
                                actionCallbacks.selectedThumb(Thumb.MIN);
                                actionCallbacks.changeByGrab({
                                    min: +e.target.value,
                                    max: maxValue,
                                });
                            }}
                            disabled={disabled}
                            // onMouseUp={selectedThumb && changedValue}
                            max={maxRange}
                            type='range'
                        />
                    }
                    <input
                        id={Thumb.MAX}
                        className={cx(css.slider, selectedThumb === Thumb.MAX ? css.selectedThumb : '')}
                        onClick={() => actionCallbacks.selectedThumb(selectedThumb === Thumb.MAX ? undefined : Thumb.MAX)}
                        onChange={(e) => {
                            actionCallbacks.selectedThumb(Thumb.MAX);
                            actionCallbacks.changeByGrab({
                                min: minValue ?? 0,
                                max: +e.target.value
                            });
                        }}
                        disabled={disabled}
                        min={minRange}
                        value={maxValue ?? 0}
                        max={maxRange}
                        type='range'
                    />
                </div>
                <span>{maxRange}</span>
            </div>
        </div>
    </>;

};

export default MultiThumbSliderTemplate;
