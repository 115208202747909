import React, { ReactElement, useEffect, useState } from 'react';
import { cx } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import css from './Rating.module.scss';
import BallIcon from '../../../assets/images/icons/icon_soccer_ball_green.svg';

interface ComponentProps {
    label: string,
    minLimit?: number,
    maxLimit?: number,
    disabled?: boolean,
    className?: string,
    value: number | null,
    onRate: (rate: number) => void
}

export const Rating = function ({
    label,
    minLimit = 1,
    maxLimit = 10,
    disabled = false,
    className = '',
    value,
    onRate,
}: ComponentProps): ReactElement {

    const [rate, setRate] = useState<number>(value ?? 0);

    const [rateList, setRateList] = useState<number[]>([]);

    useEffect(
        () => {
            const list = [];
            for (let i = minLimit; i <= maxLimit; i++) {
                list.push(i);
            }
            setRateList(list);
        },
        [maxLimit, minLimit]
    );

    const selectRate = function(rating: number) {
        setRate(rating);
        onRate(rating);
    };

    const moduleClassNames = className.split(' ').map(
        (cl: string) => css[cl]
    ).join(' ');

    return <>
        <div className={css.RatingBox}>
            <div className={css.RatingHeader}>
                <span className={css.RatingTitle}>{t(label)}{!!rate && ':'}</span>
                {!!rate && <span className={css.RatingValue}>{rate}</span>}
            </div>
            <div className={cx(css.RatingLine, disabled ? css.disabled : null, moduleClassNames)}>
                {rateList.map(rateValue =>
                    <button
                        className={cx('btn icon', rate < rateValue ? css.NotRated : css.Rated)}
                        key={rateValue}
                        onClick={() => selectRate(rateValue)}
                    >
                        <img src={BallIcon} alt=""/>
                    </button>
                )}
            </div>
        </div>
    </>;

};
