import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { cloneDeep, without } from 'lodash';
import { PlayerLayerContext } from '../../../../shared/contexts/PlayerLayer.context';
import { PlayerList, PlayerSearchData } from '../../../../shared/services/openapi';
import { Toastify } from '../../../../shared/modules/Toastify/Toastify';
import { createSvg, SortingDescriptor } from '../../short-list/ShortList';
import { generatePdf } from '../../../../shared/services/pdf-generation/pdf-generation-playerlist';
import { sortDescriptor, SortDirection } from '../../../../shared/helpers/utility';
import { CategoryEnum, CategoryListOrder } from '../../../../shared/interfaces/reports.interface';
import LoadedPlayerListTemplate from './LoadedPlayerListTemplate';

const LoadedPlayerList = function({
    loadedList,
    players
}: {
    loadedList?: PlayerList,
    players: PlayerSearchData[]
}): ReactElement {

    const [loadings, setLoadings] = useState<string[]>([]);
    const [sorting, setSorting] = useState<SortingDescriptor>({ column: 'ReportDate', direction: SortDirection.desc });
    const [playerList, setPlayerList] = useState<PlayerSearchData[]>(players);

    const layerContext = useContext(PlayerLayerContext);

    const sortingList = useCallback(
        (p1: PlayerSearchData, p2: PlayerSearchData): number => {
            if (sorting.column === 'ClassificationID') {
                const a = CategoryListOrder.indexOf(p1.ClassificationID as CategoryEnum);
                const b = CategoryListOrder.indexOf(p2.ClassificationID as CategoryEnum);
                return sortDescriptor(a, b, sorting.direction);
            }
            else {
                return sortDescriptor(
                    p1[sorting.column as keyof PlayerSearchData],
                    p2[sorting.column as keyof PlayerSearchData],
                    sorting.direction
                );
            }
        },
        [sorting.column, sorting.direction]
    );

    useEffect(
        () => {
            setPlayerList(cloneDeep(players).sort(sortingList));
        },
        [players, sortingList]
    );

    const saveCurrentListPdf = function(list: PlayerList, players: PlayerSearchData[]) {
        setLoadings(l => [...l, 'pdf']);
        generatePdf({
            list: players,
            charts: createSvg(players),
            additionalData: {
                label: list.ListName,
                date: list.ListDate,
                username: list.UserName
            }
        }).finally(
            () => setLoadings(l => without(l, 'pdf'))
        ).then(
            (pdf: jsPDF) => {
                pdf.save(`Playerlist_${list.ListName}.pdf`.replace(/\s/g, '_'));
            }
        ).catch(
            (error: Error) => {
                Toastify({
                    titleKey: 'general.message.error.pdf-generation.playerlist-failed'
                }).error();
                console.error(error);
            }
        );
    };

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true
        });
    };

    const searchToTeam = function(teamName: string) {
        layerContext.setContext({
            playerId: 0,
            isSearchModalOpen: teamName,
        });
    };

    const changeSorting = function(column: keyof PlayerSearchData) {
        if (sorting.column === column) {
            setSorting({
                column,
                direction: sorting.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc
            });
        }
        else {
            setSorting({
                column,
                direction: SortDirection.asc
            });
        }
    };

    return <>
        <LoadedPlayerListTemplate
            handlePlayerLayer={handlePlayerLayer}
            searchToTeam={searchToTeam}
            saveCurrentListPdf={saveCurrentListPdf}
            loadedList={loadedList}
            players={playerList}
            loadings={loadings}
            sorting={sorting}
            changeSorting={changeSorting}
        />
    </>;

};

export default LoadedPlayerList;
