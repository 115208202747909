/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyReturn } from '../models/EmptyReturn';
import type { FilterParams } from '../models/FilterParams';
import type { PlayerSearch } from '../models/PlayerSearch';
import type { PlayerSearches } from '../models/PlayerSearches';
import type { SearchParam } from '../models/SearchParam';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlayerSearchService {

    /**
     * Get base data of player
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static deleteSavedPlayerSearchCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * SearchID to delete
             */
            SearchID?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteSavedPlayerSearch/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns FilterParams
     * @throws ApiError
     */
    public static filterParamsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<FilterParams> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/FilterParams/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns SearchParam
     * @throws ApiError
     */
    public static savePlayerSearchCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            SearchParams?: {
                SearchID?: number;
                SearchName?: string;
                AgeRange?: {
                    min?: number;
                    max?: number;
                };
                SpeedRange?: {
                    min?: number;
                    max?: number;
                };
                DynamicRange?: {
                    min?: number;
                    max?: number;
                };
                AgilityRange?: {
                    min?: number;
                    max?: number;
                };
                PhysiqueGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                GameIntelligenceRange?: {
                    min?: number;
                    max?: number;
                };
                AgressionRange?: {
                    min?: number;
                    max?: number;
                };
                MentalityGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                TechniqueRange?: {
                    min?: number;
                    max?: number;
                };
                BallStrikingAbilityRange?: {
                    min?: number;
                    max?: number;
                };
                PressureResistanceRange?: {
                    min?: number;
                    max?: number;
                };
                TechnicalGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                PositioningRange?: {
                    min?: number;
                    max?: number;
                };
                BoxDefendingRange?: {
                    min?: number;
                    max?: number;
                };
                OneVOneDefendingRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingDuelRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingIntensityRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingInTransitionRange?: {
                    min?: number;
                    max?: number;
                };
                PressingIntensityRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingArealDuelRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                CombinativeGameRange?: {
                    min?: number;
                    max?: number;
                };
                AccelerationRange?: {
                    min?: number;
                    max?: number;
                };
                OneVOneAttackingRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingIntensityRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingInTransitionRange?: {
                    min?: number;
                    max?: number;
                };
                PassingRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingDuelRange?: {
                    min?: number;
                    max?: number;
                };
                ProgressivityRange?: {
                    min?: number;
                    max?: number;
                };
                BoxPresenceRange?: {
                    min?: number;
                    max?: number;
                };
                FinishingRange?: {
                    min?: number;
                    max?: number;
                };
                CrossingRange?: {
                    min?: number;
                    max?: number;
                };
                KeyPassesRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingArealDuelRange?: {
                    min?: number;
                    max?: number;
                };
                PlayAgainstDeepBlockRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                ScoutingIndexGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                Nationality?: Array<{
                    NationalityID?: string;
                }>;
                SearchField?: string;
                MinutesPlayedMin?: number;
                ClassificationID?: Array<{
                    ClassificationID?: string;
                }>;
                PlayerPositionID?: number;
                PlayerProfileID?: string;
                ResellableFlg?: boolean;
            };
        },
    ): CancelablePromise<SearchParam> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavePlayerSearch/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerSearches
     * @throws ApiError
     */
    public static savedPlayerSearchesCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearches> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavedPlayerSearches/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns PlayerSearch
     * @throws ApiError
     */
    public static searchReportedPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            SearchParams?: {
                AgeRange?: {
                    min?: number;
                    max?: number;
                };
                SpeedRange?: {
                    min?: number;
                    max?: number;
                };
                DynamicRange?: {
                    min?: number;
                    max?: number;
                };
                AgilityRange?: {
                    min?: number;
                    max?: number;
                };
                PhysiqueGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                GameIntelligenceRange?: {
                    min?: number;
                    max?: number;
                };
                AgressionRange?: {
                    min?: number;
                    max?: number;
                };
                MentalityGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                TechniqueRange?: {
                    min?: number;
                    max?: number;
                };
                BallStrikingAbilityRange?: {
                    min?: number;
                    max?: number;
                };
                PressureResistanceRange?: {
                    min?: number;
                    max?: number;
                };
                TechnicalGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                PositioningRange?: {
                    min?: number;
                    max?: number;
                };
                BoxDefendingRange?: {
                    min?: number;
                    max?: number;
                };
                OneVOneDefendingRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingDuelRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingIntensityRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingInTransitionRange?: {
                    min?: number;
                    max?: number;
                };
                PressingIntensityRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingArealDuelRange?: {
                    min?: number;
                    max?: number;
                };
                DefendingGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                CombinativeGameRange?: {
                    min?: number;
                    max?: number;
                };
                AccelerationRange?: {
                    min?: number;
                    max?: number;
                };
                OneVOneAttackingRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingIntensityRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingInTransitionRange?: {
                    min?: number;
                    max?: number;
                };
                PassingRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingDuelRange?: {
                    min?: number;
                    max?: number;
                };
                ProgressivityRange?: {
                    min?: number;
                    max?: number;
                };
                BoxPresenceRange?: {
                    min?: number;
                    max?: number;
                };
                FinishingRange?: {
                    min?: number;
                    max?: number;
                };
                CrossingRange?: {
                    min?: number;
                    max?: number;
                };
                KeyPassesRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingArealDuelRange?: {
                    min?: number;
                    max?: number;
                };
                PlayAgainstDeepBlockRange?: {
                    min?: number;
                    max?: number;
                };
                AttackingGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                ScoutingIndexGroupMeanRange?: {
                    min?: number;
                    max?: number;
                };
                Nationality?: Array<{
                    NationalityID?: string;
                }>;
                SearchField?: string;
                MinutesPlayedMin?: number;
                ClassificationID?: Array<{
                    ClassificationID?: string;
                }>;
                PlayerPositionID?: number;
                PlayerProfileID?: string;
                ResellableFlg?: boolean;
            };
        },
    ): CancelablePromise<PlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SearchReportedPlayers/',
            body: data,
        });
    }

}
