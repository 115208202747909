import React, { ReactElement, useContext } from 'react';
import { translate as t } from '../../../../shared/helpers/translate';
import { PlayerSearchData } from '../../../../shared/services/openapi';
import { cx, parsePlayerName } from '../../../../shared/helpers/utility';
import { indicatorOrder } from '../../../../shared/constants';
import { PlayerLayerContext } from '../../../../shared/contexts/PlayerLayer.context';
import EchartsRadar from '../../../../shared/modules/EchartsWrapper/EchartsRadar/EchartsRadar';
import Tooltip from '../../../../shared/modules/Tooltip/Tooltip';
import { CategoryEnum } from '../../../../shared/interfaces/reports.interface';
import ListButton from '../../../../shared/modules/ListButtons/ListButton';
import css from './PlayerFilterCard.module.scss';

interface ComponentProps {
    player: PlayerSearchData,
}

export const PlayerFilterCard = function({
    player,
}: ComponentProps): ReactElement {

    const layerContext = useContext(PlayerLayerContext);

    const playerName = parsePlayerName(player.PlayerName);

    function searchToTeam(teamName: string) {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isSearchModalOpen: teamName,
            });
        }
    }

    function openPlayer() {
        layerContext.setContext({
            playerId: player.PlayerID,
            isModalOpen: true
        });
    }

    return <>
        <div className={css.listElement}>
            <div className={css.basicInfo}>
                <div className={css.pictureAndName}>
                    <div className={cx(
                        css.playerPicture,
                        css['category-' + (Object.keys(CategoryEnum)[
                            Object.values(CategoryEnum).indexOf(player.ClassificationID as CategoryEnum)
                        ] ?? 'none')]
                    )}>
                        <img src={player.PlayerImageUrl} alt="" />
                    </div>
                    <div className={css.personalData}>
                        <button
                            onClick={openPlayer}
                            className={cx('btn btn-div', css.firstName)}
                            tabIndex={-1}
                        >
                            {playerName[0]}
                        </button>
                        <button
                            onClick={openPlayer}
                            className={cx('btn btn-div', css.surName)}
                        >
                            <Tooltip text={player.PlayerName} ifOverflow={true}>
                                <span className={css.playerName}>{playerName[1]}</span>
                            </Tooltip>
                        </button>
                        <p className={css.birthDate}>{player.Birthday}</p>
                    </div>
                </div>
                <div className={css.chartContainer}>
                    <EchartsRadar
                        showLabel={false}
                        size={{ width: 80, height: 80 }}
                        dataList={player}
                        order={indicatorOrder}
                        dotSize={5}
                        showTicks={false}
                    />
                </div>
            </div>
            <div className={css.clubInfo}>
                <div className={css.team}>
                    <p className={css.title}>{t('playerDetails.label.team')}</p>
                    <div className={css.nameAndLogo}>
                        <img className={css.teamLogo} src={player.TeamImageUrl} alt="" />
                        <p className={css.teamName} onClick={() => searchToTeam(player.TeamName)}>{player.TeamName}</p>
                    </div>
                </div>
                <div className={css.post}>
                    <p className={css.title}>{t('playerDetails.label.post')}</p>
                    <p className={css.postNumber}>{player.PlayerPositionID}</p>
                </div>
                <div className={css.minutesPlayed}>
                    <p className={css.title}>{t('playerDetails.label.minutes')}</p>
                    <p className={css.value}>{player.MinutesPlayed}</p>
                </div>
            </div>
            <ListButton
                type="shortList"
                playerId={player.PlayerID}
                playerInList={player.InShortlistFlg}
            />
        </div>
    </>;

};
