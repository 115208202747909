import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor,
    PointerSensor, TouchSensor, rectIntersection, useSensor, useSensors
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import SimpleBar from 'simplebar-react';
import { cloneDeep, isUndefined } from 'lodash';
import TabAccordion from '../../TabAccordion/TabAccordion';
import { TeamTabEnum } from '../../../interfaces/carried-on.interfaces';
import Select from '../../select/select';
import { translate as t } from '../../../helpers/translate';
import { CancelablePromise, PlayerSearchData, SearchAllPlayer, SearchFieldService, SearchPlayer } from '../../../services/openapi';
import { ArrayOfObjects, Html, SortDirection, cx, sortDescriptor } from '../../../helpers/utility';
import { FradiLoader } from '../../FradiLoader/FradiLoader';
import { PlayerLayerContext } from '../../../contexts/PlayerLayer.context';
import useShortList, { playerSort } from '../../../hooks/shortList.hook';
import { ShadowTeamContext } from '../../../contexts/ShadowTeam.context';
import PlayerShadowTeamCard from '../../PlayerShadowTeamCard/PlayerShadowTeamCard';
import { nullPlayer, PLAYER_SEARCH_MIN_CHARS, PLAYER_SEARCH_WAITING, SHADOW_TEAM_ALLOWED_PLAYER_NUM } from '../../../constants';
import { RawShadowTeamDescriptor } from '../../../../pages/scouting-page/shadow-team/ShadowTeamTemplate';
import { Draggable, Droppable, SortableItem } from '../DragnDrop';
import Tooltip from '../../Tooltip/Tooltip';
import { toastError } from '../../../helpers/errorhandler';
import useAuth from '../../../hooks/auth.hook';
import useDelayedSearch from '../../../hooks/delayed-search.hook';
import PlayerDragListCard from '../PlayerDragListCard/PlayerDragListCard';
import css from './PositionModifier.module.scss';
import PlaceholderIcon from '../../../../assets/images/state_player_outline.svg';

export interface SearchListSortingDescriptor {
    column: keyof SearchPlayer;
    direction: SortDirection;
}

export const PositionModifier = function({
    positionId,
    currentShadowTeam,
    setShadowPlayersForPosition,
    filterByPosition
}: {
    positionId: string,
    currentShadowTeam: RawShadowTeamDescriptor,
    setShadowPlayersForPosition: (positionId: string, playerList: (PlayerSearchData | SearchPlayer | null)[]) => void,
    filterByPosition: boolean
}): ReactElement {

    const tabList = Object.values(TeamTabEnum);

    const { requestParams } = useAuth();
    const layerContext = useContext(PlayerLayerContext);
    const shadowTeamContext = useContext(ShadowTeamContext);

    const {
        loadings,
        shortList,
        selfList,
        searchTerm,
        changeSearchTerm,
        selectedPosition,
        setSelectedPosition,
        optionPosition,
        filteredShortList,
        filteredSelfList,
        sorting,
        changeSorting
    } = useShortList({ column: 'PlayerName', direction: SortDirection.asc });

    const [selectedPositionId, setSelectedPositionId] = useState<string>(positionId);
    const [availableShortList, setAvailableShortList] = useState<PlayerSearchData[]>(shortList);
    const [availableSelfList, setAvailableSelfList] = useState<PlayerSearchData[]>(selfList);
    const [shadowPlayerList, setShadowPlayerList] = useState<(PlayerSearchData | SearchPlayer | null)[]>(currentShadowTeam[positionId]);

    const [placeholderList, setPlaceholderList] = useState<number[]>(
        Array(SHADOW_TEAM_ALLOWED_PLAYER_NUM).fill(null).map((_, i) => i + 1)
    );
    const [droppedPlayers, setDroppedPlayers] = useState<(PlayerSearchData | SearchPlayer | null)[]>([]);
    const [draggingPlayer, setDraggingPlayer] = useState<PlayerSearchData | null>(null);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                distance: 8
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const [searchList, setSearchList] = useState<SearchPlayer[]>([]);
    const [availableSearchList, setAvailableSearchList] = useState<SearchPlayer[]>([]);
    const [searchListTerm, setSearchListTerm] = useState<string>('');
    const [searchListLoading, setSearchListLoading] = useState<boolean>(false);
    const [searchListSorting, setSearchListSorting] = useState<SearchListSortingDescriptor>({
        column: 'PlayerName', direction: SortDirection.asc
    });
    const request = useRef<CancelablePromise<SearchAllPlayer>>();

    const sortingSearchList = useCallback(
        (p1: SearchPlayer, p2: SearchPlayer): number => sortDescriptor(
            p1[searchListSorting.column as keyof SearchPlayer],
            p2[searchListSorting.column as keyof SearchPlayer],
            searchListSorting.direction
        ),
        [searchListSorting.column, searchListSorting.direction]
    );

    const searchHandler = useCallback(
        (term: string) => {
            setSearchListTerm(term);
            if (requestParams.UserID && term.length >= PLAYER_SEARCH_MIN_CHARS) {
                setSearchListLoading(true);
                request.current = SearchFieldService.searchAllPlayersCreate({
                    ...requestParams,
                    SeachField: term
                });
                request.current.finally(
                    () => {
                        setSearchListLoading(false);
                        request.current = undefined;
                    }
                ).then(resp => {
                    setSearchList(resp.Players ?? []);
                    setAvailableSearchList((resp.Players ?? []).filter(
                        player => !ArrayOfObjects.take(droppedPlayers, 'PlayerID').includes(player.PlayerID)
                    ).sort(
                        sortingSearchList
                    ));
                    return resp;
                }).catch(
                    toastError
                );
                return request.current;
            }
        },
        [droppedPlayers, requestParams, sortingSearchList]
    );

    const {
        search: searchListTriggerer,
        waiting,
        searchValue: searchListValue
    } = useDelayedSearch<SearchAllPlayer>(searchHandler, request, PLAYER_SEARCH_MIN_CHARS, PLAYER_SEARCH_WAITING);

    useEffect(
        () => {
            setSelectedPositionId(positionId);
        },
        [positionId]
    );

    useEffect(
        () => {
            setShadowPlayerList(currentShadowTeam[selectedPositionId]);
            setAvailableShortList(
                filteredShortList.filter(
                    playerList => (
                        (
                            !currentShadowTeam[selectedPositionId] ||
                            !currentShadowTeam[selectedPositionId].find(
                                playerShadowTeam => playerList.PlayerID === playerShadowTeam?.PlayerID
                            )
                        ) && (
                            !filterByPosition || playerList.PlayerPositionID === 2 // FIXME: positionId (ha lesz megfeleltetés)
                        )
                    )
                )
            );
            setAvailableSelfList(
                filteredSelfList.filter(
                    playerList => (
                        !currentShadowTeam[selectedPositionId] ||
                        !currentShadowTeam[selectedPositionId].find(
                            playerShadowTeam => playerList.PlayerID === playerShadowTeam?.PlayerID
                        )
                    ) && (
                        !filterByPosition || playerList.PlayerPositionID === 2 // FIXME: positionId
                    )
                )
            );
        },
        [currentShadowTeam, filterByPosition, filteredSelfList, filteredShortList, selectedPositionId]
    );

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true,
            closeCallback: shadowTeamContext.context
                ? () => shadowTeamContext.context && shadowTeamContext.setContext({
                    ...shadowTeamContext.context, isModalOpen: true
                })
                : undefined
        });
        if (shadowTeamContext.context) {
            shadowTeamContext.setContext({ ...shadowTeamContext.context, isModalOpen: false });
        }
    };

    const searchToTeam = function(teamName: string) {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isSearchModalOpen: teamName
            });
        }
    };

    const cacheShadowTeam = function(list: (PlayerSearchData | SearchPlayer | null)[]) {
        setPlaceholderList(
            Array(SHADOW_TEAM_ALLOWED_PLAYER_NUM).fill(null).map((_, i) => i + 1)
        );
        setShadowPlayersForPosition(selectedPositionId, list);
    };

    const getShadowTeamFromDroppedPlayers = useCallback(
        (droppeds: (PlayerSearchData | SearchPlayer | null)[], placeholders: number[]): (PlayerSearchData | SearchPlayer | null)[] =>
            placeholders.map((item) => {
                if (droppeds[item]) {
                    return droppeds[item];
                }
                else {
                    return null;
                }
            })
        ,
        []
    );

    useEffect(
        () => {
            setDroppedPlayers([null, ...(currentShadowTeam[selectedPositionId] ?? Array(SHADOW_TEAM_ALLOWED_PLAYER_NUM).fill(null))]);
        },
        [currentShadowTeam, selectedPositionId]
    );

    useEffect(
        () => {
            setShadowPlayerList(getShadowTeamFromDroppedPlayers(droppedPlayers, placeholderList));
        },
        [droppedPlayers, getShadowTeamFromDroppedPlayers, placeholderList]
    );

    useEffect(
        () => {
            setAvailableSearchList(current => cloneDeep(current).sort(
                sortingSearchList
            ));
        },
        [sortingSearchList, searchListSorting.column, searchListSorting.direction]
    );

    const removeFromList = function(playerId: number) {
        const newDroppedList = droppedPlayers.map(
            player => player?.PlayerID === playerId ? null : player
        );
        setDroppedPlayers(newDroppedList);

        setAvailableShortList(list => {
            const receivedPlayer = filteredShortList.find(
                player => player.PlayerID === playerId
            );
            if (receivedPlayer) {
                list.push(receivedPlayer);
            }
            return list.sort(playerSort);
        });
        setAvailableSelfList(list => {
            const receivedPlayer = filteredSelfList.find(
                player => player.PlayerID === playerId
            );
            if (receivedPlayer) {
                list.push(receivedPlayer);
            }
            return list.sort(playerSort);
        });
        setAvailableSearchList(list => {
            const receivedPlayer = searchList.find(
                player => player.PlayerID === playerId
            );
            if (receivedPlayer) {
                list.push(receivedPlayer);
            }
            return list.sort(sortingSearchList);
        });

        cacheShadowTeam(getShadowTeamFromDroppedPlayers(newDroppedList, placeholderList));
    };

    function dragConstraint(args: any) {
        const { transform } = args;

        return {
            ...transform,
            x: transform.x <= 9 ? transform.x : 0,
            y: transform.y
        };
    }

    function handleDragStart(event: DragStartEvent) {
        setDraggingPlayer(event.active.data.current?.player);
    }

    function handleDragEnd(event: DragEndEvent) {
        const currentlyDroppedPlayer: PlayerSearchData = event.active.data.current?.player;
        if (event.over && !droppedPlayers[+event.over!.id] && currentlyDroppedPlayer) {
            // drag
            const dPlayers = [...droppedPlayers];
            dPlayers[+event.over.id] = currentlyDroppedPlayer;
            setDroppedPlayers(dPlayers);
            setAvailableShortList(list => list.filter(
                player => player.PlayerID !== currentlyDroppedPlayer.PlayerID
            ));
            setAvailableSelfList(list => list.filter(
                player => player.PlayerID !== currentlyDroppedPlayer.PlayerID
            ));
            setAvailableSearchList(list => list.filter(
                player => player.PlayerID !== currentlyDroppedPlayer.PlayerID
            ));
            cacheShadowTeam(getShadowTeamFromDroppedPlayers(dPlayers, placeholderList));
        }
        else if (event.active.id !== event.over?.id && !currentlyDroppedPlayer) {
            // sort
            const oldIndex = placeholderList.indexOf(event.active.id as number);
            const newIndex = placeholderList.indexOf(event.over?.id as number);
            const newPlaceholderList = arrayMove(placeholderList, oldIndex, newIndex);
            setPlaceholderList(newPlaceholderList);
            cacheShadowTeam(getShadowTeamFromDroppedPlayers(droppedPlayers, newPlaceholderList));
        }
        setDraggingPlayer(null);
    }

    const sortableColumn = function(
        column: keyof PlayerSearchData, label: string, style: Record<string, string> = css
    ) {
        return (
            <Tooltip text={t(`general.label.sort.${label}`)}>
                <p
                    className={cx(
                        style[label], 'sortableColumn', sorting.column === column && 'sort-' + sorting.direction
                    )}
                    onClick={() => changeSorting(column)}
                >
                    {t(`playerDetails.label.${label}`)}
                </p>
            </Tooltip>
        );
    };

    const changeSearchListSorting = function(column: keyof SearchPlayer) {
        if (searchListSorting.column === column) {
            setSearchListSorting({
                column,
                direction: searchListSorting.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc
            });
        }
        else {
            setSearchListSorting({
                column,
                direction: SortDirection.asc
            });
        }
    };

    const sortableSearchListColumn = function(
        column: keyof SearchPlayer, label: string, style: Record<string, string> = css
    ) {
        return (
            <Tooltip text={t(`general.label.sort.${label}`)}>
                <p
                    className={cx(
                        style[label], 'sortableColumn', searchListSorting.column === column && 'sort-' + searchListSorting.direction
                    )}
                    onClick={() => changeSearchListSorting(column)}
                >
                    {t(`playerDetails.label.${label}`)}
                </p>
            </Tooltip>
        );
    };

    return <>
        <div className={css.contentBox}>
            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={rectIntersection}>
                <div className={css.selectedPlayers}>
                    <Select
                        isSoloLabel={true}
                        options={shadowTeamContext.context?.selectedFormation?.Positions.map(
                            pos => ({
                                id: pos.PositionID,
                                value: pos.PositionID
                            })
                        ) ?? []}
                        changeSelected={
                            (options) => {
                                const newPositionId = options[0]?.id as string ?? '';
                                setSelectedPositionId(newPositionId);
                            }
                        }
                        selectedOptions={[
                            { id: selectedPositionId, value: selectedPositionId }
                        ]}
                        label={t('scouting.shadow.modal.label.position')}
                    ></Select>

                    <div className={css.scrollbarContainer}>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <div className={css.players}>
                                <SortableContext
                                    items={placeholderList}
                                    strategy={rectSortingStrategy}
                                >
                                    {placeholderList.map((item) => (
                                        <SortableItem key={item} id={item}>
                                            <Droppable key={item} id={item} droppedPlayers={droppedPlayers}>
                                                {droppedPlayers[item]
                                                    ? <>
                                                        <PlayerShadowTeamCard
                                                            player={droppedPlayers[item] as PlayerSearchData}
                                                            handlePlayerLayer={handlePlayerLayer}
                                                            removeFromList={removeFromList}
                                                        />
                                                    </>
                                                    : <>
                                                        <div className={css.playerPlaceholder}>
                                                            <img src={PlaceholderIcon} alt=""/>
                                                            <span>{t('scouting.shadow.modal.label.placeholder')}</span>
                                                        </div>
                                                    </>
                                                }
                                            </Droppable>
                                        </SortableItem>
                                    ))}
                                </SortableContext>
                            </div>
                        </SimpleBar>
                    </div>
                </div>
                <div className={css.playerSelector}>
                    <TabAccordion<TeamTabEnum>
                        tabs={tabList}
                        preTag={'tab.team.'}
                        defaultTab={TeamTabEnum.SHORTLIST}
                    >
                        {(selectedTab) => <>
                            {(selectedTab === TeamTabEnum.SHORTLIST || selectedTab === TeamTabEnum.OWN_TEAM) && (
                                <div className={cx(css.shortListContainer, css.listed)}>
                                    <div className={css.filters}>
                                        <input
                                            className={css.search}
                                            type="search"
                                            placeholder={t('scouting.search')}
                                            value={searchTerm}
                                            onChange={event => changeSearchTerm(event)}
                                        />
                                        <div className={css.select}>
                                            <Select
                                                isSoloLabel={true}
                                                options={[
                                                    { id: 0, value: t('scouting.short-list.all-positions') },
                                                    ...optionPosition
                                                ]}
                                                changeSelected={
                                                    (options) => setSelectedPosition(
                                                        +options[0]?.id || 0
                                                    )
                                                }
                                                selectedOptions={[{
                                                    id: selectedPosition,
                                                    value: selectedPosition === 0
                                                        ? t('scouting.short-list.all-positions')
                                                        : String(selectedPosition)
                                                }]}
                                                label={t('playerDetails.label.post')}
                                            ></Select>
                                        </div>
                                    </div>
                                    <p className={css.emptyStateText}><Html>{t('scouting.short-list.empty-list-text')}</Html></p>
                                    <div className={css.listHeader}>
                                        <div className={css.playerPicture}></div>
                                        {sortableColumn('PlayerName', 'playerName')}
                                        {sortableColumn('ClassificationID', 'classification')}
                                        {sortableColumn('Birthday', 'birthYear')}
                                        {sortableColumn('TeamName', 'teamName')}
                                        {sortableColumn('PlayerPositionID', 'post')}
                                        {sortableColumn('MinutesPlayed', 'minutes')}
                                        {sortableColumn('ScoutingIndexGroupMean', 'fradiIndex')}
                                        <p className={css.shadowTeam}>{t('playerDetails.label.shadowTeam')}</p>
                                    </div>
                                    <SimpleBar style={{ maxHeight: '90%' }} autoHide={false}>
                                        <div className={css.list}>
                                            {
                                                selectedTab === TeamTabEnum.SHORTLIST &&
                                                availableShortList.map(player => (
                                                    <Draggable
                                                        key={player.PlayerID}
                                                        id={player.PlayerID}
                                                        player={player}
                                                        currentPlayerList={shadowPlayerList}
                                                    >
                                                        <div className={css.cardContainer}>
                                                            <PlayerDragListCard
                                                                player={player}
                                                                handlePlayerLayer={handlePlayerLayer}
                                                                searchToTeam={searchToTeam}
                                                                excludedColumns={['polarChart', 'playerList', 'shortList', 'date']}
                                                            />
                                                        </div>
                                                    </Draggable>
                                                ))
                                            }
                                            {
                                                selectedTab === TeamTabEnum.OWN_TEAM &&
                                                availableSelfList.map(player => (
                                                    <Draggable
                                                        key={player.PlayerID}
                                                        id={player.PlayerID}
                                                        player={player}
                                                        currentPlayerList={shadowPlayerList}
                                                    >
                                                        <div className={css.cardContainer}>
                                                            <PlayerDragListCard
                                                                player={player}
                                                                handlePlayerLayer={handlePlayerLayer}
                                                                searchToTeam={searchToTeam}
                                                                excludedColumns={['polarChart', 'playerList', 'shortList', 'date']}
                                                            />
                                                        </div>
                                                    </Draggable>
                                                ))
                                            }
                                        </div>
                                    </SimpleBar>
                                    <FradiLoader visible={loadings.includes('shortList')}/>
                                </div>
                            )}
                            {selectedTab === TeamTabEnum.SEARCHLIST && (
                                <div className={cx(css.shortListContainer, css.listed)}>
                                    <div className={css.filters}>
                                        <input
                                            className={css.search}
                                            type="search"
                                            placeholder={t('scouting.search')}
                                            value={searchListValue}
                                            onChange={event => searchListTriggerer(event.target.value)}
                                        />
                                    </div>
                                    <p className={css.emptyStateText}><Html>{t('scouting.short-list.empty-list-text')}</Html></p>
                                    <div className={css.listHeader}>
                                        <div className={css.playerPicture}></div>
                                        {sortableSearchListColumn('PlayerName', 'playerName')}
                                        {sortableSearchListColumn('TeamName', 'teamName')}
                                    </div>
                                    <SimpleBar style={{ maxHeight: '90%' }} autoHide={false}>
                                        <div className={css.list}>
                                            {searchListTerm.length >= PLAYER_SEARCH_MIN_CHARS && waiting && (
                                                <span className={css.waiting}>{t('general.message.waiting')}</span>
                                            )}
                                            {(
                                                searchListTerm.length >= PLAYER_SEARCH_MIN_CHARS &&
                                                !searchList?.length &&
                                                !searchListLoading &&
                                                !waiting
                                            ) && (
                                                <span className={css.noResult}>{t('general.message.noListedItems')}</span>
                                            )}
                                            {availableSearchList.map(player => (
                                                <Draggable
                                                    key={player.PlayerID}
                                                    id={player.PlayerID}
                                                    player={player}
                                                    currentPlayerList={shadowPlayerList}
                                                >
                                                    <div className={css.cardContainer}>
                                                        <PlayerDragListCard
                                                            player={{
                                                                ...nullPlayer,
                                                                ...player
                                                            }}
                                                            handlePlayerLayer={handlePlayerLayer}
                                                            searchToTeam={searchToTeam}
                                                            excludedColumns={[
                                                                'classification', 'birthYear', 'post', 'minutes', 'date',
                                                                'fradiIndex', 'polarChart', 'playerList', 'shortList', 'shadowTeam'
                                                            ]}
                                                        />
                                                    </div>
                                                </Draggable>
                                            ))}
                                        </div>
                                    </SimpleBar>
                                    <FradiLoader visible={searchListLoading} />
                                </div>
                            )}
                        </>}
                    </TabAccordion>
                </div>
                <DragOverlay modifiers={[dragConstraint]}>
                    {draggingPlayer ? (
                        <div className={css.cardContainer}>
                            <div className={css.drag} style={{ cursor: 'grabbing' }}></div>
                            {isUndefined(draggingPlayer.ReportDate) && (
                                <PlayerDragListCard
                                    player={draggingPlayer}
                                    handlePlayerLayer={handlePlayerLayer}
                                    excludedColumns={[
                                        'polarChart', 'playerList', 'shortList', 'date', 'fradiIndex',
                                        'classification', 'birthYear', 'post', 'minutes', 'date', 'shadowTeam'
                                    ]}
                                />
                            ) || (
                                <PlayerDragListCard
                                    player={draggingPlayer}
                                    handlePlayerLayer={handlePlayerLayer}
                                    excludedColumns={['polarChart', 'playerList', 'shortList', 'date']}
                                />
                            )}
                        </div>
                    ): null}
                </DragOverlay>
            </DndContext>
        </div>
    </>;

};
