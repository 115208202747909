import React, { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { translate as t } from '../../helpers/translate';
import css from './Toastify.module.scss';
import InfoIcon from '../../../assets/images/icons/icon_info.svg';
import ErrorIcon from '../../../assets/images/icons/icon_warning_triangle.svg';
import WarningIcon from '../../../assets/images/icons/icon_warning_circle.svg';
import SuccessIcon from '../../../assets/images/icons/icon_check_circle.svg';

interface ComponentProps {
    title?: string;
    titleKey?: string;
    content?: ReactNode;
    option?: ToastOptions,
}

export const Toastify = function ({
    title,
    titleKey,
    content,
    option,
}: ComponentProps) {

    const defaultOption: ToastOptions = {
        closeButton: true,
    };

    function info() {
        toast.info(contentify, {
            ...defaultOption,
            icon: <img className={css.icon} src={InfoIcon} alt=""/>,
            ...(option ? option : {}),
        });
    }

    function error() {
        toast.error(contentify, {
            ...defaultOption,
            icon: <img className={css.icon} src={ErrorIcon} alt=""/>,
            ...(option ? option : {}),
        });
    }

    function success() {
        toast.success(contentify, {
            ...defaultOption,
            icon: <img className={css.icon} src={SuccessIcon} alt=""/>,
            ...(option ? option : {}),
        });
    }

    function warning() {
        toast.warning(contentify, {
            ...defaultOption,
            icon: <img className={css.icon} src={WarningIcon} alt=""/>,
            ...(option ? option : {}),
        });
    }

    function contentify(): ReactNode {
        return <>
            { content
                ? <>
                    {titleKey || title && <strong>{titleKey ? t(titleKey) : title}</strong>}
                    {content}
                </>
                : <span>{titleKey ? t(titleKey) : title}</span>
            }
        </>;
    }

    return {
        info,
        error,
        success,
        warning,
    };

};
