import { createContext } from 'react';
import { ContextProps } from './context.helper';
import { Formation } from '../services/openapi';

export interface ShadowTeamContextProps {
    formations: Formation[];
    selectedFormation?: Formation;
    isModalOpen?: boolean;
}

export const ShadowTeamContext = createContext<ContextProps<ShadowTeamContextProps>>({
    context: undefined,
    setContext: () => undefined,
});
