import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { CancelablePromise } from '../services/openapi';

/**
 * Késleltetés keresőmezőhöz
 * @param performSearch - keresést végző api kérés
 * @param request - api kérés megszakításhoz
 * @param minChars - minimális karakterek
 * @param delay - várakozás a kérés indítása előtt a következő karakter leütéséig
 * @return ?
 * @example
 *  const request = useRef<CancelablePromise<T>>();
 *
 *  const searchHandler = useCallback(
 *      (searchText: string) => {
 *          setIsLoading(true);
 *          request.current = SearchFieldService.searchAllPlayersCreate({
 *              ...
 *          });
 *          request.current.finally(
 *              () => {
 *                  setIsLoading(false);
 *                  request.current = undefined;
 *              }
 *          ).then(resp => {
 *              setSearchList(...);
 *              return resp;
 *          }).catch(error => {
 *              if (!error instanceof CancelError) {
 *                  console.error(error);
 *              }
 *          );
 *          return request.current;
 *      },
 *      [requestParams]
 *  );
 *
 *  const {
 *      search,
 *      waiting,
 *      searchValue
 *  } = useDelayedSearch<T>(searchHandler, request, 3, 1200);
 *
 *  <input
 *      type="search"
 *      value={searchValue}
 *      onChange={event => search(event.target.value)}
 *  />
 */
const useDelayedSearch = function<T>(
    performSearch: (term: string) => Promise<T> | undefined,
    request: MutableRefObject<CancelablePromise<T> | undefined>,
    minChars: number,
    delay: number
) {

    const [waiting, setWaiting] = useState(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const searchTimeoutId = useRef(0);

    useEffect(
        () => {
            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                request.current?.cancel();
                searchTimeoutId && clearTimeout(searchTimeoutId.current);
            };
        },
        [request]
    );

    const search = function(term: string) {
        setSearchValue(term);
        setWaiting(true);
        request.current?.cancel();
        searchTimeoutId && clearTimeout(searchTimeoutId.current);

        if (term.length < minChars) {
            setWaiting(false);
        }
        else {
            searchTimeoutId.current = window.setTimeout(() => {
                setWaiting(false);
                if (term.length >= minChars) {
                    performSearch(term);
                }
            }, delay);
        }
    };

    return {
        search,
        waiting,
        searchValue,
        setSearchValue
    };

};

export default useDelayedSearch;
