import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { without } from 'lodash';
import {
    ComparePlayersService, PlayerBaseData, PlayerReport, PlayerReportService, PlayerSearchData, ReportParams
} from '../../../shared/services/openapi';
import { PlayerLayerContext } from '../../../shared/contexts/PlayerLayer.context';
import { toastError } from '../../../shared/helpers/errorhandler';
import useAuth from '../../../shared/hooks/auth.hook';
import { OptionType } from '../../../shared/modules/select/select';
import { SortDirection, sortDescriptor } from '../../../shared/helpers/utility';
import { nullPlayerReport } from '../../../shared/constants';
import CompareTemplate from './CompareTemplate';

export interface PlayerReportData {
    baseData: PlayerBaseData;
    report: PlayerReport;
}

const Compare = function(): ReactElement {

    const { requestParams } = useAuth();
    const comparePlayerFromUrl = useParams();

    const layerContext = useContext(PlayerLayerContext);

    const [loadings, setLoadings] = useState<string[]>([]);
    const [players, setPlayers] = useState<OptionType<PlayerSearchData>[]>([]);
    const [teamReport, setTeamReport] = useState<PlayerReport>();
    const [comparePlayerId1, setComparePlayerId1] = useState<number>();
    const [comparePlayerId2, setComparePlayerId2] = useState<number>();
    const [comparePlayer1, setComparePlayer1] = useState<PlayerReportData>();
    const [comparePlayer2, setComparePlayer2] = useState<PlayerReportData>();
    const [reportConfig, setReportConfig] = useState<ReportParams>();

    const fetchPlayer = useCallback(
        (which: 1 | 2, playerId: number) => {
            const dispatchFunction = which === 1 ? setComparePlayer1 : setComparePlayer2;
            if (requestParams.UserID) {
                setLoadings(l => [...l, `comparePlayer${which}`]);
                return Promise.all([
                    ComparePlayersService.playerBaseDataCreate({
                        ...requestParams,
                        PlayerID: playerId
                    }),
                    ComparePlayersService.latestReportForPlayerCreate({
                        ...requestParams,
                        PlayerID: playerId
                    })
                ]).finally(
                    () => {
                        setLoadings(l => without(l, `comparePlayer${which}`));
                    }
                ).then(
                    ([playerBaseData, playerReport]) => {
                        dispatchFunction({ baseData: playerBaseData, report: playerReport });
                        return playerReport.PlayerPositionID;
                    }
                ).catch(
                    toastError
                );
            }
            else {
                return Promise.resolve();
            }
        },
        [requestParams]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'players']);
                Promise.all([
                    ComparePlayersService.allReportedPlayersCreate(requestParams),
                    PlayerReportService.reportParamsCreate(requestParams)
                ]).finally(
                    () => {
                        setLoadings(l => without(l, 'players'));
                    }
                ).then(
                    ([playerList, reportParams]) => {
                        setPlayers(
                            (playerList.Players as PlayerSearchData[]).map(
                                player => ({
                                    id: player.PlayerID,
                                    value: player.PlayerName,
                                    data: player
                                })
                            ).sort(
                                (p1: OptionType<PlayerSearchData>, p2: OptionType<PlayerSearchData>) =>
                                    sortDescriptor(p1.value, p2.value, SortDirection.asc)
                            )
                        );
                        setReportConfig(reportParams);
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    useEffect(
        () => {
            if (comparePlayerFromUrl.playerId) {
                setComparePlayerId1(+comparePlayerFromUrl.playerId);
                setComparePlayerId2(undefined);
            }
        },
        [comparePlayerFromUrl]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                if (comparePlayerId1) {
                    let passedPositionId: number | undefined;
                    fetchPlayer(
                        1, comparePlayerId1
                    ).then(
                        positionId => {
                            passedPositionId = positionId ?? undefined;
                            setLoadings(l => [...l, 'avgStat']);
                            return ComparePlayersService.ownTeamPlayerAvgReportCreate({
                                ...requestParams,
                                PositionID: passedPositionId
                            });
                        }
                    ).finally(
                        () => {
                            setLoadings(l => without(l, 'players'));
                        }
                    ).then(
                        (teamAvgReport) => {
                            setTeamReport(
                                passedPositionId ? { ...teamAvgReport.Positions[0].AvgStat, ReadOnlyFlg: false } : nullPlayerReport
                            );
                        }
                    ).catch(
                        toastError
                    );
                }

                if (comparePlayerId2) {
                    fetchPlayer(2, comparePlayerId2);
                }
                else {
                    setComparePlayer2(undefined);
                }
            }
        },
        [requestParams, comparePlayerId1, comparePlayerId2, fetchPlayer]
    );

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true
        });
    };

    return <>
        <CompareTemplate
            loadings={loadings}
            players={players}
            teamReport={teamReport}
            comparePlayer1={comparePlayer1}
            comparePlayer2={comparePlayer2}
            setComparePlayerId1={setComparePlayerId1}
            setComparePlayerId2={setComparePlayerId2}
            reportConfig={reportConfig}
            handlePlayerLayer={handlePlayerLayer}
        />
    </>;

};

export default Compare;
