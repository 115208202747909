import React, {ReactElement} from 'react';
import { Link } from 'react-router-dom';
import { translate as t } from '../../shared/helpers/translate';
import css from './NotFound.module.scss';

const NotFoundTemplate = function (): ReactElement {

    return (<>
        <div className={css.notFound}>
            <h2>{t('general.error404.text')}</h2>
            <div className={css.link}><Link to={'/scouting/overview'}>{t('general.error404.back-to-homapage')}</Link></div>
        </div>
    </>);

};

export default NotFoundTemplate;
