import React, { ReactElement, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {  translate as t } from '../../helpers/translate';
import { cx } from '../../helpers/utility';
import css from './GenericModal.module.scss';
import CloseIcon from '../../../assets/images/icons/icon_close_modal.svg';

const GenericModal = function ({
    title,
    className = '',
    isVisible = false,
    changeVisibility,
    children,
}: {
    title: string,
    className?: string,
    isVisible?: boolean,
    changeVisibility: (isVisible: boolean) => void,
    children?: React.ReactNode
}): ReactElement {

    const [visibility, setVisibility] = useState<boolean>(isVisible);

    useEffect(
        () => {
            setVisibility(isVisible);
        },
        [isVisible, setVisibility]
    );

    useEffect(
        () => {
            const onModalEscaped = function(event: KeyboardEvent) {
                if (isVisible && event.key === 'Escape') {
                    changeVisibility(false);
                }
            };

            document.addEventListener('keyup', onModalEscaped);

            return () => {
                document.removeEventListener('keyup', onModalEscaped);
            };
        },
        [changeVisibility, isVisible]
    );

    const moduleClassNames = className.split(' ').map(
        (cl: string) => css[cl]
    ).join(' ');

    const stopEvent = function(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        event.stopPropagation();
    };

    return <>
        {visibility &&
            ReactDOM.createPortal(
                <div className={css.backDrop}>
                    {/*onClick={() => changeVisibility(false)}*/}
                    <div className={cx(css.modalBox, moduleClassNames)} onClick={stopEvent}>
                        <div className={css.modalBoxHeader}>
                            <span className={css.modalTitle}>{t(title)}</span>
                            <button className={cx('btn icon', css.close)} onClick={() => changeVisibility(false)}>
                                <img src={CloseIcon} alt="close"/>
                            </button>
                        </div>
                        <div className={css.modalBoxContent}>
                            {children}
                        </div>
                    </div>
                </div>,
                document.body
            )
        }
    </>;

};

export default GenericModal;
