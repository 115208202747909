/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerSearchFull } from '../models/PlayerSearchFull';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OverviewService {

    /**
     * Get base data of player
     * @param data
     * @returns PlayerSearchFull
     * @throws ApiError
     */
    public static searchAllReportedPlayersCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<PlayerSearchFull> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SearchAllReportedPlayers/',
            body: data,
        });
    }

}
