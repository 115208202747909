import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import jsPDF from 'jspdf';
import { omit, without } from 'lodash';
import { FeatureCollection } from 'geojson';
import { ArrayOfObjects, cx, Html, uniqueArray } from '../../../shared/helpers/utility';
import { getCurrentLang, translate as t } from '../../../shared/helpers/translate';
import MultiThumbSlider from '../../../shared/modules/MultiThumbSlider/multi-thumb-slider';
import {
    Classification,
    DefaultParamRanges, FilterParams, Nationalities, PlayerSearchData,
    PlayerSearchService, ProfileIndexID, Range, SavedSearchParam, SearchParam
} from '../../../shared/services/openapi';
import TabAccordion from '../../../shared/modules/TabAccordion/TabAccordion';
import {
    FilterTabMainEnum, FilterTabSecondaryEnum
} from '../../../shared/interfaces/carried-on.interfaces';
import { PlayerFilterCard } from './PlayerFilterCard/PlayerFilterCard';
import { FradiLoader } from '../../../shared/modules/FradiLoader/FradiLoader';
import Select, { OptionType } from '../../../shared/modules/select/select';
import { GeoCountryProperties } from '../../../shared/interfaces/geo-json.interface';
import { CategorySelect } from '../../../shared/modules/CaterorySelect/CategorySelect';
import GenericModal from '../../../shared/modules/GenericModal/GenericModal';
import ConfirmModal from '../../../shared/modules/ConfirmModal/ConfirmModal';
import { toastError } from '../../../shared/helpers/errorhandler';
import PlayerSearchSaves from './PlayerSearchSaves/PlayerSearchSaves';
import useAuth from '../../../shared/hooks/auth.hook';
import { indicatorOrder, nullFilterParam } from '../../../shared/constants';
import Tooltip from '../../../shared/modules/Tooltip/Tooltip';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import { generatePdf } from '../../../shared/services/pdf-generation/pdf-generation-player-search';
import EchartsRadar from '../../../shared/modules/EchartsWrapper/EchartsRadar/EchartsRadar';
import { CustomGeoCollection, GeoCustomProperties } from '../../../assets/geojson/gb/customGeoCollection';
import geoJson from './../../../assets/geojson/mediumgeo.json';
import css from './PlayerFilter.module.scss';
import Clipboard from '../../../assets/images/icons/icon_file_clipboard.svg';
import ClipboardCheck from '../../../assets/images/icons/icon_file_clipboard_check.svg';
import PitchPlaceholder from '../../../assets/images/football_field.svg';

const geoData: GeoCountryProperties[] = [
    ...(geoJson as FeatureCollection).features,
    ...CustomGeoCollection.features
].map(
    feature => feature.properties as GeoCountryProperties
);

const PlayerFilter = function (): ReactElement {

    const RANGE = 'Range';
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const playerSvg = useRef<Map<number, SVGSVGElement>>(new Map());
    const { account, requestParams } = useAuth();
    const [loadings, setLoadings] = useState<string[]>([]);
    const [filteredPlayers, setFilteredPlayers] = useState<PlayerSearchData[]>();
    const [filterConfig, setFilterConfig] = useState<FilterParams>();
    const [filterParams, setFilterParams] = useState<SearchParam>({...nullFilterParam});
    const [defaultFilterParams, setDefaultFilterParams] = useState<SearchParam>({...nullFilterParam});
    const fullParamList = useMemo(() =>
        uniqueArray<ProfileIndexID>(
            filterConfig?.ProfileIndex?.flatMap(
                i => i?.ProfileIndexList
            ).flatMap(
                i => i?.ProfileIndexIDList
            ).filter(
                i => !!i
            ) as ProfileIndexID[] ?? [],
            'ProfileIndexID'
        ), [filterConfig]
    );
    const [optionNationalitiesList, setOptionNationalitiesList] = useState<OptionType[]>([]);
    const [optionPositionList, setOptionPositionList] = useState<OptionType[]>([]);
    const [optionProfileList, setOptionProfileList] = useState<OptionType[]>([]);
    const [isListModalVisible, setIsListModalVisible] = useState<boolean>(false);
    const [isSaveModalVisible, setIsSaveModalVisible] = useState<boolean>(false);
    const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState<boolean>(false);
    const [saveAsName, setSaveAsName] = useState<string>('');
    const [loadedSearch, setLoadedSearch] = useState<SavedSearchParam | null>(null);
    const [dropDownOpened, setDropDownOpened] = useState<boolean>(false);

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'filters']);
                PlayerSearchService.filterParamsCreate(
                    requestParams
                ).finally(
                    () => setLoadings(l => without(l, 'filters'))
                ).then(filterParams => {
                    setFilterConfig(filterParams);
                    setFilterParams(filterParams.DefaultParams);
                    setDefaultFilterParams(filterParams.DefaultParams);
                }).catch(
                    toastError
                );
            }
        },
        [requestParams]
    );

    useEffect(
        () => {
            if (!filterConfig) {
                return;
            }
            const missingList: Nationalities[] = [];
            setOptionNationalitiesList(filterConfig.Nationalities
                ? uniqueArray<Nationalities>(
                    filterConfig.Nationalities, 'NationalityID'
                ).map<OptionType>(
                    item => {
                        const propData = geoData.find(
                            data => data.adm0_iso === item.NationalityID
                        );
                        if (!propData) {
                            if (item.NationalityID) {
                                missingList.push(item);
                            }
                            // console.warn('missing!', item.NationalityID, item, missingList);
                        }
                        const label = propData ?
                            propData['name_' + getCurrentLang().toString() as keyof GeoCustomProperties] ?? propData.name :
                            item.NationalityID;
                        return {
                            id: item.NationalityID,
                            value: String(label)
                        };
                    }
                ).sort(
                    (a, b) => a.value < b.value ? -1 : 1
                )
                : []
            );
            if (filterConfig.PositionProfileMap) {
                setOptionPositionList(
                    filterConfig.PositionProfileMap.map(
                        pp => ({
                            id: pp.PositionID,
                            value: String(pp.PositionID)
                        })
                    )
                );
            }
        },
        [filterConfig]
    );

    useEffect(
        () => {
            const profileMap = filterConfig?.PositionProfileMap?.find(
                item => item.PositionID === filterParams.PlayerPositionID
            );
            if (profileMap && profileMap.ProfileIDList) {
                setOptionProfileList(
                    profileMap.ProfileIDList.map<OptionType>(
                        item => ({
                            id: item.ProfileID,
                            value: t('general.profile.' + item.ProfileID)
                        })
                    )
                );
            } else  {
                setOptionProfileList([]);
            }
        },
        [filterConfig?.PositionProfileMap, filterParams.PlayerPositionID]
    );

    const tabMainList = Object.values(FilterTabMainEnum);
    const tabSecondaryList = Object.values(FilterTabSecondaryEnum);

    const filterPlayers = function() {
        if (!requestParams.UserID || !filterParams.PlayerPositionID) {
            return;
        }
        const paramList = filterConfig?.ProfileIndex?.find(
            position => position.PositionID === filterParams.PlayerPositionID
        )?.ProfileIndexList?.flatMap(
            i => i.ProfileIndexIDList
        );
        const difference = fullParamList.filter(
            param => paramList?.every(
                item => item?.ProfileIndexID !== param.ProfileIndexID
            )
        );
        const flatFilterParam = Object.fromEntries(Object.entries(filterParams).map(
            ([key, value]) => [
                key,
                difference.some(
                    d => d.ProfileIndexID + 'Range' === key
                ) ? null : value
            ]
        ));
        setLoadings(l => [...l, 'search']);
        PlayerSearchService.searchReportedPlayersCreate({
            ...requestParams,
            SearchParams: flatFilterParam,
        }).finally(
            () => setLoadings(l => without(l, 'search'))
        ).then(resp => {
            setFilteredPlayers(resp.Players);
        }).catch(
            toastError
        );
    };

    const toggleDropDown = function() {
        setDropDownOpened(current => !current);
    };

    const loadSearch = function(params: SavedSearchParam) {
        setLoadedSearch(params);
        setFilterParams({
            ...defaultFilterParams,
            ...omit(params, ['SearchID', 'SearchName', 'UserName', 'Date'])
        });
        setIsListModalVisible(false);
    };

    const resetSearch = function() {
        setLoadedSearch(null);
        setFilterParams({ ...defaultFilterParams });
    };

    const overwriteCurrentSearch = function() {
        return PlayerSearchService.savePlayerSearchCreate({
            ...requestParams,
            SearchParams: {
                ...filterParams,
                SearchID: loadedSearch?.SearchID,
                SearchName: loadedSearch?.SearchName
            }
        }).then(
            _resp => {
                return;
            }
        ).catch(
            toastError
        );
    };

    const saveCurrentSearch = function(): Promise<number | void> {
        if (inputRef.current && buttonRef.current) {
            inputRef.current.disabled = true;
            buttonRef.current.disabled = true;
        }
        setLoadings(l => [...l, 'save']);
        return PlayerSearchService.savedPlayerSearchesCreate(
            requestParams
        ).finally(
            () => setLoadings(l => without(l, 'save'))
        ).then(playerSearches => {
            if ((playerSearches.SavedSearches ?? []).find(item => item.SearchName === saveAsName)) {
                Toastify({
                    titleKey: t('scouting.player-filter.errors.rename.already-used')
                }).warning();
                return null;
            }
            else {
                return PlayerSearchService.savePlayerSearchCreate({
                    ...requestParams,
                    SearchParams: {
                        ...filterParams,
                        SearchName: saveAsName
                    }
                });
            }
        }).then(
            searchParams => {
                if (searchParams) {
                    loadSearch({
                        ...searchParams,
                        SearchName: saveAsName,
                        UserName: account?.name ?? '',
                        Date: ''
                    });
                    setIsSaveAsModalVisible(false);
                    setSaveAsName('');
                    Toastify({
                        titleKey: t('scouting.player-filter.save-success')
                    }).success();
                    return searchParams.SearchID;
                }
            }
        ).catch(
            toastError
        );
    };

    const putSvg = function(player: PlayerSearchData, elem: SVGSVGElement | null | undefined) {
        if (elem) {
            playerSvg.current.set(player.PlayerID, elem);
        }
    };

    const savePdf = async function(): Promise<void> {
        if (filteredPlayers && filteredPlayers.length > 0 && filterParams.PlayerPositionID) {
            setLoadings(l => [...l, 'pdf']);
            generatePdf({
                filterParams,
                filterConfig: filterConfig!,
                nationalities: optionNationalitiesList,
                players: filteredPlayers || [],
                charts: playerSvg.current,
                additionalData: {
                    label: loadedSearch ? loadedSearch.SearchName : undefined,
                    date: loadedSearch ? loadedSearch.Date : undefined,
                    username: loadedSearch ? loadedSearch.UserName : account?.name
                }
            }).finally(
                () => setLoadings(l => without(l, 'pdf'))
            ).then(
                (pdf: jsPDF) => {
                    pdf.save('Player_search.pdf');
                }
            ).catch(
                (error: Error) => {
                    Toastify({
                        titleKey: 'general.message.error.pdf-generation.player-search-failed'
                    }).error();
                    console.error(error);
                }
            );
        }
        else {
            Toastify({
                titleKey: 'general.message.error.pdf-generation.player-search-empty'
            }).warning();
        }
    };

    return <>
        <div className={cx('contentContainer', css.playerFilter)}>
            <div className="contentHeader">
                <h1>{t('scouting.player-filter.title')}</h1>
                {loadedSearch && (
                    <Tooltip text={loadedSearch.SearchName} ifOverflow={true}>
                        <h3>{loadedSearch.SearchName}</h3>
                    </Tooltip>
                )}
                <div className="buttonsAndFilters">
                    <button
                        className={
                            cx(
                                'btn btn-secondary btn-outline',
                                css.filterPageButton,
                                loadings.includes('pdf') ? css.loading : null
                            )
                        }
                        onClick={savePdf}
                    >
                        <img src={Clipboard} alt=""/>
                        {t('scouting.player-filter.export')}
                    </button>
                    <button
                        className={cx('btn btn-danger btn-outline', css.filterPageButton)}
                        onClick={() => resetSearch()}
                    >
                        {t('scouting.player-filter.reset')}
                    </button>
                    <button
                        onClick={() => setIsListModalVisible(true)}
                        className={cx('btn btn-primary btn-outline', css.filterPageButton)}
                    >
                        {t('scouting.player-filter.saved-searches')}
                    </button>
                    <button className={cx('btn btn-secondary btn-outline', css.dropdownToggle)} onClick={() => toggleDropDown()}>
                        {t('scouting.player-filter.filter-save')}
                        {dropDownOpened && (
                            <div className={css.dropdownMenu}>
                                <a
                                    className={cx(css.dropdownItem, loadedSearch ? '' : css.disabled)}
                                    onClick={() => setIsSaveModalVisible(true)}
                                >
                                    {t('scouting.player-filter.save')}
                                </a>
                                <a
                                    className={css.dropdownItem}
                                    onClick={() => setIsSaveAsModalVisible(true)}
                                >
                                    {t('scouting.player-filter.save-as')}
                                </a>
                            </div>
                        )}
                    </button>
                    <button
                        onClick={filterPlayers}
                        className={cx('btn btn-primary btn-outline', css.filterPageButton)}
                    >
                        <img src={ClipboardCheck} alt=""/>
                        {t('scouting.player-filter.refresh')}
                    </button>
                </div>
            </div>

            <div className={css.playerFilterContent}>
                {filterConfig && <>
                    <div className={css.filterContainer}>
                        <div className={css.filterSettings}>

                            <div className={css.tabContainer}>
                                <TabAccordion<FilterTabMainEnum>
                                    tabs={tabMainList}
                                    preTag={'tab.filter.'}
                                    defaultTab={FilterTabMainEnum.DEFAULT}
                                >
                                    {(selectedTab) => <>
                                        {selectedTab === FilterTabMainEnum.DEFAULT && <>
                                            <div className={cx(css.content)}>
                                                <div className={css.postAndProfile}>
                                                    <Select
                                                        // hideLabelWhenSelected={true}
                                                        isSoloLabel={true}
                                                        options={optionPositionList}
                                                        changeSelected={
                                                            (selectedOptions) => setFilterParams(
                                                                params => ({...params, PlayerPositionID: +selectedOptions[0]?.id || 0})
                                                            )
                                                        }
                                                        selectedOptions={
                                                            optionPositionList.filter(
                                                                item => item.id === filterParams.PlayerPositionID
                                                            )
                                                        }
                                                        label={t('playerDetails.label.post')}
                                                    ></Select>
                                                    <Select
                                                        // hideLabelWhenSelected={true}
                                                        disabled={!filterParams.PlayerPositionID}
                                                        isSoloLabel={true}
                                                        options={optionProfileList}
                                                        changeSelected={
                                                            (selectedOptions) => setFilterParams(
                                                                params => ({
                                                                    ...params,
                                                                    PlayerProfileID: (
                                                                        selectedOptions[0] ? String(selectedOptions[0]?.id) : ''
                                                                    )
                                                                })
                                                            )
                                                        } // todo: set to null
                                                        selectedOptions={
                                                            optionProfileList.filter(
                                                                item => item.id === filterParams.PlayerProfileID
                                                            )
                                                        }
                                                        label={t('playerDetails.label.profile')}
                                                        clearable={true}
                                                    ></Select>
                                                </div>
                                                <div className={css.otherFilters}>
                                                    <div className={css.filterDiv}>
                                                        <Select
                                                            // hideLabelWhenSelected={true}
                                                            multiple={true}
                                                            filter={true}
                                                            options={optionNationalitiesList}
                                                            changeSelected={
                                                                (selectedOptions) => setFilterParams(
                                                                    params => ({
                                                                        ...params,
                                                                        Nationality: (
                                                                            selectedOptions.length
                                                                                ? selectedOptions.map(
                                                                                    option => ({
                                                                                        NationalityID: String(option.id)
                                                                                    })
                                                                                )
                                                                                : []
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                            selectedOptions={
                                                                optionNationalitiesList.filter(
                                                                    item => (filterParams.Nationality ?? []).some(
                                                                        nation => nation.NationalityID === item.id
                                                                    )
                                                                )
                                                            }
                                                            className={css.firstRow}
                                                            label={t('playerDetails.label.nationality')}
                                                        ></Select>

                                                        <div className={css.categorySelect}>
                                                            <span className={css.lineLabel}>
                                                                {t('playerReport.label.category')}
                                                            </span>
                                                            <CategorySelect
                                                                values={ArrayOfObjects.takeOne(filterParams.ClassificationID ?? [])}
                                                                multiple={true}
                                                                onSelect={
                                                                    (categories) => setFilterParams(
                                                                        params => ({
                                                                            ...params,
                                                                            ClassificationID: ArrayOfObjects.reverseTakeOne(
                                                                                categories, 'ClassificationID'
                                                                            ) as Classification[]
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className={css.filterDiv}>
                                                        <MultiThumbSlider
                                                            label={t('playerDetails.label.minutes')}
                                                            minRange={filterConfig?.ParamRanges.MinutesPlayedRange.min}
                                                            maxRange={filterConfig?.ParamRanges.MinutesPlayedRange.max}
                                                            maxValue={filterParams.MinutesPlayedMin}
                                                            inverted={true}
                                                            soloThumb={true}
                                                            changedValue={
                                                                (range) => setFilterParams(
                                                                    params => ({...params, MinutesPlayedMin: range.max})
                                                                )
                                                            }
                                                        />
                                                        <MultiThumbSlider
                                                            label={t('playerDetails.label.age')}
                                                            minRange={filterConfig?.ParamRanges.AgeRange.min}
                                                            maxRange={filterConfig?.ParamRanges.AgeRange.max}
                                                            minValue={(filterParams.AgeRange).min}
                                                            maxValue={(filterParams.AgeRange).max}
                                                            inverted={true}
                                                            changedValue={
                                                                (range) => setFilterParams(
                                                                    params => ({...params, AgeRange: range})
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {selectedTab === FilterTabMainEnum.INDEX &&
                                            <div className={css.sliders}>
                                                {indicatorOrder.map(indicator =>
                                                    <MultiThumbSlider
                                                        key={indicator}
                                                        label={'general.indicator.' + indicator}
                                                        minRange={0}
                                                        maxRange={100}
                                                        minValue={(filterParams[(indicator + RANGE) as keyof SearchParam] as Range)?.min}
                                                        maxValue={(filterParams[(indicator + RANGE) as keyof SearchParam] as Range)?.max}
                                                        changedValue={
                                                            (range) => setFilterParams(
                                                                params => ({...params, [indicator + RANGE]: range})
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        }

                                        <div className={css.sliders}>
                                            {!!filterParams.PlayerPositionID &&
                                                filterConfig?.ProfileIndex?.find(
                                                    profile => profile.PositionID === filterParams.PlayerPositionID
                                                )?.ProfileIndexList?.find(
                                                    item => item.ProfileIndexGroupID === selectedTab
                                                )?.ProfileIndexIDList?.map(
                                                    item => item.ProfileIndexID
                                                ).map(
                                                    indicator =>
                                                        <MultiThumbSlider
                                                            key={indicator}
                                                            label={'general.indicator.' + indicator}
                                                            minRange={
                                                                // eslint-disable-next-line max-len
                                                                filterConfig?.ParamRanges[indicator as keyof DefaultParamRanges]?.min ?? filterConfig?.ParamRanges.DefaultRange.min
                                                            }
                                                            maxRange={
                                                                // eslint-disable-next-line max-len
                                                                filterConfig?.ParamRanges[indicator as keyof DefaultParamRanges]?.max ?? filterConfig?.ParamRanges.DefaultRange.max
                                                            }
                                                            minValue={
                                                                (filterParams[(indicator + RANGE) as keyof SearchParam] as Range)?.min
                                                            }
                                                            maxValue={
                                                                (filterParams[(indicator + RANGE) as keyof SearchParam] as Range)?.max
                                                            }
                                                            changedValue={
                                                                (range) => setFilterParams(
                                                                    params => ({...params, [indicator + RANGE]: range})
                                                                )
                                                            }
                                                        />
                                                )
                                            }
                                        </div>

                                        {(
                                            !filterParams.PlayerPositionID &&
                                            selectedTab !== FilterTabMainEnum.DEFAULT &&
                                            selectedTab !== FilterTabMainEnum.INDEX
                                        ) &&
                                            <div className={cx(css.content, css.empty)}>
                                                <div>
                                                    <img src={PitchPlaceholder} alt=""/>
                                                    <p className={css.emptyStateText}>{t('scouting.short-list.empty-filter-text')}</p>
                                                </div>
                                            </div>
                                        }
                                    </>}
                                </TabAccordion>
                            </div>

                            <div className={css.tabContainer}>
                                <TabAccordion<FilterTabSecondaryEnum>
                                    tabs={tabSecondaryList}
                                    preTag={'tab.filter.'}
                                    defaultTab={FilterTabSecondaryEnum.DEFENDING}
                                >
                                    {(selectedTab) => (
                                        <div className={cx(css.content, !filterParams.PlayerPositionID ? css.empty : null)}>

                                            <div className={css.sliders}>
                                                {!!filterParams.PlayerPositionID &&
                                                    filterConfig?.ProfileIndex?.find(
                                                        profile => profile.PositionID === filterParams.PlayerPositionID
                                                    )?.ProfileIndexList?.find(
                                                        item => item.ProfileIndexGroupID === selectedTab
                                                    )?.ProfileIndexIDList?.map(
                                                        item => item.ProfileIndexID
                                                    ).map(
                                                        indicator =>
                                                            <MultiThumbSlider
                                                                key={indicator}
                                                                label={'general.indicator.' + indicator}
                                                                minRange={
                                                                    // eslint-disable-next-line max-len
                                                                    filterConfig?.ParamRanges[indicator as keyof DefaultParamRanges]?.min ?? filterConfig?.ParamRanges.DefaultRange.min
                                                                }
                                                                maxRange={
                                                                    // eslint-disable-next-line max-len
                                                                    filterConfig?.ParamRanges[indicator as keyof DefaultParamRanges]?.max ?? filterConfig?.ParamRanges.DefaultRange.max
                                                                }
                                                                minValue={
                                                                    (filterParams[(indicator + RANGE) as keyof SearchParam] as Range)?.min
                                                                }
                                                                maxValue={
                                                                    (filterParams[(indicator + RANGE) as keyof SearchParam] as Range)?.max
                                                                }
                                                                changedValue={
                                                                    (range) => setFilterParams(
                                                                        params => ({...params, [indicator + RANGE]: range})
                                                                    )
                                                                }
                                                            />
                                                    )
                                                }
                                            </div>

                                            {!filterParams.PlayerPositionID &&
                                                <div>
                                                    <img src={PitchPlaceholder} alt=""/>
                                                    <p className={css.emptyStateText}>{t('scouting.short-list.empty-filter-text')}</p>
                                                </div>
                                            }
                                        </div>
                                    )}
                                </TabAccordion>
                            </div>
                        </div>
                    </div>

                    <div className={cx(css.playerList, !filteredPlayers?.length ? css.empty : css.listed)}>
                        {!filteredPlayers && (
                            <p className={css.emptyStateText}><Html>{t('scouting.player-filter.empty-list-text')}</Html></p>
                        )}
                        {filteredPlayers && filteredPlayers.length === 0 && (
                            <p className={css.emptyStateText}><Html>{t('scouting.player-filter.no-results')}</Html></p>
                        )}

                        <SimpleBar style={{ minHeight: '100%', maxHeight: '100%' }} autoHide={false}>
                            <div className={css.listContainer}>
                                {filteredPlayers?.map(player =>
                                    <PlayerFilterCard key={player.PlayerID} player={player}/>
                                )}
                                <div hidden>
                                    {filteredPlayers?.map(player => (
                                        <EchartsRadar
                                            key={player.PlayerID}
                                            showLabel={false}
                                            size={{ width: 80, height: 80 }}
                                            dataList={player}
                                            order={indicatorOrder}
                                            dotSize={5}
                                            showTicks={false}
                                            createSvg={(elem) => putSvg(player, elem)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </SimpleBar>

                        <FradiLoader visible={loadings.includes('search')}/>
                    </div>
                </>}

                <FradiLoader visible={loadings.includes('filers')}/>
            </div>
        </div>

        <GenericModal
            isVisible={isListModalVisible}
            title={'scouting.player-filter.saved.list'}
            changeVisibility={() => setIsListModalVisible(false)}
        >
            <PlayerSearchSaves
                loadSearchCallback={loadSearch}
            />
        </GenericModal>

        <ConfirmModal
            isVisible={isSaveModalVisible}
            text={'scouting.player-filter.saved.save'}
            changeVisibility={() => setIsSaveModalVisible(false)}
            callback={overwriteCurrentSearch}
        />

        <GenericModal
            className='saveAsBox'
            isVisible={isSaveAsModalVisible}
            title={'scouting.player-filter.saved.save-as'}
            changeVisibility={() => setIsSaveAsModalVisible(false)}
        >
            <div className={css.saveAsContainer}>
                <input
                    ref={inputRef}
                    className={css.saveAsInput}
                    type="text"
                    placeholder={t('scouting.player-filter.saved.save-as-name')}
                    value={saveAsName}
                    maxLength={50}
                    onChange={event => setSaveAsName(event.target.value)}
                />
                <button
                    ref={buttonRef}
                    className={cx(css.saveAsButton, 'btn btn-primary')}
                    disabled={saveAsName.length === 0}
                    onClick={() => saveCurrentSearch()}
                >
                    {t('general.button.save')}
                </button>
            </div>
        </GenericModal>
    </>;

};

export default PlayerFilter;
