import { useState } from 'react';
import { ProfileGroupTypes } from '../interfaces/carried-on.interfaces';
import { RadarConfig } from '../modules/EchartsWrapper/EchartsRadar/EchartsRadar';
import { PlayerReport, ReportParams } from '../services/openapi';
import { indicatorOrder } from '../constants';

const usePlayerProfile = function() {

    const [orderCollection, setOrderCollection] = useState<Record<string, string[]>>({
        ['all']: indicatorOrder,
        [ProfileGroupTypes.PHYSIQUE]: [],
        [ProfileGroupTypes.MENTALITY]: [],
        [ProfileGroupTypes.TECHNICAL]: [],
        [ProfileGroupTypes.DEFENDING]: [],
        [ProfileGroupTypes.ATTACKING]: [],
    });

    const chartCollectionConfig: Record<string, RadarConfig<PlayerReport>> = {
        ['all']: {},
        [ProfileGroupTypes.PHYSIQUE]: {
            preKey: 'general.indicator.',
            modifiedValue: (data, key) => data ? Number(data[key]) * 10 : 0
        },
        [ProfileGroupTypes.MENTALITY]: {
            preKey: 'general.indicator.',
            modifiedValue: (data, key) => data ? Number(data[key]) * 10 : 0
        },
        [ProfileGroupTypes.TECHNICAL]: {
            preKey: 'general.indicator.',
            modifiedValue: (data, key) => data ? Number(data[key]) * 10 : 0
        },
        [ProfileGroupTypes.DEFENDING]: {
            preKey: 'general.indicator.',
            modifiedValue: (data, key) => data ? Number(data[key]) * 10 : 0
        },
        [ProfileGroupTypes.ATTACKING]: {
            preKey: 'general.indicator.',
            modifiedValue: (data, key) => data ? Number(data[key]) * 10 : 0
        },
    };

    const getIndicators = function(PlayerPositionID: number, reportConfig: ReportParams) {
        if (reportConfig.ProfileIndex) {
            const position = reportConfig.ProfileIndex.find(
                item => item.PositionID === PlayerPositionID
            );
            if (position && position.ProfileIndexList) {
                return Object.fromEntries(
                    Object.keys(orderCollection).map(
                        key => [
                            key,
                            (position.ProfileIndexList?.find(
                                group => group.ProfileIndexGroupID === key
                            ))?.ProfileIndexIDList?.map(
                                item => item.ProfileIndexID
                            ) ?? orderCollection[key]
                        ]
                    )
                );
            }
            else {
                return orderCollection;
            }
        }
        else {
            return orderCollection;
        }
    };

    return {
        orderCollection,
        setOrderCollection,
        chartCollectionConfig,
        getIndicators
    };

};

export default usePlayerProfile;
