import React, { ReactElement, useContext } from 'react';
import { cx } from '../../helpers/utility';
import { CategoryEnum } from '../../interfaces/reports.interface';
import { PlayerLayerContext } from '../../contexts/PlayerLayer.context';
import { AreaContext } from '../../contexts/Area.context';
import { PlayerSearchData } from '../../services/openapi';
import { indicatorOrder } from '../../constants';
import EchartsRadar from '../EchartsWrapper/EchartsRadar/EchartsRadar';
import ListButton from '../ListButtons/ListButton';
import css from './OverviewPlayerCard.module.scss';

interface OverviewPlayerCardProps {
    player: PlayerSearchData,
    inShortList?: boolean,
    changeInShortList?: (inList: boolean) => void,
    isSelected?: boolean
    category?: CategoryEnum,
}

const OverviewPlayerCard = function({
    player,
    inShortList,
    changeInShortList,
    isSelected,
    category
}: OverviewPlayerCardProps): ReactElement {

    const layerContext = useContext(PlayerLayerContext);
    const areaContext = useContext(AreaContext);

    function handlePlayerLayer() {
        layerContext.setContext({
            playerId: player.PlayerID,
            isModalOpen: true,
            closeCallback: areaContext.context
                ? () => areaContext.context && areaContext.setContext({
                    ...areaContext.context, isModalOpen: true
                })
                : undefined
        });
        if (areaContext.context) {
            areaContext.setContext({ ...areaContext.context, isModalOpen: false });
        }
    }

    return <>
        <div className={cx(css.playerCardBox, isSelected ? css.selected : null)}>
            {/*
            <div className={style.checkBox}>
                <input type={'checkbox'}/>
                {player.id}
            </div>
            */}
            <div className={css.imageBox}>
                <img
                    className={cx(
                        css.category,
                        css['category-' + (Object.keys(CategoryEnum)[
                            Object.values(CategoryEnum).indexOf(category as unknown as CategoryEnum)
                        ] ?? 'none')]
                    )}
                    src={player.PlayerImageUrl}
                    alt="profile"
                />
            </div>
            <div className={css.nameBox}>
                <span onClick={() => handlePlayerLayer()}>{player.PlayerName}</span>
            </div>
            <div className={css.yearBox}>
                {player.Birthday}
            </div>
            <div className={css.teamBox}>
                {player.TeamImageUrl && <img src={player.TeamImageUrl} alt=""/>}
                {player.TeamName}
            </div>
            <div className={css.postBox}>
                {player.PlayerPositionID}
            </div>
            <div className={css.minutesBox}>
                {player.MinutesPlayed}
            </div>
            <div className={css.chartContainer}>
                <EchartsRadar
                    showLabel={false}
                    size={{ width: 80, height: 80 }}
                    dataList={player}
                    order={indicatorOrder}
                    dotSize={5}
                    showTicks={false}
                />
            </div>
            {inShortList !== undefined && changeInShortList && <>
                <div className={css.actionBox}>
                    <ListButton
                        type="shortList"
                        playerId={player.PlayerID}
                        playerInList={inShortList}
                        onToggle={(inList) => changeInShortList(inList!)}
                    />
                </div>
            </>}
        </div>
    </>;

};

export default OverviewPlayerCard;
