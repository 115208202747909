import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { without, uniqWith, isEqual } from 'lodash';
import { PlayerLayerContext } from '../contexts/PlayerLayer.context';
import { toastError } from '../helpers/errorhandler';
import { SortDirection, sortDescriptor } from '../helpers/utility';
import { OptionType } from '../modules/select/select';
import { PlayerSearchData, SearchPlayer, ShadowTeamService } from '../services/openapi';
import useAuth from './auth.hook';
import { CategoryEnum, CategoryListOrder } from '../interfaces/reports.interface';

export interface SortingDescriptor {
    column: keyof PlayerSearchData;
    direction: SortDirection;
}

export const playerSort = function(
    pl1: PlayerSearchData | SearchPlayer, pl2: PlayerSearchData | SearchPlayer
) {
    return sortDescriptor(pl1.PlayerName, pl2.PlayerName, SortDirection.asc);
};

const useShortList = function(
    defaultSort: SortingDescriptor = { column: 'ReportDate', direction: SortDirection.desc }
) {

    const { requestParams } = useAuth();
    const layerContext = useContext(PlayerLayerContext);

    const [loadings, setLoadings] = useState<string[]>([]);
    const [shortList, setShortList] = useState<PlayerSearchData[]>([]);
    const [filteredShortList, setFilteredShortList] = useState<PlayerSearchData[]>([]);
    const [selfList, setSelfList] = useState<PlayerSearchData[]>([]);
    const [filteredSelfList, setFilteredSelfList] = useState<PlayerSearchData[]>([]);
    const [optionPosition, setOptionPosition] = useState<OptionType[]>([]);
    const [selectedPosition, setSelectedPosition] = useState<number>(0);
    const [optionCategory, setOptionCategory] = useState<OptionType[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sorting, setSorting] = useState<SortingDescriptor>(defaultSort);

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'shortList']);
                Promise.all([
                    ShadowTeamService.shortlistCreate(requestParams),
                    ShadowTeamService.ownTeamPlayersCreate(requestParams)
                ]).finally(
                    () => {
                        setLoadings(l => without(l, 'shortList'));
                    }
                ).then(
                    ([flatShortList, flatSelfList]) => {
                        if (flatShortList.Players && flatSelfList.Players) {
                            const positions = uniqWith(
                                [...flatShortList.Players, ...flatSelfList.Players].map(
                                    player => ({
                                        id: player.PlayerPositionID,
                                        value: String(player.PlayerPositionID)
                                    })
                                ),
                                isEqual
                            ).sort(
                                (pos1: OptionType, pos2: OptionType) => sortDescriptor(pos1.id, pos2.id, SortDirection.asc)
                            );
                            setOptionPosition(positions);

                            const categories = uniqWith(
                                [...flatShortList.Players, ...flatSelfList.Players].map(
                                    player => ({
                                        id: player.ClassificationID,
                                        value: String(player.ClassificationID)
                                    })
                                ),
                                isEqual
                            ).filter(
                                category => !!category.id
                            ).sort(
                                (cat1: OptionType, cat2: OptionType) => {
                                    const a = CategoryListOrder.indexOf(cat1.id as CategoryEnum);
                                    const b = CategoryListOrder.indexOf(cat2.id as CategoryEnum);
                                    return sortDescriptor(a, b, SortDirection.desc);
                                }
                            );
                            setOptionCategory(categories);

                            setShortList(flatShortList.Players.sort(playerSort) ?? []);
                            setSelfList(flatSelfList.Players.sort(playerSort) ?? []);
                            setSelectedPosition(0);
                            if (positions.length > 0) {
                                setFilteredShortList((flatShortList.Players ?? []).filter(
                                    player => positions[0].id === 0 || player.PlayerPositionID === positions[0].id
                                ).sort(playerSort));
                                setFilteredSelfList((flatSelfList.Players ?? []).filter(
                                    player => positions[0].id === 0 || player.PlayerPositionID === positions[0].id
                                ).sort(playerSort));
                            }
                        }
                    }
                ).catch(
                    toastError
                );
            }
        },
        [requestParams, layerContext.context]
    );

    const sortingList = useCallback(
        (p1: PlayerSearchData, p2: PlayerSearchData): number => {
            if (sorting.column === 'ClassificationID') {
                const a = CategoryListOrder.indexOf(p1.ClassificationID as CategoryEnum);
                const b = CategoryListOrder.indexOf(p2.ClassificationID as CategoryEnum);
                return sortDescriptor(a, b, sorting.direction);
            }
            else {
                return sortDescriptor(
                    p1[sorting.column as keyof PlayerSearchData],
                    p2[sorting.column as keyof PlayerSearchData],
                    sorting.direction
                );
            }
        },
        [sorting.column, sorting.direction]
    );

    useEffect(
        () => {
            setFilteredShortList(shortList.filter(
                player => (
                    (selectedPosition === 0 || player.PlayerPositionID === selectedPosition) &&
                    (selectedCategory === '' || player.ClassificationID === selectedCategory) &&
                    (player.PlayerName.toLowerCase()).includes(searchTerm.toLowerCase())
                )
            ).sort(
                sortingList
            ));
            setFilteredSelfList(selfList.filter(
                player => (
                    (selectedPosition === 0 || player.PlayerPositionID === selectedPosition) &&
                    (selectedCategory === '' || player.ClassificationID === selectedCategory) &&
                    (player.PlayerName.toLowerCase()).includes(searchTerm.toLowerCase())
                )
            ).sort(
                sortingList
            ));
        },
        [searchTerm, selectedCategory, selectedPosition, selfList, shortList, sortingList]
    );

    const changeSearchTerm = function(event: ChangeEvent<HTMLInputElement>) {
        setSearchTerm(event.target.value);
    };

    const changeSorting = function(column: keyof PlayerSearchData) {
        if (sorting.column === column) {
            setSorting({
                column,
                direction: sorting.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc
            });
        }
        else {
            setSorting({
                column,
                direction: SortDirection.asc
            });
        }
    };

    return {
        loadings,
        setLoadings,
        shortList,
        selfList,
        filteredShortList,
        setFilteredShortList,
        filteredSelfList,
        setFilteredSelfList,
        optionPosition,
        selectedPosition,
        setSelectedPosition,
        optionCategory,
        selectedCategory,
        setSelectedCategory,
        searchTerm,
        changeSearchTerm,
        sorting,
        changeSorting
    };

};

export default useShortList;
