import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { ToastContainer } from 'react-toastify';
import { translate as t } from './shared/helpers/translate';
import Header from './shared/modules/header/Header';
import { HeaderContext, HeaderContextProps } from './shared/contexts/Header.context';
import { OpenAPI } from './shared/services/openapi';
import { FradiLoader } from './shared/modules/FradiLoader/FradiLoader';
import { b2cPolicies } from './authConfig';
import { getCurrentLang, LanguageEnum, setCurrentLang } from './shared/helpers/translate';
import { toastError } from './shared/helpers/errorhandler';
import { PlayerLayerContext, PlayerLayerContextProps } from './shared/contexts/PlayerLayer.context';
import GenericModal from './shared/modules/GenericModal/GenericModal';
import SearchModal from './shared/modules/header/SearchModal';
import css from './AppTheme.module.scss';

function AppTheme() {

    const { instance } = useMsal();
    const [headerContext, setHeaderContext] = useState<HeaderContextProps>();
    const [playerIdentity, setPlayerIdentity] = useState<PlayerLayerContextProps>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    let callbackId: string | null;

    useEffect(
        () => {
            setHeaderContext({
                searchClickCallback: openSearchModal,
            });
        },
        []
    );

    function openSearchModal() {
        setIsModalVisible(true);
    }

    function closeModal() {
        setIsModalVisible(false);
        setPlayerIdentity(context => {
            if (context?.isSearchModalOpen) {
                return {
                    ...context,
                    isSearchModalOpen: undefined
                };
            }
            return context;
        });
    }

    function openPlayerDetails(playerId: number) {
        setIsModalVisible(false);
        setPlayerIdentity({
            playerId: playerId,
            isModalOpen: true,
            closeCallback: () => undefined
        });
    }

    useLayoutEffect(
        () => {
            OpenAPI.BASE = process.env.REACT_APP_APIURL ?? ''; // 'http://localhost:8000/API/POST'
        },
        [navigate]
    );

    useEffect(
        () => {
            window.addEventListener('unhandledrejection', genericErrorHandler);

            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
            }

            if (!callbackId) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                callbackId = instance.addEventCallback(
                    (event: EventMessage) => {
                        if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
                            const account = (event.payload as AuthenticationResult).account;
                            instance.setActiveAccount(account);
                        }
                    }
                );
                // handle auth redired/do all initial setup for msal
                instance.handleRedirectPromise().then(authResult => {
                    if (authResult?.account) {
                        instance.setActiveAccount(authResult.account);
                    }
                    setLangKey(authResult?.account?.idTokenClaims?.['extension_ChosenLanguage'] as string);

                    const account = instance.getActiveAccount();
                    if (!account) {
                        instance.loginRedirect();
                    }
                }).catch(err => {
                    // TODO: Handle errors
                    if ((err as AuthError).errorMessage.indexOf('AADB2C90118') > -1) {
                        instance.loginRedirect(b2cPolicies.authorities.forgotPassword as RedirectRequest);
                    } else {

                        if (!instance.getActiveAccount()) {
                            instance.loginRedirect();
                        }
                    }
                    console.info(err);
                });
            }

            return () => {
                if (callbackId) {
                    instance.removeEventCallback(callbackId);
                    window.removeEventListener('unhandledrejection', genericErrorHandler);
                }
            };
        },
        []
    );

    function genericErrorHandler(e: PromiseRejectionEvent) {
        toastError(e.reason);
    }

    function setLangKey(lang?: string | undefined) {
        const currentLang = getCurrentLang();
        const newLang = lang as LanguageEnum ?? LanguageEnum.HU;
        if (currentLang !== newLang) {
            setCurrentLang(newLang);
        }
    }

    return <>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={(e) => <>
                <span>{JSON.stringify(e)}</span>
            </>}
            loadingComponent={() => <>
                <FradiLoader visible={true}/>
            </>}
        >
            <div className={css.appTheme}>
                <PlayerLayerContext.Provider value={{ context: playerIdentity, setContext: setPlayerIdentity }}>
                    <HeaderContext.Provider value={{context: headerContext, setContext: setHeaderContext}}>
                        <Header/>
                        <Outlet/>
                    </HeaderContext.Provider>
                </PlayerLayerContext.Provider>
                <GenericModal
                    title={t('search.title')}
                    changeVisibility={closeModal}
                    isVisible={isModalVisible || !!playerIdentity?.isSearchModalOpen}
                >
                    <SearchModal selectedPlayer={openPlayerDetails}/>
                </GenericModal>
                <ToastContainer
                    position={'bottom-right'}
                    closeOnClick
                    pauseOnHover
                    theme={'colored'}
                    autoClose={5000}
                />
            </div>
        </MsalAuthenticationTemplate>
    </>;

}

export default AppTheme;
