import React, { ReactElement, useMemo, useState } from 'react';
import { cx } from '../../helpers/utility';
import { translate as t } from '../../helpers/translate';
import { Data } from '../../interfaces/common';
import css from './TabAccordion.module.scss';

interface TabAccordionProps<T extends number | string | symbol> {
    tabs: string[],
    preTag?: string,
    defaultTab: T,
    text?: Data<string>,
    children: (selectedTab: T) => React.ReactNode,
    tabSelect?: (selectedTab: T) => void,
}

const TabAccordion = function<T extends number | string | symbol>({
    tabs,
    preTag,
    defaultTab,
    text,
    children,
    tabSelect,
}: TabAccordionProps<T>): ReactElement {

    const [tabList, setTabList] = useState<Record<T, boolean>>(
        tabs.reduce((record, key) => ({...record, [key]: false}), {}) as Record<T, boolean>
    );
    const [selected, setSelected] = useState<T>(defaultTab);

    useMemo(
        () => {
            if (!tabList[defaultTab]) {
                selectTab(defaultTab);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [defaultTab]
    );

    function selectTab(tab: T) {
        // selectableTabs(tab);
        setSelected(tab);
        tabSelect && tabSelect(tab);
        setTabList(oldState =>
            Object.keys(oldState).reduce(
                (record, key) => ({...record, [key]: tab === key}),
                { }
            ) as Record<T, boolean>
        );
    }

    return <>
        <div className={css.TabAccordion}>
            <div className={css.TabHeader}>
                {Object.entries(tabList).map(([tab, isActive]) =>
                    <button
                        key={tab}
                        onClick={() => selectTab(tab as T)}
                        className={cx('btn-div', css.TabItem, isActive ? css.active : null)}>
                        <span>{(text && text[tab]) ?? t(preTag + tab)}</span>
                    </button>
                )}
            </div>
            <div className={css.TabContent}>
                {children(selected)}
            </div>
        </div>
    </>;

};

export default TabAccordion;
