import React, { ReactElement, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { noop } from 'lodash';
import { toggleArray } from '../../helpers/utility';
import SelectTemplate from './select.template';

export type OptionType<T = undefined> = {
    id: number | string;
    value: string;
    data?: T;
}

const Select = function<T>({
    label = '',
    className = '',
    position = 'absolute',
    options,
    selectedOptions = [],
    runInitialChange = false,
    multiple = false,
    disabled = false,
    isSoloLabel = false,
    isLabelOnTop = false,
    hideLabelWhenSelected = false,
    clearable = false,
    filter = false,
    changeSelected = noop
}: {
    label?: string,
    className?: string,
    position?: 'absolute' | 'static',
    options: OptionType<T>[],
    selectedOptions?: OptionType<T>[],
    runInitialChange?: boolean,
    multiple?: boolean,
    disabled?: boolean,
    isSoloLabel?: boolean,
    isLabelOnTop?: boolean,
    hideLabelWhenSelected?: boolean,
    clearable?: boolean,
    filter?: boolean,
    changeSelected?: (selecteds: OptionType<T>[]) => void
}): ReactElement {

    const [filteredOptions, setFilteredOptions] = useState<OptionType<T>[]>(options);
    const [currentSelectedOptions, setCurrentSelectedOptions] = useState<OptionType<T>[]>();
    const [filterText, setFilterText] = useState('');

    useDeepCompareEffect(
        () => {
            setCurrentSelectedOptions(selectedOptions);
            if (runInitialChange) {
                changeSelected(selectedOptions);
            }
        },
        [selectedOptions]
    );

    useEffect(
        () => {
            if (!filterText) {
                setFilteredOptions(options);
            }
            else {
                setFilteredOptions(
                    options.filter(
                        opt => opt.value.toLowerCase().includes(filterText.toLowerCase())
                    )
                );
            }
        },
        [filterText, options]
    );

    const selection = function(selected: OptionType<T>): void {
        if (multiple) {
            const newSelecteds = toggleArray(currentSelectedOptions ?? [], selected);
            setCurrentSelectedOptions(newSelecteds);
            changeSelected(newSelecteds);
        }
        else {
            setCurrentSelectedOptions([selected]);
            changeSelected([selected]);
            setFilterText('');
        }
    };

    const emptySelection = function() {
        setCurrentSelectedOptions([]);
        changeSelected([]);
    };

    return (
        <SelectTemplate
            label={label}
            className={className}
            multiple={multiple}
            position={position}
            options={filteredOptions}
            currentSelectedOptions={currentSelectedOptions ?? []}
            emptySelection={emptySelection}
            disabled={disabled}
            isSoloLabel={isSoloLabel}
            hideLabelWhenSelected={hideLabelWhenSelected}
            selection={selection}
            isLabelOnTop={isLabelOnTop}
            clearable={clearable}
            filter={filter}
            filterText={filterText}
            setFilterText={setFilterText}
        />
    );

};

export default Select;
