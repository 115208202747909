import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import {
    DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor,
    PointerSensor, TouchSensor, rectIntersection, useSensor, useSensors
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import SimpleBar from 'simplebar-react';
import { without } from 'lodash';
import Select from '../../select/select';
import { translate as t } from '../../../helpers/translate';
import { OwnShadowTeamService, OwnTeamPlayerSearchData, SearchPlayer } from '../../../services/openapi';
import { Html, cx } from '../../../helpers/utility';
import { FradiLoader } from '../../FradiLoader/FradiLoader';
import { PlayerLayerContext } from '../../../contexts/PlayerLayer.context';
import { playerSort } from '../../../hooks/shortList.hook';
import { ShadowTeamContext } from '../../../contexts/ShadowTeam.context';
import PlayerShadowTeamCard from '../../PlayerShadowTeamCard/PlayerShadowTeamCard';
import { SHADOW_TEAM_ALLOWED_PLAYER_NUM } from '../../../constants';
import { RawOwnShadowTeamDescriptor } from '../../../../pages/scouting-page/shadow-team/ShadowTeamTemplate';
import { Draggable, Droppable, SortableItem } from '../DragnDrop';
import { toastError } from '../../../helpers/errorhandler';
import useAuth from '../../../hooks/auth.hook';
import PlayerDragListCard from '../PlayerDragListCard/PlayerDragListCard';
import css from '../PositionModifier/PositionModifier.module.scss';
import PlaceholderIcon from '../../../../assets/images/state_player_outline.svg';

export const OwnPositionModifier = function({
    positionId,
    currentShadowTeam,
    setShadowPlayersForPosition,
    filterByPosition
}: {
    positionId: string,
    currentShadowTeam: RawOwnShadowTeamDescriptor,
    setShadowPlayersForPosition: (positionId: string, playerList: (OwnTeamPlayerSearchData | SearchPlayer | null)[]) => void,
    filterByPosition: boolean
}): ReactElement {

    const { requestParams } = useAuth();

    const layerContext = useContext(PlayerLayerContext);
    const shadowTeamContext = useContext(ShadowTeamContext);

    const [loadings, setLoadings] = useState<string[]>([]);
    const [selfList, setSelfList] = useState<OwnTeamPlayerSearchData[]>([]);
    const [selectedPositionId, setSelectedPositionId] = useState<string>(positionId);
    const [availableSelfList, setAvailableSelfList] = useState<OwnTeamPlayerSearchData[]>(selfList);
    const [shadowPlayerList, setShadowPlayerList] = useState<(OwnTeamPlayerSearchData | SearchPlayer | null)[]>(
        currentShadowTeam[positionId]
    );

    useEffect(
        () => {
            if (requestParams.UserID) {
                setLoadings(l => [...l, 'list']);
                OwnShadowTeamService.ownTeamPlayersOwnShadowTeamCreate(
                    requestParams
                ).finally(
                    () => {
                        setLoadings(l => without(l, 'list'));
                    }
                ).then(
                    (players) => {
                        setSelfList((players.Players ?? []).sort(playerSort));
                    }
                ).catch(
                    toastError
                );
            }
        },
        [positionId, requestParams]
    );

    useEffect(
        () => {
            setSelectedPositionId(positionId);
        },
        [positionId]
    );

    useEffect(
        () => {
            setShadowPlayerList(currentShadowTeam[selectedPositionId]);
            setAvailableSelfList(
                selfList.filter(
                    playerList => (
                        !currentShadowTeam[selectedPositionId] ||
                        !currentShadowTeam[selectedPositionId].find(
                            playerShadowTeam => playerList.PlayerID === playerShadowTeam?.PlayerID
                        )
                    ) && (
                        !filterByPosition || playerList.PlayerPositionID === 2 // FIXME: positionId
                    )
                )
            );
        },
        [currentShadowTeam, selfList, selectedPositionId, filterByPosition]
    );

    const handlePlayerLayer = function(playerId: number) {
        layerContext.setContext({
            playerId,
            isModalOpen: true,
            closeCallback: shadowTeamContext.context
                ? () => shadowTeamContext.context && shadowTeamContext.setContext({
                    ...shadowTeamContext.context, isModalOpen: true
                })
                : undefined
        });
        if (shadowTeamContext.context) {
            shadowTeamContext.setContext({ ...shadowTeamContext.context, isModalOpen: false });
        }
    };

    const searchToTeam = function(teamName: string) {
        if (layerContext.context) {
            layerContext.setContext({
                ...layerContext.context,
                isSearchModalOpen: teamName
            });
        }
    };

    const [placeholderList, setPlaceholderList] = useState<number[]>(
        Array(SHADOW_TEAM_ALLOWED_PLAYER_NUM).fill(null).map((_, i) => i + 1)
    );
    const [droppedPlayers, setDroppedPlayers] = useState<(OwnTeamPlayerSearchData | SearchPlayer | null)[]>([]);
    const [draggingPlayer, setDraggingPlayer] = useState<OwnTeamPlayerSearchData | null>(null);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                distance: 8
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const cacheShadowTeam = function(list: (OwnTeamPlayerSearchData | SearchPlayer | null)[]) {
        setPlaceholderList(
            Array(SHADOW_TEAM_ALLOWED_PLAYER_NUM).fill(null).map((_, i) => i + 1)
        );
        setShadowPlayersForPosition(selectedPositionId, list);
    };

    const getShadowTeamFromDroppedPlayers = useCallback(
        (
            droppeds: (OwnTeamPlayerSearchData | SearchPlayer | null)[], placeholders: number[]
        ): (OwnTeamPlayerSearchData | SearchPlayer | null)[] =>
            placeholders.map((item) => {
                if (droppeds[item]) {
                    return droppeds[item];
                }
                else {
                    return null;
                }
            })
        ,
        []
    );

    useEffect(
        () => {
            setDroppedPlayers([null, ...(currentShadowTeam[selectedPositionId] ?? Array(SHADOW_TEAM_ALLOWED_PLAYER_NUM).fill(null))]);
        },
        [currentShadowTeam, selectedPositionId]
    );

    useEffect(
        () => {
            setShadowPlayerList(getShadowTeamFromDroppedPlayers(droppedPlayers, placeholderList));
        },
        [droppedPlayers, getShadowTeamFromDroppedPlayers, placeholderList]
    );

    const removeFromList = function(playerId: number) {
        const newDroppedList = droppedPlayers.map(
            player => player?.PlayerID === playerId ? null : player
        );
        setDroppedPlayers(newDroppedList);
        setAvailableSelfList(list => {
            const receivedPlayer = selfList.find(
                player => player.PlayerID === playerId
            );
            if (receivedPlayer) {
                list.push(receivedPlayer);
            }
            return list.sort(playerSort);
        });
        cacheShadowTeam(getShadowTeamFromDroppedPlayers(newDroppedList, placeholderList));
    };

    function dragConstraint(args: any) {
        const { transform } = args;

        return {
            ...transform,
            x: transform.x <= 9 ? transform.x : 0,
            y: transform.y
        };
    }

    function handleDragStart(event: DragStartEvent) {
        setDraggingPlayer(event.active.data.current?.player);
    }

    function handleDragEnd(event: DragEndEvent) {
        const currentlyDroppedPlayer: OwnTeamPlayerSearchData = event.active.data.current?.player;
        if (event.over && !droppedPlayers[+event.over!.id] && currentlyDroppedPlayer) {
            // drag
            const dPlayers = [...droppedPlayers];
            dPlayers[+event.over.id] = currentlyDroppedPlayer;
            setDroppedPlayers(dPlayers);
            setAvailableSelfList(list => list.filter(
                player => player.PlayerID !== currentlyDroppedPlayer.PlayerID
            ));
            cacheShadowTeam(getShadowTeamFromDroppedPlayers(dPlayers, placeholderList));
        }
        else if (event.active.id !== event.over?.id && !currentlyDroppedPlayer) {
            // sort
            const oldIndex = placeholderList.indexOf(event.active.id as number);
            const newIndex = placeholderList.indexOf(event.over?.id as number);
            const newPlaceholderList = arrayMove(placeholderList, oldIndex, newIndex);
            setPlaceholderList(newPlaceholderList);
            cacheShadowTeam(getShadowTeamFromDroppedPlayers(droppedPlayers, newPlaceholderList));
        }
        setDraggingPlayer(null);
    }

    return <>
        <div className={css.contentBox}>
            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={rectIntersection}>
                <div className={css.selectedPlayers}>
                    <Select
                        isSoloLabel={true}
                        options={shadowTeamContext.context?.selectedFormation?.Positions.map(
                            pos => ({
                                id: pos.PositionID,
                                value: pos.PositionID
                            })
                        ) ?? []}
                        changeSelected={
                            (options) => {
                                const newPositionId = options[0]?.id as string ?? '';
                                setSelectedPositionId(newPositionId);
                            }
                        }
                        selectedOptions={[
                            { id: selectedPositionId, value: selectedPositionId }
                        ]}
                        label={t('scouting.shadow.modal.label.position')}
                    ></Select>

                    <div className={css.scrollbarContainer}>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <div className={css.players}>
                                <SortableContext
                                    items={placeholderList}
                                    strategy={rectSortingStrategy}
                                >
                                    {placeholderList.map((item) => (
                                        <SortableItem key={item} id={item}>
                                            <Droppable key={item} id={item} droppedPlayers={droppedPlayers}>
                                                {droppedPlayers[item]
                                                    ? <>
                                                        <PlayerShadowTeamCard
                                                            player={droppedPlayers[item] as OwnTeamPlayerSearchData}
                                                            handlePlayerLayer={handlePlayerLayer}
                                                            removeFromList={removeFromList}
                                                        />
                                                    </>
                                                    : <>
                                                        <div className={css.playerPlaceholder}>
                                                            <img src={PlaceholderIcon} alt=""/>
                                                            <span>{t('scouting.shadow.modal.label.placeholder')}</span>
                                                        </div>
                                                    </>
                                                }
                                            </Droppable>
                                        </SortableItem>
                                    ))}
                                </SortableContext>
                            </div>
                        </SimpleBar>
                    </div>
                </div>
                <div className={css.playerSelector}>
                    <div className={cx(css.shortListContainer, css.listed)}>
                        <p className={css.emptyStateText}><Html>{t('scouting.short-list.empty-list-text')}</Html></p>
                        <div className={css.listHeader}>
                            <div className={css.playerPicture}></div>
                            <p className={css.playerName}>{t('playerDetails.label.name')}</p>
                            <p className={css.classification}>{t('playerDetails.label.classification')}</p>
                            <p className={css.birthYear}>{t('playerDetails.label.info.Birthday')}</p>
                            <p className={css.teamName}>{t('playerDetails.label.info.TeamName')}</p>
                            <p className={css.post}>{t('playerDetails.label.post')}</p>
                            <p className={css.minutes}>{t('playerDetails.label.minutes')}</p>
                            <p className={css.fradiIndex}>{t('playerDetails.label.fradiIndex')}</p>
                            <p className={css.shadowTeam}>{t('playerDetails.label.shadowTeam')}</p>
                        </div>
                        <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                            <div className={css.list}>
                                {availableSelfList.map(player => (
                                    <Draggable
                                        key={player.PlayerID}
                                        id={player.PlayerID}
                                        player={player}
                                        currentPlayerList={shadowPlayerList}
                                    >
                                        <div className={css.cardContainer}>
                                            <PlayerDragListCard
                                                player={player}
                                                handlePlayerLayer={handlePlayerLayer}
                                                searchToTeam={searchToTeam}
                                                excludedColumns={['polarChart', 'playerList', 'shortList', 'date']}
                                                expiration={true}
                                            />
                                        </div>
                                    </Draggable>
                                ))}
                            </div>
                        </SimpleBar>
                        <FradiLoader visible={loadings.includes('list')}/>
                    </div>
                </div>
                <DragOverlay modifiers={[dragConstraint]}>
                    {draggingPlayer ? (
                        <div className={css.cardContainer}>
                            <div className={css.drag} style={{ cursor: 'grabbing' }}></div>
                            <PlayerDragListCard
                                player={draggingPlayer}
                                handlePlayerLayer={handlePlayerLayer}
                                searchToTeam={searchToTeam}
                                excludedColumns={['polarChart', 'playerList', 'shortList', 'date']}
                                expiration={true}
                            />
                        </div>
                    ): null}
                </DragOverlay>
            </DndContext>
        </div>
    </>;

};
