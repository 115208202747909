import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { translate as t } from '../../../../shared/helpers/translate';
import { cx } from '../../../../shared/helpers/utility';
import { ConfigSetting } from '../../../../shared/interfaces/carried-on.interfaces';
import { PlayerSearchService, SavedSearchParam } from '../../../../shared/services/openapi';
import { toastError } from '../../../../shared/helpers/errorhandler';
import { FradiLoader } from '../../../../shared/modules/FradiLoader/FradiLoader';
import useAuth from '../../../../shared/hooks/auth.hook';
import { Toastify } from '../../../../shared/modules/Toastify/Toastify';
import css from './PlayerSearchSaves.module.scss';
import SimpleBar from 'simplebar-react';

const PlayerSearchSaves = function({
    loadSearchCallback
}: {
    loadSearchCallback: (searchParams: SavedSearchParam) => void
}): ReactElement {

    const { requestParams } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [configCollectionList, setConfigCollectionList] = useState<SavedSearchParam[]>([]);
    const [selectedConfigSettings, setSelectedConfigSettings] = useState<ConfigSetting | null>(null);
    const [renameText, setRenameText] = useState<string>('');

    const getSavedSearches = useCallback(
        () => {
            setIsLoading(true);
            return PlayerSearchService.savedPlayerSearchesCreate(
                requestParams
            ).finally(
                () => setIsLoading(false)
            ).then(playerSearches => {
                setConfigCollectionList(playerSearches.SavedSearches ?? []);
            }).catch(
                toastError
            );
        },
        [requestParams]
    );

    useEffect(() => {
        if (requestParams.UserID) {
            getSavedSearches();
        }
    }, [getSavedSearches, requestParams]);

    const preRename = function(params: SavedSearchParam) {
        setSelectedConfigSettings(
            { id: params.SearchID, type: 'rename' }
        );
        setRenameText(params.SearchName);
    };

    const renameSearch = function(params: SavedSearchParam) {
        if (requestParams.UserID) {
            if (renameText.length === 0) {
                Toastify({
                    titleKey: t('scouting.player-filter.errors.rename.empty')
                }).warning();
                return;
            }
            if (
                params.SearchName !== renameText &&
                configCollectionList.find(item => item.SearchName === renameText)
            ) {
                Toastify({
                    titleKey: t('scouting.player-filter.errors.rename.already-used')
                }).warning();
                return;
            }
            if (params.SearchName === renameText) {
                setRenameText('');
                setSelectedConfigSettings(null);
                return;
            }
            setIsLoading(true);
            PlayerSearchService.savePlayerSearchCreate({
                ...requestParams,
                SearchParams: {
                    ...params,
                    SearchName: renameText
                }
            }).then(_resp => {
                setRenameText('');
                setSelectedConfigSettings(null);
                return getSavedSearches();
            }).catch(
                toastError
            ).finally(
                () => setIsLoading(false)
            );
        }
    };

    const deleteSearch = function(searchId: number) {
        if (requestParams.UserID) {
            setIsLoading(true);
            PlayerSearchService.deleteSavedPlayerSearchCreate({
                ...requestParams,
                SearchID: searchId
            }).then(_resp => {
                setSelectedConfigSettings(null);
                return getSavedSearches();
            }).catch(
                toastError
            ).finally(
                () => setIsLoading(false)
            );
        }
    };

    return <>
        <SimpleBar style={{ minHeight: '100%', maxHeight: '100%' }} autoHide={false}>
            <div className={css.configList}>
                <div className={cx(css.configRecord, css.header)}>
                    <div className={css.configLabel}>
                        <span>{t('scouting.player-filter.saved.label.config')}</span>
                    </div>
                    <div className={css.configLabel}>
                        <span>{t('scouting.player-filter.saved.label.username')}</span>
                    </div>
                    <div className={css.actionLabel}>
                        <span>{t('scouting.player-filter.saved.label.action')}</span>
                    </div>
                </div>
                {configCollectionList.map(config =>
                    <div className={css.configRecord} key={config.SearchID}>
                        <div className={css.configLabel}>
                            <span>{config.SearchName}</span>
                        </div>
                        <div className={css.configLabel}>
                            <span>{config.UserName}</span>
                        </div>
                        <div className={css.actionLabel}>
                            <button
                                onClick={
                                    () => setSelectedConfigSettings(
                                        { id: config.SearchID, type: 'delete' }
                                    )
                                }
                                className={'btn btn-secondary btn-outline'}
                            >{t('scouting.player-filter.delete')}</button>
                            <button
                                onClick={
                                    () => preRename(config)
                                }
                                className={'btn btn-primary btn-outline'}
                            >{t('scouting.player-filter.rename')}</button>
                            <button
                                onClick={
                                    () => loadSearchCallback(config)
                                }
                                className={'btn btn-primary'}
                            >{t('scouting.player-filter.load')}</button>
                        </div>

                        {selectedConfigSettings?.type === 'rename' && (
                            <div className={cx(css.backdrop, selectedConfigSettings?.id === config.SearchID ? css.visible : null)}>
                                <div className={css.modification}>
                                    <input
                                        className={css.textInput}
                                        type="text"
                                        placeholder={t('scouting.player-filter.new-name')}
                                        value={renameText}
                                        maxLength={50}
                                        onChange={event => setRenameText(event.target.value)}
                                    />
                                    <button
                                        onClick={() => setSelectedConfigSettings(null)}
                                        className={'btn btn-secondary btn-outline'}
                                    >{t('general.button.cancel')}</button>
                                    <button
                                        onClick={() => renameSearch(config)}
                                        className={'btn btn-primary btn-outline'}
                                    >{t('general.button.save')}</button>
                                </div>
                            </div>
                        )}

                        {selectedConfigSettings?.type === 'delete' && (
                            <div className={cx(css.backdrop, selectedConfigSettings?.id === config.SearchID ? css.visible : null)}>
                                <div className={css.modification}>
                                    <button
                                        onClick={() => setSelectedConfigSettings(null)}
                                        className={'btn btn-secondary btn-outline'}
                                    >{t('general.button.cancel')}</button>
                                    <button
                                        onClick={() => deleteSearch(config.SearchID)}
                                        className={'btn btn-danger btn-outline'}
                                    >{t('general.button.delete')}</button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <FradiLoader visible={isLoading}/>
            </div>
        </SimpleBar>
    </>;

};

export default PlayerSearchSaves;
