/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmptyReturn } from '../models/EmptyReturn';
import type { OwnTeamPlayerSearch } from '../models/OwnTeamPlayerSearch';
import type { ShadowTeam } from '../models/ShadowTeam';
import type { ShadowTeamPlayers } from '../models/ShadowTeamPlayers';
import type { ShadowTeams } from '../models/ShadowTeams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OwnShadowTeamService {

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeams
     * @throws ApiError
     */
    public static deleteShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            ShadowTeamID?: number;
        },
    ): CancelablePromise<ShadowTeams> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/DeleteShadowTeam/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeamPlayers
     * @throws ApiError
     */
    public static loadShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            ShadowTeamID?: number;
        },
    ): CancelablePromise<ShadowTeamPlayers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/LoadShadowTeam/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns OwnTeamPlayerSearch
     * @throws ApiError
     */
    public static ownTeamPlayersOwnShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<OwnTeamPlayerSearch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/OwnTeamPlayersOwnShadowTeam/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeam
     * @throws ApiError
     */
    public static saveOwnShadowTeamCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            ShadowTeamData?: {
                ShadowTeamID?: number;
                /**
                 * Formation ID
                 */
                FormationID?: string;
                /**
                 * Name of the shadow team
                 */
                ShadowTeamName?: string;
                Positions?: Array<{
                    PositionID?: string;
                    Players?: Array<{
                        PlayerID?: number;
                        PlayerRank?: number;
                    }>;
                }>;
            };
        },
    ): CancelablePromise<ShadowTeam> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SaveOwnShadowTeam/',
            body: data,
        });
    }

    /**
     * Save to shortlist
     * @param data
     * @returns EmptyReturn
     * @throws ApiError
     */
    public static savePlayerContractExpiryYearCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
            /**
             * PlayerID
             */
            PlayerID?: number;
            /**
             * Player contract expiration year
             */
            ExpiryYear?: number;
        },
    ): CancelablePromise<EmptyReturn> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavePlayerContractExpiryYear/',
            body: data,
        });
    }

    /**
     * Get base data of player
     * @param data
     * @returns ShadowTeams
     * @throws ApiError
     */
    public static savedOwnShadowTeamsCreate(
        data: {
            /**
             * User Object ID from Azure AD B2C
             */
            UserID?: string;
            /**
             * User language id from Azure AD B2C
             */
            LangID?: string;
        },
    ): CancelablePromise<ShadowTeams> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/SavedOwnShadowTeams/',
            body: data,
        });
    }

}
