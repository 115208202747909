import React, { ReactElement, useRef, useState } from 'react';
import { translate as t } from '../../../shared/helpers/translate';
import { cx } from '../../../shared/helpers/utility';
import { PlayerList, PlayerSearchData } from '../../../shared/services/openapi';
import { FradiLoader } from '../../../shared/modules/FradiLoader/FradiLoader';
import GenericModal from '../../../shared/modules/GenericModal/GenericModal';
import { ProgressLine } from '../../../shared/modules/ProgressLine/ProgressLine';
import LoadedPlayerList from './PlayerListSaves/LoadedPlayerList';
import { Toastify } from '../../../shared/modules/Toastify/Toastify';
import { PlayerListConfigSetting } from './SavedPlayerList';
import css from './SavedPlayerList.module.scss';
import cssList from '../short-list/ShortList.module.scss';
import ClipboardGrey from '../../../assets/images/icons/icon_file_clipboard_grey.svg';

const SavedPlayerListTemplate = function({
    loadedList,
    players,
    configCollectionList,
    createPlayerList,
    loadPlayerList,
    renamePlayerList,
    deletePlayerList,
    downloadZip,
    progressValue,
    setProgressValue,
    selectedConfigSettings,
    setSelectedConfigSettings,
    isListModalVisible,
    setIsListModalVisible,
    loadings
}: {
    loadedList: PlayerList | undefined,
    players: PlayerSearchData[],
    configCollectionList: PlayerList[],
    createPlayerList: (name: string) => Promise<void>,
    loadPlayerList: (list: PlayerList) => Promise<void>,
    renamePlayerList: (listId: number, name: string) => Promise<void>,
    deletePlayerList: (listId: number) => void,
    downloadZip: (filename: string) => Promise<void>,
    progressValue: number,
    setProgressValue: React.Dispatch<React.SetStateAction<number>>,
    selectedConfigSettings: PlayerListConfigSetting | null,
    setSelectedConfigSettings: React.Dispatch<React.SetStateAction<PlayerListConfigSetting | null>>,
    isListModalVisible: boolean,
    setIsListModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    loadings: string[]
}): ReactElement {

    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState<boolean>(false);
    const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState<boolean>(false);
    const [filename, setFilename] = useState<string>('');
    const [saveAsName, setSaveAsName] = useState<string>('');
    const [listName, setListName] = useState<string>('');

    const openPopup = function() {
        setFilename('');
        setProgressValue(0);
        setIsDownloadModalVisible(true);
    };

    const changeListName = function(event: React.ChangeEvent<HTMLInputElement>) {
        setListName(event.target.value);
    };

    const createList = function() {
        if (inputRef.current && buttonRef.current) {
            inputRef.current.disabled = true;
            buttonRef.current.disabled = true;
        }
        createPlayerList(
            saveAsName
        ).then(
            () => {
                setIsSaveAsModalVisible(false);
                Toastify({
                    titleKey: t('scouting.actual-player-list.save-success')
                }).success();
            }
        );
    };

    const renameList = function(listId: number) {
        renamePlayerList(
            listId, listName
        ).then(
            () => {
                setSelectedConfigSettings(null);
            }
        );
    };

    const download = function() {
        if (inputRef.current && buttonRef.current) {
            inputRef.current.disabled = true;
            buttonRef.current.disabled = true;
        }
        downloadZip(
            filename
        ).then(
            () => {
                setIsDownloadModalVisible(false);
            }
        );
    };

    return <>
        <div className={cx('contentContainer', cssList.shortList)}>
            <div className={cx('contentHeader', cssList.contentHeader)}>
                <h1>{t('scouting.saved-player-list.title')}</h1>
                <div className="buttonsAndFilters">
                    <button
                        className={cx(
                            'btn btn-primary',
                            cssList.filterPageButton
                        )}
                        onClick={() => setIsSaveAsModalVisible(true)}
                    >
                        {t('scouting.player-list.create')}
                    </button>
                    <button
                        className={cx(
                            'btn btn-secondary btn-outline',
                            cssList.filterPageButton,
                            loadings.includes('download') ? cssList.loading : null
                        )}
                        disabled={loadings.includes('download')}
                        onClick={() => openPopup()}
                    >
                        <img src={ClipboardGrey} alt=""/>
                        {t('scouting.saved-player-list.full-export')}
                    </button>
                </div>
            </div>
            <div className={css.configList}>
                <div className={cx(css.configRecord, css.header)}>
                    <div className={css.configLabel}>
                        <span>{t('scouting.saved-player-list.saved.label.name')}</span>
                    </div>
                    <div className={css.configLabel}>
                        <span>{t('scouting.saved-player-list.saved.label.username')}</span>
                    </div>
                    <div className={css.configLabel}>
                        <span>{t('scouting.saved-player-list.saved.label.date')}</span>
                    </div>
                    <div className={css.actionLabel}>
                        <span>{t('scouting.saved-player-list.saved.label.action')}</span>
                    </div>
                </div>
                {configCollectionList.map(list =>
                    <div className={css.configRecord} key={list.PlayerListID}>
                        <div className={css.configLabel}>
                            <span>{list.ListName}</span>
                        </div>
                        <div className={css.configLabel}>
                            <span>{list.UserName}</span>
                        </div>
                        <div className={css.configLabel}>
                            <span>{list.ListDate.split('T')[0]}</span>
                        </div>
                        <div className={css.actionLabel}>
                            <button
                                onClick={
                                    () => setSelectedConfigSettings(
                                        { id: list.PlayerListID, type: 'rename' }
                                    )
                                }
                                className={'btn btn-secondary btn-outline'}
                            >{t('scouting.saved-player-list.saved.buttons.rename')}</button>
                            <button
                                onClick={
                                    () => setSelectedConfigSettings(
                                        { id: list.PlayerListID, type: 'delete' }
                                    )
                                }
                                className={'btn btn-secondary btn-outline'}
                            >{t('scouting.saved-player-list.saved.buttons.delete')}</button>
                            <button
                                onClick={
                                    () => loadPlayerList(list)
                                }
                                className={'btn btn-primary'}
                            >{t('scouting.saved-player-list.saved.buttons.load')}</button>
                        </div>
                        {selectedConfigSettings?.type === 'rename' && (
                            <div className={cx(css.backdrop, selectedConfigSettings?.id === list.PlayerListID ? css.visible : null)}>
                                <div className={css.modification}>
                                    <input
                                        className={css.textInput}
                                        type="text"
                                        placeholder={t('scouting.saved-player-list.saved.new-name')}
                                        value={listName}
                                        onChange={(event) => changeListName(event)}
                                    />
                                    <button
                                        onClick={() => {
                                            setSelectedConfigSettings(null);
                                            setListName('');
                                        }}
                                        className={'btn btn-secondary btn-outline'}
                                    >{t('general.button.cancel')}</button>
                                    <button
                                        onClick={() => renameList(list.PlayerListID)}
                                        className={'btn btn-primary'}
                                    >{t('general.button.save')}</button>
                                </div>
                            </div>
                        )}
                        {selectedConfigSettings?.type === 'delete' && (
                            <div className={cx(css.backdrop, selectedConfigSettings?.id === list.PlayerListID ? css.visible : null)}>
                                <div className={css.modification}>
                                    <button
                                        onClick={() => setSelectedConfigSettings(null)}
                                        className={'btn btn-secondary btn-outline'}
                                    >{t('general.button.cancel')}</button>
                                    <button
                                        onClick={() => deletePlayerList(list.PlayerListID)}
                                        className={'btn btn-danger btn-outline'}
                                    >{t('general.button.delete')}</button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <FradiLoader visible={loadings.length > 0}/>
            </div>
        </div>
        <GenericModal
            isVisible={isListModalVisible}
            title={'scouting.saved-player-list.saved.list'}
            changeVisibility={() => setIsListModalVisible(false)}
        >
            <LoadedPlayerList
                loadedList={loadedList}
                players={players}
            />
        </GenericModal>
        <GenericModal
            className='downloadBox'
            isVisible={isDownloadModalVisible}
            title={'scouting.general-list.download'}
            changeVisibility={() => setIsDownloadModalVisible(false)}
        >
            <div className={css.downloadContainer}>
                <fieldset>
                    <input
                        ref={inputRef}
                        className={css.downloadInput}
                        type="text"
                        placeholder={t('scouting.general-list.filename')}
                        value={filename}
                        maxLength={50}
                        onChange={event => setFilename(event.target.value)}
                    />
                    <span>.zip</span>
                </fieldset>
                <button
                    ref={buttonRef}
                    className={cx(css.downloadButton, 'btn btn-primary')}
                    disabled={filename.length === 0}
                    onClick={() => download()}
                >
                    {t('scouting.general-list.download')}
                </button>
                <ProgressLine value={progressValue}/>
            </div>
        </GenericModal>
        <GenericModal
            className='saveAsBox'
            isVisible={isSaveAsModalVisible}
            title={'scouting.player-list.create-title'}
            changeVisibility={() => setIsSaveAsModalVisible(false)}
        >
            <div className={css.saveAsContainer}>
                <input
                    ref={inputRef}
                    className={css.saveAsInput}
                    type="text"
                    placeholder={t('scouting.actual-player-list.saved.save-as-name')}
                    value={saveAsName}
                    maxLength={50}
                    onChange={event => setSaveAsName(event.target.value)}
                />
                <button
                    ref={buttonRef}
                    className={cx(css.saveAsButton, 'btn btn-primary')}
                    disabled={saveAsName.length === 0}
                    onClick={() => createList()}
                >
                    {t('scouting.player-list.create-button')}
                </button>
            </div>
        </GenericModal>
    </>;

};

export default SavedPlayerListTemplate;
