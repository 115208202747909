import React, { ReactElement, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { clamp, omit } from 'lodash';
import { FieldPosition, ViewDirection } from './transformer.interfaces';
import { cx } from '../../helpers/utility';
import { getDataValue, setDataValue } from './transformer.helper';
import FootballPositionPopup from './FootbalPositionPopup/FootballPositionPopup';
import { BooleanSelect } from '../BooleanSelect/BooleanSelect';
import Select from '../select/select';
import { translate as t } from '../../helpers/translate';
import { ShadowTeamContext } from '../../contexts/ShadowTeam.context';
import { ShadowTeamDescriptor } from '../../../pages/scouting-page/shadow-team/ShadowTeamTemplate';
import { Geometry, Rect, Rect3D } from '../../helpers/geometry';
import { Data } from '../../interfaces/common';
import css from './FootballPitch.module.scss';
import PitchLines from '../../../assets/images/footballPitchLines.svg';
import IconPortrait from '../../../assets/images/icons/icon_pitch_portrait.svg';
import IconLandscape from '../../../assets/images/icons/icon_pitch_landscape.svg';
import IconRotate from '../../../assets/images/icons/icon_pitch_rotate.svg';
import IconExpand from '../../../assets/images/icons/icon_arrows_expand.svg';

enum Buttons {
    None = 0,
    Primary,
    Secondary = 2,
    Auxiliary = 4,
    Back = 8,
    Forward = 16
}

const FootballPitch = function ({
    toggleFullScreen,
    currentShadowTeam,
    isFullScreen,
    selectPosition
}: {
    isFullScreen?: boolean,
    currentShadowTeam: ShadowTeamDescriptor,
    toggleFullScreen?: () => void,
    selectPosition: (position: string) => void
}): ReactElement {

    const sceneBox = useRef<HTMLDivElement>(null);
    const axisBox = useRef<HTMLDivElement>(null);
    const footballPitchBox = useRef<HTMLDivElement>(null);
    const { context: shadowTeam } = useContext(ShadowTeamContext);

    const [sceneAxis, setSceneAxis] = useState<ViewDirection>();
    const [viewDirection, setViewDirection] = useState<ViewDirection>();
    const [moveDirection, setMoveDirection] = useState<ViewDirection>({ x: 0, y: 0 });
    const [scrollScale, setScrollScale] = useState<number>(1.3);
    const [is2DMode, setIs2DMode] = useState<boolean>(true);
    const [isStrictly2DMode, setIsStrictly2DMode] = useState<boolean>(true);
    const [isAnimated, setIsAnimated] = useState<boolean>(false);
    const [touchPos, setTouchPos] = useState<{direction: ViewDirection, value: ViewDirection, action: 'move' | 'zoom'}>();
    const [positions, setPositions] = useState<FieldPosition[]>([]);
    const [lineHeight, setLineHeight] = useState<Data<number>>({ });

    const boxes = useRef<Data<HTMLDivElement | null>>({ });

    const field = {
        lat: 44.75,
        lng: 64.75,
    };

    useEffect(
        () => {
            if (sceneBox.current) {
                setDataValue(sceneBox.current, 'rotateX', 180);
                sceneBox.current.addEventListener('wheel', (event) => {
                    event.preventDefault();
                }, { passive: false });
            }
        },
        []
    );

    useEffect(
        () => {
            if (shadowTeam && shadowTeam.selectedFormation) {
                setPositions(
                    shadowTeam.selectedFormation.Positions.map(
                        pos => ({
                            PositionID: pos.PositionID,
                            name: pos.PositionID,
                            lat: pos.PositionY ?? 0,
                            lng: pos.PositionX ?? 0,
                            players: currentShadowTeam[pos.PositionID] ?? []
                        })
                    )
                );
            }
            else {
                setPositions([]);
            }
        },
        [currentShadowTeam, shadowTeam]
    );

    useEffect(
        () => {
            if (!touchPos) {
                return;
            }
            if (touchPos.action === 'move') {
                setMoveDirection((prevState) => ({
                    x: clamp(prevState.x + touchPos?.direction.x, -1000, 1000),
                    y: clamp(prevState.y + touchPos?.direction.y, -1000, 1000),
                }));
            }
            if (touchPos.action === 'zoom') {
                setScrollScale(scale => {
                    return clamp(+(scale + (touchPos.direction.y > 0 ? 0.01 : -0.01)), 0.5, 2);
                });
            }
        },
        [touchPos]
    );

    const rotator = useCallback(
        (viewDirection: ViewDirection = { x: 0, y: 0 }, box?: RefObject<HTMLDivElement>, fixPos?: ViewDirection) => {
            if (!box?.current) {
                return;
            }
            setDataValue(
                box.current,
                'rotateX',
                clamp(
                    fixPos
                        ? fixPos.x
                        : (getDataValue(box.current, 'rotateX', 180) + viewDirection.x),
                    0, 360
                )
            );
            setDataValue(
                box.current,
                'rotateY',
                clamp(
                    fixPos
                        ? fixPos.y
                        : (getDataValue(box.current, 'rotateY', -55) + viewDirection.y),
                    -90, 0
                )
            );
            return `
                rotateX(${-getDataValue(box.current, 'rotateY')}deg)
                rotateZ(${-getDataValue(box.current, 'rotateX')}deg)
                scale(${scrollScale})
            `;
        },
        [scrollScale]
    );

    useEffect(
        () => {
            // axisBox.current?.setAttribute('style', `transform: ${rotator(viewDirection, axisBox)}`);
            footballPitchBox.current?.setAttribute(
                'style', `transform:  translate(-50%, -50%) ${rotator(viewDirection, footballPitchBox)}`
            );
            setSceneAxis(() => {
                const x = getDataValue(footballPitchBox.current, 'rotateX');
                const y = getDataValue(footballPitchBox.current, 'rotateY');
                return {
                    x: x,
                    y: y,
                };
            });
        },
        [rotator, viewDirection]
    );

    useEffect(
        () => {
            footballPitchBox.current?.setAttribute(
                'style', `transform:  translate(-50%, -50%) ${rotator({x: 0, y: 0}, footballPitchBox)}`
            );
        },
        [rotator, scrollScale]
    );

    const changeSpecificView = useCallback(
        (x: number, y: number) => {
            const transformation = rotator({x: 0, y: 0}, footballPitchBox, {
                x: x,
                y: y
            });
            setIsStrictly2DMode(y === 0 && is2DMode);
            setIsAnimated(true);
            setMoveDirection({x: 0, y: 0});
            footballPitchBox.current?.setAttribute(
                'style', `transform: translate(-50%, -50%) ${transformation}; transition: all 250ms`
            );
            window.setTimeout(() => {
                setIsAnimated(false);
                setViewDirection({ x: 0, y: 0 });
            }, 250);
        },
        [is2DMode, rotator]
    );

    useEffect(
        () => {
            changeSpecificView(is2DMode ? 180 : 130, is2DMode ? 0 : -75);
        },
        [is2DMode, changeSpecificView]
    );

    useEffect(
        () => {
            const offsets: Map<string, Rect3D> = new Map();
            const containerPos = sceneBox.current?.getBoundingClientRect() ?? new DOMRect();
            const containerRect: Rect = {
                x: containerPos.x,
                y: containerPos.y,
                w: containerPos.width,
                h: containerPos.height
            };
            positions.forEach(
                position => {
                    if (boxes.current[position.PositionID]) {
                        (boxes.current[position.PositionID] as HTMLElement).style.transform = 'translateY(-100%)';
                        setLineHeight(current => ({ ...current, [position.PositionID]: 0 }));
                    }
                    const boxPos = boxes.current[position.PositionID]?.getBoundingClientRect() ?? new DOMRect();
                    const boxRect: Rect = {
                        x: boxPos.x - containerRect.x,
                        y: boxPos.y - containerRect.y,
                        w: boxPos.width,
                        h: boxPos.height
                    };
                    const radZ = -(sceneAxis?.x ?? 0) * (Math.PI / 180);
                    const radX = (sceneAxis?.y ?? 0) * (Math.PI / 180);
                    const translateZ = -Math.sin(radX) * (Math.sin(radZ) * position.lat + Math.cos(radZ) * position.lng);
                    offsets.set(position.PositionID, { ...boxRect, z: translateZ });
                }
            );
            const offsetArray: [string, Rect][] = [...offsets.entries()].sort(
                (a, b) => (b[1].z as number) - (a[1].z as number)
            ).map(
                ([positionId, rect3D]) => [
                    positionId, omit(rect3D, 'z')
                ] as [string, Rect]
            ).filter(
                ([positionId, _box]: [string, Rect]) => !!boxes.current[positionId]
            );
            offsetArray.forEach(
                ([positionId, box], index) => {
                    if (index > 0) {
                        const oldY = box.y;
                        for (let i = 1; i <= index; i++) {
                            const otherBox = offsetArray[i - 1][1];
                            const intersection = Geometry.getRectIntersection(box, otherBox);
                            if (intersection.h > 0 && boxes.current[positionId]) {
                                offsetArray[index][1].y = otherBox.y - box.h;
                                const transY = Math.ceil((offsetArray[index][1].y - oldY) * (1 / scrollScale));
                                (boxes.current[positionId] as HTMLElement).style.transform = `translateY(calc(-100% + ${transY}px))`;
                                setLineHeight(current => ({ ...current, [positionId]: Math.abs(transY) }));
                            }
                        }
                    }
                    else {
                        (boxes.current[positionId] as HTMLElement).style.transform = 'translateY(-100%)';
                        setLineHeight(current => ({ ...current, [positionId]: 0 }));
                    }
                }
            );
        },
        [positions, sceneAxis, scrollScale]
    );

    function pointerMoveListener(parentEvent: PointerEvent) {
        const eventCollection = parentEvent.getCoalescedEvents
            ? parentEvent.getCoalescedEvents()
            : [parentEvent];
        if (!eventCollection.length) {
            return;
        }

        eventCollection.map(pointerEvent => {
            pointerEvent.preventDefault();
            if (
                pointerEvent.buttons === Buttons.Secondary ||
                pointerEvent.buttons === Buttons.Auxiliary ||
                (is2DMode && pointerEvent.buttons === Buttons.Primary)
            ) {
                setMoveDirection((prevState) => ({
                    x: clamp(prevState.x + pointerEvent.movementX, -1000, 1000),
                    y: clamp(prevState.y + pointerEvent.movementY, -1000, 1000),
                }));
            }
            else if (!is2DMode && pointerEvent.buttons === Buttons.Primary) {
                setViewDirection({
                    x: pointerEvent.movementX,
                    y: pointerEvent.movementY,
                });
            }
        });
    }

    function touchHandler(event: TouchEvent) {
        if (event.touches.length === 1 || event.touches.length === 2) {
            setTouchPos(prevState => {
                const newX = event.touches.item(0)?.pageX ?? 0;
                const newY = event.touches.item(0)?.pageY ?? 0;
                if (prevState === undefined) {
                    return {
                        direction: {
                            x: 0,
                            y: 0,
                        },
                        value: { x: newX, y: newY },
                        action: event.touches.length === 1 ? 'move' : 'zoom'
                    };
                }
                return {
                    direction: {
                        x: Math.round(newX - prevState.value.x),
                        y: Math.round(newY - prevState.value.y)
                    },
                    value: { x: newX, y: newY },
                    action: event.touches.length === 1 ? 'move' : 'zoom'
                };
            });
        }
    }

    function changeViewByScroll(event: WheelEvent) {
        setScrollScale(scale => {
            return clamp(+(scale + (event.deltaY > 0 ? -0.1 : 0.1)).toFixed(2), 0.5, 2);
        });
    }

    function positionTransformer(position: FieldPosition): string {
        // return `scale(${scrollScale})`;
        const lat = (-(field.lat / 2) + (field.lat * position.lat) / 100) * scrollScale;
        const lng = (-(field.lng / 2) + (field.lng * position.lng) / 100) * scrollScale;
        const radZ = -(sceneAxis?.x ?? 0) * (Math.PI / 180);
        const radX =  (sceneAxis?.y ?? 0) * (Math.PI / 180);
        return `
            translate(-50%, calc(-80% - ${0.5 * scrollScale}rem))
            translateX(${(Math.cos(radZ) * lat - Math.sin(radZ) * lng)}rem)
            translateY(${(Math.sin(radZ) * lat + Math.cos(radZ) * lng) * Math.cos(radX)}rem)
            translateZ(${-Math.sin(radX) * (Math.sin(radZ) * lat + Math.cos(radZ) * lng)}rem)
            scale(${scrollScale})
        `;
    }

    function selectPositionAndPlayer(positionId: string, _playerId?: number) {
        selectPosition(positionId);
    }

    return <>
        {shadowTeam && (
            <div
                className={css.sceneBox}
                ref={sceneBox}
                onTouchMoveCapture={(event) => touchHandler(event.nativeEvent)}
                // onTouchEnd={() => setTouchPos(undefined)}
                onPointerMove={(event) => pointerMoveListener(event.nativeEvent)}
                onWheel={(event) => changeViewByScroll(event.nativeEvent)}
                onContextMenu={event => event.preventDefault()}
            >
                <div className={css.scene} style={{
                    transform: `translateX(${moveDirection.x}px) translateY(${100 * scrollScale + moveDirection.y}px)`,
                    transition: isAnimated ?  'all 250ms' : 'unset'
                }}>
                    <div className={css.footballPitch} ref={footballPitchBox}>
                        <div className={css.length}></div>
                        <img className={css.pitchLines} src={PitchLines} alt=""/>
                    </div>
                    {positions.map(position =>
                        <div
                            className={css.positionBox}
                            style={{
                                transform: positionTransformer(position),
                                transition: isAnimated ? 'all 250ms' : 'none',
                            }}
                            key={position.PositionID}
                        >
                            <div className={css.position}>
                                <button
                                    className={css.positionButton}
                                    onClick={() => selectPositionAndPlayer(position.PositionID)}
                                >{position.PositionID}</button>
                                {position.players.length > 0 && (
                                    <div
                                        ref={elem => boxes.current[position.PositionID] = elem}
                                        className={cx(css.positionLayer, isStrictly2DMode && css.below)}
                                        style={{ top: isStrictly2DMode ? `${position.players.length * 20 + 75}px` : undefined }}
                                    >
                                        <FootballPositionPopup
                                            players={position.players}
                                            selectPlayer={(playerId) => selectPositionAndPlayer(position.PositionID, playerId)}
                                            lineHeight={lineHeight[position.PositionID]}
                                            below={isStrictly2DMode}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={css.axisBox}
                    ref={axisBox}
                >
                    <div className={cx(css.direction, css.directionX)}></div>
                    <div className={cx(css.direction, css.directionY)}></div>
                    <div className={cx(css.direction, css.directionZ)}></div>
                </div>
                <div className={cx(css.hud, isFullScreen ? css.fullScreen : null)}>
                    <BooleanSelect
                        value={is2DMode}
                        small={true}
                        onSelect={(state) => setIs2DMode(!!state)} trueLabel={'2D'} falseLabel={'3D'}
                    />
                    <div className={css.actions}>
                        <div
                            className={css.topRight}
                            onWheel={event => event.stopPropagation()}
                        >
                            <Select
                                options={
                                    shadowTeam.formations.map(
                                        formation => ({
                                            id: formation.FormationID,
                                            value: formation.FormationID,
                                            data: formation.Positions
                                        })
                                    )
                                }
                                selectedOptions={
                                    shadowTeam.selectedFormation
                                        ? [{
                                            id: shadowTeam.selectedFormation?.FormationID,
                                            value: shadowTeam.selectedFormation?.FormationID
                                        }]
                                        : []
                                }
                                isSoloLabel={true}
                                hideLabelWhenSelected={true}
                                position={'static'}
                                label={t('scouting.shadow.pitch.label.formation')}
                                changeSelected={
                                    selecteds => {
                                        if (selecteds.length > 0) {
                                            shadowTeam.selectedFormation = {
                                                FormationID: selecteds[0].id as string,
                                                Positions: selecteds[0].data ?? []
                                            };
                                            setPositions(
                                                selecteds[0].data?.map(
                                                    pos => ({
                                                        PositionID: pos.PositionID,
                                                        name: pos.PositionID,
                                                        lat: pos.PositionY,
                                                        lng: pos.PositionX,
                                                        players: currentShadowTeam[pos.PositionID] ?? []
                                                    })
                                                ) ?? []
                                            );
                                        }
                                    }
                                }
                            ></Select>
                            <button
                                onClick={toggleFullScreen} className={cx('btn icon', css.toggleExpand, isFullScreen ? css.opened : null)}
                            >
                                <img src={IconExpand} alt=""/>
                            </button>
                        </div>
                        <div className={css.views}>
                            <button
                                onClick={() => changeSpecificView(180, 0)} className={cx('btn icon', css.viewSelect)}
                                title={'portrait'}
                            >
                                <img src={IconPortrait} alt="portrait"/>
                            </button>
                            <button
                                onClick={() => changeSpecificView(90, 0)} className={cx('btn icon', css.viewSelect)}
                                title={'landscape'}
                            >
                                <img src={IconLandscape} alt="landscape"/>
                            </button>
                            <button
                                onClick={() => changeSpecificView(180, -60)} className={cx('btn icon', css.viewSelect, css.to3d)}
                                title={'3d front'}
                            >
                                <img src={IconPortrait} alt="3d front"/>
                            </button>
                            <button
                                onClick={() => changeSpecificView(90, -60)} className={cx('btn icon', css.viewSelect, css.to3d)}
                                title={'3d side'}
                            >
                                <img src={IconLandscape} alt="3d side"/>
                            </button>
                            <button
                                onClick={() => changeSpecificView(130, -75)} className={cx('btn icon', css.viewSelect, css.to3d)}
                                title={'isometric'}
                            >
                                <img src={IconRotate} alt="isometric"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>;

};

export default FootballPitch;
