import React, { ReactElement, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { without } from 'lodash';
import { toastError } from '../../helpers/errorhandler';
import { cx, toggleArray } from '../../helpers/utility';
import useAuth from '../../hooks/auth.hook';
import { PlayerList, PlayerListsService } from '../../services/openapi';
import { translate as t } from '../../helpers/translate';
import Tooltip from '../Tooltip/Tooltip';
import GenericModal from '../GenericModal/GenericModal';
import { FradiLoader } from '../FradiLoader/FradiLoader';
import css from './PlayerListButton.module.scss';
import AddIcon from '../../../assets/images/icons/icon_add.svg';
import RemoveIcon from '../../../assets/images/icons/icon_check_circle.svg';
import PlayerListIcon from '../../../assets/images/icons/icon_player_list.svg';
import ListButton from '../ListButtons/ListButton';
import { Toastify } from '../Toastify/Toastify';

const PlayerListButton = function({
    className = '',
    playerId
}: {
    className?: string,
    playerId: number
}): ReactElement {

    const { requestParams } = useAuth();
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [loadings, setLoadings] = useState<string[]>([]);
    const [lists, setLists] = useState<PlayerList[]>([]);
    const [inList, setInList] = useState<number[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState<boolean>(false);
    const [saveAsName, setSaveAsName] = useState<string>('');

    const getSavedPlayerLists = function() {
        if (requestParams.UserID) {
            setLoadings(l => [...l, 'lists']);
            let listIds: number[] = [];

            PlayerListsService.playerListsCreate(
                requestParams
            ).then(
                (lists) => {
                    listIds = lists.PlayerLists.map(
                        list => list.PlayerListID
                    );
                    setLists(lists.PlayerLists);
                    return Promise.all(
                        lists.PlayerLists.map(
                            list => PlayerListsService.playerListCreate({
                                ...requestParams,
                                PlayerListID: list.PlayerListID
                            })
                        )
                    );
                }
            ).then(
                (listData) => {
                    const extendedLists = listData.map(
                        (list, index) => ({
                            listID: listIds[index],
                            Players: list.Players
                        })
                    );
                    setInList(
                        extendedLists.filter(
                            list => (list.Players ?? []).find(
                                player => player.PlayerID === playerId
                            )
                        ).map(
                            list => list.listID
                        )
                    );
                }
            ).catch(
                toastError
            ).finally(
                () => {
                    setLoadings(l => without(l, 'lists'));
                }
            );
        }
    };

    const createPlayerList = function(name: string) {
        setLoadings(l => [...l, 'list']);
        return PlayerListsService.savePlayerListCreate({
            ...requestParams,
            PlayerListName: name,
            PlayerListID: null
        }).finally(
            () => {
                setLoadings(l => without(l, 'list'));
            }
        ).then(
            () => {
                getSavedPlayerLists();
            }
        ).catch(
            toastError
        );
    };

    const createList = function() {
        if (inputRef.current && buttonRef.current) {
            inputRef.current.disabled = true;
            buttonRef.current.disabled = true;
        }
        createPlayerList(
            saveAsName
        ).then(
            () => {
                setIsSaveAsModalVisible(false);
                Toastify({
                    titleKey: t('scouting.actual-player-list.save-success')
                }).success();
            }
        );
    };

    const openModal = function() {
        setIsModalVisible(true);
        getSavedPlayerLists();
    };

    const toggleInList = function(listId: number): Promise<void> {
        if (requestParams.UserID) {
            let EndpointRequest;
            if (inList.includes(listId)) {
                EndpointRequest = PlayerListsService.deleteFromPlayerListCreate;
            }
            else {
                EndpointRequest = PlayerListsService.saveToPlayerListCreate;
            }
            setLoadings(l => [...l, 'toggleList']);

            return EndpointRequest({
                ...requestParams,
                PlayerID: playerId,
                PlayerListID: listId
            }).finally(
                () => {
                    setLoadings(l => without(l, 'toggleList'));
                }
            ).then(
                (_resp) => {
                    setInList(current => toggleArray(current, listId));
                }
            ).catch(
                toastError
            );
        }
        else {
            return Promise.resolve();
        }
    };

    return <>
        <Tooltip text={t('general.button.addToPlayerListTitle')}>
            <button
                className={cx(
                    css.toggleList,
                    // inList.length > 0 ? css.inList : css.notInList,
                    loadings.includes('toggleList') ? css.loading : null,
                    className
                )}
                disabled={loadings.includes('toggleList')}
                onClick={() => openModal()}
            >
                <img className={css.addIcon} src={inList ? RemoveIcon : AddIcon} alt=""/>
                {/* <p>
                    {t('general.button.addToPlayerList')}
                </p> */}
                <img className={css.playerIcon} src={PlayerListIcon} alt="" />
            </button>
        </Tooltip>
        <GenericModal
            className='playerListBox'
            isVisible={isModalVisible}
            title={'scouting.player-list.title'}
            changeVisibility={() => setIsModalVisible(false)}
        >
            <SimpleBar style={{ maxHeight: '100%' }} autoHide={false}>
                <div className={css.configList}>
                    <button
                        className={cx(
                            'btn btn-primary',
                            css.filterPageButton
                        )}
                        onClick={() => setIsSaveAsModalVisible(true)}
                    >
                        {t('scouting.player-list.create')}
                    </button>
                    <div className={cx(css.configRecord, css.header)}>
                        <div className={css.configLabel}>
                            <span>{t('scouting.saved-player-list.saved.label.name')}</span>
                        </div>
                        <div className={css.configLabel}>
                            <span>{t('scouting.saved-player-list.saved.label.username')}</span>
                        </div>
                        <div className={css.configLabel}>
                            <span>{t('scouting.saved-player-list.saved.label.date')}</span>
                        </div>
                        <div className={css.actionLabel}>
                            <span>{t('scouting.saved-player-list.saved.label.action')}</span>
                        </div>
                    </div>
                    {loadings.includes('lists') && <>
                        <FradiLoader visible={true} />
                    </> || <>
                        {lists.map(list => (
                            <div className={css.configRecord} key={list.PlayerListID}>
                                <div className={css.configLabel}>
                                    <span>{list.ListName}</span>
                                </div>
                                <div className={css.configLabel}>
                                    <span>{list.UserName}</span>
                                </div>
                                <div className={css.configLabel}>
                                    <span>{list.ListDate.split('T')[0]}</span>
                                </div>
                                <div className={css.actionLabel}>
                                    <ListButton
                                        type="playerList"
                                        className={cx('btn', css.tallButton)}
                                        playerId={playerId}
                                        playerInList={inList.includes(list.PlayerListID)}
                                        onToggle={() => toggleInList(list.PlayerListID)}
                                    />
                                </div>
                            </div>
                        ))}
                    </>}
                </div>
            </SimpleBar>
        </GenericModal>
        <GenericModal
            className='saveAsBox'
            isVisible={isSaveAsModalVisible}
            title={'scouting.player-list.create-title'}
            changeVisibility={() => setIsSaveAsModalVisible(false)}
        >
            <div className={css.saveAsContainer}>
                <input
                    ref={inputRef}
                    className={css.saveAsInput}
                    type="text"
                    placeholder={t('scouting.actual-player-list.saved.save-as-name')}
                    value={saveAsName}
                    maxLength={50}
                    onChange={event => setSaveAsName(event.target.value)}
                />
                <button
                    ref={buttonRef}
                    className={cx(css.saveAsButton, 'btn btn-primary')}
                    disabled={saveAsName.length === 0}
                    onClick={() => createList()}
                >
                    {t('scouting.player-list.create-button')}
                </button>
            </div>
        </GenericModal>
    </>;

};

export default PlayerListButton;
